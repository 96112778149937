import supportedChains from "./chains";
export function ellipseAddress(address) {
  const width = 6;
  return `${address.slice(0, width)}...${address.slice(-4)}`;
}

export function getChainData(chainId) {
  const chainData = supportedChains.filter(
    (chain) => chain.chain_id === chainId
  )[0];

  if (!chainData) {
    return { isChainValid: false };
  }
  chainData.isChainValid = true;
  return chainData;
}

export const isValidWalletAccount = (selectedAddress, savedAddress) => {
  if (selectedAddress === savedAddress) {
    return true
  } else {
    return false
  }
}

export const shortAddress = (address) => {
  address = address.toLowerCase();
  return address.substring(0, 6) + '...' + address.substring(address.length - 4);
}

export const etherscanLink = (address) => {
  address = address.toLowerCase();
  const chain = getChainData(process.env.REACT_APP_CHAIN_ID);
  return 'https://' + chain.etherscan + '/address/' + address;
}