import React, { useEffect, useState, useContext } from 'react'
import { Container } from 'react-bootstrap';
import { PageTitle } from '../../components/Typography';
import { ConformationComponent, Step1, Step2, Step3, Step4, Step5 } from '../../components/SubmitArticleComponent'
import queryString from 'query-string';
import { useHistory } from 'react-router-dom'
import { finalSubmitArticle } from '../../action/article.action'
import { getUserDetails } from '../../action/auth.action';
import { GlobalContext } from '../../context/globalContext'

const SubmitArticlePage = () => {
    const history = useHistory();
    const { userData, setUserInfo } = useContext(GlobalContext);
    
    const [state, setState] = useState(localStorage.article ? JSON.parse(localStorage.article) : {
        content_provider: 'editor',
        content: '<div>Test Content</div>',
        content_editor: null,
        smartContractPayment: true
    });
    
    let { step } = queryString.parse(history.location.search);
    step = parseInt(step, 10);
    if (isNaN(step))
        step = 1;

    const [formStep, setFormStep] = useState(step)
    const onChangeStep = (fStep) => {

        setFormStep(fStep)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        if (step) {
            setFormStep(step)
            if (!localStorage.article && step !== 1) {
                history.push('/article/submit')
            }
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }, [step])// eslint-disable-line react-hooks/exhaustive-deps


    const onChangeState = (newState) => {
        setState({ ...state, ...newState });
        localStorage.article = JSON.stringify({ ...state, ...newState });
    }
    
    const onSubmitArticle = async () => {
        const tags = state.tags ? state.tags.map(res => res.text).join(',') : '';
        
        const formData = new FormData();
        formData.append('user_id', userData.id);
        let attrs = [];
        Object.keys(state).map(res => {
            if (res === 'tags') {
                formData.append('tags', tags)
            } else if (res.startsWith('attr_')) {
                attrs.push({ name: res, value: state[res] })
            } else if (res === 'content_editor') {
                formData.append('content_editor', JSON.stringify(state.content_editor))
            } else if (res === 'content') {
                formData.append('content', state.content)
            } else {
                formData.append(res, state[res])
            }
            return res;
        })

        formData.append('attrs', JSON.stringify(attrs));
        
        const { data } = await finalSubmitArticle(history.location.search, formData);
        if (data.status === 200) {
            setFormStep(6);
            const d = await getUserDetails();
            
            if(d.data.status === 200) {
                const { funds } = d.data.data;
                setUserInfo({
                    ...userData,
                    funds
                })
            }
            
            setTimeout(() => {
                localStorage.removeItem('article')
                history.push(`/author/${userData.username}/articles/${data.data.article.alias}`);
            }, 4000);
        }
    }
    
    return (<>
        <PageTitle text="Submit Article" />
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                {/* ------------ */}
                <form>
                    {formStep === 1 && <Step1 onChangeStep={onChangeStep} state={state} onChangeState={onChangeState} />}
                    {formStep === 2 && <Step2 onChangeStep={onChangeStep} state={state} onChangeState={onChangeState} />}
                    {formStep === 3 && <Step3 onChangeStep={onChangeStep} state={state} onChangeState={onChangeState} />}
                    {formStep === 4 && <Step4 onChangeStep={onChangeStep} state={state} onChangeState={onChangeState} />}
                    {formStep === 5 && <Step5 onChangeStep={onChangeStep} state={state} onChangeState={onChangeState} onSubmitArticle={onSubmitArticle} />}
                    {formStep === 6 && <ConformationComponent onChangeStep={onChangeStep} state={state} onChangeState={onChangeState} />}
                </form>
            </div>
        </Container >
    </>)
}
export default SubmitArticlePage;
