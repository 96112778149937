import React, { useState, useEffect } from 'react'
import { Container, Table, Form, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PageTitle } from '../components/Typography'
import { getAllTransactions } from '../action/billing.action'
import { useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import queryString from 'query-string'
import { etherscanLink, shortAddress } from '../web3/helpers/utils';
import { GlobalContext } from '../context/globalContext'

const Transactions = () => {

    const history = useHistory();
    const [ state, setState ] = useState({});
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState("");
    const { userData } = React.useContext(GlobalContext); 
    const retriveTransactions = async () => {
        const data = await getAllTransactions(history.location.search);
        if(data.data && data.data.status === 200) {
            setState({...data.data.data});
        }
    }

    useEffect(() => {
        let {page, filterCurrency} = queryString.parse(history.location.search)
        if(page) {
            setPage(page - 1);
        } else {
            setPage(0);
        }

        if(filterCurrency) {
            setFilter(filterCurrency);
        }
        retriveTransactions();
        // eslint-disable-next-line
    }, [history.location.search])
    
    const onChangeQuery = (status='page', e) => {
        const {pathname, search} = history.location;
        let query = search?queryString.parse(search):{};
        if(status!=='page') {
            if(status) {
                query = { ...query, filterCurrency: status};
            } else {
                delete query.filterCurrency;
            }
        } else {
            query.page = e.selected + 1;
        }
        query = queryString.stringify(query);
        history.push(`${pathname}?${query}`)
    }

    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Transactions" />
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <Row>
                    <Col md={8} className='mb-3'>
                        <div className='text-md-start mt-4 mt-md-0 text-font-size18 text-green'>
                            <strong>USD: {userData.funds?userData.funds:0.00}</strong><br/>
                            <strong>USDC: {userData.usdc_funds?userData.usdc_funds:0.00}</strong>
                        </div>
                    </Col>
                    <Col md={4} className='mb-3'>
                        <Form.Select value={filter} onChange={(e) => {
                            setFilter(e.target.value)
                            onChangeQuery(e.target.value);
                        }}>
                            <option value="">All Currencies</option>
                            <option value="USD">USD</option>
                            <option value="USDC">USDC</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Table hover striped responsive className='cm_tableBlue'>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Info</th>
                            <th>Amount</th>
                            <th>Date</th>
                        </tr>
                        {state.transactions && state.transactions.map((res, indx) => {
                            const renderData = typeof res.render === 'string'?{}:res.render;
                            const { start, author_address, usd_funds, usdc_funds,publisher_address, contract_address, middle_url, middle_name, end } = renderData;
                            let output = [];
                            // <a href=”link to etherscan transaction”>Deposit</a> into <a href=”link to etherscan contract url”>contract address</a>
                            if(start === 'Withdraw' || start === 'Deposite') {
                                if(contract_address) {
                                    if(start === 'Deposite') {
                                        output.push(<td>
                                            <a target="_blank" rel="noreferrer" href={etherscanLink(publisher_address)}>Deposit</a>  into <a target="_blank" rel="noreferrer" href={etherscanLink(contract_address)}>{shortAddress(contract_address)}</a>
                                        </td>);
                                    } else if(start === 'Withdraw'){
                                        output.push(<td>
                                            <a target="_blank" rel="noreferrer" href={etherscanLink(author_address)}>Withdraw</a>  From <a target="_blank" rel="noreferrer" href={etherscanLink(contract_address)}>{shortAddress(contract_address)}</a>
                                        </td>);
                                    }
                                } else {
                                    output.push(<td>
                                        {start} &nbsp;
                                        {middle_url && <Link to={middle_url?middle_url:''}>{middle_name}</Link>} &nbsp;
                                        {end}
                                     </td>);    
                                }
                            } else {
                                output.push(<td>
                                    {start} &nbsp;
                                    {middle_url && <Link to={middle_url?middle_url:''}>{middle_name}</Link>} &nbsp;
                                    {end}
                                 </td>);
                            }
                            return (<tr key={indx + 1}>
                                        <td>{res.id}</td>
                                        {output}  
                                        <td style={{color: res.color}}>{res.amount}</td>
                                        <td>{res.created}</td>
                                    </tr>)
                        })}
                    </tbody>
                </Table>
            </div>
            {state.total_pages > 0 && <ReactPaginate
                style={{color: 'red'}}
                className="cmn-Pagination mt-4"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={(e) => onChangeQuery('page', e)}
                pageRangeDisplayed={5}
                pageCount={state.total_pages?state.total_pages:0}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                forcePage={page}
            />}
        </Container>
    </>)
}

export default Transactions