import { toast as reactToastfy } from "react-toastify";

const options = {
    position: "bottom-right",
    //type: 'error',
    //transition: 'flip', // toast doesn't close if transition is applied
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: false
};

const Toast =  {
    success(text) {
        reactToastfy.success(text, options);
    },
    
    info(text) {
        reactToastfy.info(text, options);
    },

    error(text) {
        reactToastfy.error(text, options);
    },

    warning(text) {
        reactToastfy.warning(text, options);
    }
}

export default Toast;