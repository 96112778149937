import axios from '../helpers/axios';
import { APIEndpoints } from './endpoint'

export function inviteListing(query) {
    return axios.post(`${APIEndpoints.inviteListing}${query}`, {});
}

export function sendinvite() {
    return axios.post(`${APIEndpoints.sendinvite}`, {});
}

export function addinvite(data) {
    return axios.post(`${APIEndpoints.addinvite}`, data);
}

export function cancelInvite(id) {
    return axios.post(`${APIEndpoints.cancelInvite}?id=${id}`, {});
}

export function resendInvite(id) {
    return axios.post(`${APIEndpoints.resendInvite}?id=${id}`, {});
}

export function registerInvite(query, data) {
    return axios.post(`${APIEndpoints.registerInvite}${query}`, data);
}

export function requestApprove(query, data = {}) {
    return axios.post(`${APIEndpoints.requestApprove}${query}`, data);
}

