import React, { useState, useEffect } from 'react'
import { Container, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PageTitle } from '../components/Typography'
import { getBilling } from '../action/billing.action'
import { useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import queryString from 'query-string';

const Billing = () => {

    const history = useHistory();

    const [state, setState] = useState({});
    const [page, setPage] = useState(0);
    const onRetriveBilling = async () => {
        const { search } = history.location;
        const {data} = await getBilling(search);
        if(data.status === 200) {
            setState(data.data);
        }
    }

    useEffect(() => {
        let {page} = queryString.parse(history.location.search)
        if(page) {
            setPage(page - 1);
        } else {
            setPage(0);
        }
        onRetriveBilling();
    }, [history.location])// eslint-disable-line react-hooks/exhaustive-deps

    const onPageClick = (status='page', e) => {
        const {pathname, search} = history.location;
        let query = queryString.parse(search)
        if(status!=='page') {
            query = { ...query, ...status}
        } else {
            query.page = e.selected + 1;
        }
        query = queryString.stringify(query);
        history.push(`${pathname}?${query}`)
    }

    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Billing" />
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <Table bordered hover striped responsive className='cm_table'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Invoice</th>
                            <th>Period</th>
                            <th>Paid</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.invoice && state.invoice.map((res, index) => <tr key={index + 1}>
                            <td>{res.id}</td>
                            <td><Link to={`/billing/invoice?id=${res.id}`}>Invoice #{res.id}</Link></td>
                            <td>{res.period_from} - {res.period_to}</td>
                            <td>{parseInt(res.paid)===1?'Yes':'No'}</td>
                            <td>{res.date}</td>
                        </tr>)}
                    </tbody>
                </Table>
            </div>
            {state.total_pages > 0 && <ReactPaginate
                style={{color: 'red'}}
                className="cmn-Pagination mt-4"
                breakLabel="..."
                    nextLabel="next >"
                onPageChange={(e) => onPageClick('page', e)}
                pageRangeDisplayed={5}
                pageCount={state.total_pages?state.total_pages:0}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                forcePage={page}
            />}
        </Container>
    </>)
}

export default Billing