import React from 'react'

export const PageTitle = (props) => {
    return (<><div className='cm_pageTitle'><span>{props.text}</span></div></>)
}
export const Label = (props) => {
    if(props.className)
        return (<><label className={props.className}>{props.text}</label></>)
    return (<><label>{props.text}</label></>)
}
export const RadioButton = (props) => {
    return (<><label className='CM_RadioButton'><input type='radio' {...props} />{props.text}</label></>)
}
export const Checkbox = (props) => {
    return (<><label className='CM_checkbox'><input type='checkbox' {...props} />{props.text}</label></>)
}