import React, {useEffect} from 'react'
import {Redirect, Route} from 'react-router-dom'
import {GlobalContext} from '../context/globalContext'
import {getQueryStringObj} from '../Utils/AppUtill'
import {useHistory} from 'react-router-dom'
import queryString from 'query-string';

export default function ProtectedRoute(rest) {
  const queryObj = getQueryStringObj()
  const history = useHistory()

  const {isLoggedIn, setIsLoggedIn} = React.useContext(GlobalContext)
  
  useEffect(() => {    
    if(!isLoggedIn) {
      // window.localStorage.clear();
    }
    
    const {pathname, search} = history.location;
    if(!isLoggedIn && rest.auth && window.location.pathname !== '/invite/register') {
      let path = '/user/login';
      history.push(path)
    }
    
    if(isLoggedIn && window.location.pathname === '/user/login' && window.location.pathname === '/invite/register') {
      
      if(window.location.pathname === '/user/login' && search) {
        const query = queryString.parse(search)
        if(query?.return) {
          history.push(query?.return);
        } else {
          history.push('/')
        }
      } else {
        history.push('/')
      }
    }
    
    // if (!isLoggedIn && window.location.pathname !== '/') {
    //   history.push('/')
    // }
    // eslint-disable-next-line
  }, [setIsLoggedIn, isLoggedIn, window.location.pathname])
  
  const renderAppropriateRoute = props => {
    if (
      window.location.pathname !== '/' &&
      !queryObj.resetPwd &&
      !isLoggedIn
    ) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: {from: props.location},
          }}
        />
      )
    }
    
    return <Route {...rest} />
  }
  
  return (
    <Route
      {...rest}
      render={props => renderAppropriateRoute(props)}
    />
  )
}
