/* eslint-disable camelcase */
export const url = process.env.REACT_APP_API_URL;

export const APIEndpoints = {
  login: url + '/api/user/login',
  getOneArticle: url + '/api/author',
  getRecentArticle: url + '/api/recent_article',
  getArticleFeed: url + '/feed/article_feed',
  getAutors: url + '/api/author',
  getCategoryList: url + '/api/getAllCategory',
  followAuthor: url + '/api/feed/follow_author',
  addBookMark: url + '/api/article/bookmark',
  getBookmark: url + '/api/article/getBookmark',
  deleteBookMark: url + '/api/article/deleteBookmark',
  addAutorComment: url + '/api/author/comment',
  getAllAttributes: url + '/api/getAllAttributes',
  getAllLanguage: url + '/api/getAllLanguage',
  generateVerificationId: url + '/api/article/generateArticleVerificationId',
  uniquenesscheck: url + '/api/article/uniquenesscheck',
  articleCheckSubmittedContent: url + '/api/article/submited_content_check',
  finalSubmitArticle: url + '/api/article/submit_article',
  getAllDomains: url + '/api/getDomain',
  requestpermission: url + '/api/article/requestpermission',
  getSingleArticle: url + `/api/article/getSingleArticle`,
  updateArticlePrice: url + '/api/article/updatePrice',
  editArticleCheckCopyRightUrl: url + '/api/article/check_copy_right_url',
  check_unique: url + '/api/article/check_unique',
  updateArticle: url + '/api/article/updateArticle',
  getAllNotification: url + '/api/getAllNotification',
  fetchNotification: url + '/api/notifications',
  getAllMyArticles: url + '/api/getAllMyArticles',
  deleteArticle: url + '/api/article/delete',
  getAllMyrequests: url + '/api/getAllMyRequests',
  requestversions: url + '/api/article/requestversions',
  requestMessage: url + '/api/request/message',
  articleRequestCustomize: url + '/api/articlerequest/customize',
  articleRequestTerminateSubscription: url + '/api/request/terminatesubscription',
  articleRequestApprove: url + '/api/request/approverequest',
  articleRequestDisapprove: url + '/api/request/disapproverequest',
  articleRevision: url + '/api/article/revisions',
  articlerevisionrestore: url + '/api/article/revisionrestore',
  articlerevisiondelete: url + '/api/article/revisiondelete',
  articleRevisionCompare: url + '/api/article/revision_compare',
  articleCopyhistory: url + '/api/article/viewcopyhistory',
  articleCheckCopy: url + '/api/article/checkcopy',
  getBilling: url + '/api/getBilling',
  getInvoice: url + '/api/getInvoice',
  getAllTransactions: url + '/api/transactions',
  withdraw: url + '/api/withdraw',
  deposite: url + '/api/deposit',
  depositsuccess: url + '/api/depositsuccess',
  depositcancel: url + '/api/depositcancel',
  inviteListing: url + '/api/inviteListing',
  sendinvite: url + '/api/sendinvite',
  addinvite: url + '/api/addinvite',
  cancelInvite: url + '/api/cancelInvite',
  resendInvite: url + '/api/resendInvite',
  registerInvite: url + '/api/registerInvite',
  myBookmarks: url + '/api/user/bookmarks',
  myAccount: url + '/api/user/myaccount',
  bookmark: url + '/api/article/bookmark',
  splittesting: url + '/api/articlerequest/splittesting',
  negotiate: url + '/api/articlerequest/negotiate',
  negotiationAccept: url + '/api/articlerequest/negotiation_accept',
  preferences: url + '/api/user/preferences',
  negotiationDelete: url + `/api/articlerequest/negotiationDelete`,
  negotiationMessage: url + `/api/articlerequest/negotiationMessage`,
  getmutualcode: url + `/api/articlerequest/getmutualcode`,
  getmutualcodePost: url + `/api/articlerequest/getmutualcode_post`,
  requestgraph: url + `/api/analytics/requestgraph`,
  articlegraph: url + `/api/analytics/articlegraph`,
  viewCopy: url + `/api/article/viewcopy`,
  dragDropCopyright: url + '/api/article/drag_drop_copyright',
  deletecopyright: url + `/api/article/deletecopyright`,
  takedown: url + '/api/article/takedown',
  request2: url + `/api/request/request2`,
  lostPassword: url + '/api/user/lostpassword',
  resetPassword: url + '/api/user/reset',
  featuresArticleList: url + '/api/featuresArticleList',
  imageSearch: url + `/api/image/search`,
  imageInfo: url + `/api/image/flickr_image_info`,
  searchTags: url + `/api/article/searchTags`,
  analytics: url + `/api/analytics`,
  requestApprove: url + `/api/user/request_approve`,
  loginWithMetaMask: url + `/api/user/loginWithMetaMask`,
  disconnectWallet: url + `/api/user/DisconnectWallet`,
  web3nonce: url + '/api/user/web3nonce',
  connectWallet: url + '/api/user/connect_wallet',
  verifyEmail: url + '/api/user/verifyEmail',
  emailVerification: url + '/api/user/email_verification',
  updateVersion: url + '/api/article/update_version',
  versionStatus: url + '/api/article/version_status',
  addRevision: url + '/api/article/add_revision',
  nftGetContractDetails: url + '/api/nft/',
  fixDatabase: url + '/api/cron/fixDatabase',
  getUserDetails: url + '/api/user/getUserDetails',
  getUserFeed: url + '/api/feed/user_feed',
  logout: url + '/api/user/logout',
  deploySmartContract: url + '/api/articlerequest/deploy_smart_contract',
  isVerifyWallet: url + '/api/isVerifyWallet',
  authorWithdraw: url + '/api/articlerequest/author_withdraw',
  deposite: url + '/api/articlerequest/deposite',
  resetNotificationCount: url + '/api/notifications/reset_count'
}
