import axios from '../helpers/axios';
import { APIEndpoints } from './endpoint'

export function myBookmarks(query) {
    return axios.post(`${APIEndpoints.myBookmarks}${query}`, {});
}

export function myAccount(data) {
    return axios.post(`${APIEndpoints.myAccount}`, data);
}

export function preferences(data = {}) {
    return axios.post(`${APIEndpoints.preferences}`, data);
}

export function bookmark(query={}, data = {}) {
    return axios.post(`${APIEndpoints.bookmark}${query}`, data);
}