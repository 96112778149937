import { useSlate } from 'slate-react'
import { Button } from "react-bootstrap"
import { isMarkActive, toggleMark } from './utils'
import Icon from './../../components/Icon'

const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <Icon name={icon}/>
        </Button>
    )
}

export default MarkButton;