import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Col, Row, Button, Modal } from "react-bootstrap";
import Joi from 'joi'
import { Controller, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../../Utils/AppUtill'
import ErrorLabel from '../../widgets/ErrorLable';
import Icon from "../Icon";
import { login, getNonce } from '../../action/auth.action';
import { request2 } from "../../action/request.action";
import { GlobalContext } from '../../context/globalContext'
import toast from "../../helpers/toast"
import { useHistory } from "react-router-dom";
import MetamaskIcon from '../../assets/images/metamask.svg';
import CoinbaseIcon from '../../assets/images/coinbase.svg';
import { Web3Context } from '../../web3/contexts/web3Context'
import { signMessage } from '../../web3/functions'

const SigninModal = () => {
    const { signIn, setSignIn, setIsLoggedIn, setUserInfo, setUserToken } = useContext(GlobalContext);
    const { handleConnect } = useContext(Web3Context);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [rememberme, setRememberme] = useState(false);
    const [responseError, setResponseError] = useState({});

    const history = useHistory();

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                password: Joi.string()
                    .required()
                    .label('Password')
                    .messages(joiUpdatedMessage),
                username: Joi.string()
                    .required()
                    .label('Username')
                    .messages(joiUpdatedMessage),
            }),
        ),
    })

    const {
        handleSubmit: handleSubmitInvite,
        formState: { errors: errorsInvite },
        control: controlInvite,
        setValue,
        getValues,
        reset: resetInvite
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                firstname: Joi.string()
                    .required()
                    .label('firstname')
                    .messages(joiUpdatedMessage),
                lastname: Joi.string()
                    .required()
                    .label('lastname')
                    .messages(joiUpdatedMessage),
                email: Joi.string()
                    .required()
                    .email({ tlds: { allow: false } })
                    .label('Email')
                    .messages(joiUpdatedMessage),
                type: Joi.string()
                    .required()
                    .label('type')
                    .messages(joiUpdatedMessage),
                linkedin: Joi.string()
                    .required()
                    .label('linkedin')
                    .messages(joiUpdatedMessage),
                isWebsite: Joi.string()
                    .required()
                    .label('isWebsite')
                    .messages(joiUpdatedMessage),
                website: Joi
                    .when('isWebsite', { is: "yes", then: Joi.string().required() }),
            }),
        ),
    })

    const onClickToSubmit = async (values) => {
        const formData = new FormData();
        formData.append('username', values.username);
        formData.append('password', values.password);
        formData.append('rememberme', rememberme);
        const { data } = await login(formData);
        loginUser(data);
    }

    const loginUser = (data) => {
        if (data.status === 200) {
            const { success, user, errors, message } = data.data;
            if (success) {
                toast.success(message)
                setSignIn(null);
                setShow(false);
                setUserInfo(user);
                setUserToken(user.access_token);
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
                const { username, password } = errors;
                setResponseError({"username": `${username !== undefined ? username : ''} ${password !== undefined ? password : ''}`})
                setTimeout(() => {
                    setResponseError({});
                }, 3000)
            }
        }
    }

    useEffect(() => {
        resetInvite({});
        setValue('isWebsite', 'no', { shouldValidate: true })
        // eslint-disable-next-line
    }, [])

    const onClickToWebsite = (status) => {
        setValue('isWebsite', status, { shouldValidate: true })
    }

    const onClickToType = (status) => {
        setValue('type', status, { shouldValidate: true })
    }

    const handleClose = () => {
        setSignIn(null);
        setShow(false);
        resetInvite({ isWebsite: 'no' });
        reset({})
    }

    const handleShow = () => {
        resetInvite({ isWebsite: 'no' });
        reset({})
        setShow(true)
    };

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => {
        setShow1(true)
        setSignIn(null);
        setShow(false)
    };

    const handleShow2 = () => {
        setShow(true)
        reset({})
        setShow1(false)
    };

    useEffect(() => {
        if (signIn !== null) {
            handleShow();
        }
        // eslint-disable-next-line
    }, [signIn])

    const onSubmitInvite = async (values) => {
        const formData = new FormData();
        Object.keys(values).map(r => {
            if (r !== 'isWebsite')
                return formData.append(r, values[r]);
            
            return r;
        })

        const { data } = await request2(formData);
        if (data.status === 200) {
            toast.success(data.data.message);
            resetInvite({ isWebsite: 'no' });
            setShow(false);
            setShow1(false);
        }
    }

    const web3login = async (plugin) => {
        handleConnect(plugin).then(address => {
            getNonce(address).then(async nonce => {
                let signature = await signMessage(address, nonce.data.data.message, nonce.data.data.nonce)
                login({ web3: 1, signature: signature, address: address }).then(d => {
                    loginUser(d.data);
                })
            })
        });
    }

    return (
        <>
            <Button className="bg-trs bg-transparent border-0" onClick={handleShow}>
                <NavLink className='navlink' to="#">Sign In</NavLink>
            </Button>

            <Modal show={show} onHide={handleClose} className="model_width">
                <Modal.Header closeButton className="border-0">
                    <h2 className="popup-title text-green">Login</h2>
                </Modal.Header>
                <Modal.Body>
                    
                        <Row >
                            {/*<Col xl={6} lg={6} >
                                <div className="modal-social-login form-row-wide">

                                    <a className="mt-3" href="#"><img src={Linkdeinbtn} /></a>

                                    <a className="mt-4" href="#"><img src={Twitbtn} /></a>
                                </div>
                            </Col>*/}
                            <Col xl={12} lg={12}>
                                <div className="mylogin " >
                                    {Object.keys(responseError).length > 0 && <div className='cm_error text-green fs-13px'>
                                        <strong>Error: </strong>
                                        {responseError.username && <ErrorLabel
                                            msg={responseError.username && responseError.username}
                                        />}
                                    </div>}
                                    <form onSubmit={handleSubmit(onClickToSubmit)}>
                                        <div className="input-group">
                                            <Controller
                                                name="username"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-user"></i></span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Username"
                                                                aria-label="Username or email address"
                                                                aria-describedby="basic-addon1"
                                                                value={value}
                                                                onChange={e => {
                                                                    onChange(e)
                                                                }}
                                                            />
                                                            
                                                    </>
                                                )}
                                            />

                                        </div>
                                        <div className="mb-3">
                                            {errors.username && <ErrorLabel
                                                msg={errors.username && errors.username.message}
                                            />}
                                        </div>
                                        <div className="input-group">
                                            <Controller
                                                name="password"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                        <span className="input-group-text" id="basic-addon1"><i className="fa fa-unlock-alt"></i></span>                                                <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Password"
                                                            aria-label="Password"
                                                            aria-describedby="basic-addon1"
                                                            value={value}
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            {errors.password && <ErrorLabel
                                                msg={errors.password && errors.password.message}
                                            />}
                                        </div>
                                        <div className='Login_lg_btn mb-3'>
                                            <div><button className='btn-green' onClick={handleSubmit(onClickToSubmit)}>Login</button></div>
                                        </div>
                                    </form>
                                    <div className='Login_lg_btn mb-3'>
                                        <button className="btn btn-outline-black crypto-connect" onClick={() => web3login('metamask')}><img alt="" src={MetamaskIcon} />Connect With Metamask</button>
                                    </div>
                                    <div className='Login_lg_btn mb-3'>
                                        <button className="btn btn-outline-black crypto-connect" onClick={() => web3login('coinbase')}><img alt="" src={CoinbaseIcon} />Connect With Coinbase</button>
                                    </div>
                                    <div className="d-flex justify-content-between align-item-center">
                                        <div className='remeber_me '>
                                            <label className="CM_checkbox ml-5"><input type="checkbox" checked={rememberme} onChange={() => setRememberme(!rememberme)}/>Remember me</label>
                                        </div>
                                        <div className="lost_your_pass"> <p><a href="#/" onClick={() => {
                                            history.push('/user/lost-password');
                                            handleClose()

                                        }}>Lost your password?</a></p></div>
                                    </div>

                                    <p className="invitation_link">ContentMutual.com is an Invite Only Platform. If you don't have an invitation link, you may request one<a href="#/">
                                        <button onClick={handleShow1} className="bg-transparent border-0">here.</button></a></p>
                                </div>
                            </Col>
                        </Row>
                    
                </Modal.Body>
            </Modal>
            <Modal size="xl" show={show1} onHide={handleClose1} className="model_width">
                <Modal.Header closeButton className="text-center d-flex justify-content-center border-0">
                    <Modal.Title> <h2 className="page-title cover-wrapper">Request an Invitation to Join ContentMutual.com</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body className="model_body">
                    <Row className="padding_inner">
                        <Col xl={6} lg={6}>
                            <form onSubmit={handleSubmitInvite(onSubmitInvite)}>
                                <div className="req_an_invitation" >
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-user"></i></span>
                                            <Controller
                                                name="firstname"
                                                control={controlInvite}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={value}
                                                            className="form-control"
                                                            placeholder="First Name"
                                                            aria-label="First Name"
                                                            aria-describedby="basic-addon1"
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                        />

                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="errorInvite">
                                            {errorsInvite.firstname && <ErrorLabel
                                                msg={"First Name is required!"}
                                            />}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-user"></i></span>
                                            <Controller
                                                name="lastname"
                                                control={controlInvite}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={value}
                                                            className="form-control"
                                                            placeholder="Last Name"
                                                            aria-label="Last Name"
                                                            aria-describedby="basic-addon1"
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="errorInvite">
                                            {errorsInvite.lastname && <ErrorLabel
                                                msg={"Last Name is required!"}
                                            />}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-paper-plane"></i></span>
                                            <Controller
                                                name="email"
                                                control={controlInvite}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={value}
                                                            className="form-control"
                                                            placeholder="Email"
                                                            aria-label="Email"
                                                            aria-describedby="basic-addon1"
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="errorInvite">
                                            {errorsInvite.email && <ErrorLabel
                                                msg={errorsInvite.email && errorsInvite.email.message}
                                            />}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-user"></i></span>
                                            <input type="text" className="form-control" placeholder="What describes you the most?" aria-label="Email" disabled="disabled" aria-describedby="basic-addon1" />
                                        </div>
                                    </div>
                                    <div className="clearfix"> <br /> </div>
                                    <div align="center" className="content_both">
                                        <input type="radio" name="type" value="Content creator" onClick={() => onClickToType('Content creator')} />Content creator
                                        <input type="radio" name="type" value="Publisher" onClick={() => onClickToType('Publisher')} />Publisher
                                        <input type="radio" name="type" value="Both" onClick={() => onClickToType('Both')} />Both
                                    </div>
                                    <div className="errorInvite">
                                        {errorsInvite.type && <ErrorLabel
                                            msg={"Type is required!"}
                                        />}
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-paper-plane"></i></span>
                                            <Controller
                                                name="linkedin"
                                                control={controlInvite}
                                                render={({ field: { value, onChange } }) => (
                                                    <>

                                                        <input
                                                            type="text"
                                                            value={value}
                                                            className="form-control"
                                                            placeholder="What is Your LinkedIn Profile?"
                                                            aria-label="What is Your LinkedIn Profile?"
                                                            aria-describedby="basic-addon1"
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="errorInvite">
                                            {errorsInvite.linkedin && <ErrorLabel
                                                msg={"Linkedin is required!"}
                                            />}
                                        </div>
                                    </div>
                                    <div className="form-group"  >
                                        <div className="input-group mb-3">
                                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-paper-plane"></i></span>
                                            <input type="text" className="form-control" placeholder="Do you have a website?" aria-label="Email" disabled="disabled" aria-describedby="basic-addon1" />
                                        </div>
                                    </div>
                                    <div className="clearfix"> <br /> </div>
                                    <div align="center" className="content_both">
                                        <input type="radio" name="website" value="yes" onClick={() => onClickToWebsite('yes')} />Yes
                                        <input type="radio" name="website" defaultChecked value="no" onClick={() => onClickToWebsite('no')} />No
                                    </div>
                                    {getValues('isWebsite') === 'yes' && <div className="form-group">
                                        <div className="input-group mb-3">
                                            <Controller
                                                name="website"
                                                control={controlInvite}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={value}
                                                            className="form-control"
                                                            placeholder="Enter website URL"
                                                            aria-label="Enter website URL"
                                                            aria-describedby="basic-addon1"
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div><br />
                                        <div className="errorInvite">
                                            {errorsInvite.website && <ErrorLabel
                                                msg={"website URL is required!"}
                                            />}
                                        </div>
                                    </div>}
                                    <div className='Login_lg_btn '>
                                        <div><button className='btn-green' onClick={handleSubmitInvite(onSubmitInvite)}>Send Request</button></div>
                                    </div>
                                    <div className='remeber_me allredy_have_account'>
                                        <label className="CM_checkbox ">Already have an account?</label><a href="#/" onClick={handleShow2} >Sign In.</a>
                                    </div>
                                </div>
                            </form>
                        </Col>
                        <Col xl={6} lg={6} >
                            <div className="registration-right d-xs-none d-sm-none d-xl-block d-lg-block fadeInRight">
                                <h2>How It Works</h2>
                                <div className="icon_text_round">
                                    <div className="icon_round_custom">
                                        <Icon name="map" className="feather feather-map-pin" />
                                    </div>
                                    <div className="icon_round_right">
                                        <h4>Locate Content You Like</h4>
                                    </div>
                                </div>

                                <div className="icon_text_round">
                                    <div className="icon_round_custom">
                                        <Icon name="hospital"  className="feather feather-trello"/>
                                    </div>
                                    <div className="icon_round_right">
                                        <h4>Ask For Permission</h4>
                                    </div>
                                </div>
                                <div className="icon_text_round">
                                    <div className="icon_round_custom">
                                        <Icon name="share"  className="feather feather-share-2"/>
                                    </div>
                                    <div className="icon_round_right">
                                        <h4>Share On Your Website</h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default SigninModal;