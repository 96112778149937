import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Label } from '../components/Typography';
import Joi from 'joi'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import {joiUpdatedMessage} from '../Utils/AppUtill'
import ErrorLabel from '../widgets/ErrorLable';
import {lostPassword} from '../action/auth.action';
import toast from "../helpers/toast"

const LostPasswordPage = () => {
    
    const {
        handleSubmit,
        formState: {errors},
        control,
        reset
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                user_login: Joi.string()
                    .required()
                    .label('user_login')
                    .messages(joiUpdatedMessage)
            }),
        ),
    })

    const onClickToSubmit = async (values) => {
        const formData = new FormData();
        formData.append('user_login', values.user_login);
        
        const {data} = await lostPassword(formData);
        if(data.status === 200) {
            reset({user_login: ''});
            toast.success(data.data.message);
        }
    }
    
    return (<>
        <Container>
            <Col className='text-center'>
                <h1 className="page-title cover-wrapper green_color pt-5">My Account</h1>
            </Col>
        </Container>
        <Container className="py-5">
            <Row>
                {/* {console.log('errors.email', errors.email.message)} */}
                <Col md={12} sm={12} xs={12}>
                    <div className='CM_whiteBox user_login'>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <h2 className='green_color'>Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</h2>
                            </Col>
                        </Row>
                        {/* {Object.keys(errors).length > 0 && <div className='cm_error text-green fs-13px'>
                            <strong>Error: </strong> 
                            {errors.username && <><ErrorLabel
                                msg={errors.username && errors.username.message}
                            /> &nbsp;&nbsp;&nbsp;&nbsp;</>}

                            {errors.password && <ErrorLabel
                                msg={errors.password && errors.password.message}
                            />}
                        </div>} */}
                        <form className='row'>
                            <Col md={12} className='mb-3'>
                                <Controller
                                    name="user_login"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <Label text="Username or email" />
                                            <input 
                                                type="text" 
                                                className="cm_input"
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }} 
                                            />
                                            {errors.user_login && <ErrorLabel    
                                                msg={"Username or email is required!"}
                                            />}
                                        </>
                                    )}
                                />
                                
                            </Col>
                            <div className='login_cehckbox mb-3'>
                                <div><button className='btn-green' onClick={handleSubmit(onClickToSubmit)}>Reset Password</button></div>
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    </>)
}
export default LostPasswordPage;