import React, { useState, useEffect } from 'react'
import { Button, Container } from 'react-bootstrap';
import { Label } from '../components/Typography';
import { useHistory } from 'react-router-dom';
import { bookmark } from '../action/myaccount.action';
import toast from '../helpers/toast';

const EditBookmarkPage = () => {
    
    const history = useHistory();
    const [state, setState] = useState({});
    
    const onRetriveBookmark = async () => {
        const {data} = await bookmark(history.location.search);
        if(data.status === 200) {
            setState(data.data);
        }
    }

    useEffect(() => {
        onRetriveBookmark();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const onChangeInput = (e) => {
        setState({...state, [e.target.name]: e.target.value})
    }

    const onClickToSubmit = async () => {
        const formData = new FormData();
        Object.keys(state).map(res => formData.append(res, state[res]))
        const {data} = await bookmark(history.location.search, formData);
        if(data.status === 200) {
            toast.success(data.data.message);
            onRetriveBookmark()
        }
    }
    
    return (<>
        <Container className="py-5">
            <div className='text-center'>
                <h1 className="page-title cover-wrapper text-green pb-5">Edit Bookmark</h1>
            </div>
            <div className='CM_whiteBox'>
                <form>
                    <div className='mb-3'>
                        <Label text="Notes" />
                        <textarea rows="3" name="notes" value={state.notes} className='cm_input' onChange={onChangeInput}></textarea>
                    </div>
                    <Button variant='green' onClick={onClickToSubmit}>Save</Button>
                </form>
            </div>
        </Container>
    </>)
}
export default EditBookmarkPage;