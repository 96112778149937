import React, {useEffect, useState} from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { PageTitle } from '../components/Typography'
import { fetchNotification } from '../action/common.action'
import ReactPaginate from 'react-paginate';
import { RTimeAgo } from '../components/RTimeAgo';
import { formattedDate } from '../Utils/AppUtill';
import queryString from 'query-string';

const Notifications = () => {
    const history = useHistory();
    const [notification, setNotification] = useState([]);
    const [itemPerPage, setItemPerPage] = useState(0);
    const [page, setPage] = useState(0);
    const [notificationType] = useState({
        INFO: 1,
        SUCCESS: 2,
        FAILURE: 3,
    });

    useEffect(() => {
        let {page} = queryString.parse(history.location.search)
        if(page) {
            setPage(page - 1);
        } else {
            setPage(0);
        }
        (async() => {
            const { data } = await fetchNotification(history.location.search);
            if(data.status === 200) {
                setNotification(data.data.notification);
                setItemPerPage(data.data.total_page);
            }
        })()
    }, [history.location])


    const onPageClick = (e) => {
        history.push(`notification?page=${e.selected + 1}`)
    }

    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Notifications" />
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <Row>
                    <Col lg={12}>
                        <Table hover striped responsive className='cm_tableBlue'>
                            <tbody>
                                <tr>
                                    <th>Notifications</th>
                                </tr>
                                {notification && notification.map((res, indx) => {
                                    let color = "";
                                    switch (parseInt(res.type_id)) {
                                      case notificationType.FAILURE:
                                        color = "#ff4444";
                                        break;
                                      case notificationType.SUCCESS:
                                        color = "#adffaf";
                                        break;
                                      case notificationType.INFO:
                                        color = "#47bcf0";
                                        break;
                                      default:
                                        color="";
                                    }
                                    return (<tr key={indx + 1}>
                                                <td>
                                                    <Link to={res.url?res.url:''} className='fs-13px fw-bold text-dark'>
                                                        <span className='text' style={{color: color}}>| </span>
                                                        {/* <img className="cm_img32px" src="https://contentmutual.com/upload/userimage/16339571708924c6de0b207ad0c58d35a10fa5a7be563f.jpg" />  */}
                                                        {res.text}
                                                    </Link>
                                                    <small className='text-dark'><RTimeAgo date={formattedDate(res.created)}/></small>
                                                </td>
                                            </tr>)
                                })}
                                {/* <tr>
                                    <td><Link to="/" className='fs-13px fw-bold text-dark'><span className='text-blue'>| </span><img className="cm_img32px" src="https://contentmutual.com/upload/userimage/16339571708924c6de0b207ad0c58d35a10fa5a7be563f.jpg" /> Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Link><small className='text-dark'> 2 years ago</small></td>
                                </tr>
                                <tr>
                                    <td><Link to="/" className='fs-13px fw-bold text-dark'><span className='text-blue'>| </span><img className="cm_img32px" src="https://contentmutual.com/upload/userimage/16339571708924c6de0b207ad0c58d35a10fa5a7be563f.jpg" /> Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Link><small className='text-dark'> 2 years ago</small></td>
                                </tr>
                                <tr>
                                    <td><Link to="/" className='fs-13px fw-bold text-dark'><span className='text-blue'>| </span><img className="cm_img32px" src="https://contentmutual.com/upload/userimage/16339571708924c6de0b207ad0c58d35a10fa5a7be563f.jpg" /> Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Link><small className='text-dark'> 2 years ago</small></td>
                                </tr>
                                <tr>
                                    <td><Link to="/" className='fs-13px fw-bold text-dark'><span className='text-blue'>| </span><img className="cm_img32px" src="https://contentmutual.com/upload/userimage/16339571708924c6de0b207ad0c58d35a10fa5a7be563f.jpg" /> Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Link><small className='text-dark'> 2 years ago</small></td>
                                </tr> */}
                            </tbody>
                        </Table>
                    </Col>
                    {/* <Col lg={4}>
                        <div className="card card-border">
                            <div className="card-header fs-13px"><i className="fa fa-user"></i> Author Box</div>
                            <div className="card-body">
                                <img className="cm_img60pxrounded me-2 mb-2 pull-left" src="https://contentmutual.com/upload/userimage/16339571708924c6de0b207ad0c58d35a10fa5a7be563f.jpg" alt="" /> <div className='fs-13px text-green'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore .</div><br />
                                <div className="row pb-4">
                                    <div className="col-lg-6">
                                        <Button variant='link' className="p-0 fs-13px text-decoration-none text-dark cm_linkedin_connect"><span className='linkedinIcon' />reidhoffman</Button>
                                    </div>

                                    <div className="col-lg-6">
                                        <iframe title="Twitter Follow Button" className='h-25px' src="https://platform.twitter.com/widgets/follow_button.8f764d5bd2778f88121d31d7d8d8e1e3.en.html#dnt=false&amp;id=twitter-widget-0&amp;lang=en&amp;screen_name=twitterdev&amp;show_count=false&amp;show_screen_name=true&amp;size=m&amp;time=1642760323132" data-screen-name="twitterdev"></iframe>
                                    </div>
                                </div>
                                <Table bordered className='cm_table shadow-none'>
                                    <tbody>
                                        <tr>
                                            <td>Total Days Active:</td><td>30</td>
                                        </tr>
                                        <tr>
                                            <td>Total Page Views:</td><td>200</td>
                                        </tr>
                                        <tr>
                                            <td>Average Daily Page Views:</td><td>30</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col> */}
                </Row>
            </div>
            {itemPerPage > 0 && <ReactPaginate
                style={{color: 'red'}}
                className="cmn-Pagination"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={onPageClick}
                pageRangeDisplayed={5}
                pageCount={itemPerPage?itemPerPage:0}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                forcePage={page}
            />}
        </Container>
    </>)
}

export default Notifications