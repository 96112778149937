import React from "react";
import PropTypes from "prop-types";

const Search = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /></svg>
);
const C1 = ({ className }) => (
    <svg className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" >
        <g>
            <circle fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" cx="32" cy="19" r="4" />
            <path fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" d="M45,21c0-7.7-5.8-14-13-14s-13,6.3-13,14   c0,10,13,26,13,26S45,31,45,21z" />
        </g>
        <polyline fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" points="21,37 12,37 1,57 32,57 63,57 52,37 43,37   " />
    </svg>
);
const C2 = ({ className }) => (
    <svg className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" >
        <g>
            <path fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" d="M57,18c-1.5,1.5-2.7,2-5,2c-4.6,0-8-3.4-8-8   c0-2.3,0.5-3.5,2-5l-6-6L1,40l6,6c1.5-1.5,2.7-2,5-2c4.6,0,8,3.4,8,8c0,2.3-0.5,3.5-2,5l6,6l39-39L57,18z" />
            <line fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" x1="26" y1="15" x2="30" y2="19" />
            <line fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" x1="45" y1="34" x2="49" y2="38" />
        </g>
        <line fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" x1="32" y1="21" x2="36" y2="25" />
        <line fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" x1="39" y1="28" x2="43" y2="32" />
    </svg>
);
const C3 = ({ className }) => (
    <svg className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" >
        <rect x="1" y="7" fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" width="62" height="50" />
        <line fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" x1="1" y1="15" x2="63" y2="15" />
        <line fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" x1="10" y1="11" x2="6" y2="11" />
        <line fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" x1="18" y1="11" x2="14" y2="11" />
        <line fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" x1="26" y1="11" x2="22" y2="11" />
        <polygon fill="none" stroke="#3396D1" strokeWidth="2" strokeMiterlimit="10" points="32.2,41.2 24.8,46.9 27.8,37.9 21,32.6   29.2,32.6 32.2,24 35.2,32.6 43.5,32.6 36.8,37.9 39.8,46.9 " />
    </svg>
);
const ResizeFull = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z" />
    </svg>
);
const FullScreen = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
    </svg>
);
const ResizeSmall = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707zM15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707z" />
    </svg>
);
const Close = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
    </svg>
);
const Check = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="11.502" height="8.002" fill="currentColor" viewBox="0 0 11.502 8.002">
        <path id="check2" d="M13.854,3.646a.5.5,0,0,1,0,.708l-7,7a.5.5,0,0,1-.708,0l-3.5-3.5a.5.5,0,0,1,.708-.708L6.5,10.293l6.646-6.647a.5.5,0,0,1,.708,0Z" transform="translate(-2.499 -3.499)" />
    </svg>
);

const Compass = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"></polygon></svg>
);

const Bell = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
    </svg>
);

const EditIcon = ({ className }) => (
    <svg className={className} fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g id="create-outline" transform="translate(-63 -48)">
            <path id="Path_1" data-name="Path 1" d="M84,134v11.5a2.5,2.5,0,0,1-2.5,2.5h-15a2.5,2.5,0,0,1-2.5-2.5v-15a2.5,2.5,0,0,1,2.5-2.5H76.967" transform="translate(0 -75)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path id="Path_2" data-name="Path 2" d="M223.745,48.328a1,1,0,0,0-1.451-.035l-.773.769a.5.5,0,0,0,0,.707l.709.708a.5.5,0,0,0,.709,0l.754-.75A1.016,1.016,0,0,0,223.745,48.328Zm-3.788,2.3L208.675,61.887a.562.562,0,0,0-.144.246l-.522,1.554a.244.244,0,0,0,.3.3l1.553-.522a.563.563,0,0,0,.246-.144l11.262-11.284a.562.562,0,0,0,0-.791l-.622-.625A.562.562,0,0,0,219.957,50.625Z" transform="translate(-134.999 0)" />
        </g>
    </svg>
);

const Map = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
        <circle cx="12" cy="10" r="3"></circle>
    </svg>
)

const Hospital = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
        <rect x="7" y="7" width="3" height="9"></rect>
        <rect x="14" y="7" width="3" height="5"></rect>
    </svg>
)

const Share = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="18" cy="5" r="3"></circle>
        <circle cx="6" cy="12" r="3"></circle>
        <circle cx="18" cy="19" r="3"></circle>
        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
    </svg>
)

const DownArrow = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="27.828" height="14.914" viewBox="0 0 27.828 14.914">
        <path id="chevron-down-outline" d="M112,184l12.5,12.5L137,184" transform="translate(-110.586 -182.586)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>

);
const Bold = ({ className }) => (
    <svg className={className} height="24" fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M576 661.333H426.667v-128H576c35.413 0 64 28.587 64 64 0 35.414-28.587 64-64 64m-149.333-384h128c35.413 0 64 28.587 64 64 0 35.414-28.587 64-64 64h-128m238.933 55.04C706.987 431.36 736 384 736 341.333c0-96.426-74.667-170.666-170.667-170.666H298.667V768H599.04c89.6 0 158.293-72.533 158.293-161.707 0-64.853-36.693-120.32-91.733-145.92z" /></svg>
);

const Italic = ({ className }) => (
    <svg className={className} height="24" fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M426.667 170.667v128h94.293L375.04 640H256v128h341.333V640H503.04l145.92-341.333H768v-128H426.667z" /></svg>
);

const Underline = ({ className }) => (
    <svg className={className} height="24" fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M232 872h560v-80H232v80m280-160c132.4 0 240-107.6 240-240V152H652v320c0 77.2-62.8 140-140 140s-140-62.8-140-140V152H272v320c0 132.4 107.6 240 240 240z" /></svg>
);

const Code = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>
);

const Quote = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" stroke="currentColor" fill="currentColor"><path d="M9.983 3v7.391c0 5.704-3.731 9.57-8.983 10.609l-.995-2.151c2.432-.917 3.995-3.638 3.995-5.849h-4v-10h9.983zm14.017 0v7.391c0 5.704-3.748 9.571-9 10.609l-.996-2.151c2.433-.917 3.996-3.638 3.996-5.849h-3.983v-10h9.983z" /></svg>
);

const NumberedList = ({ className }) => (
    <svg className={className} style={{ "width": "1.2em", "height": "1.2em", "fill": "currentColor" }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M384 832l640 0 0 128-640 0zM384 448l640 0 0 128-640 0zM384 64l640 0 0 128-640 0zM192 0l0 256-64 0 0-192-64 0 0-64zM128 526.00832l0 49.99168 128 0 0 64-192 0 0-146.00192 128-60.0064 0-49.99168-128 0 0-64 192 0 0 146.00192zM256 704l0 320-192 0 0-64 128 0 0-64-128 0 0-64 128 0 0-64-128 0 0-64z" /></svg>
);

const List = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-list">
        <line x1="8" y1="6" x2="21" y2="6"></line>
        <line x1="8" y1="12" x2="21" y2="12"></line>
        <line x1="8" y1="18" x2="21" y2="18"></line>
        <line x1="3" y1="6" x2="3.01" y2="6"></line>
        <line x1="3" y1="12" x2="3.01" y2="12"></line>
        <line x1="3" y1="18" x2="3.01" y2="18"></line>
    </svg>
);

const BulletedList = ({ className }) => (
    <svg className={className} style={{ "width": "1.7em", "height": "1.7em", "fill": "currentColor" }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M298.667 213.333v85.334H896v-85.334M298.667 554.667H896v-85.334H298.667m0 341.334H896v-85.334H298.667m-128-14.08c-31.574 0-56.747 25.6-56.747 56.747s25.6 56.747 56.747 56.747c31.146 0 56.746-25.6 56.746-56.747s-25.173-56.747-56.746-56.747m0-519.253c-35.414 0-64 28.587-64 64s28.586 64 64 64c35.413 0 64-28.587 64-64s-28.587-64-64-64m0 256c-35.414 0-64 28.587-64 64s28.586 64 64 64c35.413 0 64-28.587 64-64s-28.587-64-64-64z" /></svg>
);

const AlignRight = ({ className, style }) => (
    <svg className={className} style={style} width="20px" height="20px" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1 1h18v2H1V1zm0 8h18v2H1V9zm0 8h18v2H1v-2zM7 5h12v2H7V5zm0 8h12v2H7v-2z" /></svg>
);

const AlignLeft = ({ className, style }) => (
    <AlignRight style={{ transform: 'rotate(180deg)' }} />
);

const AlignCenter = ({ className }) => (
    <svg className={className} width="20px" height="20px" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1 1h18v2H1V1zm0 8h18v2H1V9zm0 8h18v2H1v-2zM4 5h12v2H4V5zm0 8h12v2H4v-2z" /></svg>
);

const Image = ({ className }) => (
    <svg className={className} viewBox="0 0 350 350" width="20px" height="20px" fill="currentColor" enableBackground="new 0 0 350 350">
        <path d="M5,350h340V0H5V350z M25,330v-62.212h300V330H25z M179.509,247.494H60.491L120,171.253L179.509,247.494z   M176.443,211.061l33.683-32.323l74.654,69.05h-79.67L176.443,211.061z M325,96.574c-6.384,2.269-13.085,3.426-20,3.426  c-33.084,0-60-26.916-60-60c0-6.911,1.156-13.612,3.422-20H325V96.574z M25,20h202.516C225.845,26.479,225,33.166,225,40  c0,44.112,35.888,80,80,80c6.837,0,13.523-0.846,20-2.518v130.306h-10.767l-104.359-96.526l-45.801,43.951L120,138.748  l-85.109,109.04H25V20z" />
    </svg>
)

const Link = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"></path><line x1="8" y1="12" x2="16" y2="12"></line></svg>
)

const LinkBreak = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"></path>
        <line x1="8" y1="12" x2="16" y2="12"></line>
        <line x1="4" y1="20" x2="20" y2="4"></line>
    </svg>
)

const Video = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path><polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon></svg>
)

const Mail = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path fill="#fff" d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
        <polyline color="#048c96" points="22,6 12,13 2,6"></polyline>
    </svg>
)

const Info = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff" stroke="currentColor" color="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <line color="#3e96d1" x1="12" y1="16" x2="12" y2="12"></line>
        <line color="#3e96d1" x1="12" y1="8" x2="12.01" y2="8"></line>
    </svg>
)

const Icon = props => {
    let El = Search;
    switch (props.name) {
        case "search":
            El = Search;
            break;
        case "c1":
            El = C1;
            break;
        case "c2":
            El = C2;
            break;
        case "c3":
            El = C3;
            break;
        case "resizefull":
            El = ResizeFull;
            break;
        case "fullscreen":
            El = FullScreen;
            break;
        case "resizesmall":
            El = ResizeSmall;
            break;
        case "close":
            El = Close;
            break;
        case "check":
            El = Check;
            break;
        case "compass":
            El = Compass;
            break;
        case "editicon":
            El = EditIcon;
            break;
        case "downarrow":
            El = DownArrow;
            break;
        case "bold":
            El = Bold;
            break;
        case "italic":
            El = Italic;
            break;
        case "underline":
            El = Underline;
            break;
        case "code":
            El = Code;
            break;
        case "quote":
            El = Quote;
            break;
        case "list":
            El = List;
            break;
        case "numbered-list":
            El = NumberedList;
            break;
        case "bulleted-list":
            El = BulletedList;
            break;
        case "align-right":
            El = AlignRight;
            break;
        case "align-left":
            El = AlignLeft;
            break;
        case "align-center":
            El = AlignCenter;
            break;
        case "image":
            El = Image;
            break;
        case "link":
            El = Link;
            break;
        case "link-break":
            El = LinkBreak;
            break;
        case "video":
            El = Video;
            break;
        case "mail":
            El = Mail;
            break;
        case "info":
            El = Info;
            break;
        case "map":
            El = Map;
            break;
        case "hospital":
            El = Hospital;
            break;
        case "share":
            El = Share;
            break;
        case "bell":
            El = Bell;
            break;
        default:
            El = Search;
            break;
    }
    return (
        <El
            className={`rvt-icon rat-icon-${props.name} ${props.className}`.trim()}
        />
    );
};

Icon.propTypes = {
    name: PropTypes.string
};

export default Icon;