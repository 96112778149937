import algoliasearch from 'algoliasearch';
export const client = algoliasearch(process.env.REACT_APP_ALGOLIAL_APPLICATIONID, process.env.REACT_APP_ALGOLIAL_APIKEY);

export const get = (a, b, c) => {
  const retValue = c !== undefined ? c : null
  return a.reduce(
    (obj, key) =>
      obj && key && obj[key] !== null && obj[key] !== undefined
        ? obj[key]
        : retValue,
    b,
  )
}

export const formattedDate = (unix_timestamp) => {
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  unix_timestamp = parseInt(unix_timestamp);
  let date = new Date(unix_timestamp * 1000);
  // Will display time in 10:30:23 format
  return date;
}

export const findKeyFromArrayOfObject = (
  key,
  keynameofObject,
  data,
) => {
  if (data && data.length > 0) {
    const find = data.find(k => k[keynameofObject] === key)
    return find
  }

  return []
}

export const getQueryStringObj = () => {
  const url = new URL(window.location.href)
  return Object.fromEntries(new URLSearchParams(url.search))
}

export function getQuery(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return query
}

export const joiUpdatedMessage = {
  'string.empty': `Please enter a {{#label}}.`,
  'string.min': `{#label} should have a minimum length of {#limit}`,
  'array.min': `should have minimum {#limit} {#label}`,
  'array.max': `should have maximum {#limit} {#label}`,
  'string.max': `{#label} should have a max length of {#limit}`,
  'any.required': `{#label} is required`,
  'string.email': `Please enter a valid email address.`,
  'string.image': `Image size limit exceeded!`,
  '.number.min': `should have minimum {#limit} {#label}`,
  '.number.max': `should have maximum {#limit} {#label}`,
  'any.only': 'New Password and {{#label}} do not match.',
}


export const removeDoubleQuotes = string => {
  return string.replace(/"/g, '')
}
