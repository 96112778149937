import React, {useState, useContext, useEffect} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Label } from '../components/Typography';
import { GlobalContext } from '../context/globalContext';
import { addBookMark, getBookmark, deleteBookMark  } from '../action/article.action';

export const BookMarkComponent = ({type, id, isBookmarked, onCallBack}) => {
    const [show, setShow] = useState(false);
    const [notes, setNotes] = useState('');
    const [bookMarkDetails, setBookMarkDetails] = useState({})
    const { isLoggedIn, setSignIn } = useContext(GlobalContext)

    const onChangeText = (e) => {
        setNotes(e.target.value)
    }

    const onCloseModal = () => {
        setShow(false)
    } 

    const onHandleBookMark = () => {
        if(!isLoggedIn) {
            setSignIn(true)
        } else {
            setShow(true)
        }
    }
    
    useEffect(() => {
        if(show) {
            (async () => {
                if(id) {
                    const {data} = await getBookmark(id, type);
                    if(data.status === 200) {
                        setBookMarkDetails(data.data)
                        setNotes(data.data.notes)
                    }
                }
            })()
        }
    }, [isBookmarked, show, id]) // eslint-disable-line react-hooks/exhaustive-deps

    const onRemoveBookMark = async () => {
        const { id } = bookMarkDetails;
        
        if(id) {
            const {data} = await deleteBookMark(id)
            if(data.status === 200) {
                setBookMarkDetails({});
                setNotes("")
                onCallBack(false)
            }
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('id', id);
        formData.append('type', type);
        formData.append('notes', notes);
        const {data} = await addBookMark(formData);
        if(data.status === 200) {
            onCallBack(true)
            onCloseModal();
        }
    }
    
    return (<>
        <Button variant='link' className='p-0' onClick={onHandleBookMark}>
            {isBookmarked && <i className="fa fa-heart" aria-hidden="true"></i>}
            {!isBookmarked && <i className="fa fa-heart-o fs-18px text-danger" aria-hidden="true"></i>}
        </Button>
        < Modal show={show} onHide={onCloseModal} size="xl" centered >
            <Modal.Header className='border-0' closeButton>
                <div className='fs-20px text-green fs-md-24px'>Bookmark This Listing</div>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className='mb-3'>
                        {isBookmarked && notes && <Button variant='error' onClick={onRemoveBookMark} >Remove Bookmark</Button>}
                        <Label text="Notes:" />
                        <textarea rows="3" value={notes} onChange={onChangeText} className='cm_input'></textarea>
                    </div>
                    <Button variant='green' onClick={onSubmit} >{isBookmarked?'Update':'Add'} Bookmark</Button>
                </form>
            </Modal.Body>
        </Modal >
    </>)
}