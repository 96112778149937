import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Label } from '../components/Typography';
import Joi from 'joi'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import {joiUpdatedMessage} from '../Utils/AppUtill'
import ErrorLabel from '../widgets/ErrorLable';
import {request2} from '../action/request.action';
import { useHistory } from 'react-router-dom';
import toast from "../helpers/toast"

const RequestInvitationPage = () => {
    const history = useHistory();

    const {
        handleSubmit,
        setValue,
        formState: {errors},
        control,
        getValues,
        reset
      } = useForm({
        resolver: joiResolver(
            Joi.object({
                firstname: Joi.string()
                    .required()
                    .label('firstname')
                    .messages(joiUpdatedMessage),
                lastname: Joi.string()
                    .required()
                    .label('lastname')
                    .messages(joiUpdatedMessage),
                email: Joi.string()
                    .required()
                    .email({tlds:{allow: false}})
                    .label('Email')
                    .messages(joiUpdatedMessage),
                type: Joi.string()
                    .required()
                    .label('type')
                    .messages(joiUpdatedMessage),
                linkedin: Joi.string()
                    .required()
                    .label('linkedin')
                    .messages(joiUpdatedMessage),
                isWebsite: Joi.string()
                    .required()
                    .label('isWebsite')
                    .messages(joiUpdatedMessage),
                website: Joi
                      .when('isWebsite', { is: "yes", then: Joi.string().required() }),
            }),
        ),
    })
    
    const onClickToSubmit = async (values) => {
        // console.log("values", values);
        const formData = new FormData();
        Object.keys(values).map(r => {
            if(r === 'website') {
                formData.append(r, values[r] === 'yes'?1:0);
            } else {
                formData.append(r, values[r]);
            }
            return r
        })
        
        const {data} = await request2(formData);
        if(data.status === 200) {
            toast.success(data.data.message);
            history.push('/');
            reset({});
        }
    }

    useEffect(() => {
        setValue('isWebsite', 'no', { shouldValidate: true })
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    
    const onClickToWebsite = (status) => {
        setValue('isWebsite', status, { shouldValidate: true })
    }

    const onClickToType = (status) => {
        setValue('type', status, { shouldValidate: true })
    }

    return (<>
        <Container>
            <Col className='text-center'>
                <h1 className="page-title cover-wrapper green_color pt-5">Request an Invitation to Join ContentMutual.com</h1>
            </Col>
        </Container>
        <Container className="py-5">
            <Row>
                {/* {console.log('errors.email', errors.email.message)} */}
                <Col md={12} sm={12} xs={12}>
                    <div className='CM_whiteBox user_login'>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <h2 className='green_color'>Sorry, ContentMutual.com is an Invite Only Platform, please fill out the form below to request permission.</h2>
                            </Col>
                        </Row>
                        {/* {Object.keys(errors).length > 0 && <div className='cm_error text-green fs-13px'>
                            <strong>Error: </strong> 
                            {errors.username && <><ErrorLabel
                                msg={errors.username && errors.username.message}
                            /> &nbsp;&nbsp;&nbsp;&nbsp;</>}

                            {errors.password && <ErrorLabel
                                msg={errors.password && errors.password.message}
                            />}
                        </div>} */}
                        <form className='row' onSubmit={handleSubmit(onClickToSubmit)}>
                            <Col md={12} className='mb-3'>
                                <Controller
                                    name="firstname"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <Label text="First Name*" />
                                            <input 
                                                type="text" 
                                                className="cm_input"
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }} 
                                            />
                                            {errors.firstname && <ErrorLabel
                                                msg={"First Name is required!"}
                                            />}
                                        </>
                                    )}
                                />
                                
                            </Col>
                            <Col md={12} className='mb-3'>
                                <Controller
                                    name="lastname"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <Label text="Last Name *" />
                                            <input 
                                                type="text" 
                                                className="cm_input"
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }} 
                                            />
                                            {errors.lastname && <ErrorLabel
                                                msg={"Last Name is required!"}
                                            />}
                                        </>
                                    )}
                                />
                            </Col>
                            <Col md={12} className='mb-3'>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <Label text="Email *" />
                                            <input 
                                                type="text" 
                                                className="cm_input"
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }} 
                                            />
                                            {errors.email && <ErrorLabel
                                                msg={errors.email && errors.email.message}
                                            />}
                                        </>
                                    )}
                                />
                            </Col>
                            <Col md={12} className='mb-3'>
                                <Label text="What describes you the most? *" />
                                <div className="contentInvite_both">
                                    <input type="radio" name="type" value="Content creator" onClick={() => onClickToType('Content creator')} className='ms-0' />Content creator
                                    <input type="radio" name="type" value="Publisher" onClick={() => onClickToType('Publisher')}/>Publisher
                                    <input type="radio" name="type" value="Both" onClick={() => onClickToType('Both')} />Both
                                </div>
                                {errors.type && <ErrorLabel
                                    msg={"Type is required!"}
                                />}
                            </Col>
                            <Col md={12} className='mb-3'>
                                <Controller
                                    name="linkedin"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <Label text="What is Your LinkedIn Profile?" />
                                            <input 
                                                type="text" 
                                                className="cm_input"
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }} 
                                            />
                                            {errors.linkedin && <ErrorLabel
                                                msg={"Linkedin is required!"}
                                            />}
                                        </>
                                    )}
                                />
                            </Col>
                            <Col md={12} className='mb-3'>
                                <Label text="Do you have a website? *" />
                                <div className="contentInvite_both">
                                    <input type="radio" name="website" value="yes" onClick={() => onClickToWebsite('yes')} className='ms-0' />Yes
                                    <input type="radio" name="website" value="no" defaultChecked onClick={() => onClickToWebsite('no')}/>No
                                </div>
                            </Col>
                            {getValues('isWebsite') === 'yes' && <Col md={12} className='mb-3'>
                                <Controller
                                    name="website"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <Label text="Website Url" />
                                            <input 
                                                type="text" 
                                                className="cm_input"
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }} 
                                            />
                                            {errors.website && <ErrorLabel
                                                msg={"Website URL is required!"}
                                            />}
                                        </>
                                    )}
                                />
                            </Col>}
                        </form>
                        <div className='login_cehckbox mb-3'>
                            <div><button className='btn-green' onClick={handleSubmit(onClickToSubmit)}>Send Request</button></div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </>)
}
export default RequestInvitationPage;