import {
    Editor,
    Transforms,
    Element as SlateElement,
    Text,
    Range
} from 'slate'
import escapeHtml from 'escape-html'
import isUrl from 'is-url'
import { jsx } from 'slate-hyperscript'
import ReactDOMServer from "react-dom/server"

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const toggleBlock = (editor, format, isActive) => {
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n => {
            return !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                LIST_TYPES.includes(n.type)
        },
        split: true,
    });
    const newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        align: 'right'
    }

    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isBlockActive = (editor, format) => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
        })
    )

    return !!match
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const withImages = editor => {
    const { isVoid } = editor

    editor.isVoid = element => {
        return ['image', 'add-image'].indexOf(element.type) !== -1 ? true : isVoid(element)
    }

    return editor
}

const withVideos = editor => {
    const { isVoid } = editor

    editor.isVoid = element => {
        return ['video', 'add-video'].indexOf(element.type) !== -1 ? true : isVoid(element)
    }

    return editor
}

const insertImage = (editor, url, title = null, path = null) => {
    const text = { text: '' }
    const all = [{ type: 'image', url, children: [text] }, ...title]
    const options = { at: path };
    Transforms.insertNodes(editor, all, options)
}

const insertVideo = (editor, url, title = null, path = null) => {
    const text = { text: '' }
    const all = [{ type: 'video', url, children: [text] }, ...title]
    const options = { at: path };
    Transforms.insertNodes(editor, all, options)
}

const insertNFT = (editor, url, title) => {
    const text = { text: '' }
    const image = { type: 'nft', url, title, children: [text] }
    Transforms.insertNodes(editor, image)
}

const isImageUrl = url => {
    if (!url) return false
    //if (!isUrl(url)) return false
    const ext = new URL(url).pathname.split('.').pop()
    return ['jpg', 'jpeg', 'png'].includes(ext)
}

const editorToHTML = (node) => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text)
        if (node.bold) {
            string = `<strong>${string}</strong>`
        }
        if (node.code) {
            string = `<code>${string}</code>`
        }
        if (node.italic) {
            string = `<em>${string}</em>`
        }
        if (node.underline) {
            string = `<u>${string}</u>`
        }
        return string
    }

    const children = node.children.map(n => editorToHTML(n)).join('')

    switch (node.type) {
        case 'block-quote':
            return `<blockquote><p>${children}</p></blockquote>`
        case 'paragraph':
            if (node.image_description === true)
                return `<p class="img-description">${children}</p>`
            else if (node.video_description === true)
                return `<p class="video-description">${children}</p>`
            else
                return `<p>${children}</p>`
        case 'link':
            return `<a href="${escapeHtml(node.url)}" target="_blank" rel="noreferrer">${children}</a>`
        case 'heading-one':
            return `<h1>${children}</h1>`
        case 'heading-two':
            return `<h2>${children}</h2>`
        case 'image':
            return `<div class="article-image"><img src="${escapeHtml(node.url)}"/><label>${node.title ? escapeHtml(node.title) : ''}</label></div>`
        case 'add-image':
            return '';
        case 'video':
            return `<div class="article-video">${ReactDOMServer.renderToStaticMarkup(getVideoEmbedCode(node.url))}</div>`
        case 'add-video':
            return '';
        case 'the-conversation-embed':
            return '<p><span style="font-size:11px; color:rgb(222, 222, 223); float:right;">This article was facilitated by <a href="http://contentmutual.com" rel="noreferrer" target="_blank" style="color:rgb(222, 222, 223);">ContentMutual.com</a> via <a href="https://theconversation.com/us/columns" rel="noreferrer" target="_blank" style="color:rgb(222, 222, 223);">The Conversation</a>.</span></p><img src="' + escapeHtml(node.code) + '" alt="The Conversation" style="width: 1px; height: 1px;" />'
        case 'bulleted-list':
            return `<ul>${children}</ul>`;
        case 'numbered-list':
            return `<ol>${children}</ol>`;
        case 'list-item':
            return `<li>${children}</li>`;
        default:
            return children
    }
}

const alignImage = (editor, align) => {
    Transforms.setNodes(editor, { type: 'image', align: align });
};

const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
}

const getLink = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return link;
}

const insertLink = (editor, url) => {
    if (editor.selection) {
        wrapLink(editor, url)
    }
}

const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor)
    }

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
    }
}

const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
}

const withInlines = editor => {
    const { insertData, insertText, isInline } = editor

    editor.isInline = element =>
        ['link'].includes(element.type) || isInline(element)

    editor.insertText = text => {
        if (text && isUrl(text)) {
            wrapLink(editor, text)
        } else {
            insertText(text)
        }
    }

    editor.insertData = data => {
        const text = data.getData('text/plain')

        if (text && isUrl(text)) {
            wrapLink(editor, text)
        } else {
            insertData(data)
        }
    }

    return editor
}

const ELEMENT_TAGS = {
    A: el => ({ type: 'link', url: el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'quote' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-two' }),
    H3: () => ({ type: 'heading-three' }),
    H4: () => ({ type: 'heading-four' }),
    H5: () => ({ type: 'heading-five' }),
    H6: () => ({ type: 'heading-six' }),
    IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'bulleted-list' }),
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    B: () => ({ bold: true }),
    U: () => ({ underline: true }),
}

const deserialize = el => {
    console.log(el);
    if (el.nodeType === 3) {
        return el.textContent
    } else if (el.nodeType !== 1) {
        return null
    } else if (el.nodeName === 'BR') {
        return '\n'
    }

    const { nodeName } = el
    let parent = el

    if (
        nodeName === 'PRE' &&
        el.childNodes[0] &&
        el.childNodes[0].nodeName === 'CODE'
    ) {
        parent = el.childNodes[0]
    }
    let children = Array.from(parent.childNodes)
        .map(deserialize)
        .flat()

    if (children.length === 0) {
        children = [{ text: '' }]
    }

    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children)
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el)
        return jsx('element', attrs, children)
    }

    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el)
        return children.map(child => jsx('text', attrs, child))
    }

    return children
}

const withHtml = editor => {
    const { insertData, isInline, isVoid } = editor

    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element)
    }

    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid(element)
    }

    editor.insertData = data => {
        const html = data.getData('text/html')

        if (html) {
            const parsed = new DOMParser().parseFromString(html, 'text/html')
            const fragment = deserialize(parsed.body)
            Transforms.insertFragment(editor, fragment)
            return
        }

        insertData(data)
    }

    return editor
}

const getVideoPlatform = (url) => {
    if (url.indexOf('youtube.com') !== -1)
        return 'youtube'
    if (url.indexOf('vimeo.com') !== -1)
        return 'vimeo'

    return '';
}

const getVideoEmbedCode = (url) => {
    switch (getVideoPlatform(url)) {
        case 'youtube':
            return <iframe width="600" height="338" src={url.replace('/watch?v=', '/embed/') + "?controls=0"}
                title="YouTube video player1" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        case 'vimeo':
            return <iframe src={url.replace('https://vimeo.com/', 'https://player.vimeo.com/video/') + '?title=0&byline=0&portrait=0'}
                width="600" height="338" frameBorder="0"
                title="2"
                allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        default:
            return '';
    }
}

export {
    toggleBlock,
    toggleMark,
    isBlockActive,
    isMarkActive,
    withImages,
    insertImage,
    isImageUrl,
    insertNFT,
    editorToHTML,
    alignImage,
    isLinkActive,
    getLink,
    insertLink,
    wrapLink,
    unwrapLink,
    withInlines,
    ELEMENT_TAGS,
    TEXT_TAGS,
    deserialize,
    withHtml,
    insertVideo,
    withVideos,
    getVideoEmbedCode
}