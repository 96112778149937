import { getChainData } from "./helpers/utils";
import { USDC_Contract, Contract } from '../constants/contract';
import { ethers } from "ethers";

const CM_CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

export const setWalletProvider = (wallet) => {
    if (wallet === 'coinbase')
        window.localStorage.setItem('web3_provider_wallet', wallet);
    else
        window.localStorage.setItem('web3_provider_wallet', 'metamask');
}

const getWalletProvider = () => {
    return window.localStorage.getItem('web3_provider_wallet');
}

export const provider = () => {
    const wallet = getWalletProvider()?getWalletProvider():'metamask';
    if (typeof window.ethereum !== "undefined") {
        let provider = window.ethereum;
        // edge case if MM and CBW are both installed
        if (window.ethereum.providers?.length) {
            window.ethereum.providers.forEach(async (p) => {
                if (wallet === 'metamask' && p.isMetaMask)
                    provider = p;
                else if (wallet === 'coinbase' && p.isCoinbaseWallet)
                    provider = p;
            });
        }

        // console.log("CM_CHAIN_ID !== provider.chainId", CM_CHAIN_ID, provider.chainId)
        if (CM_CHAIN_ID !== provider.chainId) {
            const cmChainData = getChainData(CM_CHAIN_ID);
            const userChainData = getChainData(provider.chainId) ?? {};
            throw new Error(userChainData.name + " is not supported. You should connect with " + cmChainData.name +
                ". If you are already on the right network, please make sure you use only one wallet provider and disable the others.");
        }

        return provider;
    } else {
        throw new Error('Non-Ethereum browser detected. You should consider trying MetaMask.')
    }
}

export const connectWallet = async () => {
    return provider().request({
        method: "eth_requestAccounts",
        params: [],
    })
}

export const signMessage = async (account, message) => {
    //const msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
    let signature = await provider().request({ method: 'personal_sign', params: [message, account] })

    return signature
}

function getUSDCContract() {
    const ethersProvider = new ethers.providers.Web3Provider(provider());

    let usdc = new ethers.Contract(
        process.env.REACT_APP_USDC_ADDRESS,
        USDC_Contract,
        ethersProvider
    );

    return usdc;
};

function getMultisigInstance(address) {
    const ethersProvider = new ethers.providers.Web3Provider(provider());

    let multisign = new ethers.Contract(
        address,
        Contract,
        ethersProvider
    );

    return multisign;
};

export function depositToContract(multisignAddress, amount, callBack) {
    return new Promise(async (resolve, reject) => {
        try {
            
            const ethersProvider = new ethers.providers.Web3Provider(provider());
            const signer = ethersProvider.getSigner();
            const contract = getUSDCContract().connect(signer);
            const fees = await ethersProvider.getFeeData();
            const tx = await contract.transfer(multisignAddress, amount, { gasPrice: fees.gasPrice, gasLimit: 100000 });
            callBack(tx.hash);
            const result = await ethersProvider.waitForTransaction(tx.hash);
            if (result) {
                resolve(result);
            }
        } catch (error) {
            console.log('error', error);
            reject({ error });
        }
    });
}

export function withdrawFromContract(address, callBack) {
    return new Promise(async (resolve, reject) => {
        try {
            const ethersProvider = new ethers.providers.Web3Provider(provider());
            const signer = ethersProvider.getSigner();
            const contract = getMultisigInstance(address).connect(signer);
            const fees = await ethersProvider.getFeeData();
            const tx = await contract.payToAuthor({ gasPrice: fees.gasPrice, gasLimit: 100000 });
            callBack(tx.hash);
            const result = await ethersProvider.waitForTransaction(tx.hash);
            if (result) {
                resolve(result);
            }
        } catch (error) {
            reject({ error });
        }
    });
}
