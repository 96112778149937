import React, { useState, useEffect } from 'react'
import MultiRangeSlider from "multi-range-slider-react";
//import Slider from 'react-rangeslider'
//import 'react-rangeslider/lib/index.css'
// To include the default styles
const RangeInput = ({ currentRefinement, count, min, max, currentRefinementState, precision, refine }) => {
    const [state, setState] = useState({minValue: 0, maxValue: 0})

    useEffect(() => {
        if(!currentRefinementState) {
            setState({minValue: min, maxValue: max})
        } else {
            setState({minValue: currentRefinementState[0], maxValue: currentRefinementState[1]===0?max:currentRefinementState[1]})
        }
    }, [min, max, currentRefinementState])
    
    return (
        <>
            <div className='slider'>
                <MultiRangeSlider
                    min={min?min:0}
                    max={max?max:1}
                    step={1}
                    ruler={false}
                    label={true}
                    preventWheel={false}
                    minValue={state.minValue?state.minValue:0}
                    maxValue={state.maxValue?state.maxValue:0}
                    onInput={(e) => {
                        refine({
                            ...currentRefinement,
                            min: min > e.minValue?min:e.minValue,
                            max: e.maxValue,
                        })
                        setState({minValue: e.minValue, maxValue: e.maxValue});
                    }}
                />
            </div>
        </>
    )
}

export default RangeInput