import React, { useState, useEffect, useContext } from 'react'
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import { Label } from '../components/Typography';
import Joi from 'joi'
import { Controller, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../Utils/AppUtill'
import ErrorLabel from '../widgets/ErrorLable';
import { useHistory } from 'react-router-dom';
import { requestApprove } from '../action/invite.action';
import { NavLink } from "react-router-dom";
import { signMessage } from '../web3/functions';
import { Web3Context } from '../web3/contexts/web3Context'
import { GlobalContext } from '../context/globalContext';
import MetamaskIcon from '../assets/images/metamask.svg';
import CoinbaseIcon from '../assets/images/coinbase.svg';
import { getNonce } from '../action/auth.action';
import toast from "../helpers/toast"
const apiUrl = process.env.REACT_APP_API_URL;

const RequstApprove = () => {

    const history = useHistory();
    const [state, setState] = useState({});
    const { handleConnect } = useContext(Web3Context);
    const { setSignIn, setIsLoggedIn, setUserInfo, setUserToken } = useContext(GlobalContext);

    const {
        handleSubmit,
        setError,
        formState: { errors },
        control,
        reset
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                password: Joi.string()
                    .required()
                    .label('Password')
                    .messages(joiUpdatedMessage),
                repassword: Joi.string()
                    .required()
                    .label('Confirm Password')
                    .valid(Joi.ref('password'))
                    .messages(joiUpdatedMessage),
                username: Joi.string()
                    .regex(/^[^0x]{1}[a-zA-Z0-9_]*$/)
                    .required()
                    .messages(joiUpdatedMessage),
                first_name: Joi.string()
                    .required()
                    .label('First Name')
                    .messages(joiUpdatedMessage),
                last_name: Joi.string()
                    .required()
                    .label('Last Name')
                    .messages(joiUpdatedMessage),
                email: Joi.string()
                    .required()
                    .email({ tlds: { allow: false } })
                    .label('Email')
                    .messages(joiUpdatedMessage),
            }),
        ),
    })

    const getImageUrl = (path) => {
        if(path.includes(window.location.origin)) {
            return path;   
        }
        return apiUrl + "/thumb?src=" + path + "&w=120&h=120"
    }

    const retriveInvite = async (values = {}) => {
        const { data } = await requestApprove(history.location.search);
        if (data.status === 200) {
            if (data.data.error) {
                history.push('/');
            } else {
                reset({ email: data.data.email })
                setState(data.data);
            }
        }
    }
    
    useEffect(() => {
        retriveInvite()
        // eslint-disable-next-line
    }, [])

    const onClickToSubmit = async (values) => {
        const formData = new FormData();
        Object.keys(values).map(res => formData.append(res, values[res]))

        const { data } = await requestApprove(history.location.search, formData);
        loginUser(data);
    }

    const loginUser = (data) => {
        if (data.status === 200) {
            const { success, user, errors, message } = data.data;
            if (success) {
                reset({});
                toast.success(message)
                setSignIn(null);
                setUserInfo(user);
                setUserToken(user.access_token);
                setIsLoggedIn(true);
                history.push('/');
            } else {
                setIsLoggedIn(false);
                const { username, password } = errors;

                setError("username", {
                    type: "manual",
                    message: `${username !== undefined ? username : ''} ${password !== undefined ? password : ''}`,
                });
            }
        }
    }
    
    const connectweb3 = (plugin) => {
        // console.log("getValues", getValues('email'));
        handleConnect(plugin).then(address => {
            getNonce(address).then(async nonce => {
                let signature = await signMessage(address, nonce.data.data.message, nonce.data.data.nonce)
                const formData = new FormData();
                formData.append('signature', signature);
                formData.append('address', address);
                formData.append('wallet', plugin);
                const { data } = await requestApprove(history.location.search, formData);
                loginUser(data)           
            })
        });
    }

    return (<>
        <Container className="py-5">
            <div className='text-center'>
                <h1 className="page-title cover-wrapper text-green pb-5">Register with Content Mutual</h1>
            </div>
            <div className='CM_whiteBox'>
                {state.invite && <Row>
                    <Col lg={12}>
                        <div className='text-center pb-4'>
                            <img alt="" src={getImageUrl(state.invite.image)} className='cm_img60pxrounded' />
                            <div><span className='text-green fs-18px'>You've been referred by </span><NavLink className='navlink' to={state.invite.author_url}>{state.invite.print_name}</NavLink></div>
                        </div>
                    </Col>
                </Row>}
                <Row>
                    <Col lg={12}>
                        <div className='cm_boxTitle text-green'>Register</div>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Register with email</Accordion.Header>
                                <Accordion.Body>
                                    <form>
                                        <div className='mb-3'>
                                            <Label text="Username*" />
                                            <Controller
                                                name="username"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                        <input
                                                            type="text"
                                                            className="cm_input"
                                                            value={value}
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                            {errors.username && <ErrorLabel
                                                msg={errors.username && [`Please enter a "username".`, `"username" is required`].includes(errors.username.message)?"Username is required.":"It should not be start from 0x and allow only alphanumeric values"}
                                            />}
                                        </div>
                                        <div className='mb-3'>
                                            <Label text="First Name*" />
                                            <Controller
                                                name="first_name"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                        <input
                                                            type="text"
                                                            className="cm_input"
                                                            value={value}
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                        />

                                                    </>
                                                )}
                                            />
                                            {errors.first_name && <ErrorLabel
                                                msg={errors.first_name && errors.first_name.message}
                                            />}
                                        </div>
                                        <div className='mb-3'>
                                            <Label text="Last Name*" />
                                            <Controller
                                                name="last_name"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                        <input
                                                            type="text"
                                                            className="cm_input"
                                                            value={value}
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                        />

                                                    </>
                                                )}
                                            />
                                            {errors.last_name && <ErrorLabel
                                                msg={errors.last_name && errors.last_name.message}
                                            />}
                                        </div>
                                        <div className='mb-3'>
                                            <Label text="Email*" />
                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                        <input
                                                            type="text"
                                                            className="cm_input"
                                                            value={value}
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                            disabled
                                                        />

                                                    </>
                                                )}
                                            />
                                            {errors.email && <ErrorLabel
                                                msg={errors.email && errors.email.message}
                                            />}
                                        </div>
                                        <div className='mb-3'>
                                            <Label text="Password*" />
                                            <Controller
                                                name="password"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                        <input
                                                            type="password"
                                                            className="cm_input"
                                                            value={value}
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                        />

                                                    </>
                                                )}
                                            />
                                            {errors.password && <ErrorLabel
                                                msg={errors.password && errors.password.message}
                                            />}
                                        </div>
                                        <div className='mb-3'>
                                            <Label text="Repeat Password*" />
                                            <Controller
                                                name="repassword"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <>
                                                        <input
                                                            type="password"
                                                            className="cm_input"
                                                            value={value}
                                                            onChange={e => {
                                                                onChange(e)
                                                            }}
                                                        />

                                                    </>
                                                )}
                                            />
                                            {errors.repassword && <ErrorLabel
                                                msg={errors.repassword && errors.repassword.message}
                                            />}
                                        </div>
                                        <div className='mb-3'>
                                            <button className='btn-green' onClick={handleSubmit(onClickToSubmit)}>Register</button>
                                        </div>
                                    </form>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Register with wallet</Accordion.Header>
                                <Accordion.Body>
                                    <div className='mb-3'>
                                        <button className="btn btn-outline-black crypto-connect" onClick={() => connectweb3('metamask')}><img alt="" src={MetamaskIcon} style={{width: "27px"}}/>Connect With Metamask</button>&nbsp;&nbsp;
                                        <button className="btn btn-outline-black crypto-connect" onClick={() => connectweb3('coinbase')}><img alt="" src={CoinbaseIcon} style={{width: "27px"}}/>Connect With Coinbase</button>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </div>
        </Container>
    </>)
}

export default RequstApprove;