import React, {useEffect, useState} from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { InstantSearch, SearchBox, SortBy, Hits, Stats, Pagination, RefinementList, connectHierarchicalMenu, connectRange, Configure } from 'react-instantsearch-dom';
import { ArticleList } from '../components/Article/ArticleList'
import RangeInput from '../components/Article/RangeInput'
import { client } from '../Utils/AppUtill'
import queryString from 'query-string';
import { useDebouncedCallback } from 'use-debounce';

const CustomRangeInput = connectRange(RangeInput);
const index = process.env.REACT_APP_ALGOLIA_INDEX;

const HierarchicalMenu = ({ items, label = '', refine, createURL, level=0 }) => (
    <div className="ais-RefinementList">
        <ul className="ais-RefinementList-list">   
        {items.map(item => (
            <li key={item.label} style={{cursor: 'pointer'}} className="ais-RefinementList-item">
                <label className="ais-RefinementList-label" >
                    {/* <input onClick={(event) =>{
                        event.preventDefault();
                        refine(item.value);
                    }} type="radio" checked={item.isRefined}/> */}
                    <span className="ais-RefinementList-labelText"  onClick={(event) =>{
                        event.preventDefault();
                        // `${label!=''?`${label} > ${item.value}`:item.value}`
                        refine(item.value);
                    }} style={{ fontWeight: item.isRefined ? 'bold' : '' }}>{item.label}</span>
                    <span className="ais-RefinementList-count" style={{ fontWeight: item.isRefined ? 'bold' : '' }}>{item.count}</span>
                </label>
            {item.items && (<>
                <HierarchicalMenu
                    items={item.items}
                    label={item.label}
                    refine={refine}
                    createURL={createURL}
                    level={1}
                />
            </>)}
            </li>
        ))}
        </ul>
    </div>
);

const CustomHierarchicalMenu = connectHierarchicalMenu(HierarchicalMenu);

let priceInput = {};
let viewsInput = {};

const SearchPage = () => {
    const history = useHistory()
    const [state, setState] = useState({});
    
    useEffect(() => {
        let obj = queryString.parse(history.location.search)
        let queryD = {};
        if(obj.p) {
            let parseQuery = []
            if(obj.q) {
                parseQuery = JSON.parse(decodeURIComponent(history.location.search.split("?")[1].split("=")[1].slice(0, -2)));
            } else {
                parseQuery = JSON.parse(decodeURIComponent(history.location.search.split("?")[1].split("=")[1]));
            }
             
            if(parseQuery && parseQuery.length > 0) {
                parseQuery.map(r => {
                    queryD = {
                        ...queryD,
                        [r.name]: r.value
                    }
                    return r
                })
            }
        } 
        
        if(obj.q){
            queryD = {
                ...queryD,
                search: obj.q
            }
        }

        priceInput = {attribute:"price"}
        if(queryD.price && ((queryD.price[0]>0 && queryD.price[1]!==0) || (queryD.price[0]===0 && queryD.price[1]!==0))) {
            priceInput.currentRefinementState = queryD.price
            priceInput.defaultRefinement = {min: queryD.price[0], max: queryD.price[1]}
        }
        
        viewsInput = {attribute:"views"}
        if(queryD.views && ((queryD.views[0]>0 && queryD.views[1]!==0) || (queryD.views[0] === 0 && queryD.views[1]!==0))) {
            viewsInput.currentRefinementState = queryD.views
            viewsInput.defaultRefinement = {min: queryD.views[0], max: queryD.views[1]}
        }
        
        setState(queryD);
    }, [history.location.search])

    const findRefinementValue = (data, name, value) => {
        const index = data.findIndex(r => r.name === name);
        if(Array.isArray(value) || name === 'category_level_1' || 'category_level_2' || name === 'page') {
            if(index !== -1) {
                data[index].value = value;
            } else {
                data.push({name, value: value})
            }
        } else {
            if(name && !value && index > -1) {
                data = data.filter(r => r.name !== name);
            }
        }
        return data;
    }
    
    const generateUrl = useDebouncedCallback(async (data) => {
        let obj = queryString.parse(history.location.search)
        
        if(obj.p) {
            let parseQuery = []
            if(obj.q) {
                parseQuery = JSON.parse(decodeURIComponent(history.location.search.split("?")[1].split("=")[1].slice(0, -2)));
            } else {
                parseQuery = JSON.parse(decodeURIComponent(history.location.search.split("?")[1].split("=")[1]));
            }
            
            obj.p = parseQuery;
        } else {
            obj.p = [];
        }
        
        if(data.refinementList) {
            const { type, author_profession } = data.refinementList;
            if(type!==undefined) {
                obj.p = await findRefinementValue(obj.p, 'type', type); 
            }

            if(author_profession!==undefined) {
                obj.p = await findRefinementValue(obj.p, 'author_profession', author_profession); 
            }
            
            if(data.refinementList['attributes.Style']!==undefined) {
                obj.p = await findRefinementValue(obj.p, 'attributes_style', data.refinementList['attributes.Style']); 
            }

            if(data.refinementList['attributes.Type']!==undefined) {
                obj.p = await findRefinementValue(obj.p, 'attributes_type', data.refinementList['attributes.Type']); 
            }
        }

        if(data.hierarchicalMenu) {
            if(data.hierarchicalMenu.category_level_1!==undefined) {
                obj.p = await findRefinementValue(obj.p, 'category_level_1', data.hierarchicalMenu.category_level_1); 
            }
        }

        if(data.range) {    
            if(data.range.price) {
                const min = data.range.price.min?data.range.price.min:0
                const max = data.range.price.max?data.range.price.max:0
                obj.p = await findRefinementValue(obj.p, 'price', [min, max]);
            }

            if(data.range.views) {
                const min = data.range.views.min?data.range.views.min:0
                const max = data.range.views.max?data.range.views.max:0
                obj.p = await findRefinementValue(obj.p, 'views', [min, max]);
            }
        }
        
        if(data.page > 1) {
            obj.p = await findRefinementValue(obj.p, 'page', data.page);
        } else {
            obj.p = await findRefinementValue(obj.p, 'page', 1);
        }

        if(data.query !== undefined) {
            obj.q = data.query
        } else {
            obj.q = obj.q?obj.q:'';
        }
        
        let qstring = "";
        if(obj.p && obj.p.length > 0) {
            obj.p = encodeURIComponent(JSON.stringify(obj.p))
            qstring = `?p=${obj.p}`;
        }

        if(obj.q) {
            if(obj.p && obj.p.length > 0) {
                qstring = `?p=${obj.p}&q=${obj.q}`;
            } else {
                qstring = `?q=${obj.q}`;
            }
        }

        history.push(`/search${qstring}`)
    }, 500);

    return (<>
        <Container className="pt-3">
            <InstantSearch searchClient={client} onSearchStateChange={(data) => {
                if(data) {
                    generateUrl(data)
                }
            }} indexName={index + "_cm_index_created_desc"}>
                <div className='search_content'>
                    <Row className='flex-row-reverse'>
                        <Col md={9}>
                            <div className='advanced-filter'>
                                <div className='row align-items-center'>
                                    <div className="col-lg-4 col-sm-12 searchres_font ">
                                        <div className="stats">
                                            <Stats />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="input-group">
                                            <SearchBox translations={{ placeholder: 'Search' }} defaultRefinement={state.search?state.search:''}/>
                                        </div>
                                    </div>
                                    <div className=" col-lg-4 col-sm-12">
                                        <SortBy
                                            defaultRefinement={index + "_cm_index_created_desc"}
                                            items={[
                                                { value: index + '_cm_index_created_desc', label: 'Newest Articles' },
                                                { value: index + '_cm_index_relevance', label: 'Relevance' },
                                                { value: index + '_cm_index_price_asc', label: 'Lowest Price' },
                                                { value: index + '_cm_index_price_desc', label: 'Highest Price' },
                                                { value: index + '_cm_index_created_asc', label: 'Oldest Articles' },
                                                { value: index + '_cm_index_views_asc', label: 'Lowest Pageviews' },
                                                { value: index + '_cm_index_views_desc', label: 'Highest Pageviews' },
                                                { value: index + '_cm_index_socialshares_desc', label: 'Highest Social Shares' },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='result_details '>
                                <Configure
                                    hitsPerPage={10}
                                />
                                <Hits hitComponent={ArticleList} />
                                <Pagination showlast defaultRefinement={state.page?state.page:1} page={() => state.page?state.page:1}/>
                            </div>
                        </Col>
                        <Col md={3}>
                            <Card className='mb-3' >
                                <Card.Header>Type</Card.Header>
                                <RefinementList
                                    transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)}
                                    attribute="type"
                                    defaultRefinement={state.type?state.type:''}
                                    
                                />
                            </Card>
                            <Card className='mb-3'>
                                <Card.Header>Categories</Card.Header>
                                <CustomHierarchicalMenu
                                    attributes={[
                                        'category_level_1',
                                        'category_level_2',
                                    ]}
                                    // rootPath={state.category_level_1?state.category_level_1:''}
                                    defaultRefinement={state.category_level_1?state.category_level_1:''}
                                />
                            </Card>
                            <Card className='mb-3'>
                                <Card.Header>Type</Card.Header>
                                <RefinementList
                                    attribute="attributes.Type"
                                    defaultRefinement={state.attributes_type?state.attributes_type:''}
                                />
                            </Card>
                            <Card className='mb-3' >
                                <Card.Header>Style</Card.Header>
                                <RefinementList
                                    transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)}
                                    attribute="attributes.Style"
                                    defaultRefinement={state.attributes_style?state.attributes_style:''}
                                />
                            </Card>
                            <Card className='mb-3'>
                                <Card.Header>Author Profession</Card.Header>
                                <RefinementList
                                    transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)}
                                    attribute="author_profession"
                                    defaultRefinement={state.author_profession?state.author_profession:''}
                                />
                            </Card>
                            <Card className='mb-3'>
                                <Card.Header>Price</Card.Header>
                                <div className='range_slider'>
                                    {(!history.location.search || (history.location.search && Object.keys(state).length > 0)) && <CustomRangeInput
                                        {...priceInput}
                                    />}
                                </div>
                            </Card>
                            <Card className='mb-3'>
                                <Card.Header>Page Views</Card.Header>
                                <div className='range_slider'>
                                    {(!history.location.search || (history.location.search && Object.keys(state).length > 0)) && <CustomRangeInput
                                        {...viewsInput}
                                    />}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </InstantSearch>
        </Container>
    </>)
}

export default SearchPage;