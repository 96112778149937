import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Label } from '../components/Typography';
import Joi from 'joi'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import {joiUpdatedMessage} from '../Utils/AppUtill'
import ErrorLabel from '../widgets/ErrorLable';
import { useHistory } from 'react-router-dom';
import { registerInvite } from '../action/invite.action';
import { NavLink } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const RegisterPage = () => {
    
    const history = useHistory();
    const [state, setState] = useState({});

    const {
        handleSubmit,
        formState: {errors},
        control,
        reset
      } = useForm({
        resolver: joiResolver(
          Joi.object({
            password: Joi.string()
              .required()
              .label('Password')
              .messages(joiUpdatedMessage),
            repassword: Joi.string()
              .required()
              .label('Confirm Password')
              .valid(Joi.ref('password'))
              .messages(joiUpdatedMessage),
            username: Joi.string()
              .required()
              .label('Username')
              .messages(joiUpdatedMessage),
            email: Joi.string()
              .required()
              .email({tlds:{allow: false}})
              .label('Email')
              .messages(joiUpdatedMessage),
          }),
        ),
    })
    
    const getImageUrl = (path) => {
        if(path.includes(window.location.origin)) {
            return path;   
        }
        return apiUrl + "/thumb?src=" + path + "&w=120&h=120"
    }
    
    const retriveInvite = async (values = {}) => {
        const {data} = await registerInvite(history.location.search, values);
        if(data.status === 200) {
            if(data.data.error) {
                history.push('/');
            } else {
                reset({email: data.data.email})
                setState(data.data);
            }
        }
    }
    
    useEffect(() => {
        retriveInvite()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const onClickToSubmit = async (values) => {
        const formData = new FormData();
        Object.keys(values).map(res => formData.append(res, values[res]))
        const {data} = await registerInvite(history.location.search, formData);
        if(data.status === 200) {
            history.location('/user/login')
            reset({});
        }
    }
    
    return (<>
        <Container className="py-5">
            <div className='text-center'>
                <h1 className="page-title cover-wrapper text-green pb-5">Register with Content Mutual</h1>
            </div>
            <div className='CM_whiteBox'>
                {state.invite && <Row>
                    <Col lg={6}>
                        <div className='text-center pb-4'>
                            <img alt="" src={getImageUrl(state.invite.image)} className='cm_img60pxrounded' />
                            <div><span className='text-green fs-18px'>You've been referred by </span><NavLink className='navlink' to={state.invite.author_url}>{state.invite.print_name}</NavLink></div>
                        </div>
                    </Col>
                </Row>}
                <Row>
                    <Col lg={6}>
                        <div className='cm_boxTitle text-green'>Register</div>
                        <form>
                            <div className='mb-3'>
                                <Label text="Username*" />
                                <Controller
                                    name="username"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <input 
                                                type="text" 
                                                className="cm_input" 
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }}
                                            />
                                            
                                        </>
                                    )}
                                />
                                {errors.username && <ErrorLabel
                                    msg={errors.username && errors.username.message}
                                />}
                            </div>
                            <div className='mb-3'>
                                <Label text="Email*" />
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <input 
                                                type="text" 
                                                className="cm_input" 
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }}
                                            />
                                            
                                        </>
                                    )}
                                />
                                {errors.email && <ErrorLabel
                                    msg={errors.email && errors.email.message}
                                />}
                            </div>
                            <div className='mb-3'>
                                <Label text="Password*" />
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <input 
                                                type="password" 
                                                className="cm_input" 
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }}
                                            />
                                            
                                        </>
                                    )}
                                />
                                {errors.password && <ErrorLabel
                                    msg={errors.password && errors.password.message}
                                />}
                            </div>
                            <div className='mb-3'>
                                <Label text="Repeat Password*" />
                                <Controller
                                    name="repassword"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <input 
                                                type="password" 
                                                className="cm_input" 
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }}
                                            />
                                            
                                        </>
                                    )}
                                />
                                {errors.repassword && <ErrorLabel
                                    msg={errors.repassword && errors.repassword.message}
                                />}
                            </div>
                            <div className='mb-3'>
                                <button className='btn-green' onClick={handleSubmit(onClickToSubmit)}>Register</button>
                            </div>
                        </form>
                    </Col>
                    <Col lg={6} className="user_login">
                        <div className='cm_boxTitle text-green mb-4'>Register with social network</div>
                        <div className="wc-social-login form-row-wide ">
                            {/* <a href="#" className="sc-btn-lin"></a>
                            <br />
                            <a href="#" className="sc-btn-tw"></a>
                            <br /> */}
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </>)
}

export default RegisterPage;