import React, { useContext, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Label } from '../components/Typography';
import Joi from 'joi'
import { Controller, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../Utils/AppUtill'
import ErrorLabel from '../widgets/ErrorLable';
import { login, getNonce } from '../action/auth.action';
import { GlobalContext } from '../context/globalContext'
import { useHistory } from 'react-router-dom';
import toast from "../helpers/toast"
import MetamaskIcon from '../assets/images/metamask.svg';
import CoinbaseIcon from '../assets/images/coinbase.svg';
import { Web3Context } from '../web3/contexts/web3Context'
import { signMessage } from '../web3/functions'
import queryString from 'query-string';

const LoginPage = () => {
    const history = useHistory();
    const { setSignIn, setIsLoggedIn, setUserInfo, setUserToken, isLoggedIn } = useContext(GlobalContext);
    const { handleConnect } = useContext(Web3Context);
    const [rememberme, setRememberme] = useState(false);
    const [responseError, setResponseError] = useState({});

    if (isLoggedIn) {
        const {search, pathname} = history.location;
        if(pathname === '/user/login' && search) {
            const query = queryString.parse(search)
            if(query?.return) {
              history.push(query?.return);
            } else {
              history.push('/')
            }
        } else {
            history.push('/')
        }
    }

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                password: Joi.string()
                    .required()
                    .label('Password')
                    .messages(joiUpdatedMessage),
                username: Joi.string()
                    .required()
                    .label('Username')
                    .messages(joiUpdatedMessage),
            }),
        ),
    })
    
    const web3login = async (plugin) => {
        handleConnect(plugin).then(address => {
            getNonce(address).then(async nonce => {
                let signature = await signMessage(address, nonce.data.data.message, nonce.data.data.nonce)
                login({ web3: 1, signature: signature, address: address }).then(d => {
                    loginUser(d.data);
                })
            })
        });
    }

    const onClickToSubmit = async (values) => {
        const formData = new FormData();
        formData.append('username', values.username);
        formData.append('password', values.password);
        formData.append('rememberme', rememberme);
        const { data } = await login(formData);
        loginUser(data);
    }

    const loginUser = (data) => {
        if (data.status === 200) {
            const { success, user, errors, message } = data.data;
            if (success) {
                toast.success(message)
                setSignIn(null);
                setUserInfo(user);
                setUserToken(user.access_token);
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
                const { username, password } = errors;
                setResponseError({
                    "username":`${username !== undefined ? username : ''} ${password !== undefined ? password : ''}`,
                });
                setTimeout(() => {
                    setResponseError({})
                }, 3000)
                
            }
        }
    }

    return (<>
        <Container>
            <Col className='text-center'>
                <h1 className="page-title cover-wrapper green_color pt-5">My Account</h1>
            </Col>
        </Container>
        <Container className="py-5">
            <Row>
                {/* {console.log('errors.email', errors.email.message)} */}
                <Col md={8} xs={12}>
                    <div className='CM_whiteBox user_login'>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <h2 className='green_color'>Login</h2>
                            </Col>
                        </Row>
                        {Object.keys(responseError).length > 0 && <div className='cm_error text-green fs-13px'>
                            <strong>Error: </strong>
                            {responseError.username && <ErrorLabel
                                msg={responseError.username && responseError.username}
                            />}
                        </div>}
                        <form onSubmit={handleSubmit(onClickToSubmit)} className='row'>
                            <Col md={12} className='mb-3'>
                                <Controller
                                    name="username"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <>
                                            <Label text="Username or email address *" />
                                            <input
                                                type="text"
                                                className="cm_input"
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }}
                                            />
                                            {errors.username && <ErrorLabel
                                                msg={errors.username && errors.username.message}
                                            />}
                                        </>
                                    )}
                                />
                            </Col>
                            <Col md={12} className='mb-3'>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <>
                                            <Label text="Password *" />
                                            <input
                                                type="password"
                                                className="cm_input"
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }}
                                            />
                                            {errors.password && <ErrorLabel
                                                msg={errors.password && errors.password.message}
                                            />}
                                        </>
                                    )}
                                />
                            </Col>
                            <div className='login_cehckbox mb-3 d-flex align-items-center'>
                                <div><button className='btn-green' onClick={handleSubmit(onClickToSubmit)}>Login</button></div>
                                <div className='remeber_me'>
                                    <label className="CM_checkbox ml-5 mt-1"><input type="checkbox" checked={rememberme} onClick={() => setRememberme(!rememberme)}/>Remember me</label>
                                </div>
                            </div>
                        </form>
                        <button className="btn btn-outline-black crypto-connect me-3 mb-3 mb-lg-0" onClick={() => web3login('metamask')}><img alt="" src={MetamaskIcon} />Connect With Metamask</button>
                        
                        <button className="btn btn-outline-black crypto-connect" onClick={() => web3login('coinbase')}><img alt="" src={CoinbaseIcon} />Connect With Coinbase</button>

                        <p className='mt-3 mt-md-2'><a href="#/" onClick={() => history.push('/user/lost-password')}>Lost your password?</a></p>
                        <p className='mt-2 mt-md-1 lh-sm'><a href="#/" onClick={() => history.push('/user/request')}>ContentMutual.com is an Invite Only Platform. If you don't have an invitation link, you may request one here.</a></p>
                        {/*<div className="wc-social-login form-row-wide">
                            <a href="#" className="sc-btn-lin"></a>
                            <br />
                            <a href="#" className="sc-btn-tw"></a>
                            <br />
                        </div>*/}
                    </div>
                </Col>
                <Col md={4} xs={12} className='mt-3 mt-md-0'>
                    <div className='CM_whiteBox user_login_search'>
                        <form className='row'>
                            <Col md={12} className='mt-3 search_position'>
                                <input type="text" className="cm_input" placeholder='search' />
                                <button type="submit" className="search-submit_user bg-transparent border-0"><i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </Col>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    </>)
}
export default LoginPage;