import Image from './Image'
import AddImage from './AddImage'
import Link from './Link'
import TheConversationTracker from './TheConversationTracker'
import AddVideo from './AddVideo'
import Video from './Video'

const Element = props => {
    const { attributes, children, element } = props

    switch (element.type) {
        case 'nft':
            return <Image {...props} />
        case 'image':
            return <Image {...props} align={element.align} />
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        case 'add-image':
            return <AddImage {...props} />
        case 'add-video':
            return <AddVideo {...props} />
        case 'video':
            return <Video {...props} />
        case 'link':
            return <Link {...props} />
        case 'the-conversation-embed':
            return <TheConversationTracker {...props} />
        default:
            if (element.image_description)
                return <p className="img-description" {...attributes}>{children}</p>
            else  if(element.video_description)
                return <p className="video-description" {...attributes}>{children}</p>
            else
                return <p {...attributes}>{children}</p>
    }
}

export default Element;