import React from 'react'
import {removeDoubleQuotes} from '../Utils/AppUtill'

const ErrorLabel = ({msg = '', style = {color: 'red'}}) => {
  msg = msg.charAt(0).toUpperCase() + msg.slice(1)
  msg = msg.replace('required', 'required')
  return (
    <span style={{color: 'red'}}>
      {removeDoubleQuotes(msg)}
    </span>
  )
}

export default ErrorLabel
