import React, { useEffect, useState, useRef } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Icon from '../components/Icon';
import heroVideo from './../assets/images/milky-way-over-town_HD_720.webm'
// import { articlesList } from './list'
import { InstantSearch, connectRefinementList } from 'react-instantsearch-dom';
import { client } from '../Utils/AppUtill';
import { getRecentArticle, featuresArticleList } from '../action/article.action';
import { BookMarkComponent } from '../components/BookMarkComponent';
const articlePerPage = 6;
let arrayForHoldingArticles = [];

const algoliaIndex = process.env.REACT_APP_ALGOLIA_INDEX;

const RefinementList = ({ items }) => (
    <>
      {items.sort((a, b) => a.label > b.label ? 1 : -1).map(item => (
        <option key={item.label} value={item.label}>{item.label}</option>
      ))}
    </>
);

const CustomRefinementList = connectRefinementList(RefinementList);

const HomePage = () => {
    const myRef = useRef(null)
    const history = useHistory();
    const [getRecentArticles, setRecentArticles] = useState([])
    const [ArticleToShowList, setArticleToShowList] = useState([]);
    const [next, setNext] = useState(0);
    const [articleCount, setArticleCount] = useState(0);
    const [searchCategory, setSearchCategory] = useState("");
    const [currentParams, setCurrentParams] = useState({
        offset: 1,
        limit: 6
    });
    const [featuresArticle, setFeaturesArticle] = useState({});
    
    const loopWithSlice = (start, end) => {
        const slicedPosts = getRecentArticles.slice(start, end);
        arrayForHoldingArticles = [...arrayForHoldingArticles, ...slicedPosts];
        setArticleToShowList(arrayForHoldingArticles);
    };
    
    const onRetriveRecentArticle = async () => {
        const { data } = await getRecentArticle(currentParams)
        if(data.status === 200) {
            setArticleCount(data.data.count)
            setRecentArticles([...getRecentArticles, ...data.data.article])
        }
    }
    
    const onRetriveFeaturesArticleList = async () => {
        const { data } = await featuresArticleList();
        if(data.status === 200) {
            setFeaturesArticle(data.data)
        }
    }

    useEffect(() => {
        if(getRecentArticles.length > 0) {
            loopWithSlice(next, next + articlePerPage)
            setNext(next + articlePerPage);
            setCurrentParams({offset: (next + articlePerPage + 1), limit: 6})
        }
    }, [getRecentArticles])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        onRetriveRecentArticle()
        onRetriveFeaturesArticleList();
        return () => {
            setRecentArticles([])
            setArticleToShowList([])
            arrayForHoldingArticles = [];
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    
    const handleLoadMore = async () => {
        // window.scrollTo(0, document.querySelector('.LoadMoreBtn').scrollHeight);
        
        // myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        await onRetriveRecentArticle();
        window.scrollTo(0, myRef.current.offsetTop - 150);
    }

    const onSearchCategory = () => {
        history.push(`/search?p=${encodeURIComponent(JSON.stringify([{'name': 'category_level_1', 'value': searchCategory}]))}`);
    }
    
    useEffect(() => {
        let index = 0;
        const interval = setInterval(() => {
            if(index > 2) {
                index = 0;
            } else {
                index++;
            }
            let prev = document.querySelector(`.cm-character${index === 0?3:index - 1}`);
            prev.classList.remove('is-visible');
            prev.classList.add('is-hidden');
            let next = document.querySelector(`.cm-character${index}`);
            next.classList.remove('is-hidden');
            next.classList.add('is-visible');
        }, 4000);
        return () => {
            clearInterval(interval);
        }
    }, [])

    return (<>
        <div className='CM_hero'>
            <video src={heroVideo} autoPlay muted loop type="video/webm" />
            <div className='CM_heroContent'>
                <Container>
                    <div className='CM_heroTitle pb-2'>CONTROL ACCESS TO YOUR &nbsp; 
                        <span className='cm-headline slide'>
                            <span className='cm-character0 is-visible'>CONTENT</span>
                            <span className='cm-character1 is-hidden'>COMMUNITY</span>
                            <span className='cm-character2 is-hidden'>SUBSCRIPTION</span>
                            <span className='cm-character3 is-hidden'>MEMBERSHIP</span>
                        </span>
                    </div>
                    <div className='CM_heroText pb-5'>THE WORLD'S 1ST QUALITY CONTENT EXCHANGE ON THE BLOCKCHAIN</div>
                    <form className="CM_searchHero row mx-auto max-w-900px">
                        <Col md={9}>
                            <select className='CM_searchCategoryField' name="searchCategory" onChange={(e) => setSearchCategory(e.target.value)}>
                                <option value="">Select Category</option>
                                <option value="">All</option>
                                <InstantSearch searchClient={client} indexName={algoliaIndex + "_cm_index_created_desc"}>
                                    <CustomRefinementList attribute="category_level_1"/>
                                </InstantSearch>
                            </select>
                        </Col>
                        <Col md={3}>
                            <Button variant="green" className='w-100' onClick={onSearchCategory}>Search Content</Button>
                        </Col>
                    </form>
                </Container>
            </div>
        </div>
        <Container>
            <Row className='py-md-5 py-3 my-md-3'>
                <CategoryBox name='c1' title="Locate Content You Like" text="Content listed on our marketplace is vetted and available for syndication to your audience. Discover content to republish on your site." />
                <CategoryBox name='c2' title="Ask For Permission" text="If another person wants to use your work, even huge admirers, they still need to ask for permission to republish. It’s just the law." />
                <CategoryBox name='c3' title="Share On Your Website" text="If approved by the owner, you'll be provided unique Mutual-Code™ that will execute upon your common agreement." />
            </Row>
            {(featuresArticle.featuredArticlesCount !== undefined && featuresArticle.featuredArticlesCount > 0) &&
            <Row>
                <Col md={12}>
                    <div className='CM_secTitle'>Featured Articles</div>
                    <div className='CM_secSubTitle mb-md-5 mb-3'>Find Premium Content For Your Site</div>
                </Col>
                {featuresArticle.featured_articles.map((item, i) => {
                    return (<FeaturesArticleBox key={i + 1} item={item} callBack={onRetriveFeaturesArticleList}/>)
                })}
                {/* <ArticleBox starShow coverIMG="https://images.unsplash.com/photo-1640904758535-067492e54ce9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80" logoIMG="https://contentmutual.com/assets/front/img/accolades/iflscience.png" title="Why Frequent Dieting Makes You Put On Weight – And What To Do About It" />
                <ArticleBox coverIMG="https://images.unsplash.com/photo-1640904758535-067492e54ce9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80" logoIMG="https://contentmutual.com/assets/front/img/accolades/iflscience.png" title="Why Frequent Dieting Makes You Put On Weight – And What To Do About It" /> */}
            </Row>}
            <Row>
                <Col md={12}>
                    <div className='CM_secTitle mt-md-5 mt-3'>Recently Listed and Newly Available</div>
                    <div className='CM_secSubTitle mb-md-5 mb-3'>Discover our Latest Content Listings</div>
                </Col>
                {ArticleToShowList.map((item, i) => {
                    return (<ArticleBox key={i + 1} coverIMG={item.image} title={item.articleName} starShow={item.averageRating} item={item}/>)
                })}
                {articleCount > (next + articlePerPage) && <div className='text-center'>
                    <Button variant="pink" ref={myRef} onClick={handleLoadMore}>Load More</Button>
                </div>}
            </Row>
            <Row className='mb-md-5 mb-3'>
                <Col md={12}>
                    <div className='CM_secTitle mt-md-5 mt-3'>Explore Content By Writing Type</div>
                    <div className='CM_secSubTitle mb-md-5 mb-3'>The World's 1st Permission Based Content Repository.</div>
                </Col>
                <Col md={6} lg={8} className='mb-3'>
                    <TitleIMGBox title="Editorial" coverIMG="https://images.unsplash.com/photo-1640904758535-067492e54ce9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80" logoIMG="https://contentmutual.com/assets/front/img/accolades/iflscience.png" />
                </Col>
                <Col md={6} lg={4} className='mb-3'>
                    <TitleIMGBox title="How To/Technical" coverIMG="https://images.unsplash.com/photo-1640904758535-067492e54ce9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80" logoIMG="https://contentmutual.com/assets/front/img/accolades/iflscience.png" />
                </Col>
                <Col md={6} lg={6} className='mb-3'>
                    <TitleIMGBox title="Opinion" coverIMG="https://images.unsplash.com/photo-1640904758535-067492e54ce9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80" logoIMG="https://contentmutual.com/assets/front/img/accolades/iflscience.png" />
                </Col>
                <Col md={6} lg={6} className='mb-3'>
                    <TitleIMGBox title="Personal Narrative" coverIMG="https://images.unsplash.com/photo-1640904758535-067492e54ce9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80" logoIMG="https://contentmutual.com/assets/front/img/accolades/iflscience.png" />
                </Col>
            </Row>
        </Container>
        <section className='CM_secHIW'>
            <div className='CM_secHIWinner'>
                <Container>
                    <div className='CM_title'>GET PAID PER PAGE VIEW</div>
                    <div className='CM_text pb-3 pt-4'>We facilitate private transactions ensuring your copyrighted works are presented exactly the way mutually agreed.
                        Click the button below to read the White Paper on our Patent Pending Process:</div>
                    <div className='text-center'><Link to="/" className='btn btn-outline-white'>How It Works</Link></div>
                </Container>
            </div>
        </section>
    </>)
}

export default HomePage;

export const CategoryBox = (props) => {
    return (<>
        <Col lg={4} className='mb-3'>
            <div className='CM_categoryBox'>
                <Icon name={props.name} />
                <h2>{props.title}</h2>
                <p>{props.text}</p>
            </div>
        </Col>
    </>)
}

export const ArticleBox = (props) => {

    const [isBookmarked, setIsBookMarked] = useState(props.item.bookmarked_by);

    const rateCount = (rated, count) => {
        let htmlOutput = [];
        for(let i = 0; i <count; i++) {
            htmlOutput.push((<i key={i + 1} className={`fa fa-star ${rated?'filled':''}`} aria-hidden="true"></i>))
        }
        return htmlOutput
    }
    const onHandleRate = (rate) => {
        return (<>
            {rateCount(true, rate)}
            {rateCount(false, 5 - rate)}
            <span className='CM_starValue'>{rate}</span>
        </>)
    }
    
    const authorName = props.item && props.item.author?props.item.author.replaceAll(/\s/g,''):'';
    const type = props.item && props.item.type?props.item.type.toLowerCase():'';
    const alias = props.item && props.item.alias;
    
    return (<>
        <Col lg={4} className='mb-3'>
            <div className='CM_ArticleBox fadeInUp'>
                <Link to={`/author/${authorName}/${type}/${alias}`}>
                    <img className='CM_cover' src={props.coverIMG} width={`100%`} alt="" />
                    <div className='CM_ArticleBoxContent'>
                        {props.item.accolade && <div className='CM_logo'>
                            <img src={props.item.accolade} alt="" />
                        </div>}
                        <h2>{props.title}</h2>
                    </div>
                </Link>
                <div className='px-4 py-1 d-flex align-items-center justify-content-between'>
                
                    {(props.item && props.item.averageRating)? <div className='d-flex align-items-center'>
                        {onHandleRate(parseInt(props.item.averageRating))}
                        </div> : <div className='CM_NYR'>Not Yet Rated</div>}
                    <BookMarkComponent type='article' id={props.item.articleId} isBookmarked={isBookmarked} onCallBack={(status) => setIsBookMarked(status)}/>
                </div>
            </div>
        </Col>
    </>)
}

export const FeaturesArticleBox = (props) => {
    const rateCount = (rated, count) => {
        let htmlOutput = [];
        for(let i = 0; i <count; i++) {
            htmlOutput.push((<i key={i + 1} className={`fa fa-star ${rated?'filled':''}`} aria-hidden="true"></i>))
        }
        return htmlOutput
    } 
    
    const onHandleRate = (rate) => {
        return (<>
            {rateCount(true, rate)}
            {rateCount(false, 5 - rate)}
            <span className='CM_starValue'>{rate}</span>
        </>)
    }

    // const authorName = props.item && props.item.author?props.item.author.replaceAll(/\s/g,''):'';
    // const type = props.item && props.item.type?props.item.type.toLowerCase():'';
    // const alias = props.item && props.item.alias;
    
    return (<>
        <Col lg={4} className='mb-3'>
            <div className='CM_ArticleBox fadeInUp'>
                <Link to={`${props.item && props.item.publicUrl}`}>
                    <img className='CM_cover' src={props.item && props.item.image} width={`100%`} alt="" />
                    <div className='CM_ArticleBoxContent'>
                        {props.item.accolade && <div className='CM_logo'>
                            <img src={props.item.accolade} alt="" />
                        </div>}
                        <h2>{props.item && props.item.name}</h2>
                    </div>
                </Link>
                <div className='px-4 py-1 d-flex align-items-center justify-content-between'>
                
                    {(props.item && props.item.averageRound)? <div className='d-flex align-items-center'>
                        {onHandleRate(parseInt(props.item.averageRound))}
                        </div> : <div className='CM_NYR'>Not Yet Rated</div>}
                    <BookMarkComponent type='article' id={props.item.id} isBookmarked={props.item.bookmarked_by} onCallBack={() => props.callBack()}/>
                </div>
            </div> 
        </Col>
    </>)
}

export const TitleIMGBox = (props) => {
    const history = useHistory();
    return (<>
        <a href="#/" onClick={() => history.push(`/search?p=${encodeURIComponent(JSON.stringify([{'name': 'attributes_type', 'value': props.title}]))}`)
        } className='CM_TitleIMGBox' style={{ backgroundImage: `url(${props.coverIMG})` }}>
            <h2>{props.title}</h2>
        </a>
    </>)
}