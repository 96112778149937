import axios from '../helpers/axios';
import { APIEndpoints } from './endpoint'

export function getAllMyrequests(query) {
    return axios.post(`${APIEndpoints.getAllMyrequests}${query}`);
}

export function articleRequestTerminateSubscription(id) {
    return axios.post(`${APIEndpoints.articleRequestTerminateSubscription}?id=${id}`, {});
}

export function articleRequestApprove(id) {
    return axios.post(`${APIEndpoints.articleRequestApprove}?id=${id}`, {});
}

export function articleRequestDisapprove(id) {
    return axios.post(`${APIEndpoints.articleRequestDisapprove}?id=${id}`, {});
}

export function splittesting(query, data = {}) {
    return axios.post(`${APIEndpoints.splittesting}${query}`, data, {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    });
}

export function negotiate(id, data = {}) {
    return axios.post(`${APIEndpoints.negotiate}?id=${id}`, data);
}

export function negotiationDelete(id) {
    return axios.post(`${APIEndpoints.negotiationDelete}?id=${id}`);
}

export function negotiationMessage(id, data = {}) {
    return axios.post(`${APIEndpoints.negotiationMessage}?id=${id}`, data);
}

export function getmutualcode(id, data = {}) {
    return axios.post(`${APIEndpoints.getmutualcode}?id=${id}`, data);
}

export function getmutualcodePost(data = {}) {
    return axios.post(`${APIEndpoints.getmutualcodePost}`, data);
}

export function requestgraph(query) {
    return axios.post(`${APIEndpoints.requestgraph}${query}`, {});
}

export function articlegraph(query) {
    return axios.post(`${APIEndpoints.articlegraph}${query}`, {});
}

export function negotiationAccept(id) {
    return axios.post(`${APIEndpoints.negotiationAccept}?id=${id}`, {});
}

export function request2(data) {
    return axios.post(`${APIEndpoints.request2}`, data);
}

export function analytics(query) {
    return axios.post(`${APIEndpoints.analytics}${query}`, {});
}

export function deploySmartContract(data) {
    return axios.post(`${APIEndpoints.deploySmartContract}`, data);
}

export function authorWithdraw(data) {
    return axios.post(`${APIEndpoints.authorWithdraw}`, data);
}

export function deposite(data) {
    return axios.post(`${APIEndpoints.deposite}`, data);
}