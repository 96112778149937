import React, { useEffect, useState, useContext } from 'react'
import { Col, Button, Container, Row } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import qs from 'query-string';
import { getAuthors, getUserFeed } from '../action/article.action';
import { FollowComponent } from '../components/FollowComponent';
import { BookMarkComponent } from '../components/BookMarkComponent';
import { ReviewRatingComponent } from '../components/Author/ReviewRatingComponent';
import { GlobalContext } from '../context/globalContext'
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import Banner from '../assets/images/banner.jpg'

const articlePerPage = 5;
let arrayForHoldingNewsFeeds = [];

const apiUrl = process.env.REACT_APP_API_URL;

const Authors = () => {
    const history = useHistory()
    const {isLoggedIn, userData} = useContext(GlobalContext);
    const [getAllAuthors, setAllAuthors] = useState([]);
    const { author } = useParams();
    const [feeds, setFeeds] = useState([])
    const [ArticleToShowList, setArticleToShowList] = useState([]);
    const [next, setNext] = useState(0);
    const [page, setPage] = useState(0);
    const [isFeedLoader, setIsFeedLoader] = useState(false);

    const getImageUrl = (path) => {
        if(path.includes(window.location.origin)) {
            return path;   
        }
        return apiUrl + "/thumb?src=" + path + "&w=120&h=120"
    }

    const loopWithSlice = (start, end) => {
        const slicedPosts = feeds.slice(start, end);
        arrayForHoldingNewsFeeds = [...arrayForHoldingNewsFeeds, ...slicedPosts];
        setArticleToShowList(arrayForHoldingNewsFeeds);
    };

    const onRenderAuthors = async () => {
        const query = qs.parse(history.location.search);
        const {data} = await getAuthors({url: `/${author}${qs.stringify(query)?`?${qs.stringify(query)}`:''}`})
        if(data.status === 200) {
            setAllAuthors(data.data)
        }
    }

    
    const onRenderFeeds = async () => {
        if(getAllAuthors.author_id) {
            const {data} = await getUserFeed({user_id: getAllAuthors.author_id, lt: feeds.length > 0?feeds[feeds.length - 1].last_id:0})
            
            if(data.status === 200) {
                if(data.data.length > 0) {
                    setIsFeedLoader(data.data[0]['isLoadMore']);
                } else {
                    setIsFeedLoader(false);
                }
                setFeeds([...feeds, ...data.data])
            }
        }
    }

    useEffect(() => {
        onRenderAuthors();
    }, [isLoggedIn])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            arrayForHoldingNewsFeeds = [];
            setArticleToShowList([]);
        }
    }, [])

    useEffect(() => {
        if(getAllAuthors.author_id) {
            onRenderFeeds();
        }
    }, [getAllAuthors.author_id])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let {page} = queryString.parse(history.location.search)
        if(page) {
            setPage(page - 1);
        } else {
            setPage(0);
        }
        setFeeds([]);
        onRenderAuthors();
    }, [history.location])// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if(feeds.length > 0) {
            const addNext = next + articlePerPage;
            loopWithSlice(feeds.length===5?0:next, addNext);
            setNext(addNext);
        }
    }, [feeds])// eslint-disable-line react-hooks/exhaustive-deps

    const handleLoadMore = () => {
        onRenderFeeds()
    }
    
    const onClickToSort = (string) => {
        let obj = qs.parse(history.location.search);
        if(typeof string === 'number') {
            obj = {...obj, type_id: string}
        } else {
            obj = {...obj, ob: string, ot: getAllAuthors.search?getAllAuthors.search[string]:''}  
        }
        history.push(`/author/${author}${qs.stringify(obj)?`?${qs.stringify(obj)}`:''}`)
    }
    
    const onClickToFolowers = url => {
        history.push(url)
    }
    
    const onPageClick = (e) => {
        const {pathname, search} = history.location;
        let query = queryString.parse(search)
        query.page = e.selected + 1;
        query = queryString.stringify(query);
        history.push(`${pathname}?${query}`)
    }
    
    return (<>
        <div className='cm_TimSpectorCover' style={{ backgroundImage: `url(${getAllAuthors.author_image?getAllAuthors.author_image:Banner})` }}>
            <Container>
                <div className='d-flex align-items-center justify-content-center'>
                    {getAllAuthors.public_image && <div className='cm_TimSpectorImg'>
                        <img src={getImageUrl(getAllAuthors.public_image)} alt='' />
                    </div>}
                    <div className='cm_TimSpectorTitle mx-3'>{getAllAuthors.print_name}</div>
                    {getAllAuthors.author_id !== userData.id && <FollowComponent type='Author' id={getAllAuthors.author_id} isFollowing={getAllAuthors.isFollowing} onCallBack={onRenderAuthors}/>}
                </div>
                <div className='d-flex align-items-center justify-content-center py-4'>
                    <span className="ion-ios-copy-outline fs-24px text-white"></span>
                    <span className='cm_reviews'>{getAllAuthors.search?getAllAuthors.search.foundRows:0} Articles Created</span>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    {isLoggedIn && <BookMarkComponent type='user' id={getAllAuthors.author_id} isBookmarked={getAllAuthors.bookmarked_by} onCallBack={onRenderAuthors}/>}
                    <span className='cm_reviews'>{getAllAuthors.bookmark_count?getAllAuthors.bookmark_count:0} Favorites</span>
                </div>
            </Container>
        </div >
        <Container className='py-5'>
            <Row>
                <Col md={8}>
                    <div className='text-green fs-16px pb-4'>{getAllAuthors.print_name} Listings ({getAllAuthors.articles?getAllAuthors.articles.length:0})</div>
                    <div className='fs-13px d-flex align-items-center'>
                        <div className='text-green'>Sort by</div>
                        <Button variant='link' className='cm_FilterBtn py-0 px-1' onClick={() => onClickToSort('name')}>Name</Button>|
                        <Button variant='link' className='cm_FilterBtn py-0 px-1' onClick={() => onClickToSort('created')}>Date</Button>|
                        <Button variant='link' className='cm_FilterBtn py-0 px-1' onClick={() => onClickToSort('applications')}>Reprints</Button>
                    </div>
                    <div className='fs-13px d-flex align-items-center'>
                        <div className='text-green'>Types</div>
                        <Button variant='link' className='cm_FilterBtn py-0 px-1' onClick={() => onClickToSort(1)}>Article</Button>|
                        <Button variant='link' className='cm_FilterBtn py-0 px-1' onClick={() => onClickToSort(2)}>Image</Button>|
                        <Button variant='link' className='cm_FilterBtn py-0 px-1' onClick={() => onClickToSort(3)}>Video </Button>|
                        <Button variant='link' className='cm_FilterBtn py-0 px-1' onClick={() => onClickToSort(4)}>Audio</Button>
                    </div>
                    {/* -------------------------------- */}
                    {getAllAuthors.articles && getAllAuthors.articles.map((res, idx) => (
                        <div key={idx + 1} className='CM_whiteBox cm_listspectorItem align-items-center d-flex mb-4 justify-content-between'>
                            <div className='align-items-center d-flex text-green'>
                                <Link to={res.public_url?res.public_url:''} className='cm_imglistspector d-none d-md-inline-block'>
                                    <img src={getImageUrl(res.image)} alt='' />
                                </Link>
                                <div className='max-w-325px ps-md-3'>
                                    <Link to={res.public_url?res.public_url:''} className='cm_spectorTitle'>{res.article_name}</Link>
                                    <div className='mb-3'>
                                        <a className='cm_maplink text-green' href={`http://maps.google.com/maps?q=${res.locationString}`} target='_blank' rel="noreferrer"><span className='ion-compass me-2' />{res.locationString}</a>
                                    </div>
                                </div>
                            </div>
                            <BookMarkComponent type='user' id={res.article_id} isBookmarked={res.bookmarked_by} onCallBack={onRenderAuthors}/>
                        </div>
                    ))}
                    {getAllAuthors.total_pages > 0 && <ReactPaginate
                        style={{ color: 'red' }}
                        className="cmn-Pagination"
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={onPageClick}
                        pageRangeDisplayed={5}
                        pageCount={getAllAuthors.total_pages?parseInt(getAllAuthors.total_pages):0}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        forcePage={page}
                    />}
                    {/* {console.log("getAllAuthors.can_comment", getAllAuthors.can_comment)} */}
                    {getAllAuthors && <div className='CM_whiteBox p-3'>
                        <ReviewRatingComponent data={getAllAuthors} onCallBack={onRenderAuthors}/>
                    </div>}
                </Col>
                <Col md={4}>
                    <div className='CM_whiteBox'>
                        <div className='cm_boxTitle text-green'><span className="ion-share fs-24px me-2" />Social Profiles</div>
                        {getAllAuthors.social_tw && <div className='d-flex align-items-center flex-wrap'>
                            <a className="social_btn me-1 mb-1" target="_blank" rel="noreferrer" href={getAllAuthors.social_tw_object.profile_url}>{getAllAuthors.social_tw_object && <><i className='ion-social-twitter me-1' /><span className="label" id="l">Follow <b>@{getAllAuthors.social_tw_object.display_name}</b></span></>}</a>
                        </div>}
                        <div className='cm_boxTitle text-green mt-3'><span className="ion-share fs-24px me-2" />Followers ({getAllAuthors.FollowerCount})</div>
                        <div className='d-flex align-items-center flex-wrap'>
                            {getAllAuthors.followers && getAllAuthors.followers.map((res, i) => <img key={i + 1} src={res.public_image} alt='' onClick={() => onClickToFolowers(res.author_url)} className='cm_img32px' />)}
                        </div>
                        <div className='cm_boxTitle text-green mt-3'><span className="ion-share fs-24px me-2" />Follows ({getAllAuthors.FolloweCount})</div>
                        <div className='d-flex align-items-center flex-wrap'>
                            {getAllAuthors.following && getAllAuthors.following.map((res,i) => <img key={i + 1} src={res.public_image} alt='' onClick={() => onClickToFolowers(res.author_url)} className='cm_img32px' />)}  
                        </div>
                        <div className='cm_boxTitle text-green mt-3'><span className="ion-share fs-24px me-2" />User Feed</div>
                        <div id="cm_article_feed">
                            {ArticleToShowList && ArticleToShowList.map((item, i) => {
                                return (<div className="cm_feed_activity" key={i}>
                                    <img src={item.image} alt="" className="feed-profile-image" />
                                    <Link to={item.author_url}>{item.print_name}</Link> {item.status} <Link to={item.public_url}>{item.name}</Link>
                                    <span className="feed-time">{item.activity}</span>
                                </div>)
                            })}
                        </div>
                        {isFeedLoader && <div className='text-center'><Button variant='loadmore' onClick={handleLoadMore}>Load more</Button></div>}
                    </div>
                </Col>
            </Row>
        </Container>
        {/* -----------Favorites Modal------------- */}
    </>)
}

export default Authors