import { useSlate } from 'slate-react'
import { Button } from "react-bootstrap"
import { isBlockActive, toggleBlock } from './utils'
import Icon from './../../components/Icon'

const BlockButton = ({ format, icon, text = null, className = null}) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format, isBlockActive(editor, format))
            }}
            className={className}
        >
            {text ? text : <Icon name={icon} />}
        </Button>
    )
}

export default BlockButton;