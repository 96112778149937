import React, {useState} from 'react';
import { Button, Modal } from 'react-bootstrap'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import toast from '../helpers/toast';

export const accessUrl = process.env.REACT_APP_ACCESS_URL;

const modalSubscriberCode = ({ image, http_host, description, subscriber_id }) => {
    http_host = http_host.toString().replace('api.', '');
    let output = [];
    output.push('<div class="cm_description">');
    if(image) {
        output.push(`<img src="${image}">`);
    }
    output.push(description,
        '</div>',
        '<script src="https://access.'+http_host+'/embed.js?subscriberID=' + subscriber_id + '"></script>',
        '<noscript>',
        '<iframe src="https://access.'+http_host+'/iframe.html?subscriberID=' + subscriber_id + '"></iframe>',
        '</noscript>')
    return output.join("\n");
}

export const ShowContentMutualCode = ({ data }) => {
    const [showModal, setShowModal] = useState(false);
    
    const onClickToCloseModal = () => setShowModal(false);
    const onClickToOpenModal = () => setShowModal(true);
    
    return (<>
        <button type='button' onClick={onClickToOpenModal} className="show-subscriber-textarea btn btn-danger btn-sm"><i className="fa fa-eye"></i> Show Mutual-Code</button>
        <Modal show={showModal} onHide={onClickToCloseModal} size="xl" centered>
            <Modal.Header className='border-0 py-0' closeButton>
                {/* <div className='fs-20px text-green fs-md-24px'>Bookmark This Listing</div> */}
            </Modal.Header>
            <Modal.Body>
                <textarea className='cm_input' rows="10" spellCheck="false" defaultValue={modalSubscriberCode(data)}></textarea>
                <br />
                <CopyToClipboard text={modalSubscriberCode(data)}>
                    <Button variant='link' onClick={() => toast.success("Successfully Copied.")} className='text-green p-0 text-decoration-none fs-13px'>Copy to clipboard</Button>
                </CopyToClipboard>
                {/* <Button variant='link' className='text-green p-0 text-decoration-none fs-13px'>Copy to clipboard</Button> */}
            </Modal.Body>
        </Modal>
    </>)
}
