import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';

export const Step3 = ({state}) => {

    const history = useHistory();

    const onClickToNext = async () => {
        history.push('/article/submit?step=4');
    }
    
    const createMarkup = () => {
        return {__html: state.fix_content}
    }

    return (<>
        <div>
            <div className='text-green fs-md-22px fs-18px'>{state.name}</div>
            <div className='text-green mb-3' dangerouslySetInnerHTML={createMarkup()}/>
            <Button variant='green' className='mt-3 me-2' onClick={onClickToNext}>Next →</Button>
        </div>
    </>)
}