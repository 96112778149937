import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { PageTitle } from "../components/Typography";

import { getAllMyArticles, deleteArticle } from "../action/article.action";
import { formattedDate } from "../Utils/AppUtill";
import { RTimeAgo } from "../components/RTimeAgo";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import UpdatePriceModal from "../components/UpdatePriceModal";

const MyListings = () => {
  const history = useHistory();
  const [state, setState] = useState({search_name: ""});
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false);

  const [deleteId, setDeleteId] = useState(null);

  const onClickToCloseModal = () => setShow(false);
  const onClickToOpenModal = () => setShow(true);

  const onRetriveMyArticles = async () => {
    const { data } = await getAllMyArticles(history.location.search);
    if (data.status === 200) {
      setState(data.data);
    }
  };

  useEffect(() => {
    let { page } = queryString.parse(history.location.search);
    if (page) {
      setPage(page - 1);
    } else {
      setPage(0);
    }
    onRetriveMyArticles();
  }, [history.location]);// eslint-disable-line react-hooks/exhaustive-deps

  const onPageClick = (status = "page", e) => {
    const { pathname, search } = history.location;
    let query = queryString.parse(search);
    if (status !== "page") {
      if (status.ob) {
        query.ot = query.ot
          ? query.ot === "desc"
            ? "asc"
            : "desc"
          : status.ot;
        
        query.ob = status.ob;
      }
      if(status.name) {
        query.name = status.name;  
      } else {
        query.name = "";
      }
      query = { ...status, ...query };
    } else {
      query.page = e.selected + 1;
    }
    
    query = queryString.stringify(query);
    history.push(`${pathname}?${query}`);
  };

  const onChangeSearch = (e) => {
    setState({ ...state, search_name: e.target.value });
  };

  const onClickToSubmit = (e) => {
    e.preventDefault();
    onPageClick({ name: state.search_name });
  };

  const onClickToDelete = async (e) => {
    const { data } = await deleteArticle(deleteId);
    if (data.status === 200) {
      onRetriveMyArticles();
      onClickToCloseModal();
    }
  };

  return (
    <>
      <PageTitle
        style={{
          backgroundImage: `url(../assets/images/how-it-works.jpg)`,
        }}
        text="My Listings"
      />
      <Container className="my-5">
        <div className="cm_userheader">
          <Row className="align-items-center">
            <Col md={7} className="sortby mb-2 mb-lg-0">
              <span>Sort by :</span>
                
              <a href="#/" onClick={(e) => onPageClick({ ob: "name", ot: "desc" }, e)}>
                Title
              </a>
              |
              <a href="#/" onClick={(e) => onPageClick({ ob: "created", ot: "desc" }, e)}>
                Date Posted
              </a>
              |
              <a
                href="#/"
                onClick={(e) =>
                  onPageClick({ ob: "last_updated", ot: "desc" }, e)
                }
              >
                Last Updated
              </a>
              |
              <a href="#/" onClick={(e) => onPageClick({ ob: "type_id", ot: "desc" }, e)}>
                Type
              </a>
              |
              <a
                href="#/"
                onClick={(e) =>
                  onPageClick({ ob: "applications", ot: "desc" }, e)
                }
              >
                Reprints
              </a>
            </Col>
            <Col md={5} className="mb-1 mb-lg-0">
              <form className="cm_gridform" onSubmit={onClickToSubmit}>
                <input
                  type="text"
                  value={state.search_name}
                  onChange={onChangeSearch}
                  placeholder="Search Title"
                />
                <Button
                  variant="green"
                  onClick={onClickToSubmit}
                  className="py-1"
                >
                  Submit
                </Button>
              </form>
            </Col>
          </Row>
        </div>
        {state.articles &&
          state.articles.map((res, indx) => {
            return (
              <div className="cm_usertable" key={indx + 1}>
                <Row>
                  <Col lg={2} className="text-center">
                    <img alt="" src={res.image} className="cm_img90rounded" />
                  </Col>
                  <Col lg={8}>
                    {/* to={`/article/delete?id=${res.getId}`} */}
                    <h2 className="cm_articleTitle">
                      <strong>
                        <Link to={res.public_url}>{res.name}</Link>
                      </strong>
                      <small>
                        {" "}
                        (
                        {res.created && (
                          <RTimeAgo date={formattedDate(res.created)} />
                        )}
                        )
                      </small>
                    </h2>
                    <div className="cm_detailsinfo">{res.description}</div>
                    <ul className="cm-dashboard-btn">
                      <li className="bg-success">
                        <Link to={`/article/edit?id=${res.getId}`}>
                          <i className="ion-edit" />
                          Edit
                        </Link>
                      </li>
                      <li className="bg-danger">
                        <a
                          href="#/"
                          onClick={() => {
                            onClickToOpenModal();
                            setDeleteId(res.getId);
                          }}
                        >
                          <i className="ion-close-round" />
                          Delete
                        </a>
                      </li>
                      <li className="bg-info">
                        <Link to={`/analytics/articlegraph?id=${res.getId}`}>
                          <i className="fa fa-area-chart" />
                          Analytics
                        </Link>
                      </li>
                      <li className="bg-secondary">
                        <Link to={`/article/revisions?id=${res.getId}`}>
                          <i className="fa fa-file" /> Revisions
                        </Link>
                      </li>
                      <li className="bg-primary">
                        <Link to={`/copyright/viewcopyhistory?id=${res.getId}`}>
                          <i className="fa fa-file" /> Copyright History
                        </Link>
                      </li>
                      <li className="bg-warning">
                        <UpdatePriceModal
                          state={res}
                          onCallBack={onRetriveMyArticles}
                        />
                      </li>
                    </ul>
                  </Col>
                  <Col
                    lg={2}
                    className="py-2 right_block d-flex align-items-center"
                  >
                    <div className="text-green fs-13px">
                      <div className="mt-2 mt-lg-0">
                        <i className="fa fa-folder-o me-1"></i> Type:
                        <Link
                          to={`/user/articles?type_id=${res.type_id}`}
                          className="text-green fs-13px text-decoration-none ms-1"
                        >
                          {res.type_name}
                        </Link>
                      </div>

                      <div className="mt-2 mt-lg-3">
                        <i className="fa fa-print me-1"></i> Reprints:
                        <Link
                          to={`/user/requests?article_id=${res.type_id}`}
                          className="text-green fs-13px text-decoration-none ms-1"
                        >
                          {res.applications}
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        {state.total_pages > 0 && <ReactPaginate
          style={{ color: "red" }}
          className="cmn-Pagination"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={(e) => onPageClick("page", e)}
          pageRangeDisplayed={5}
          pageCount={state.total_pages}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          forcePage={page}
        />}
        <Modal show={show} onHide={onClickToCloseModal}>
          <Modal.Header>
            <Modal.Title>Delete Article</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onClickToCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={onClickToDelete}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default MyListings;
