import axios from '../helpers/axios';
import { APIEndpoints } from './endpoint'

export function getBilling(query) {
    return axios.post(`${APIEndpoints.getBilling}${query}`, {});
}

export function getInvoice(query) {
    return axios.post(`${APIEndpoints.getInvoice}${query}`, {});
}

export function payInvoice(id) {
    return axios.post(`${APIEndpoints.payInvoice}?id=${id}`, {});
}

export function getAllTransactions(query) {
    return axios.post(`${APIEndpoints.getAllTransactions}${query}`, {});
}

export function withdraw(data) {
    return axios.post(`${APIEndpoints.withdraw}`, data);
}

export function deposite(data) {
    return axios.post(`${APIEndpoints.deposite}`, data);
}

export function depositsuccess(data) {
    return axios.post(`${APIEndpoints.depositsuccess}`, data);
}

export function depositcancel() {
    return axios.post(`${APIEndpoints.depositcancel}`);
}