import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router';
import Main from '../layout/main'
import HomePage from '../pages/HomePage'
import SearchPage from '../pages/SearchPage'
import LoginPage from '../pages/Loginpage'
import ProtectedRoute from './ProtectedRoute'
import UnprotectedRoute from './unprotected-route'
import PageNotFound from '../pages/PageNotFound'
import { GlobalContext } from '../context/globalContext'
import SubmitArticlePage from '../pages/Article/SubmitArticlePage';
import EditArticlePage from '../pages/Article/EditArticlePage';
import ArticleDetailsPage from '../pages/ArticleDetailsPage';
import Authors from '../pages/Authors';
import Notification from '../pages/Notifications'
import MyListings from '../pages/MyListings';
import MyRequests from '../pages/MyRequests';
import RequestVersions from '../pages/RequestVersions';
import Message from '../pages/Messages';
import Customize from '../pages/Customize';
import SplitTesting from '../pages/SplitTesting';
import Revision from '../pages/RevisionsPage';
import Copyright from '../pages/CopyrightPage';
import Billing from '../pages/Billing';
import Invoice from '../pages/Invoice';
import Transactions from '../pages/Transactions';
import Withdraw from '../pages/Withdraw';
import Deposit from '../pages/Deposit';
import MyInvitation from '../pages/MyInvitation';
import SendInvite from '../pages/SendInvite';
import MyAccount from '../pages/MyAccount'
import MyPreferences from '../pages/PreferencesPage';
import MyBookmarks from '../pages/BookmarksPage';
import MyRegister from '../pages/RegisterPage';
import EditBookmarkPage from '../pages/EditBookMarkPage';
import Analytics from '../pages/Analytics';
import AnalyticsPage from '../pages/AnalyticsPage'
import ViewCopyPage from '../pages/ViewCopyPage';
import RequestInvitationPage from '../pages/RequestInvitationPage';
import LostPasswordPage from '../pages/LostPasswordPage';
import resetPasswordPage from '../pages/resetPasswordPage';
import RequstApprove from '../pages/RequstApprove';

const Routes = () => {
  
  const { isLoggedIn } = useContext(GlobalContext)
  
  return (
    <Main>
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          render={props =>
            isLoggedIn ? (<HomePage {...props} />
            ) : (
              <HomePage />
              // <Login {...props} />
            )
          }
        />
        <ProtectedRoute
          exact
          path="/article/submit"
          component={SubmitArticlePage}
        />
        <ProtectedRoute
          exact
          path="/article/edit"
          component={EditArticlePage}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/user/login"
          component={LoginPage}
        />
        <ProtectedRoute
          exact
          path="/notification"
          component={Notification}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/user/articles"
          component={MyListings}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/user/requests"
          component={MyRequests}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/article/requestversions"
          component={RequestVersions}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/article/revisions"
          component={Revision}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/copyright/viewcopyhistory"
          component={Copyright}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/user/activesubscriptions"
          component={MyRequests}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/user/myrequests"
          component={MyRequests}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/article/payment"
          component={MyRequests}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/request/message"
          component={Message}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/articlerequest/customize"
          component={Customize}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/articlerequest/splittesting"
          component={SplitTesting}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/billing"
          component={Billing}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/billing/invoice"
          component={Invoice}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/billing/transactions"
          component={Transactions}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/billing/withdraw"
          component={Withdraw}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/billing/deposit"
          component={Deposit}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/invite/listing"
          component={MyInvitation}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/invite/sendinvite"
          component={SendInvite}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/user/myaccount"
          component={MyAccount}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/user/preferences"
          component={MyPreferences}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/user/bookmarks"
          component={MyBookmarks}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/invite/register"
          component={MyRegister}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/user/request_approve"
          component={RequstApprove}
          auth={false}
        />
        <ProtectedRoute
          exact
          path="/article/editbookmark"
          component={EditBookmarkPage}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/analytics/requestgraph"
          component={Analytics}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/analytics"
          component={AnalyticsPage}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/analytics/articlegraph"
          component={Analytics}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/copyright/viewcopy"
          component={ViewCopyPage}
          auth={true}
        />
        <ProtectedRoute
          exact
          path="/user/request"
          component={RequestInvitationPage}
          auth={false}
        />
        <ProtectedRoute
          exact
          path="/user/lost-password"
          component={LostPasswordPage}
          auth={false}
        />
        <ProtectedRoute
          exact
          path="/user/reset"
          component={resetPasswordPage}
          auth={false}
        />
        
        <UnprotectedRoute
          exact
          path="/search"
          component={SearchPage}
        />
        <UnprotectedRoute
          exact
          path="/search/:searchTitle"
          component={SearchPage}
        />
        <UnprotectedRoute
          exact
          path="/author"
          component={ArticleDetailsPage}
        />
        <UnprotectedRoute
          exact
          path="/author/:author/:type/:alias"
          component={ArticleDetailsPage}
        />
        <UnprotectedRoute
          exact
          path="/author/:author"
          component={Authors}
        />
        {/* <UnprotectedRoute
          exact
          path="/register"
          component={Register}
        /> */}
        {/* <ProtectedRoute
          exact
          path="homepage"
          component={HomePage}
          auth={isLoggedIn}
          role="Estates"
        /> */}
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </Main>
  )
}

export default withRouter(Routes)
