import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Button, Modal } from 'react-bootstrap';
import { Label } from '../components/Typography'
import { Link } from 'react-router-dom';
import { Negotiations } from '../components/NegotiationsModal';
import { PageTitle } from '../components/Typography';
import { getAllMyrequests, articleRequestTerminateSubscription, articleRequestApprove, negotiationAccept, deploySmartContract, authorWithdraw, deposite } from '../action/request.action';
import { useHistory } from "react-router-dom";
import { formattedDate } from '../Utils/AppUtill';
import { RTimeAgo } from '../components/RTimeAgo';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import { ShowContentMutualCode } from '../components/ShowContentMutualCode';
import { depositToContract, withdrawFromContract } from '../web3/functions'
import { Web3Context } from '../web3/contexts/web3Context'
import { GlobalContext } from '../context/globalContext';
import { shortAddress, etherscanLink } from '../web3/helpers/utils';
import toast from "../helpers/toast"
import { TransactionContext } from '../context/transactionContext';
import ErrorLabel from '../widgets/ErrorLable';
import {Controller, useForm} from 'react-hook-form'
import Joi from 'joi'
import {joiResolver} from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../Utils/AppUtill';
import CMTooltip from '../components/CMTooltip';

const apiUrl = process.env.REACT_APP_API_URL;

const MyRequests = () => {
    const { setUserInfo, userData } = useContext(GlobalContext);    
    const { handleConnect } = useContext(Web3Context);
    const { transactionHistory, handleTransactionHistory } = useContext(TransactionContext);
    const history = useHistory();
    const { pathname, search } = history.location;
    const [state, setState] = useState({});
    const [terminateId, setTerminateId] = useState('');
    const [negotiationId, setNegotiationId] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNegotiationsModal, setShowNegotiationsModal] = useState(false);
    const [showDepositAmountModal, setShowDepositAmountModal] = useState(false);
    const [depositRequest, setDepositRequest] = useState({});
    const handleCloseNegotiationsModal = () => setShowNegotiationsModal(false)
    const handleShowNegotiationsModal = () => setShowNegotiationsModal(true)
    const [page, setPage] = useState(0);   
    // const handleShowCodesModal = (article_request) => {
    //     setModalArticleRequest(article_request);
    //     setShowCodesModal(true)
    // }

    const {
        handleSubmit,
        formState: {errors},
        control,
        reset,
        getValues
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                amount: Joi.string()
                    .regex(/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/)
                    .message("Allow only number")
                    .required()
                    .label('Amount')
                    .messages(joiUpdatedMessage),
            }),
        ),
    })
    
    const getImageUrl = (path) => {
        if(path.includes(window.location.origin)) {
            return path;   
        }
        return apiUrl + "/thumb?src=" + path + "&w=120&h=120"
    }

    const onRenderRequestArticle = async () => {
        let query = queryString.parse(search);

        if (pathname === '/user/requests') {
            query.pathname = "requests";
        } else if (pathname === '/user/activesubscriptions') {
            query.pathname = "activesubscriptions";
        } else if (pathname === '/user/myrequests') {
            query.pathname = "myrequests";
        }

        query = queryString.stringify(query);
        let qString = query ? `?${query}` : '';

        const data = await getAllMyrequests(qString);
        if (data.status === 200) {
            setState(data.data.data)
        }
    }
    
    useEffect(() => {
        let {page} = queryString.parse(search)
        if(page) {
            setPage(page - 1);
        } else {
            setPage(0);
        }
        onRenderRequestArticle();
    }, [history.location]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        onRenderRequestArticle();
    }, [transactionHistory])

    const onClickToOpenDepositAmountModel = () => {
        setShowDepositAmountModal(true)
    }

    const onClickToCloseDepositAmountModel = () => {
        setShowDepositAmountModal(false)
    }

    const onClickToCloseModal = () => {
        setShowDeleteModal(false)
    }

    const onClickToOpenModal = () => {
        setShowDeleteModal(true)
    }

    const onClickToDelete = async () => {
        const { data } = await articleRequestTerminateSubscription(terminateId);
        if (data.status === 200) {
            onRenderRequestArticle();
            onClickToCloseModal();
        }
    }
    
    const onPageClick = (e) => {
        let query = queryString.parse(search)
        query.page = e.selected + 1;
        query = queryString.stringify(query);
        history.push(`${pathname}?${query}`)
    }
    
    const onClickToApprove = async (id) => {
        const { data } = await articleRequestApprove(id);
        if (data.status === 200) {
            onRenderRequestArticle();
        }
    }
    
    const onClickToAcceptNegotiation = async (id) => {
        const { data } = await negotiationAccept(id);
        if (data.status === 200) {
            onRenderRequestArticle();
        }
    }

    const invokeContract = async (ress) => {
        let txHashVal = "";
        const amount = getValues('amount');
        try {
            const callBack = (txHash) => {
                handleTransactionHistory('deposit', 'add', {address: ress.contract_address, author_address: ress.author_address, publisher_address: ress.publisher_address, amount, type: 'deposit', txHash, currency: 'USDC', id: ress.id });
                txHashVal = txHash;
            }
            
            let walletName = "metamask";
            if(pathname === '/user/requests') {
                walletName = ress.author_wallet;
            } else if(pathname === '/user/myrequests') {
                walletName = ress.publisher_wallet;
            }

            await handleConnect(walletName);
            try {
                await depositToContract(ress.contract_address, amount*1000000, callBack);
            } catch (error) {
                handleTransactionHistory('deposit', 'remove', {address: ress.contract_address, author_address: ress.author_address, publisher_address: ress.publisher_address, amount, type: 'deposit'}, txHashVal)
                toast.error(error?.error?.message);
            }
            
        } catch (error) {
            handleTransactionHistory('deposit', 'remove', {address: ress.contract_address, author_address: ress.author_address, publisher_address: ress.publisher_address, amount, type: 'deposit'}, txHashVal)
            toast.error(error?.error?.message);
        }
    }

    const invokeMultisigContract = async (res) => {
        let txHashVal = "";
        try {
            const callBack = (txHash) => {
                handleTransactionHistory('withdraw', 'add', {address: res.contract_address, author_address: res.author_address, publisher_address: res.publisher_address, type: 'withdraw', id: res.id, txHash})
                txHashVal = txHash;
            }
            
            let walletName = "metamask";
            if(pathname === '/user/requests') {
                walletName = res.author_wallet;
            } else if(pathname === '/user/myrequests') {
                walletName = res.publisher_wallet;
            }

            await handleConnect(walletName);
            try { 
                await withdrawFromContract(res.contract_address, callBack);
            } catch (error) {
                handleTransactionHistory('withdraw', 'remove', {address: res.contract_address, author_address: res.author_address, publisher_address: res.publisher_address, type: 'withdraw'}, txHashVal);
                toast.error(error?.error?.message);    
            }
            
        } catch(error) {
            handleTransactionHistory('withdraw', 'remove', {address: res.contract_address, author_address: res.author_address, publisher_address: res.publisher_address, type: 'withdraw'}, txHashVal);
            toast.error(error?.error?.message);
        }
    }

    const onClickToDeployContract = async(id) => {
        const formData = new FormData();
        formData.append('articlerequest_id', id);
        const { data } = await deploySmartContract(formData)
        if (data.status === 200) {
            onRenderRequestArticle();
            const findIndex = state.requests.findIndex(r => r.id === id);
            state.requests[findIndex].contract_address = data.data.address;
            if(data.data.subscriber_id)
                state.requests[findIndex].subscriber_id = data.data.subscriber_id;
            setState(state);
        }
    }

    const onRenderActions = (res) => {
        const output = [];
        if((userData.id !== res.user_id) && parseInt(res.approved) === 1) {
            if(!res.contract_address) {
                output.push(<li className="bg-primary text-white" onClick={() => onClickToDeployContract(res.id)}>Initiate Contract</li>)
            } else {
                output.push(<li className="bg-danger text-white" onClick={() => {
                    setDepositRequest(res);
                    onClickToOpenDepositAmountModel();
                    reset({amount: '0.04'})
            }}>Deposit {parseFloat(res.contract_funds) === 0 && <CMTooltip text="Your deposit has been spent. Please deposit more to keep the contract going."><i class="fa fa-info-circle"></i></CMTooltip>}</li>)
            }
        }
        
        if(res.contract_address && (userData.id === res.user_id) && res.usdc_funds !== null && parseFloat(res.usdc_funds) > 0) {
            output.push(<li className="bg-primary text-white" onClick={() => invokeMultisigContract(res)}>Withdraw</li>)
        }

        if (pathname === '/user/requests' || pathname === '/user/activesubscriptions') {
            if (parseInt(res.approved) < 0) {
                output.push(<>
                    {(parseInt(res.approved) === -2 || parseInt(res.approved) === -1) && <li className="bg-primary text-white">{parseInt(res.approved) === -1 ? 'Disapproved' : ''}{parseInt(res.approved) === -2 ? 'Terminated' : ''}</li>}
                    <li className="bg-secondary text-white"><a href="#/" onClick={() => onClickToApprove(res.id)}><i className="fa fa-square-o"></i>Approve</a></li>
                </>)
            } else if (parseInt(res.approved) === 1) {
                let hasViews = [];
                if (res.has_views) {
                    hasViews.push(<li className="bg-primary"><Link to={`/analytics/requestgraph?id=${res.id}`} ><i className="fa fa-area-chart"></i> Analytics</Link></li>)
                }
                output.push(<>
                    <li className="bg-secondary text-white"><i className="fa fa-check-square-o"></i> Approved</li>
                    <li className="bg-danger"><a href="#/" onClick={() => {
                        onClickToOpenModal()
                        setTerminateId(res.id)
                    }}><i className="fa fa-close"></i>Terminate</a></li>
                    <li className="bg-info"><Link to={`/article/requestversions?id=${res.id}`}><i className="fa fa-edit"></i> Versions</Link></li>
                    {hasViews}
                </>)
            } else if (parseInt(res.approved) === 0) {
                if (parseInt(res.negotiation_accepted) === 1) {
                    output.push(<>
                        <li className="bg-secondary text-white"> <a href="#/" onClick={() => onClickToApprove(res.id)}><i className="fa fa-square-o"></i>Approve</a></li>
                        <li className="bg-danger"><a href="#/" onClick={() => onClickToDelete(res.id)}><i className="fa fa-close"></i>Disapproved</a></li>
                    </>)
                } else {
                    if(res.negotiation_history > 0) {
                        if (res.check_history_with_current_user) {
                            
                            output.push(<li className="bg-primary text-white" onClick={() => {
                                handleShowNegotiationsModal()
                                setNegotiationId(res.id);
                            }}>Counter-offer</li>)
                            if (parseInt(res.negotiation_accepted) === 0) {
                                output.push(<li className="bg-info"><a href="#/" onClick={() => onClickToAcceptNegotiation(res.id)}>Accept Negotiated Price</a></li>)
                            }
                        }
                    }
                }
            }
            
            output.push(<li className="bg-success">
                <Link to={`/request/message?id=${res.id}`}><i className="fa fa-envelope"></i>Message</Link></li>)
            
        } else {
            if (parseInt(res.approved) === 0) {
                output.push(<li className="bg-primary text-white">Pending</li>)
                
                if (parseInt(res.negotiation_accepted) === 1) {
                
                } else {
                    if (res.check_history_with_current_user) {
                        if(res.check_history_with_current_user) {
                            output.push(<li className="bg-primary text-white" onClick={() => {
                                handleShowNegotiationsModal()
                                setNegotiationId(res.id);
                            }}>Counter-offer</li>)
                            if (parseInt(res.negotiation_accepted) === 0) {
                                output.push(<li className="bg-primary text-white" onClick={() => onClickToAcceptNegotiation(res.id)}><i className="fa fa-square-o"></i>Accept Negotiated Price</li>)
                            }
                        }
                    }
                }
            } else if (parseInt(res.approved) < 0) {
                output.push(<li className="bg-primary text-white"><i className="fa fa-square-o"></i>Disapproved</li>)
            } else {
                // const pay = [];
                // if (parseInt(res.paid) !== 1) {
                //     pay.push(<li className="bg-danger">Pay</li>)
                // }
                output.push(<li className="bg-primary text-white"><i className="fa fa-check-square-o"></i>
                    Approved 
                    {/* {pay} */}
                </li>)
                if (res.has_views) {
                    output.push(<li className="bg-primary"><Link to={`/analytics/requestgraph?id=${res.id}`}><i className="fa fa-area-chart"></i> Analytics</Link></li>)
                }
            }
            
            if (parseInt(res.approved) === 1) {
                output.push(<>
                    <li className="bg-info"><Link to={`/article/requestversions?id=${res.id}`}><i className="fa fa-edit"></i> Versions</Link></li>
                    <li className="bg-warning"><Link to={`/articlerequest/customize?id=${res.id}`}><i className="fa fa-edit"></i> Customize</Link>
                    </li>
                    <li className="bg-danger"><Link to={`/articlerequest/splittesting?id=${res.id}`}><i className="fa fa-pie-chart"></i> Split testing</Link>
                    </li>
                </>)
            }
        }
        
        return output;
    }

    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Requests for Articles" />
        <Container className="my-5">
            <div className="CM_whiteBox">
                <div className="cm_requestsbtn">
                    <div className="btn-group" role="group" aria-label="...">
                        <Link to="/user/requests" className={`btn btn-sm ${pathname !== '/user/requests' ? 'btn-light' : 'btn-warning'}`}>Incoming Requests</Link>
                        <Link to="/user/activesubscriptions" className={`btn btn-sm ${pathname !== '/user/activesubscriptions' ? 'btn-light' : 'btn-warning'}`}>Only active</Link>
                        <Link to="/user/myrequests" className={`btn btn-sm ${pathname !== '/user/myrequests' ? 'btn-light' : 'btn-warning'}`}>Your Requests</Link>
                        <Link to="/user/myrequests?approved=1" className={`btn btn-sm ${(pathname !== '/user/myrequests') ? 'btn-light' : 'btn-warning'}`}>Only approved</Link>
                    </div>
                </div>

                {state.requests && state.requests.map((res, indx) => {
                    let color = "";

                    switch (parseInt(res.approved)) {
                        case 1:
                        case 0:
                            color = '#F9F9F9';
                            break;
                        case -1:
                        case -2:
                            color = '#D7D7D7';
                            break;
                        default:
                            color = "";
                    }
                    
                    if(!res.showlinks.match(/^[a-zA-Z]+:\/\//)) {
                        res.showlinks = `http://${res.showlinks}`;
                    }

                    return (<div key={indx + 1} className={res.highlight ? "cm_usertable2 disapproved" : "cm_usertable2"} style={{ background: color }}>
                        <Row>
                            <div className="col-lg-4 col-md-8 pe-0 border-end">
                                <Row>
                                    <div className="col-lg-6" align="center">
                                        <img src={getImageUrl(res.public_image)} alt="" className="cm_img90rounded" />
                                    </div>
                                    
                                    <div className='col-lg-6 cm_column1'>
                                        Username <br />
                                        
                                        
                                        <Link to={res.author_url?res.author_url:''}>{res.print_name}</Link><br />
                                        URL: <a target="_blank" href={res.showlinks} className="text-truncate d-block" >{res.showlinks}</a><br />
                                        {res.location_string}
                                        <br />
                                        {res.subscriber_id && pathname === '/user/myrequests' && < ShowContentMutualCode data={res}/>}
                                    </div>
                                    <div className="col-lg-12 text-md-start text-green cm_column3" style={{paddingLeft: '48px'}}>
                                        {res.contract_address &&
                                            <>
                                                <br/>
                                                Contract: <a href={etherscanLink(res.contract_address)} target="_blank" rel="noreferrer">{shortAddress(res.contract_address)}</a>, USDC {res.contract_funds?res.contract_funds:0}
                                                <br/>
                                                Withdrawn: USDC {res.withdraw_funds?res.withdraw_funds:0.00}
                                                <br/>
                                                Deposited: USDC {res.deposit_funds?res.deposit_funds:0.00}
                                                
                                            </>
                                        }
                                    </div>
                                </Row>
                            </div>
                            {/* <div className="col-lg-2 col-md-8 border-end">
                                
                            </div> */}
                            <div className="col-lg-8">
                                <Row className='me-0'>
                                    <div className="col-md-8">
                                        <h2 className='cm_articleTitle'><strong><Link to={res.article_public_url?res.article_public_url:''}>{res.article_name}</Link></strong>
                                            <small className="ps-1"> (<RTimeAgo date={formattedDate(res.created)} />)</small></h2>
                                        <div className="cm_detailsinfo">{res.introductions}</div>
                                        <ul className="cm-dashboard-btn">
                                            {onRenderActions(res)}
                                        </ul>
                                    </div>
                                    <div className="col-md-4 text-md-end mt-4 mt-md-0">
                                        <div className='cm_column3'>
                                            Starting fee: <span className={parseInt(res.starting_fee) < parseInt(res.article_starting_fee) ? `text-danger` : `text-green`}>{res.contract_address?'USDC ':'$'}{res.starting_fee}</span><br />
                                            1,000 impressions: <span className={parseInt(res.fee1000_imp) < parseInt(res.article_fee1000_imp) ? `text-danger` : `text-green`}>{res.contract_address?'USDC ':'$'}{res.fee1000_imp}</span><br />
                                            10,000 impressions: <span className={parseInt(res.fee10000_imp) < parseInt(res.article_fee10000_imp) ? `text-danger` : `text-green`}>{res.contract_address?'USDC ':'$'}{res.fee10000_imp}</span><br />
                                            Daily cap: <span className={parseInt(res.daily_cap) < 500 ? `text-danger` : `text-green`}>{res.contract_address?'USDC ':'$'}{res.daily_cap}</span>
                                            <br />
                                            <a href="#/" className="negotiate-dialog" onClick={(e) => {
                                                handleShowNegotiationsModal()
                                                setNegotiationId(res.id)
                                            }}><i className="fa fa-usd"></i>{parseInt(res.negotiation_accepted) ? 'Negotiations' : 'Negotiate'}</a>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Row>
                    </div>)
                })}
            </div>
            {state.total_page > 0 && <ReactPaginate
                style={{ color: 'red' }}
                className="cmn-Pagination mt-4"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={onPageClick}
                pageRangeDisplayed={5}
                pageCount={parseInt(state.total_page)}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                forcePage={page}
            />}
        </Container>
        <Negotiations showNegotiationsModal={showNegotiationsModal} NegotiationId={negotiationId} handleCloseNegotiationsModal={handleCloseNegotiationsModal} />
        <Modal show={showDepositAmountModal} onHide={onClickToCloseDepositAmountModel}>
            <Modal.Header>
                <Modal.Title>Deposit Amount</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='col-lg-8 mb-3'>
                    <Label text="Amount" />
                    <Controller
                        name="amount"
                        control={control}
                        render={({field: {value, onChange}}) => (
                            <>
                                <input 
                                    type="text" 
                                    name="amount" 
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e)
                                    }} 
                                    className="cm_input" 
                                />
                                {errors.amount && <ErrorLabel
                                    msg={errors.amount && errors.amount.message}
                                />}
                            </>
                        )}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickToCloseDepositAmountModel}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit(() => {
                    invokeContract(depositRequest)
                    onClickToCloseDepositAmountModel();
                })}>
                    Okay
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showDeleteModal} onHide={onClickToCloseModal}>
            <Modal.Header>
                <Modal.Title>Delete Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickToCloseModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={onClickToDelete}>
                    Okay
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}
export default MyRequests;
