import { useSlate } from "slate-react"
import { insertLink, isLinkActive, unwrapLink, getLink } from "./utils"
import { Button } from "react-bootstrap"
import Icon from "../Icon"

const AddLinkButton = () => {
    const editor = useSlate()
    return <>
        <Button
            active={isLinkActive(editor)}
            onMouseDown={event => {
                event.preventDefault()
                const oldLink = getLink(editor)
                const oldUrl = oldLink === undefined ? '' : (oldLink[0].url ?? '');
                const url = window.prompt('Enter the URL of the link:', oldUrl)
                if (!url) return
                insertLink(editor, url)
            }}>
            <Icon name="link" />
        </Button>
        <Button
            disabled={!isLinkActive(editor)}
            onMouseDown={event => {
                event.preventDefault()
                unwrapLink(editor);
            }}>
            <Icon name="link-break" />
        </Button>
    </>
}

export default AddLinkButton;