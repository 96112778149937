import React, { useState, useEffect } from 'react';
import { Modal, Container, Col, Row } from 'react-bootstrap';
import { Label } from '../Typography';
import { updateArticlePrice, nftGetContractDetails } from '../../action/article.action';
import {useHistory} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../../Utils/AppUtill';
import ErrorLabel from '../../widgets/ErrorLable';
import Joi from 'joi'
import toast from '../../helpers/toast';

const ArticleUpdatePriceModal = ({onChangeState, state}) => {

    const [show, setShow] = useState(false);
    const [stateD, setState] = useState({isContract: false});
    const history = useHistory();
    const onCloseModal = () => setShow(false);
    const onOpenModal = () => setShow(true);

    const {
        handleSubmit,
        formState: {errors},
        control,
        reset,
      } = useForm({
        resolver: joiResolver(
          Joi.object({
            starting_fee: Joi.string()
              .required()
              .label('starting_fee')
              .messages(joiUpdatedMessage),
            fee1000imp: Joi.string()
              .required()
              .label('fee1000imp')
              .messages(joiUpdatedMessage),
            fee10000imp: Joi.string()
                .required()
                .label('fee10000imp')
                .messages(joiUpdatedMessage)
          }),
        ),
    })

    const {
        handleSubmit: nftHandleSubmit,
        control: nftControl,
        reset: nftReset,
        getValues: nftGetValues
      } = useForm({
        resolver: joiResolver(
            Joi.object({
                contract: Joi.string()
                    .required()
                    .label('Contract')
                    .messages(joiUpdatedMessage),
                token: Joi.string().allow(null, ''),
            }),
        ),
    })

    useEffect(() => {
        reset({
            starting_fee: state.starting_fee,
            fee1000imp: state.fee1000imp,
            fee10000imp: state.fee10000imp
        })
        
        if(state.contract) {
            setState({
                isContract: true, 
                contract: state.contract,
                token: state.token?state.token:'',
                banner_image_url: state.banner_image_url,
                description: state.nft_description,
                external_link:  state.external_link 
            })
            nftReset({
                contract: state.contract,
                token: state.token
            })
        } else {
            nftReset({
                contract: '',
                token: ''
            })
        }
    }, [state, nftReset, reset])

    const onChangeInput = (e) => {
        onChangeState({[e.target.name]: e.target.value});
    }

    const onSetValue = (values) => {
        onChangeState(values);
    }

    const onClickToSubmitNFT = async (values) => {
        if(stateD.isContract) {
            const formData = new FormData();
            const { contract, token } = nftGetValues();
            formData.append('starting_fee', state.starting_fee?state.starting_fee:0);
            formData.append('i1000', state.fee1000imp?state.fee1000imp:0);
            formData.append('i10000', state.fee10000imp?state.fee10000imp:0);
            formData.append('token', token?token:'');
            formData.append('contract', contract?contract:'');
            formData.append('banner_image_url', stateD.banner_image_url);
            formData.append('nft_description', stateD.description);
            formData.append('external_link', stateD.external_link);
            formData.append('id', state.id)
            const { data } = await updateArticlePrice(history.location.search, formData);
            if(data.status === 200) {
                history.push('/user/articles');
                onCloseModal();
            }
        } else {
            toast.error("Your contract address is wrong.")
        }
    }

    const onUpdatePrice = async (values) => {
        if(!stateD.isContract) {
            onChangeState({banner_image_url: '', nft_description: '', contract: '', token: ''});   
        }
        
        const formData = new FormData();
        formData.append('starting_fee', state.starting_fee);
        formData.append('i1000', state.fee1000imp);
        formData.append('i10000', state.fee10000imp);
        formData.append('token', '');
        formData.append('contract', '');
        formData.append('banner_image_url', '');
        formData.append('nft_description', '');
        formData.append('external_link', '');
        formData.append('id', state.id)
        const { data } = await updateArticlePrice(history.location.search, formData);
        if(data.status === 200) {
            history.push('/user/articles');
            onCloseModal();
        }
    }

    const onChangeContract = async () => {
        const { contract, token } = nftGetValues();
        
        if(contract) {
            const { data } = await nftGetContractDetails(contract, token);
            if(data.status === 200) {
                const obj = {external_link : data.data.url, banner_image_url: data.data.image, nft_description: data.data.description, contract, token};
                onSetValue(obj);
                setState({isContract: true, banner_image_url: data.data.image, description: data.data.description, external_link : data.data.url})
            } else {
                const obj = {banner_image_url: '', external_link : '', nft_description: '', contract: '', token: ''};
                onSetValue(obj);
                setState({isContract: false, banner_image_url: "", description: "", external_link :''})
            }
        } else {
            const obj = {external_link : '', banner_image_url: '', nft_description: '', contract: '', token: ''};
            setState({isContract: false, banner_image_url: "", description: "", external_link :''})
            onSetValue(obj);
        }
    }
    
    return (<>
        <button type="button" onClick={onOpenModal} className='btn btn-dark'>Edit Price</button>
        <Modal show={show} onHide={onCloseModal} size="xl" centered >
            <Modal.Header className='border-0' closeButton>
                <div className='fs-20px text-green fs-md-24px'>Update price for: {state.name}</div>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <div className='mb-3'>
                        <Label text="Starting Fee" />
                        <Controller
                            name="starting_fee"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="starting_fee" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                        required
                                    />
                                    {errors.starting_fee && <ErrorLabel
                                        msg={"Starting Fee is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-3'>
                        <Label text="1,000 impressions" />
                        <Controller
                            name="fee1000imp"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="fee1000imp" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                        required 
                                    />
                                    {errors.fee1000imp && <ErrorLabel
                                        msg={"Fee1000imp is required."}
                                    />}
                                </>
                            )}
                        />
                        <Label text="Effective price per 1,000 views:$0" />
                    </div>
                    <div className='mb-3'>
                        <Label text="10,000 impressions" />
                        <Controller
                            name="fee10000imp"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="fee10000imp" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                        required 
                                    />
                                    {errors.fee10000imp && <ErrorLabel
                                        msg={"Fee10000imp is required."}
                                    />}
                                </>
                            )}
                        />
                        <Label text="Effective price per 10,000 views:$0" />
                    </div>
                    <div className='mb-3'>
                        <Label text="Contract" />
                        <Controller
                            name="contract"
                            control={nftControl}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input type="text" 
                                        name="contract" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeContract();
                                            // onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-3'>
                        <Label text="Token" />
                        <Controller
                            name="token"
                            control={nftControl}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input type="text" 
                                        name="token" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeContract();
                                            // onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                    />
                                </>
                            )}
                        />
                    </div>
                    {stateD.isContract && <>
                        <div className='mb-3 text-center'>
                            <a href={stateD.external_link} target="_blank" rel="noreferrer"><img src={stateD.banner_image_url} alt="" style={{maxHeight: '300px', width: 'auto'}}/></a>
                        </div>
                        <div className='mb-3'>
                            {stateD.description}
                        </div>
                    </>}
                    <Row>
                        <Col md={2} className='mb-3'>
                            {!stateD.isContract && ['', undefined, null].includes(nftGetValues('contract')) && <button type="button" onClick={handleSubmit(onUpdatePrice)} className='btn btn-dark'>Update Price</button>}
                            {(stateD.isContract || (!stateD.isContract && nftGetValues('contract'))) && <button type="button" onClick={nftHandleSubmit(onClickToSubmitNFT)} className='btn btn-dark'>Update Price</button>}
                        </Col>
                        <Col md={2} className='mb-3'>
                            <button type="button" className='btn btn-dark' onClick={onCloseModal}>Close</button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    </>)
}

export default ArticleUpdatePriceModal;