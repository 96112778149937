import React from "react";
import Number from './Number';
import Checkbox from "./Checkbox";
import Select from "./Select";

const Attribute = (props) => {
    const { type_id } = props;

    switch (parseInt(type_id, 10)) {
        case 1: // Number
            return <Number {...props} />
        case 2: // Select
            return <Select {...props} />
        case 3: // Checkbox
            return <Checkbox {...props} />
        default:
            return 'Invalid attribute type id';
    }
}

export default Attribute;