import React, { useState, useEffect, useContext } from 'react'
import { Col, Button, Container, Row, Modal } from 'react-bootstrap'
import { PageTitle } from '../components/Typography'
import { Link, useHistory } from 'react-router-dom'
import { requestToMessage } from '../action/common.action'
import { GlobalContext } from '../context/globalContext'
import { RTimeAgo } from '../components/RTimeAgo'
import { formattedDate } from '../Utils/AppUtill'
import { articleRequestDisapprove, articleRequestApprove, articleRequestTerminateSubscription } from '../action/request.action';
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../Utils/AppUtill';
import ErrorLabel from '../widgets/ErrorLable';
import Joi from 'joi'

const apiUrl = process.env.REACT_APP_API_URL;

const Messages = () => {
    const { userData } = useContext(GlobalContext);
    const history = useHistory();
    const [state, setState] = useState({});
    const [message, setMessage] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const onRetriveMessage = async () => {
        const {data} = await requestToMessage(history.location.search);
        if(data.status === 200) {
            setState(data.data)
        }
    }

    const getImageUrl = (path) => {
        if(path.includes(window.location.origin)) {
            return path;   
        }
        return apiUrl + "/thumb?src=" + path + "&w=120&h=120"
    }

    const {
        handleSubmit,
        formState: {errors},
        control,
        reset,
      } = useForm({
        resolver: joiResolver(
            Joi.object({
                message: Joi.string()
                .required()
                .label('message')
                .messages(joiUpdatedMessage),
            }),
        ),
    })

    useEffect(() => {
        onRetriveMessage();
    }, [history.location])// eslint-disable-line react-hooks/exhaustive-deps

    const onClickToMessage = async (e) => {
        const formData = new FormData();
        formData.append('message', message);
        const {data} = await requestToMessage(history.location.search, formData);
        if(data.status === 200) {
            setMessage("")
            reset({message: ''})
            onRetriveMessage();
        }
    }

    const onInputChange = (e) => {
        setMessage(e.target.value)
    }
    
    const onClickToApprove = async (id) => {
        const { data } = await articleRequestApprove(id);
        if(data.status === 200) {
            onRetriveMessage();
        }
    }

    const onClickToDisapprove = async (id) => {
        const { data } = await articleRequestDisapprove(id);
        if(data.status === 200) {
            onRetriveMessage();
        }
    }

    const onClickToTerminate = async (id) => {
        const { data } = await articleRequestTerminateSubscription(id);
        if(data.status === 200) {
            onRetriveMessage();
            onClickToCloseModal();
        }
    }
    
    const onClickToCloseModal = () => setShowDeleteModal(false)
    const onClickToOpenModal = () => setShowDeleteModal(true)
    
    const onClickToDelete = () => {
        onClickToTerminate(state.articlerequest.id)
    }

    return (<>
        {state.article && <PageTitle style={{ backgroundImage: `url(${state.article.image})` }} text={`Messages: ${state.article.name}`} />}
        <Container className='py-5'>
            <Row>
                <Col md={8}>
                    <div className='CM_whiteBox px-0 mb-4'>
                        <div className="px-3">
                            {state.errors && <>{state.errors}</>}
                            {!state.errors && <>
                                {state.articlerequest && state.articlerequest.userId!==userData.id && <>
                                    {parseInt(state.articlerequest.approved)<0 && <>
                                        <span className='text-red fs-13px me-1'>{parseInt(state.articlerequest.approved)===-1?'Disapproved':''}{parseInt(state.articlerequest.approved)===-2?'Terminated':''}</span>
                                        <Button onClick={() => onClickToApprove(state.articlerequest.id)} variant='link' className='fs-13px text-decoration-none'>Approve</Button>
                                    </>}
                                    {parseInt(state.articlerequest.approved) === 1 && <>
                                        <span className='text-green fs-13px me-1'>Approved</span>
                                        <Button variant='link' className='text-green fs-13px text-decoration-none' onClick={() => {
                                            onClickToOpenModal();
                                        }}><span className='ion-close-round me-1'></span>Terminate</Button>
                                        {state.articlerequest.has_views && <span className='text-red fs-13px me-1'><Link to={`/analytics/requestgraph?id=${state.articlerequest.id}`}><i className="fa fa-chart"></i>Analytics</Link></span>}
                                    </>}
                                    {parseInt(state.articlerequest.approved) === 0 && <>
                                        <Button onClick={() => onClickToApprove(state.articlerequest.id)} variant='link' className='text-green fs-13px text-decoration-none'>Approve</Button>
                                        <Button onClick={() => onClickToDisapprove(state.articlerequest.id)} variant='link' className='text-green fs-13px text-decoration-none'>Disapprove</Button>
                                    </>}
                                    {/* <span className='text-green fs-13px me-1'>Approved</span><Button variant='link' className='text-green fs-13px text-decoration-none'><span className='ion-close-round me-1'></span>Terminate</Button> */}
                                </>}
                                {state.articlerequest && state.articlerequest.userId===userData.id && <>
                                    {parseInt(state.articlerequest.approved) === 0 && <>
                                        <span className='text-blue fs-13px me-1'>Pending</span>
                                    </>}
                                    {parseInt(state.articlerequest.approved) < 0 && <>
                                        <span className='text-blue fs-13px me-1'>Disapproved</span>
                                    </>}
                                    {parseInt(state.articlerequest.approved) !== 0 && parseInt(state.articlerequest.approved) > 0 && <>
                                        <span className='text-blue fs-13px me-1'>Approved
                                            {parseInt(state.articlerequest.paid)!==1 && <Button onClick={() => history.push(`/article/payment?id=${state.articlerequest.id}`)} variant='link' className='text-green fs-13px text-decoration-none'>Pay</Button>}
                                        </span>
                                    </>}
                                </>}
                                <form className="row">
                                    <div className="col-lg-10">
                                        <Controller
                                            name="message"
                                            control={control}
                                            render={({field: {value, onChange}}) => (
                                                <>
                                                    <textarea 
                                                        rows="3" 
                                                        value={value} 
                                                        className='cm_input' 
                                                        onChange={(e) => {
                                                            onChange(e)
                                                            onInputChange(e)
                                                        }}
                                                    ></textarea>
                                                    {errors.message && <ErrorLabel
                                                        msg={"Message is required."}
                                                    />}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-lg-2"><Button variant='green' className='w-100' onClick={handleSubmit(onClickToMessage)}>Send</Button></div>
                                </form>
                            </>}
                        </div>
                        {!state.errors && state.messages && (state.messages.map((res, idx) => <article className='cm_messageItem' key={idx + 1}>
                            <Row>
                                <Col md={3} lg={2} className='text-center'>
                                    <img alt="" src={getImageUrl(res.user_public_image)} className="cm_img90rounded" />
                                </Col>
                                <Col md={9} lg={10}>
                                    <Link to={res.user_author_url?res.user_author_url:''} className="text-dark text-decoration-none"><b>{res.print_name}</b></Link>
                                    <div className='text-green fs-13px py-1'>{res.message}</div>
                                    <div className='time fs-13px'><RTimeAgo date={formattedDate(res.created)}/></div>
                                </Col>
                            </Row>
                        </article>))}
                    </div>

                </Col>
                <Col md={4}>
                    <div className='CM_whiteBox mb-4 text-green'>
                        {/* <div className='cm_boxTitle'><Icon name="editicon" className="w-20px me-2" />News Feed</div>
                        {feeds[0] && <div id="cm_article_feed" dangerouslySetInnerHTML={{ __html: feeds[0].html }}>
                        </div>}
                        {feeds[0] && feeds[0].last_id && <div className='text-center'><Button variant='loadmore' onClick={handleLoadMore}>Load more</Button></div>} */}
                    </div>

                </Col>
            </Row>
        </Container>
        <Modal show={showDeleteModal} onHide={onClickToCloseModal}>
            <Modal.Header>
            <Modal.Title>Delete Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={onClickToCloseModal}>
                Close
            </Button>
            <Button variant="primary" onClick={onClickToDelete}>
                Okay
            </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default Messages