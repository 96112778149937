import React, { useState, useEffect } from 'react'
import { Button, Container, Table, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PageTitle } from '../components/Typography'
import { myBookmarks } from '../action/myaccount.action'
import { useHistory } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import queryString from 'query-string';
import { deleteBookMark } from '../action/article.action'

const BookmarksPage = () => {

    const history = useHistory();
    const [state, setState] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [page, setPage] = useState(0);

    const onClickToCloseModal = () => setShowDeleteModal(false);
    const onClickToOpenModal = () => setShowDeleteModal(true);
    
    const onRetriveBookmarks = async () => {
        const {data} = await myBookmarks(history.location.search);
        if(data.status === 200) {
            setState(data.data);
        }
    }
    
    useEffect(() => {
        let {page} = queryString.parse(history.location.search)
        if(page) {
            setPage(page - 1);
        } else {
            setPage(0);
        }
        onRetriveBookmarks()
    }, [history.location])// eslint-disable-line react-hooks/exhaustive-deps

    const onClickToDeleteBookmark = async () => {
        const {data} = await deleteBookMark(deleteId);
        if(data.status === 200) {
            onRetriveBookmarks();
        }
    }

    const onPageClick = (status='page', e) => {
        const {pathname, search} = history.location;
        let query = queryString.parse(search)
        if(status!=='page') {
            query = { ...query, ...status}
        } else {
            query.page = e.selected + 1;
        }
        query = queryString.stringify(query);
        history.push(`${pathname}?${query}`)
    }
    
    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="My Bookmarks" />
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <Table bordered hover responsive className='cm_table'>
                    <thead>
                        <tr>
                            <th>Bookmark</th>
                            <th>Notes</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.bookmarks && state.bookmarks.map((res, indx) => (<tr key={indx + 1}>
                            <td><Link to={res.public_url?res.public_url:''}>{res.print_name}</Link></td>
                            <td>{res.notes}</td>
                            <td>
                                <Button variant='link' onClick={() => {
                                    onClickToOpenModal()
                                    setDeleteId(res.id)
                                }}
                                className='px-1 me-2'
                                ><span className='ion-close-round'></span>Delete</Button>
                                <Button variant='link' onClick={() => {
                                    history.push(`/article/editbookmark?id=${res.id}`)
                                }}
                                className='px-1'
                                ><span className='ion-edit'></span>Edit</Button></td>
                        </tr>))}
                    </tbody>
                </Table>
            </div>
            {state.total_pages > 0 && <ReactPaginate
                style={{color: 'red'}}
                className="cmn-Pagination mt-3"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={(e) => onPageClick('page', e)}
                pageRangeDisplayed={5}
                pageCount={state.total_pages}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                forcePage={page}
            />}
            <Modal show={showDeleteModal} onHide={onClickToCloseModal}>
                <Modal.Header>
                    <Modal.Title>Delete Bookmarks</Modal.Title>
                </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClickToCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={onClickToDeleteBookmark}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    </>)
}

export default BookmarksPage