import React from "react";
import { Button } from "react-bootstrap";
export const UploadImage = (props) => {

    return (<>
        <div className="cm_uploadImagItem">
            <input type="file" onChange={props.onChange} />
            <img src={props.file} alt="" />
            <Button variant="light" className="p-0" onClick={props.onClick}>&times;</Button>
        </div>
    </>)
}