import React, {useContext} from 'react';
import { Button } from 'react-bootstrap'
import { GlobalContext } from '../context/globalContext'
import { followAuthor } from '../action/article.action'

export const FollowComponent = ({type, id, isFollowing, onCallBack}) => {
    const { isLoggedIn, setSignIn } = useContext(GlobalContext);
    
    const onClickToFollow = async () => {
        if(!isLoggedIn) {
            setSignIn(true)
        } else {
            if(type === 'Author') {
                await followAuthor(id);
                if(onCallBack) {
                    onCallBack()
                }
            }
        }
    }

    return (<Button variant='green' onClick={onClickToFollow}>{isFollowing ? "Following" : "Follow"}</Button>)
}