import { useSelected } from "slate-react";
import InlineChromiumBugfix from './InlineChromiumBugFix'

const Link = ({ attributes, children, element }) => {
    const selected = useSelected()
    return (
        <a
            {...attributes}
            href={element.url}
            className={selected ? 'selected' : ''}
        >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
        </a>
    )
}

export default Link;