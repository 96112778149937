import React, {useEffect, useState} from 'react';
import { Button, Table} from 'react-bootstrap';
import { useHistory } from 'react-router';
import { uniquenesscheck } from '../../action/article.action'
import { Link } from "react-router-dom"

export const Step4 = ({onChangeState, state}) => {

    const history = useHistory();
    const [stepState, setStepState] = useState({})


    useEffect(() => {
        (async () => {
            const formData = new FormData();
            Object.keys(state).map((res, i) => formData.append(res, state[res]))
            formData.append('content', state.fix_content);
            const {data} = await uniquenesscheck(formData)
            if(data.status === 200) {
                setStepState(data.data)
                const { uniqueness_test_passed, unique, articleId } = data.data;
                onChangeState({unique, uniqueness_test_passed, id: articleId})
            }
        })()
        // eslint-disable-next-line
    }, [])

    const onClickToGoBackToFirstStep = () => {
        history.push(`/article/submit?step=1`);
    }

    const onClickToNext = () => {
        history.push(`/article/submit?step=5`);
    }

    return (<>
        <div>
            <div className='text-green fs-md-22px fs-18px mb-3'>Your Content Is Being Reviewed for Uniqueness. Not published yet.</div>
            {stepState.unique && <div className='text-green cm_boxTitle mb-3'>Article is unique.</div>}
            {!stepState.unique && <>
                <div className='text-red cm_boxTitle mb-3'>
                    <span>Article is not unique.</span>&nbsp;
                    <Link to='/article/submit?step=1'><span onClick={onClickToGoBackToFirstStep}>Go to the first step.</span></Link>
                </div>
            </>}
            <Table hover striped responsive className='cm_table shadow-none'>
                <thead>
                    <tr>
                        <th>Sentence</th>
                        <th>Result</th>
                    </tr>
                </thead>
                <tbody>
                    {stepState.sentences && stepState.sentences.map((res, indx) => {
                        return (<tr key={indx + 1}>
                            <td>{res.sentence}</td>
                            <td style={{color: res.color}}>{res.status}</td>
                        </tr>)
                    })}
                </tbody>
            </Table>
            {stepState.unique &&
                <Button variant='green' className='mt-3 me-2' onClick={onClickToNext}>Next →</Button>
            }
        </div>
    </>)
}