import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop';
import toast from '../helpers/toast';
import { getCroppedImg } from './canvasUtils';
import Icon from './Icon';

export const CropImage = ({callBack, cimage, aspect}) => {
    const [image, setImage] = useState(cimage || '');
    const [fileData, setFileData] = useState('');
    const onFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            if(!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                toast.error("Invalid image content.")
            } else {
                setFileData(file)
                setImage(reader.result)
            }
        }
    }
    
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(cimage || null)
    const [croppedImage, setCroppedImage] = useState(cimage || null)
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                image,
                croppedAreaPixels
            )
            callBack(croppedImage)
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [image, croppedAreaPixels, callBack])

    return (<>
        {image === '' && <div className='cm_uploadImage'>
            <input type='file' onChange={onFileChange} />
            Drop your image here or click to add one!
        </div>}
        {image !== '' && <div className='cm_uploadImage'>
            <div className='cropWrapper w-100 h-100'>
                {croppedImage ? <img src={croppedImage} alt="" /> : <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    showGrid={false}
                    aspect={aspect}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    objectFit="horizontal-cover"
                />}
            </div>
            <div className="cm_imageTools">
                {croppedImage && typeof fileData === 'object' && <div className="btn btn-primary btn-ok cropped-image" title="Edit" onClick={() => setCroppedImage(null)}>
                    <i className="bi-pencil"></i>
                </div>}
                {croppedImage && <div className="btn btn-danger btn-cancel cropped-image" title="Cancel" onClick={() => {
                    setImage('')
                    setCroppedImage(null)
                    callBack('')
                }}>
                    <i className='bi-trash' />
                </div>}
                {!croppedImage && <div className="btn btn-danger btn-cancel cropped-image" title="Cancel" onClick={() => {
                    setImage('')
                    setCroppedImage(null)
                    callBack('')}}>
                    <Icon name="close" />
                </div>}
                {!croppedImage && <div className="btn btn-success btn-ok cropped-image" title="Ok" onClick={showCroppedImage}>
                    <Icon name="check" />
                </div>}
            </div>
        </div>}
    </>)
}