import React, { useEffect } from 'react'
import { Col, Container, Button } from 'react-bootstrap'
import { RadioButton } from '../components/Typography'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../Utils/AppUtill';
import ErrorLabel from '../widgets/ErrorLable';
import Joi from 'joi'
import { deposite, depositcancel, depositsuccess } from '../action/billing.action'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string';
import toast from "../helpers/toast"

const Deposit = () => {

    const history = useHistory();
    
    const {
        handleSubmit,
        formState: {errors},
        reset,
        getValues
      } = useForm({
        resolver: joiResolver(
          Joi.object({
            amount: Joi.string()
              .required()
              .label('amount')
              .messages(joiUpdatedMessage),
            }),
        ),
    })
    
    useEffect(() => {
        (async () => {
            const {message} = queryString.parse(history.location.search);
            if(message === 'success') {
                const {data} = await depositsuccess(localStorage.paymentId);
                if(data.status === 200) {
                    toast.success(data.data.message) 
                } 
            } else if(message === 'failure'){
                await depositcancel();
            }
        })()
    }, [history.location])
    
    const onClickToCheck = (e) => {
        reset({'amount': e.target.value})
    }
    
    const onClickToMakeDeposite = async (value) => {
        const { amount } = value;
        const formData = new FormData();
        formData.append('amount', amount);

        const { data } = await deposite(formData);
        if(data.status === 200) {
            const { url, paymentId } = data.data
            reset({'amount': ""})
            localStorage.paymentId = paymentId
            window.location.href = url;
        }
    }

    return (<>
        <div className='text-green text-center fs-md-26px fs-20px pt-5'>Withdraw</div>
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <div className='cm_boxTitle mb-3 text-green'>Deposit funds</div>
                <form className='row'>
                    <Col lg={2} className='mb-3'>
                        <RadioButton text="$20" name="amount" value="20" checked={getValues().amount === '20'} onChange={onClickToCheck}/>
                    </Col>
                    <Col lg={2} className='mb-3'>
                        <RadioButton text="$50" name="amount" value="50" checked={getValues().amount === '50'} onChange={onClickToCheck}/>
                    </Col>
                    <Col lg={2} className='mb-3'>
                        <RadioButton text="$100" name="amount" value="100" checked={getValues().amount === '100'} onChange={onClickToCheck}/>
                    </Col>
                    <Col lg={2} className='mb-3'>
                        <RadioButton text="$200" name="amount" value="200" checked={getValues().amount === '200'}  onChange={onClickToCheck}/>
                    </Col>
                    <Col lg={2} className='mb-3'>
                        <RadioButton text="$500" name="amount" value="500" checked={getValues().amount === '500'} onChange={onClickToCheck}/>
                    </Col>
                    <Col md={12} className='mb-3'>
                        {errors.amount && <ErrorLabel
                            msg={"Amount is required."}
                        />}
                    </Col>
                    <Col md={12} className='mb-3'>
                        <Button onClick={handleSubmit(onClickToMakeDeposite)} variant='green'>Make Deposit</Button>
                    </Col>
                </form>
            </div>
        </Container>
    </>)
}

export default Deposit