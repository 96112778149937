import React from 'react'
import { useSlateStatic, ReactEditor } from 'slate-react'
import { Transforms } from "slate"
import { Button } from "react-bootstrap"
import Icon from "../../components/Icon";
import { getVideoEmbedCode } from './utils';
import "./style.scss";

const Video = ({ attributes, element }) => {
    const editor = useSlateStatic()
    const path = ReactEditor.findPath(editor, element)

    return (
        <div {...attributes} className="video" contentEditable={false}>
            <div className="video-content">
                <div className="video-buttons">
                    <Button
                        active
                        onClick={() => {
                            Transforms.removeNodes(editor, { at: path })
                            Transforms.removeNodes(editor, {
                                at: path, match: (node) => {
                                    return node.type === 'paragraph' && node.video_description === true
                                }
                            })
                        }}
                    ><Icon name="close" /></Button>
                </div>
                {getVideoEmbedCode(element.url)}
            </div>
        </div>
    )
}

export default Video;