import React, { useEffect, useState, useContext } from 'react'
import { Col, Button, Container, Row, Modal } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import Icon from '../components/Icon'
import { Label } from '../components/Typography'
import { RTimeAgo } from '../components/RTimeAgo'
import { getOneArticle, getArticleFeed, updateVersion, addRevision, versionStatus } from '../action/article.action';
import { GlobalContext } from '../context/globalContext';
import { formattedDate } from '../Utils/AppUtill'
import { BookMarkComponent } from '../components/BookMarkComponent';
import {RequestToPermissionComponent} from '../components/Article/RequestToPermissionComponent';
import { GetMutualCodeComponent } from '../components/Article/GetMutualCodeComponent'
import CMEditor from '../components/Editor/CMEditor';
import toast from '../helpers/toast'
import Banner from '../assets/images/banner.jpg'

const articlePerPage = 5;
let arrayForHoldingNewsFeeds = [];

const apiUrl = process.env.REACT_APP_API_URL;

const getImageUrl = (path) => {
    if(path.includes(window.location.origin)) {
        return path;   
    }
    return apiUrl + "/thumb?src=" + path + "&w=120&h=120"
}

const ArticleDetailsPage = () => {
    const { isLoggedIn, userData, setSignIn } = useContext(GlobalContext);
    const history = useHistory()
    const [article, setArticle] = useState({})
    const [feeds, setFeeds] = useState([])
    const {author, type, alias} = useParams();
    const [showLikeModal, setShowLikeModal] = useState(false);
    const [showMailModal, setShowMailModal] = useState(false);
    const [state, setState] = useState({content_editor: null, content: ''});
    const [isContentEditor, setIsContentEditor] = useState(true);
    const handleCloseLikeModal = () => setShowLikeModal(false);
    const handleCloseMailModal = () => setShowMailModal(false);
    const handleShowMailModal = () => setShowMailModal(true);
    const [ArticleToShowList, setArticleToShowList] = useState([]);
    const [next, setNext] = useState(0);
    const [isFeedLoader, setIsFeedLoader] = useState(false);

    const loopWithSlice = (start, end) => {
        const slicedPosts = feeds.slice(start, end);
        arrayForHoldingNewsFeeds = [...arrayForHoldingNewsFeeds, ...slicedPosts];
        setArticleToShowList(arrayForHoldingNewsFeeds);
    };
    
    const onRetriveArticle = async () => {
        try {
            const {data} = await getOneArticle({author, type, alias}, history.location.search)
            if(data.status === 200) {
                setArticle(data.data)
                const response = data.data;
                const { startingfee, fee1000imp, fee10000imp } = response;
            
                if(response.type_id === '1') {
                    if(isLoggedIn) {
                        response.calculatePrice = (parseInt(startingfee) + parseInt(fee1000imp) + parseInt(fee10000imp));
                        if(response.ar) {
                            if(response.content_provider === 'editor') {
                                if(response.form_v && response.form_v.content_editor) {
                                    setState({...state, content: response.form_v.content, content_editor: response.form_v.content_editor})
                                } else {
                                    setState({...state, content: response.content, content_editor: response.content_editor})
                                }
                                setIsContentEditor(true)
                            } else {
                                if(response.form_v) {
                                    setIsContentEditor(false)
                                } else {
                                    setIsContentEditor(false)
                                }
                            }   
                        } else if(response.version) {
                            if(response.version.content_provider === 'editor') {
                                if(response.version.content_editor) {
                                    setState({...state, content: response.version.content, content_editor: response.version.content_editor})
                                } else {
                                    setState({...state, content: response.content, content_editor: response.content_editor})
                                }
                                setIsContentEditor(true);
                            } else {
                                setIsContentEditor(false);
                            }
                        } else if(response.pl) {
                            if(response.content_provider === 'editor') {
                                setIsContentEditor(false);
                            } else {
                                setIsContentEditor(false);
                            }
                        } else if(response.articlerevision) {
                            if(response.articlerevision.content_provider === 'editor') {
                                setIsContentEditor(false);
                            } else {
                                setIsContentEditor(false);
                            }
                        } else if(response.add_revision === 1) {
                            if(response.content_provider === 'editor') {
                                setState({...state, content: response.content, content_editor: response.content_editor})         
                                setIsContentEditor(true);
                            } else {
                                setIsContentEditor(false);
                            }
                        } else {
                            setIsContentEditor(false);
                        }
                    }
                }
            }    
        } catch(err) {
            history.push("/");
        }    
    }
       
    const onRetriveArticleFeed = async () => {
        if(article.id) {
            const {data} = await getArticleFeed({article_id: article.id, lt: feeds.length > 0?feeds[feeds.length - 1].last_id:0})
            
            if(data.status === 200) {
                if(data.data.length > 0) {
                    setIsFeedLoader(data.data[0]['isLoadMore']);
                } else {
                    setIsFeedLoader(false);
                }
                setFeeds([...feeds, ...data.data])
            }
        }
    }
    
    const onClickUpdateVersion = async (formData) => {
        if(isContentEditor) {
            const { data } = await updateVersion(formData);
            if(data.status === 200) {
                history.push(data.data.url);
            }
        } else {
            toast.error("The content is unchanged");
        }
    }

    const onClickAddRevision = async () => {
        if(isContentEditor) {
            const formData = new FormData();
            formData.append('article_id', article.id)
            formData.append('content_editor', JSON.stringify(state.content_editor))
            formData.append('content', state.content)
            const { data } = await addRevision(formData);
            if(data.status === 200) {

                history.push(`/article/revisions?id=${article.id}`);
            }
        } else {
            toast.error("The content is unchanged");
        }
    }

    const onClickVersionStatus = async (status) => {
        const formData = new FormData();
        formData.append('id', article.version.id);
        formData.append('submit', status);
        const { data } = await versionStatus(formData);
        if(data.status === 200) {
            history.push(data.data.url);
        }
    }

    useEffect(() => {
        return () => {
            arrayForHoldingNewsFeeds = [];
            setArticleToShowList([]);
            setNext(0);
        }
    }, [])

    useEffect(() => {
        setFeeds([]);
        onRetriveArticle()
    }, [history.location])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(article.id) {
            arrayForHoldingNewsFeeds = [];
            setArticleToShowList([]);
            setNext(0);
            onRetriveArticleFeed();
        }
    }, [article])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(feeds.length > 0) {
            const addNext = next + articlePerPage;
            loopWithSlice(feeds.length===5?0:next, addNext);
            setNext(addNext);
        }
    }, [feeds])// eslint-disable-line react-hooks/exhaustive-deps
    
    
    // useEffect(() => {
    //     if(getArticleFeeds) {
    //         setFeeds([getArticleFeeds])
    //     }
    // }, [getArticleFeeds])
    
    const handleLoadMore = async () => {
        await onRetriveArticleFeed()
    }
    
    const rateCount = (rated, count) => {
        let htmlOutput = [];
        // console.log("${rated?'0':''}", count, `${rated?'0':'1'}`)
        for(let i = 0; i <count; i++) {
            htmlOutput.push((<i key={i + 1} className={`fa fa-star${rated?'':'-o'}`} aria-hidden="true"></i>))
        }
        return htmlOutput
    }
    
    const onHandleRate = (rate) => {
        return (<>
            {rateCount(true, rate)}
            {rateCount(false, 5 - rate)}
            <Link to={`/author/${article.author_username}`} className='cm_reviews'>{article.rating_count} Reviews</Link>
        </>)
    }
    
    const onRenderPermission = () => {
        const output = [];
        if(isLoggedIn && userData.id!==article.user_id) {
            if(article.articlerequest) {
                if(article.articlerequest_approved === '1') {
                    output.push(<Button variant='green' onClick={() => history.push('/user/myrequests?approved=1')}>Request Approved</Button>)
                }
                if(article.articlerequest_approved === '0') {
                    output.push(<Button variant='blue' disabled={true}>Pending Request</Button>)
                }
            } else {
                if(userData.username) {
                    if(article.isFree) {
                        output.push(<GetMutualCodeComponent article_id={article.id} onCallBack={onRetriveArticle}/>)
                    } else {
                        output.push(<RequestToPermissionComponent data={article} article_id={article.id} onCallBack={onRetriveArticle}/>)
                    }
                } else {
                    output.push(<RequestToPermissionComponent data={article} article_id={article.id} onCallBack={onRetriveArticle}/>)
                }
            }
        } else if(isLoggedIn && userData.id===article.user_id){
            output.push(<Button variant='blue' onClick={() => history.push(`/article/edit?id=${article.id}`)}>Edit</Button>)
        } else if(!isLoggedIn){
            output.push(<RequestToPermissionComponent data={article} article_id={article.id} onCallBack={onRetriveArticle}/>)
        }
        return  output;
    }
    
    const onRenderContent = () => {
        const output = [];
        let id = "";
        let version_id = "";
        if(article.type_id === '1') {
            if(!isLoggedIn) {
                output.push(<>
                    <div dangerouslySetInnerHTML={{__html: article.content}} />
                    <a href="#/" onClick={() => setSignIn(true)}>Log-in</a> to view the full article.
                </>);
            } else {
                if(article.ar) {
                    id = article.ar.id;
                    const formData = new FormData();
                    formData.append('id', id);
                    if(article.form_v) {
                        version_id = article.form_v.id;
                        formData.append('version_id', version_id);
                    }
                    formData.append('content', state.content);
                    formData.append('content_editor', JSON.stringify(state.content_editor));
                    if(article.content_provider === 'editor') {
                        if(article.form_v) {
                            output.push(<>{state.content_editor && <CMEditor save={(content, html) => setState({ ...state, content_editor: content, content: html })}
                            content={state.content_editor} />}</>);
                        } else {
                            output.push(<>{state.content_editor && <CMEditor save={(content, html) => setState({ ...state, content_editor: content, content: html })}
                            content={state.content_editor} />}</>);
                        }
                    } else {
                        if(article.form_v) {
                            output.push(<div>{article.form_v.content}</div>)
                        } else {
                            output.push(<div>{article.content}</div>)
                        }
                    }   
                    output.push(<button type="button" className='btn btn-dark save-btn-content' onClick={()=> onClickUpdateVersion(formData)}>Save</button>)
                } else if(article.version) {
                    version_id = article.version.id;
                    const formData = new FormData();
                    formData.append('version_id', version_id)
                    formData.append('content', state.content);
                    formData.append('content_editor', JSON.stringify(state.content_editor));
                    if(article.version.content_provider === 'editor') {
                        output.push(<>{state.content_editor && <CMEditor save={(content, html) => setState({ ...state, content_editor: content, content: html })}
                            content={state.content_editor} />}</>);
                    } else {
                        output.push(<div>{article.version.content}</div>)
                    }
                    if(article.edit === 1) {
                        output.push(<button type="button" onClick={()=> onClickUpdateVersion(formData)} className='btn btn-dark save-btn-content'>Save</button>)
                    }
                    if(article.version.status === '1') {
                        output.push(<button type="button" onClick={() => onClickVersionStatus('Approve')} className='btn btn-dark save-btn-content'>Approve</button>)
                        output.push(<button type="button" onClick={() => onClickVersionStatus('Disapprove')} className='btn btn-dark save-btn-content'>Disapprove</button>)
                    }
                } else if(article.pl) {
                    if(article.content_provider === 'editor') {
                        output.push(<div dangerouslySetInnerHTML={{__html: article.pl.content}} />);
                    } else {
                        output.push(<div>{article.pl.content}</div>)
                    }
                } else if(article.articlerevision) {
                    if(article.articlerevision.content_provider === 'editor') {
                        output.push(<div dangerouslySetInnerHTML={{__html: article.articlerevision.content}} />);
                    } else {
                        output.push(<div>{article.articlerevision.content}</div>)
                    }
                } else if(article.add_revision === 1) {
                    if(article.content_provider === 'editor') {
                        output.push(<>{article.content_editor && <CMEditor save={(content, html) => setState({ ...state, content_editor: content, content: html })}
                            content={article.content_editor} />}</>);
                    } else {
                        output.push(<div>{article.content}</div>)
                    }
                    output.push(<button type="button" onClick={()=>onClickAddRevision()} className='btn btn-dark save-btn-content'>Save</button>)
                } else {
                    output.push(<div dangerouslySetInnerHTML={{__html: article.content}} />);
                }
            }
        } else if(article.type_id === '2') {
            output.push(<>
                <img src={article.content} alt=""/>
            </>);
        } else if(article.type_id === '3') {
            if(!isLoggedIn) {
                output.push(<>
                    <Link to="/user/login">Log-in</Link> to play the video.
                </>);
            } else {
                output.push(<>
                    <video controls>
                        <source src={article.content} />
                    </video>
                </>);
            }
        } else if(article.type_id === '4') {
            if(!isLoggedIn) {
                output.push(<>
                    <Link to="/user/login">Log-in</Link> to play the audio.
                </>);
            } else {
                output.push(<>
                    <audio controls>
                        <source src={article.content} />
                    </audio>
                </>);
            }
        }
        return output;
    }

    const style = {
        fontSize: '20px',
        backgroundColor: '#d9534f',
        padding: '5px',
        color: 'snow'
    }
           
    return (<>
        <div className='cm_articleBnr' style={{ backgroundImage: `url(${article.image?article.image:Banner})` }}>
            {article.accolade && <div className='cm_logo'>
                <img src={article.accolade} alt='' />
            </div>}
            <Container>
                <Row className='align-items-end'>
                    <Col lg={6} className='pe-lg-4'>
                        <div className='cm_articleTitle mb-3'>{article.name}</div>
                        <div className='mb-3'>
                            {Object.keys(article).length > 0 && article.published!=="1" && <>
                                <span style={style} className="label label-danger">
                                    {article.unique === '0' && <span>checking for plagiarism, </span>}
                                    {article.unique === '-1' && <span>not unique, </span>}
                                    {article.approved === '0' && <span>waiting for approval, </span>}
                                    {article.approved === '-1' && <span>not approved,</span>}
                                    not published
                                </span>
                            </>}
                        </div>
                        {(article.city || article.state) && <div className='mb-3'>
                            <a className='cm_maplink' href={`http://maps.google.com/maps?q=${article.location_string}&zoom=14&size=512x512&maptype=roadmap&sensor=false`} target='_blank' rel="noreferrer"><span className='ion-compass me-2' />{article.city}, {article.state}</a>
                        </div>}
                        <div className='mb-3'>
                            <a href="#/" onClick={() => history.push(`/search?p=${encodeURIComponent(JSON.stringify([{'name': 'category_level_1', 'value': article.category_level}]))}`)} className='cm_maplink mb-3' >{article.category_name}</a>
                        </div>
                        <div className='d-flex align-items-center flex-wrap'>
                            <div className='d-flex align-items-center me-4'>
                                {onHandleRate(Math.round(article.averageRating))}
                            </div>
                            <div className='d-flex align-items-center'>
                                <BookMarkComponent type='user' id={article.id} isBookmarked={article.bookmarked_by} onCallBack={onRetriveArticle}/>
                                <span className='cm_reviews'>{article.bookmark_count?article.bookmark_count:0} Favorites</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} className='d-flex justify-content-lg-end'>
                    {/* Get Mutual-Code™ */}
                        {Object.keys(article).length > 0 && <>{onRenderPermission()}</>}
                    </Col>
                </Row>
            </Container>
        </div>
        <Container className='py-5'>
            <Row>
                <Col md={8}>
                    <div className='CM_whiteBox mb-4 text-green'>
                        <div className='cm_boxTitle'><Icon name="editicon" className="w-20px me-2" />Details</div>
                        <p className='cm_boxText mb-4'>{article.description}</p>
                        <p className='cm_boxText mb-0'>Published {article.created && <RTimeAgo date={formattedDate(article.created)}/>}</p>
                    </div>
                    <div className='CM_whiteBox mb-4 text-green'>
                        <div className='cm_boxTitle'><Icon name="editicon" className="w-20px me-2" />Article Content</div>
                        {onRenderContent()}
                        {article.hash &&
                            <div><pre className="article-hash">Content hash: {article.hash}</pre></div>
                        }
                    </div>
                </Col>
                <Col md={4}>
                    {article.other_languages && article.other_languages.length > 0 && <div className='CM_whiteBox mb-4 text-green'>
                        <div className='cm_boxTitle'>
                            <Icon name="editicon" className="w-20px me-2" />Languages</div>
                        {(article.other_languages || []).map((o, indx) => {
                            return <p key={indx + 1} className='cm_boxText mb-0'><b>{o.language}</b> | <Link to={o.url?o.url:""}>{o.name}</Link>    </p>
                        })}
                    </div>}
                    <div className='CM_whiteBox mb-4 text-green'>
                        {article.author_image && <img alt="" src={getImageUrl(article.author_image)} onClick={() => history.push(`/author/${article.author_username}`)} className='cm_img105' />}
                        <div className='text-center fs-23px mb-1' style={{cursor: 'pointer'}} onClick={() => history.push(`/author/${article.author_username}`)}>{article.author_first_name} {article.author_last_name}</div>
                        <div className='text-center gray-500 fs-13px mb-2'>Listing Owner</div>
                        <div className='d-flex align-items-center justify-content-center pb-4'>
                            <Button variant='mail' onClick={handleShowMailModal}>
                                <Icon name="mail" />
                            </Button>



















                            
                            <Button variant='mail' onClick={() => history.push(`/author/${article.author_username}`)} >
                                <Icon name="info" />
                            </Button>
                        </div>
                        <div className='cm_boxTitle'><Icon name="editicon" className="w-20px me-2" />Author Bio</div>
                        <p className='cm_boxText mb-0'>{article.author_description}</p>
                    </div>
                    <div className='CM_whiteBox mb-4 text-green'>
                        <div className='cm_boxTitle'><Icon name="editicon" className="w-20px me-2" />News Feed</div>
                        <div id="cm_article_feed">
                            {ArticleToShowList && ArticleToShowList.map((item, i) => {
                                return (<div className="cm_feed_activity" key={i}>
                                    <img src={item.image} alt="" className="feed-profile-image" />
                                    <Link to={item.author_url}>{item.print_name}</Link> viewed <Link to={item.public_url}>{item.name}</Link>
                                    <span className="feed-time">{item.activity}</span>
                                </div>)
                            })}
                        </div>
                        {isFeedLoader &&  <div className='text-center'><Button variant='loadmore' onClick={handleLoadMore}>Load more</Button></div>}
                    </div>
                    <div className='CM_whiteBox price-sticky-top mb-4 text-green'>
                        <div className='cm_boxTitle'><Icon name="editicon" className="w-20px me-2" />Price</div>
                            <>
                                <p className='cm_boxText mb-0'>Starting fee: ${article.startingfee}</p>
                                <p className='cm_boxText mb-0'>Price per 1,000 impressions: ${article.fee1000imp}</p>
                                <p className='cm_boxText mb-0'>Price per 10,000 impressions: ${article.fee10000imp}</p>
                                <p className='cm_boxText mb-3'>(effective price per 1,000 views: ${article.fee10000imp/10})</p>
                                {article.calculatePrice > 0 && article.smart_contract_payment === "true" && <p className='cm_boxText mb-3'>Smart contract payments</p>}
                            </>
                            {article.contract && <>
                                <p className='cm_boxText mb-3'><a href={article.external_link} rel="noreferrer" target="_blank"><img src={article.banner_image_url} alt=""/></a></p>
                                <p className='cm_boxText mb-3'>{article.nft_description}</p>
                            </>}
                            {Object.keys(article).length > 0 && <>{onRenderPermission()}</>}
                    </div>
                </Col>
            </Row>
        </Container>
        {/* -----------Favorites Modal------------- */}
        <Modal show={showLikeModal} onHide={handleCloseLikeModal} size="xl" centered>
            <Modal.Header className='border-0' closeButton>
                <div className='fs-20px text-green fs-md-24px'>Bookmark This Listing</div>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className='mb-3'>
                        <Label text="Notes:" />
                        <textarea rows="3" className='cm_input'></textarea>
                    </div>
                    <Button variant='green'>Add Bookmark</Button>
                </form>
            </Modal.Body>
        </Modal>
        {/* -----------Get Mutual-Code Modal------------- */}
        
        {/* -----------Mail Modal------------- */}
        <Modal show={showMailModal} onHide={handleCloseMailModal} size="xl" centered>
            <Modal.Header className='border-0 py-0' closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className='text-green fs-13px'>Please request permission to initiate conversation.</div>
            </Modal.Body>
        </Modal>
    </>)
}

export default ArticleDetailsPage