import { APIEndpoints } from './endpoint'
import axios from '../helpers/axios';

export const getAllNotification = () => {
    return axios.get(`${APIEndpoints.getAllNotification}`);
}

export const fetchNotification = (query) => {
    return axios.get(`${APIEndpoints.fetchNotification}${query}`);
}

export const requestToMessage = (query, data = {}) => {
    return axios.post(`${APIEndpoints.requestMessage}${query}`, data);
}

export function isVerifyWallet(data) {
    return axios.post(`${APIEndpoints.isVerifyWallet}`, data);
}

export function resetNotificationCount() {
    return axios.get(`${APIEndpoints.resetNotificationCount}`);
}