import { useCallback, useEffect, useState } from 'react';
import { useSlateStatic, ReactEditor } from 'slate-react'
import { insertImage } from './utils'
import { Button, FormControl, InputGroup, Row, Col, ButtonGroup, ToggleButton } from "react-bootstrap"
import { imageSearch, flickrImageInfo } from '../../action/image.action';
import { Transforms } from "slate"
import Icon from '../Icon';

const AddImage = ({ element }) => {
    const editor = useSlateStatic()
    const path = ReactEditor.findPath(editor, element)
    const [preview, setPreview] = useState(null);
    const [query, setQuery] = useState('');
    const [repoResults, setRepoResults] = useState([]);
    const [imgToInsert, setImgToInsert] = useState([]);
    const [repo, setRepo] = useState('url');
    const [page, setPage] = useState(1);

    const searchImage = useCallback(async () => {
        if (!query || repo === 'url') {
            setRepoResults([]);
            return;
        }
        const data = await imageSearch('query=' + query + '&repository=' + repo.toLowerCase() + '&page=' + page);
        setRepoResults(data.data.data)
    }, [page, query, repo]);

    const loadMore = () => {
        setPage(page + 1);
    }

    useEffect(() => {
        searchImage();
    }, [page, repo, searchImage])

    const queryRepo = (e) => {
        setQuery(e.target.value);
    };

    const queryKeyUp = (e) => {
        if (e.keyCode === 13) { // enter
            searchImage();
        }
    }

    const getDescription = async (data) => {
        return new Promise(async (resolve) => {
            if (!data)
                resolve([{ type: 'paragraph', children: [{ text: 'Add image description' }], image_description: true }])

            let content = [{ text: data.text }];

            if (data.photo_id && data.user_id) { // flickr
                const imgData = await flickrImageInfo(data.photo_id, data.user_id);
                data = { ...data, license: imgData.data.data.license, user: imgData.data.data.user };
            }

            if (data.url)
                content = [...content,
                { text: ' - ' },
                { type: 'link', url: data.url, children: [{ text: 'Photo' }] },
                { text: ' by ' },
                { type: 'link', url: data.user[1], children: [{ text: data.user[0] }] },
                { text: ' / ' },
                { type: 'link', url: data.license[1], children: [{ text: data.license[0] }] },
                ]
            resolve([{
                type: 'paragraph',
                children: content,
                image_description: true
            }]);
        })
    }

    return <div contentEditable={false} className="my-4 col-md-6 col-sm-12 image-embed">
        <Row>
            <Col>
                <ButtonGroup className="mb-2 me-2">
                    <ToggleButton
                        type="checkbox"
                        variant="outline-primary"
                        checked={repo === 'url'}
                        onClick={(e) => setRepo('url')}
                    >URL</ToggleButton>
                </ButtonGroup>

                <ButtonGroup className="mb-2" onChange={(e) => console.log(e)}>
                    {['Pixabay', 'Flickr', 'Unsplash'].map((m, indx) => {
                        return <ToggleButton
                            key={indx + 1}
                            type="checkbox"
                            variant="outline-primary"
                            checked={repo === m}
                            onClick={(e) => {
                                setRepo(m)
                                setPage(1);
                            }}
                        >{m}</ToggleButton>
                    })}
                </ButtonGroup>
            </Col>
        </Row>
        {repo !== 'url' &&
            <>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <FormControl placeholder="Search..." onChange={queryRepo} onKeyUp={queryKeyUp} defaultValue={query} />
                            <Button variant="outline-secondary" onClick={() => searchImage()}><Icon name="search" /></Button>
                        </InputGroup>
                    </Col>
                </Row>

                <Row>
                    {repoResults.length === 0 &&
                        <Col className="d-flex justify-content-center">
                            No results
                        </Col>
                    }
                    {repoResults.map((p, i) => {
                        return <Col md={4} sm={4} style={{ padding: '1px' }} key={i + 1}><div style={{ 'backgroundImage': 'url(' + p.small + ')' }}
                            className={"image-repo-result" + (p.selected ? ' selected' : '')}
                            onClick={async () => {
                                Transforms.removeNodes(editor, { at: path })
                                insertImage(editor, p.original, await getDescription(p), path)
                            }}><div className="image-hover"><label>{p.text}</label></div></div></Col>
                    })}
                </Row>

                <Row>
                    {repoResults.length > 0 &&
                        <Col className="d-flex justify-content-center"><Button variant="link" onClick={() => loadMore()}>Show more</Button></Col>
                    }
                </Row>
            </>
        }

        {repo === 'url' &&
            <><FormControl placeholder="Enter the image URL" onChange={(e) => {
                setPreview(e.target.value)
                setImgToInsert(e.target.value)
            }} onKeyUp={async (e) => {
                if (e.keyCode === 13) {
                    Transforms.removeNodes(editor, { at: path })
                    insertImage(editor, imgToInsert, await getDescription(false), path)
                }
            }} />
                {preview !== null &&
                    <img src={preview} alt="" className="mt-4 w-100" />
                }
            </>
        }
    </div>
}

export default AddImage;