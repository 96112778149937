import { isString } from "lodash";
import React from "react";

const parseValue = (v) => {
    if (v === undefined)
        return [];

    if (isString(v))
        return v.split(',').map(a => parseInt(a, 10))

    return [parseInt(v, 10)];
}

const Checkbox = (props) => {
    const { id, name, values, onChange, value } = props;

    const onClick = (e) => {
        const el = e.target;

        let tmpChecked = parseValue(value);
        if (el.checked) {
            tmpChecked = [...tmpChecked, el.value]
        } else {
            tmpChecked = tmpChecked.filter(e => {
                return parseInt(e, 10) !== parseInt(el.value, 10)
            })
        }

        onChange("attr_" + id, tmpChecked.join(','));
    }

    const isChecked = (vid) => {
        return parseValue(value).indexOf(parseInt(vid, 10)) !== -1;
    }

    return <><label>{name}</label>
        <div className='d-flex align-items-center flex-wrap'>
            {(values || []).map((v, indx) => {
                return <label key={indx + 1} className="CM_checkbox"><input type='checkbox'
                    name={"attr_" + id}
                    defaultValue={v.id}
                    onChange={onClick}
                    checked={isChecked(v.id)} />{v.name}</label>
            })}
        </div></>
}

export default Checkbox;