import React, { useEffect, useState } from 'react'
import {ethers} from "ethers";
import { BrowserRouter as Router } from 'react-router-dom'

import { GlobalContext } from './context/globalContext'
import Routes from './routes/Routes'

import { AppProvider } from './state/app'
import { ToastContainer } from 'react-toastify'

import toast from './helpers/toast'

import { Web3Provider } from "./web3/contexts/web3Context";
import './App.scss'
import Spinner from 'react-bootstrap/Spinner'
import { NotificationContext } from './context/notificationContext'
import { TransactionContext } from './context/transactionContext'

import { authorWithdraw, deposite } from './action/request.action';
import { setWalletProvider, connectWallet, provider } from './web3/functions';


const App = () => {

  const [notification, setNotification] = useState(null);
  const [transactionHistory, setTransactionHistory] = useState(localStorage.transactionHistory?JSON.parse(localStorage.transactionHistory):[]);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    window.localStorage.getItem('token') !== null && window.localStorage.getItem('token') !== "" && window.localStorage.getItem('token') !== undefined
  )
  
  const [signIn, setSignIn] = useState(null);
  
  const [userData, setUserData] = useState(
    JSON.parse(window.localStorage.getItem('userData') || '{}'),
  )
  
  const [appToken] = useState(
    window.localStorage.getItem('token'),
  )
  
  const handleConnect = async (mLogin = null) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      if(mLogin !== null)
        setWalletProvider(mLogin);
      connectWallet().then(addresses => {
        setLoading(false);
        if(addresses && addresses.length)
          if(userData && Object.keys(userData).length > 0) {
            const address = mLogin === 'metamask'?userData?.user_wallet_address:userData?.coinbase_wallet_address;
            if(address && address.toLowerCase() !== addresses[0] && window.localStorage.getItem('userData')) {
              toast.error("Please connect with valid wallet address");
              reject("Please connect with valid wallet address");
            } else {
              resolve(addresses[0]);
            }
          } else {
            resolve(addresses[0]);
          }
        else
          resolve(null);
        }).catch(e => {
          setLoading(false);
          toast.error(e.message);
          reject(e);
        });
    })
  };

  const handleTransactionHistory = (type, action_type, values, txHash) => {
    let transation = transactionHistory;
    if(!txHash) {
      if(action_type === 'remove' && transactionHistory.length > 0) {
        let index = transation.findIndex(r => (r.type === type && r.address === values.address) || r.type === type );
        transation.splice(index, 1);
      } else if(action_type === 'add') {
        transation.push(values);
      }
    } else {
      let index = transation.findIndex(r => r.txHash === txHash );
      transation.splice(index, 1);
    }

    setTransactionHistory([...transation]);
    localStorage.transactionHistory = JSON.stringify([...transation]);
  }

  const setUserInfo = data => {
    setUserData(data)
    window.localStorage.setItem('userData', JSON.stringify(data))
  }

  const setUserToken = data => {
    window.localStorage.setItem('token', data)
  }

  useEffect(() => {
    if(transactionHistory.length > 0) {
      (async () => {
        try {
          const ethersProvider = await new ethers.providers.Web3Provider(provider());
          if(transactionHistory.length > 0) {
            transactionHistory.map(r => {
              if(r.txHash) {
                try{
                    ethersProvider.waitForTransaction(r.txHash).then(async () => {
                    const formData = new FormData();
                    if(r.type === 'deposit') {
                      formData.append('amount', r.amount);
                      formData.append('currency', r.currency);
                      formData.append('articlerequest_id', r.id);
                      formData.append('author_address', r.author_address);
                      formData.append('publisher_address', r.publisher_address);
                      formData.append('contract_address', r.address);
                      const res = await deposite(formData);
                      if(res.data.status === 200) {
                          toast.success(res.data.data.message);
                      }
                    } else if(r.type === 'withdraw') {
                      formData.append('id', r.id);
                      formData.append('publisher_address', r.publisher_address);
                      formData.append('author_address', r.author_address);
                      formData.append('contract_address', r.address);
                      const rs = await authorWithdraw(formData);
                      if(rs.data.status === 200) {
                        toast.success(rs.data.data.message);  
                        setUserInfo({...userData, usdc_funds: rs.data.data.usdc_funds})
                      }
                    }
                    handleTransactionHistory(r.type, 'remove', r, r.txHash)
                  })
                } catch(err) {
                  handleTransactionHistory(r.type, 'remove', r, r.txHash)
                }
              }
            })
          }
        } catch(err) {
          console.log("err", err);
        }
      })()
    }
  }, [transactionHistory])

  useEffect(() => {
    if (loading) {
      document.querySelector('.loader').style.setProperty('display', 'block', 'important');
    } else {
      document.querySelector('.loader').style.setProperty('display', 'none', 'important');
    }
  }, [loading])
  
  return (
    <Router>
      <div className="App">
        <div className="loader">
          <div className="text-center loader-inner">
            {/* <img src={logo} alt="logo" /><br /> */}
            <Spinner animation="border" className="text-blue" variant="primay" />
          </div>
        </div>
        <Web3Provider
          value={{
            loading,
            setLoading,
            handleConnect,
          }}
        >
          <GlobalContext.Provider
            value={{
              isLoggedIn,
              setIsLoggedIn,
              setUserInfo,
              userData,
              appToken,
              setUserToken,
              signIn,
              setSignIn
            }}
          >
            <NotificationContext.Provider
              value={{
                notification,
                setNotification
              }}
            >
              <TransactionContext.Provider 
                value={{
                  transactionHistory,
                  setTransactionHistory,
                  handleTransactionHistory
                }}
              >
                <AppProvider>
                  <Routes />
                </AppProvider>
                <ToastContainer />
              </TransactionContext.Provider>
            </NotificationContext.Provider>
          </GlobalContext.Provider>
        </Web3Provider>
      </div>
    </Router>
  )
}

export default App
