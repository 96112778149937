import { useSlateStatic, ReactEditor } from 'slate-react'
import theConversationLogo from "../../assets/images/the_conversation_website_text_logo.svg"
import { Button } from "react-bootstrap"
import Icon from '../Icon';
import { Transforms } from "slate"

const TheConversationTracker = ({ element }) => {
    const editor = useSlateStatic()
    const path = ReactEditor.findPath(editor, element)

    return <div contentEditable={false} className="the-conversation-embed">
        <img alt="" src={theConversationLogo} />
        <Button
            active
            onClick={() => {
                Transforms.removeNodes(editor, { at: path })
            }}
            size="sm"
        ><Icon name="close" /></Button>
        <br />
        <input type="text" placeholder="Tracking code" onChange={(e) => {
            Transforms.setNodes(editor, { code: e.target.value }, { at: path });
        }} defaultValue={element.code} />
    </div>
}

export default TheConversationTracker;