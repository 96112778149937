import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { PageTitle } from '../components/Typography';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import {  analytics } from '../action/request.action';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ReactApexChart from 'react-apexcharts';
import queryString from 'query-string';

const AnalyticsPage = () => {

    const history = useHistory();

    const [state, setState] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    
    const { start, end } = state;
    
    const graphData = {
          options: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          title: {
            text: '',
            align: 'left'
          },
          legend: {
            tooltipHoverFormatter: function(val, opts) {
              return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
            }
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function (val) {
                    // return val + " (mins)"
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val + " per session"
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val;
                  }
                }
              }
            ]
          },
          grid: {
            borderColor: '#f1f1f1',
          }
        },
    };

    const onRenderAnalytics = async () => {
        // articlegraph

        const {data} = await analytics(history.location.search);
        
        if(data.status === 200) {
            // data.data.data.data = [{
            //     name: '',
            //     data: data.data.data.data.map(r => [formattedDate(r.date), r.count?parseInt(r.count):0])
            // }];
            data.data.article = data.data.article.map(r => ({...r, isOpenPrint: false, isOpenLink: false})) 
            setState({...state, ...data.data}); 
        }
    }

    let label = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
    useEffect(() => {
        onRenderAnalytics();
        // eslint-disable-next-line
    }, [history.location])
    
    useEffect(() => {
        const {from, to} = queryString.parse(history.location.search);
        if(from && to) {
            onRenderAnalytics();
        }
        // eslint-disable-next-line
    }, [history.location])
    
    const handleCallback = (start, end) => {
        const { pathname, search } = history.location;
        const obj = queryString.parse(search);
        obj.from = start.format('DD-MM-YYYY');
        obj.to = end.format('DD-MM-YYYY')
        setState({ ...state, start, end });
        history.push(`${pathname}?${queryString.stringify(obj)}`)
    };

    const onClickToToggle = (index, name) => {
        let {article} = state;  
        article[index][name] = !article[index][name];
        setState({
          ...state,
          ...article
        })
    } 
        
    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Analytics" />
        
        <Container className="my-5">
            <div className="CM_whiteBox">
                <Row>
                    <Col lg={3}>
                        <DateRangePicker
                            onCallback={handleCallback}
                            initialSettings={{
                                applyButtonClasses: "btn-green",
                                cancelButtonClasses: "btn-green",
                                linkedCalendars: false,
                                startDate: start.toDate(),
                                endDate: end.toDate(),
                                ranges: {
                                    'Today': [moment().toDate(), moment().toDate()],
                                    'Yesterday': [
                                        moment().subtract(1, 'days').toDate(),
                                        moment().subtract(1, 'days').toDate(),
                                    ],
                                    'Last 7 Days': [
                                        moment().subtract(6, 'days').toDate(),
                                        moment().toDate(),
                                    ],
                                    'Last 30 Days': [
                                        moment().subtract(29, 'days').toDate(),
                                        moment().toDate(),
                                    ],
                                    'This Month': [
                                        moment().startOf('month').toDate(),
                                        moment().endOf('month').toDate(),
                                    ],
                                    'Last Month': [
                                        moment().subtract(1, 'month').startOf('month').toDate(),
                                        moment().subtract(1, 'month').endOf('month').toDate(),
                                    ],
                                },
                            }}
                        >
                        <Button>{label}</Button>
                        </DateRangePicker>
                        <br/><br/>
                        {/* {state.article && <div className="card card-border">
                            <div className="card-header fs-13px"><i className="fa fa-user"></i> Author Box</div>
                            <div className="card-body">
                                <Table bordered className='cm_table shadow-none'>
                                    <tbody>
                                        <tr>
                                            <td>Total Days Active:</td><td>{state.artReq.total_days_active}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Page Views:</td><td>{state.artReq.total_page_views}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Daily Page Views:</td><td>{state.artReq.average_daily_page_views}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>} */}
                    </Col>
                    <Col lg={9}>
                        {state.data && <ReactApexChart options={graphData.options} series={state.data} type="line" height={350} />}
                    </Col>
                    {state.article &&
                        state.article.map((res, indx) => {
                          return (
                            <div className="cm_usertable" key={indx + 1}>
                              <Row>
                                <Col lg={2} className="text-center">
                                  <img src={res.image} className="cm_img90rounded" alt="" />
                                </Col>
                                <Col lg={8}>
                                  {/* to={`/article/delete?id=${res.getId}`} */}
                                  <h2 className="cm_articleTitle">
                                    <strong>
                                      <Link to={res.public_url?res.public_url:''}>{res.name}</Link>
                                    </strong>
                                    <small>
                                      {" "}
                                      ({res.created})
                                    </small>
                                  </h2>
                                  <div className="cm_detailsinfo">{res.description}</div>
                                  <ul className="cm-dashboard-btn">
                                    <li className="bg-success">
                                      <a onClick={() => onClickToToggle(indx, 'isOpenPrint')}>
                                        <i className="fa fa-print" />
                                        Reprints
                                      </a>
                                    </li>
                                    <li className="bg-info">
                                      <a onClick={() => onClickToToggle(indx, 'isOpenLink')}>
                                        <i className="fa fa-link" />
                                        Links
                                      </a>
                                    </li>
                                  </ul>
                                  
                                  {res.articleRequest && res.articleRequest.length > 0 && res.isOpenPrint && <div className='article_applications bg-success-rp'>
                                      Domain: {res.articleRequest[0].domain}<br/>
                                      Introduction: {res.articleRequest[0].introduction}<br/>
                                      Total Pageviews: {res.articleRequest[0].totalPageViews}<br/><br/>
                                  </div>}
                                  {res.liveScript && res.liveScript.length > 0 && res.isOpenLink && <div className='article_applications bg-info-rp'>
                                      URL: {res.liveScript[0].url}<br/>
                                      First appeared: {res.liveScript[0].time}<br/><br/>
                                  </div>}
                                </Col>
                                <Col
                                  lg={2}
                                  className="py-2 right_block d-flex align-items-center"
                                >
                                  <div className="text-green fs-13px">
                                    <div className="mt-2 mt-lg-0">
                                      <i className="fa fa-folder-o me-1"></i> Type:
                                        {res.type_name}
                                    </div>

                                    <div className="mt-2 mt-lg-3">
                                      <i className="fa fa-print me-1"></i> Reprints:
                                        {res.applications}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}

                </Row>
            </div>
        </Container>
    </>)
}

export default AnalyticsPage;