import React, { useEffect, useState } from 'react'
import { useSlateStatic, ReactEditor, useSelected, useFocused } from 'slate-react'
import { Transforms } from "slate"
import { Button } from "react-bootstrap"
import Icon from "../../components/Icon";
// import AlignImageButton from './AlignImageButton';
import "./style.scss";

const Image = ({ attributes, children, element, align }) => {
    const editor = useSlateStatic()
    const path = ReactEditor.findPath(editor, element)

    const selected = useSelected()
    const focused = useFocused()
    const imgBtnClass = "image-btn" + (selected && focused ? " selected" : '');

    const [resizing] = useState(false);
    const [mpos1] = useState(0);
    const [mpos2] = useState(0);
    const [width, setWidth] = useState(element.width || 600);
    const [style, setStyle] = useState({ float: align, width: width + 'px' });
    //const [tmpWidth, setTmpWidth] = useState(width);
    const [tmpStyle, setTmpStyle] = useState({});
    const [mposTmp] = useState(0);
    // const [title, setTitle] = useState(element.title || '');

    // const onMouseUp = (e) => {
    //     if (resizing)
    //         return false;

    //     document.removeEventListener("mouseup", onMouseUp, false);
    //     document.removeEventListener("mousemove", resize, false);

    //     setResizing(false);
    //     setMpos2(e.clientX);
    // }

    // const onMouseDown = (e) => {
    //     e.preventDefault();

    //     document.addEventListener("mouseup", onMouseUp, false);
    //     document.addEventListener("mousemove", resize, false);
    //     setMpos1(e.clientX);
    // }

    // const resize = (e) => {
    //     e.preventDefault();

    //     setResizing(true);
    //     setMposTmp(e.clientX);
    // }

    // const changeTitle = (e) => {
    //     e.preventDefault();
    //     setTitle(e.target.value);
    //     Transforms.setNodes(editor, { title: e.target.value }, { at: path });
    // }

    useEffect(() => {
        let w = width - (mpos1 - mpos2);
        setWidth(w);
        setStyle({ ...style, width: w + 'px' });
        Transforms.setNodes(editor, { width: w }, { at: path });
        // eslint-disable-next-line
    }, [mpos2])

    useEffect(() => {
        let w = width - (mpos1 - mposTmp)
        //setTmpWidth(w);
        setTmpStyle({ width: w + 'px' });
        // eslint-disable-next-line
    }, [mposTmp]);

    useEffect(() => {
        setStyle({ ...style, float: align })
        // eslint-disable-next-line
    }, [align]);

    return (
        <div {...attributes} className="image" style={style} contentEditable={false}>
            {children}
            <div className="image-content">
                {resizing === true &&
                    <img className="resizing-img" alt="" src={element.url} style={tmpStyle} />
                }
                <img
                    src={element.url}
                    className={"image-img" + (selected && focused ? " selected" : '')}
                    alt=""
                />
                <div className="image-buttons">
                    <Button
                        active
                        onClick={() => {
                            Transforms.removeNodes(editor, { at: path })
                            Transforms.removeNodes(editor, {
                                at: path, match: (node) => {
                                    return node.type === 'paragraph' && node.image_description === true
                                }
                            })
                        }}
                        className={imgBtnClass}
                    ><Icon name="close" /></Button>
                    {/*
                    <AlignImageButton className={imgBtnClass} align="left" icon="align-left" />
                    <AlignImageButton className={imgBtnClass} align="center" icon="align-center" />
                    <AlignImageButton className={imgBtnClass} align="right" icon="align-right" />
                    */}
                </div>
                {/* uncomment and resizing should work
                <div className="resize-handle" onMouseDown={onMouseDown}></div>
                */}
            </div>
            {/*
            <input type="text" value={title} onChange={(e) => changeTitle(e)} className="image-title" />
            */
            }
        </div>
    )
}

export default Image;