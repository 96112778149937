import React, { useEffect, useState, useContext, useMemo } from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import { Label, PageTitle } from '../../components/Typography'
import { useHistory } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce';
import { GlobalContext } from '../../context/globalContext'
import { getCategoryList, getSingleArticle, getAllAttributes, getAllLanguage, editArticleCheckCopyRightUrl, updateArticle, searchTags, check_unique } from '../../action/article.action';
import { WithContext as ReactTags } from 'react-tag-input';
import ArticleUpdatePriceModal from '../../components/Article/ArticleUpdatePriceModal';
import ErrorLabel from '../../widgets/ErrorLable';
import { ArticleType } from '../../constants/ArticleConstant'
import CMEditor from '../../components/Editor/CMEditor';
import { CropImage } from '../../components/CropImage';
import queryString from 'query-string';
import toast from '../../helpers/toast';
import Attribute from '../../components/Attribute/Attribute';

const FieldLength = ({ field, length }) => {
    const left = length - (field ? field.split(' ').length : 0);
    const classList = left < 0 ? "text-danger" : null;

    return <Label text={left} className={classList} />
}

const EditArticlePage = () => {
    const history = useHistory();
    const { userData } = useContext(GlobalContext);
    const [errors, setErrors] = useState({});
    const [validatedOnce, setValidatedOnce] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    
    const [state, setState] = useState({
        content_provider: 'url',
        content: '<div>Test Content</div>'
    });
    const [newAttributes, setNewAttributes] = useState([]);

    const [category, setCategory] = useState("")

    const [language, setLanguage] = useState([])
    const KeyCodes = {
        comma: 188,
        enter: [10, 13],
    };

    const delimiters = [...KeyCodes.enter, KeyCodes.comma];
    
    const [tags, setTags] = useState(state.tags || [])

    const handleDelete = (i) => {
        const filter = tags.filter((tag, index) => index !== i);
        setTags(filter);
    }

    const handleAddition = (tag) => {
        if(tag.text.length < 3) {
            toast.error("Tag should be atleast 3 or more characters");
            return false;
        }
        setTags([...tags, tag]);
    }

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        setTags({ tags: newTags });
    }
    
    const onRetriveCategory = async (parentId = 0) => {
        const { data } = await getCategoryList(parentId);
        if (data.status === 200) {
            setCategory(data.data)
        }
    }

    const onRetriveLanguage = async () => {
        const langs = await getAllLanguage();
        setLanguage(langs)
    }

    const onRetriveArticle = async () => {
        const { data } = await getSingleArticle(history.location.search);
        if (data.status === 200) {
            const tagsA = data.data.tags.split(',').map(res => ({ id: res, text: res })).filter(r => r.id!=='');
            setState({ ...state, language: data.data.language_code, ...data.data });
            setTags([...tagsA]);
            onRetriveCategory(data.data.category_id);
            onRetriveLanguage();
        }
    }
    
    useEffect(() => {
        onRetriveArticle()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getNewAttributes();
    }, [state.id])// eslint-disable-line react-hooks/exhaustive-deps

    const onChangeInput = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const onChangeAttribute = (name, value) => {
        onChangeState({ [name]: value });
    }

    const onChangeState = (data) => {
        setState({ ...state, ...data });
    }
    
    const onSubmitArticle = async (status = '') => {
        if (!validate())
            return false;

        let tags1 = tags ? tags.map(res => res.text).join(',') : '';

        const formData = new FormData();
        formData.append('user_id', userData.id);
        let attrs = [];
        Object.keys(state).map(res => {
            if (res === 'tags') {
                formData.append('tags', tags1)
            } else if(res.startsWith('attr_')) {
                attrs.push({name: res, value: state[res]})
             } else {
                formData.append(res, state[res])
            }
            return res;
        })

        if(status === 'editUrl') {
            formData.append('update_article', 'Update Article');
        }

        formData.append('attrs', JSON.stringify(attrs));
        formData.set('content_editor', JSON.stringify(state.content_editor));
        
        const { data } = await updateArticle(history.location.search, formData);
        if (data.status === 200) {
            if(data.data.status === 'error') {
                toast.error(data.data.message);
            } else {
                toast.success(data.data.message);
                onRetriveArticle();
            }
        }
    }

    const onUpdateContent = async (status) => {
        const formData = new FormData();
        formData.append('url', state.url)
        formData.append('article_id', state.id)
        const { data } = await editArticleCheckCopyRightUrl(formData)
        if (data.status === 200) {
            if (data.data.status === 'error') {
                toast.error(data.data.msg)
            } else if(data.data.status === 'success') {
                if(status === 'editUrl') {
                    onSubmitArticle(status)
                }
            }

        }
    }

    const onClickToAdd = () => {
        history.push(`/article/submit?parent_id=${state.id}&lang=${state.language}`)
    }

    const onClickToImageUpload = (data) => {
        setState({ ...state, image: data })
    }

    const [categoryMultiple, setCategoryMultiple] = useState(false);
    const categoryEventHandlers = useMemo(() => ({
        onFocus: () => setCategoryMultiple(true),
        onBlur: () => setCategoryMultiple(false)
    }), []);

    const getNewAttributes = async () => {
        const { data } = await getAllAttributes({ article_id: queryString.parse(history.location.search).id });
        setNewAttributes(data.data);
        let attrValues = {};
        data.data.forEach(a => {
            if (a.value)
                attrValues["attr_" + a.id] = a.value;
        })
        onChangeState(attrValues);
    };

    const validate = () => {
        let required = ['name', 'category_id', 'tags', 'language', 'description', 'accolade_url',
            'image', 'content_provider'];

        if (state.content_provider === 'url')
            required.push('url');
        else
            required.push('content_editor');

        let valid = true;
        let errTmp = {};
        required.forEach(r => {
            if(r === 'accolade_url') {
                if(state['accolade']!=='' && (state[r] === undefined || !state[r])) {
                    errTmp = { ...errTmp, [r]: true };
                    valid = false;
                }
            } else if(r === 'tags') {
                if(tags.length <= 0) {
                    errTmp = { ...errTmp, [r]: true };
                    valid = false
                }
            } else {
                if (state[r] === undefined || !state[r]) {
                    errTmp = { ...errTmp, [r]: true };
                    valid = false;
                }
            }
        })
        
        setValidatedOnce(true)
        setErrors(errTmp)
        return valid;
    }

    const onClickToCheckUniqness = async () => {
        const { data } = await check_unique(history.location.search);
        if(data.status === 200) {
            toast.success(data.data.message);
            onRetriveArticle()
        }
    }
    
    const handleTagInputChange = useDebouncedCallback(async (q) => {
        if(q.length >= 3) {
            const {data} = await searchTags(q);
            if(data.status === 200) {
                setSuggestions(data.data.tags)
            }
        } else {
            setSuggestions([])
        }
    }, 500);

    useEffect(() => {
        if (validatedOnce)
            validate()
    }, [state, tags])// eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <PageTitle text={`Edit ${state.type_name?state.type_name:''}`} />
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <form>
                    <Row>
                        <Col md={10} className='mb-3'>
                            <Label text="Add New Language" />
                            <>
                                <select className="cm_input"
                                    name="language"
                                    value={state.language}
                                    onChange={(e) => {
                                        onChangeInput(e)
                                    }}>
                                    <option value="">Choose Language</option>
                                    {language && language.map((res, idx) => <option key={idx + 1} value={res.code}>{res.name}</option>)}
                                </select>
                                {errors.language && <ErrorLabel
                                    msg={"Language is required."}
                                />}
                            </>
                        </Col>
                        <Col md={2} className='mb-3'>
                            <button type="button" onClick={onClickToAdd} className='btn btn-dark'>Add</button>
                        </Col>
                        <Col md={12} className='mb-3'>
                            <Label text="Article Title" />
                            <>
                                <input
                                    type="text"
                                    name="name"
                                    value={state.name}
                                    onChange={(e) => {
                                        onChangeInput(e)
                                    }}
                                    className="cm_input"
                                    required
                                />
                                {errors.name && <ErrorLabel
                                    msg={"Name is required."}
                                />}
                            </>
                        </Col>
                        <Col md={12}>
                            <Row>
                                {newAttributes.map(a => {
                                    return <Col md={12} className='mb-12' key={a.id}>
                                        <>
                                            <Attribute {...a} onChange={(n, v) => {
                                                onChangeAttribute(n, v)
                                            }}
                                                value={state["attr_" + a.id]} />
                                            {errors['attr_' + a.id] && <ErrorLabel
                                                msg={a.name + " is required."}
                                            />}
                                        </>
                                    </Col>
                                })}
                                <Col md={4} className='mb-3'>
                                    <Label text="Category" />
                                    <>
                                        <select
                                            className="cm_input"
                                            required
                                            name="category_id"
                                            value={state.category_id}
                                            onChange={(e) => {
                                                onChangeInput(e)
                                                onRetriveCategory(e.target.value)
                                            }} dangerouslySetInnerHTML={{ __html: category }}
                                            {...categoryEventHandlers}
                                            multiple={categoryMultiple}>
                                        </select>
                                        {errors.category_id && <ErrorLabel
                                            msg={"Category is required."}
                                        />}
                                    </>
                                </Col>
                                <Col md={8} className='mb-3'>
                                    <Label text="Article Tags" />
                                    <div className='cm_input mb-3 p-1 d-flex flex-wrap align-items-center'>
                                        <ReactTags tags={tags}
                                            suggestions={suggestions}
                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                            handleDrag={handleDrag}
                                            delimiters={delimiters}
                                            handleInputChange={handleTagInputChange} />
                                    </div>
                                    {errors.tags && <ErrorLabel
                                        msg={"Tags is required."}
                                    />}
                                </Col>
                                <Col md={12} className='mb-3'>
                                    {state.id && <>
                                        <CropImage cimage={state.image} callBack={onClickToImageUpload} aspect={3 / 1} />
                                        {errors.image && <ErrorLabel
                                            msg={"Image is required."}
                                        />}
                                    </>}
                                </Col>
                                <Col md={12} className='mb-3'>
                                    <Label text="Description" />
                                    <>
                                        <textarea
                                            rows="5"
                                            className='cm_input'
                                            name="description"
                                            value={state.description}
                                            onChange={(e) => {
                                                onChangeInput(e)
                                            }}
                                        ></textarea>
                                        {errors.description && <ErrorLabel
                                            msg={"Description is required."}
                                        />}
                                    </>
                                    <FieldLength length={100} field={state.description} />
                                </Col>
                                {state.content_provider === 'url' &&
                                    <Col md={12} className='mb-3'>
                                        <Label text="URL" />
                                        <>
                                            <input
                                                type="text"
                                                name="url"
                                                value={state.url}
                                                onChange={(e) => {
                                                    onChangeInput(e)
                                                }}
                                                onBlur={() => onUpdateContent('checkUrl')}
                                                className="cm_input"
                                            />
                                            {errors.url && <ErrorLabel
                                                msg={"Url is required."}
                                            />}
                                        </>
                                    </Col>
                                }
                                {state.content_provider === 'editor' &&
                                    <Col md={12} className='mb-3'>
                                        <Label text="Editor" />
                                        <CMEditor save={(content, html) => setState({ ...state, content_editor: content, content: html })}
                                            content={state.content_editor} />
                                    </Col>
                                }
                                <Col md={12} className='mb-3'>
                                    <Label text="This article has been seen on" />
                                    <>
                                        <select
                                            className="cm_input"
                                            name="accolade"
                                            value={state.accolade}
                                            onChange={(e) => {
                                                onChangeInput(e)
                                            }}
                                        >
                                            <option value="">Choose</option>
                                            <option value="business_insider">Business Insider</option>
                                            <option value="computer_world">Computer World</option>
                                            <option value="digg">Digg</option>
                                            <option value="efactor">EFactor</option>
                                            <option value="forbes">Forbes</option>
                                            <option value="fortune">Fortune</option>
                                            <option value="gizmodo">Gizmodo</option>
                                            <option value="iflscience">IFLScience</option>
                                            <option value="information_week">Information Week</option>
                                            <option value="lifehacker">LifeHacker</option>
                                            <option value="mashable">Mashable</option>
                                            <option value="newsweek">Newsweek</option>
                                            <option value="popular_science">Popular Science</option>
                                            <option value="quora">Quora</option>
                                            <option value="scientific_american">Scientific American</option>
                                            <option value="slate">Slate</option>
                                            <option value="the_guardian">The Guardian</option>
                                            <option value="the_independent">The Independent</option>
                                            <option value="the_washington_post">The Washington Post</option>
                                            <option value="time">Time</option>
                                        </select>
                                        {errors.accolade && <ErrorLabel
                                            msg={"Accolade is required."}
                                        />}
                                    </>
                                    {state.accolade && <>
                                        <input
                                            type="text"
                                            className="cm_input"
                                            name="accolade_url"
                                            value={state.accolade_url}
                                            onChange={(e) => {
                                                onChangeInput(e)
                                            }}
                                        />
                                        {errors.accolade_url && <ErrorLabel
                                            msg={"Accolade URL is required."}
                                        />}
                                    </>}
                                </Col>
                                
                                <Col md={2} className='mb-3'>
                                    <button type="button" className='btn btn-dark' onClick={onSubmitArticle}>Save</button>
                                </Col>
                                {state.type_id === ArticleType.ARTICLE && state.content_provider === 'url' && <Col md={2} className='mb-3'>
                                    <button type="button" className='btn btn-dark' onClick={() => onUpdateContent("editUrl")}>Update Content</button>
                                </Col>}
                                {state.type_id === ArticleType.ARTICLE && state.unique_last_checked && state.unique === "0" &&  <Col md={2} className='mb-3'> */}
                                    <button type="button" className='btn btn-dark' onClick={onClickToCheckUniqness}>Check for uniqueness</button>
                                </Col>}
                                <Col md={2} className='mb-3'>
                                    <ArticleUpdatePriceModal onChangeState={onChangeState} state={state} />
                                </Col>
                                
                                <Col md={2} className='mb-3'>
                                    <button type="button" className='btn btn-dark' onClick={() => history.push(state.public_url)}>View</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </form>
            </div>
        </Container >
    </>)
}

export default EditArticlePage;


