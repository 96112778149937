import React, { useEffect, useState, useContext } from 'react'
import { Container, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PageTitle } from '../components/Typography';
import { requestversions } from '../action/article.action';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import { formattedDate } from '../Utils/AppUtill';
import { RTimeAgo } from '../components/RTimeAgo';
import { GlobalContext } from '../context/globalContext';

const RequestVersions = () => {
    const history = useHistory();
    const [state, setState] = useState({});
    const { userData } = useContext(GlobalContext);
    const [page, setPage] = useState(0);
    const onRenderRequestVersions = async () => {
        const {data} = await requestversions(history.location.search)
        if(data.status === 200) {
            // console.log("data", Array.isArray(data.data.requests))
            const requests = [];
            const getRequest = (arr) => {
                return arr.map(r => {
                    if(Array.isArray(r)) {
                        return getRequest(r);
                    } else {
                        return requests.push(r);
                    }
                })    
            }
            getRequest(data.data.requests)
            setState({...data.data, requests})
        }
    }
    
    useEffect(() => {
        let {page} = queryString.parse(history.location.search)
        if(page) {
            setPage(page - 1);
        } else {
            setPage(0);
        }
        onRenderRequestVersions()
    }, [history.location])// eslint-disable-line react-hooks/exhaustive-deps

    const onPageClick = (e) => {
        const {pathname, search} = history.location;
        let query = queryString.parse(search)
        query.page = e.selected + 1;
        query = queryString.stringify(query);
        history.push(`${pathname}?${query}`)
    }
    
    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Active Subscriptions" />
        <Container className="my-5">
            <div className="CM_whiteBox">
                <div className="cm_requestsbtn">
                    {state.articlerequest && <div className="btn-group" role="group" aria-label="...">
                        <Link to="/user/myrequests" className="btn btn-sm btn-light fs-15px">Back to article setting</Link>
                        <Link to={`${state.articlerequest.public_url}?id=${state.articlerequest.id}&add_version=1`} className="btn btn-sm btn-info fs-13px text-white"><i className="fa fa-plus"></i> Add Version of the Original</Link>
                        <Link to={`/request/message?id=${state.articlerequest.id}`} className="btn btn-sm  btn-success fs-13px"><i className="fa fa-envelope"></i>  Messages</Link>
                    </div>}
                </div>
                <div className="cm_usertable2">
                    <Table hover responsive className='cm_table shadow-none'>
                        <thead>
                            <tr className="success">
                                <th>Content</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th>Last Modified</th>
                                <th>Live</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.requests && state.requests.map((res, indx) => {
                                let userFromApprove = "";
                                switch(parseInt(res.user_from_approved)) {
                                    case 0:
                                        userFromApprove = (<span style={{color:'blue'}}>Pending</span>)
                                        break;
                                    case 1:
                                        userFromApprove = (<span style={{color:'green'}}>Approved</span>)
                                        break;
                                    case -1:
                                        userFromApprove = (<span style={{color:'red'}}>Disapproved</span>)
                                        break;
                                    default:
                                        userFromApprove = "";
                                }
                                
                                let userToApprove = "";
                                switch(parseInt(res.user_to_approved)) {
                                    case 0:
                                        userToApprove = (<span style={{color:'blue'}}>Pending</span>)
                                        break;
                                    case 1:
                                        userToApprove = (<span style={{color:'green'}}>Approved</span>)
                                        break;
                                    case -1:
                                        userToApprove = (<span style={{color:'red'}}>Disapproved</span>)
                                        break;
                                    default:
                                        userToApprove = ""
                                }

                                return (<tr key={indx + 1} style={{borderLeft: `${res.level*10}px solid`}}>
                                    <td><Link to={res.public_url?res.public_url:''}>{res.content}</Link></td>
                                    <td><Link to={res.author_url?res.author_url:''}>{res.print_from_name}</Link>: {userFromApprove} <br /><Link to={res.author_to_url?res.author_to_url:''}>{res.print_to_name}</Link>: {userToApprove} </td>
                                    <td>{<RTimeAgo date={formattedDate(res.created)}/>}</td>
                                    <td>{res.modified && <>
                                            <RTimeAgo date={formattedDate(res.modified)}/> 
                                            &nbsp;by&nbsp; 
                                            <Link to={res.modified_author_url?res.modified_author_url:''}>{res.modified_print_name}</Link>
                                        </>}
                                    </td>
                                    <td>
                                        {parseInt(res.live) === 0 && <>
                                            <span className="bg-secondary text-white rounded-sm p-1">{parseInt(res.live_approve) === 1?'Pending':'No'}</span>
                                            {(parseInt(res.live_approve) === 1) && ((res.user_to === userData.id && parseInt(res.user_to_approved) === 0) || res.user_from===userData.id && parseInt(res.user_from_approved) === 0) && <>
                                                <br /><Link to={`/articlerequest/liveapprove?id=${res.id}`}>Approve</Link> | 
                                                <Link to={`/articlerequest/livedisapprove?id=${res.id}`}>Disapprove</Link> 
                                            </>}
                                            {parseInt(res.user_to_approved) === 1 && parseInt(res.user_from_approved) === 1 && res.article_request_user_id === userData.id && <>
                                                <br /><Link to={`/articlerequest/versionlive?id=${res.id}`}>Go Live</Link>
                                            </>}
                                        </>}
                                        {parseInt(res.live) === 1 && <>
                                            <span className="bg-secondary text-white rounded-sm p-1" style={{color:'green'}}>Yes</span>
                                            <Link to={res.article_requests_public_url?res.article_requests_public_url:''}>View</Link>
                                        </>}
                                    </td>
                                    <td>
                                        <Button variant='link' onClick={() => history.push(`${res.article_origional_requests_public_url}?id=${res.article_request_id}&add_version=1&version_id=${res.id}`)}><span className='fa fa-plus'></span>Iteration of this edit</Button>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </Table>
                    {state.total_page > 0 && <ReactPaginate
                        style={{color: 'red'}}
                        className="cmn-Pagination"
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={onPageClick}
                        pageRangeDisplayed={5}
                        pageCount={state.total_pages?parseInt(state.total_pages):0}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        forcePage={page}
                    />}
                </div>
            </div>
        </Container >
    </>)
}
export default RequestVersions;