import React, { useState, useRef } from 'react';
import Icon from '../Icon';
import { Button } from 'react-bootstrap'
import Rating from "react-rating"
import { Label } from '../Typography';
import { Link } from 'react-router-dom'
import {addAutorComment} from '../../action/article.action';
import { RTimeAgo } from '../RTimeAgo'
import { formattedDate } from '../../Utils/AppUtill'

const apiUrl = process.env.REACT_APP_API_URL;

const recursionReviewRating = (comments, level, replyTocomment) => {
    
    const getImageUrl = (path) => {
        if(path.includes(window.location.origin)) {
            return path;   
        }
        return apiUrl + "/thumb?src=" + path + "&w=120&h=120"
    }

    return (<>
        {comments && comments.map((res, idx) => {
            return ( <ul key={idx + 1} className={"commentlist list-unstyled"}>
                <li>
                <div className='bg'>
                    <div className="row">
                        <div className="cm_imglistspector col-md-2 col-sm-3 col-xs-12">
                            <img src={getImageUrl(res.public_image)} alt="" />
                        </div>
                        <div className="col-md-10 col-sm-9 col-xs-12">
                            <div>
                                <Link className='text-green fs-13px text-decoration-none' to={res.author_url?res.author_url:''}>{res.author_print_name}</Link>
                            </div>
                            <div className='py-3'>
                                <a href="#/" onClick={() => replyTocomment(res.id)} className='text-green fs-16px text-decoration-none'><i className="ion-ios-chatboxes-outline"></i></a>
                            </div>
                            <div>
                                <div className="comment-rating">
                                    <span>{res.average}</span>
                                </div>
                                <div>
                                    <div className="star-rating">
                                        <div className="text-green fs-13px">Writing Quality</div>
                                        <Rating
                                            initialRating={res.rating_0} readonly
                                            emptySymbol="fa fa-star fs-13px empty me-1"
                                            fullSymbol="fa fa-star fs-13px full me-1"
                                        />
                                    </div>
                                    <div className="star-rating">
                                        <div className="text-green fs-13px">Content Expertise</div>
                                        <Rating
                                            initialRating={res.rating_1} readonly
                                            emptySymbol="fa fa-star fs-13px empty me-1"
                                            fullSymbol="fa fa-star fs-13px full me-1"
                                        />
                                    </div>
                                    <div className="star-rating">
                                        <div className="text-green fs-13px">Responsiveness</div>
                                        <Rating
                                            initialRating={res.rating_2} readonly
                                            emptySymbol="fa fa-star fs-13px empty me-1"
                                            fullSymbol="fa fa-star fs-13px full me-1"
                                        />
                                    </div>
                                    <div className="star-rating">
                                        <div className="text-green fs-13px">Professionalism</div>
                                        <Rating
                                            initialRating={res.rating_3} readonly
                                            emptySymbol="fa fa-star fs-13px empty me-1"
                                            fullSymbol="fa fa-star fs-13px full me-1"
                                        />
                                    </div>
                                </div>
                                <div className='text-green fs-13px py-3'>{res.comment}</div>
                            </div>
                            <a href="#/" className='text-green fs-13px text-decoration-none'><time><RTimeAgo date={formattedDate(res.created)}/></time></a>
                        </div>
                    </div>
                </div>
                {res.reply_comment && res.reply_comment.length > 0 && recursionReviewRating(res.reply_comment, level+1, replyTocomment)}
            </li>
        </ul>)
        })}
    </>)
}

export const ReviewRatingComponent = ( {data, onCallBack} ) => {

    const myRef = useRef(null)

    const [state, setState] = useState({
        'star-rating-0': 0,
        'star-rating-1': 0,
        'star-rating-2': 0,
        'star-rating-3': 0,
        'comment': ''
    })

    const [parent_id, setParent_id] =  useState(0);
    
    const onChangeRate = (type, rate) => {
        setState({...state, [type]: rate})
    }

    const onChangeComment = (e) => {
        setState({...state, comment: e.target.value})
    }

    const onClickToSubmitReview = async () => {
        const formData = new FormData();
        Object.keys(state).map(res => formData.append(res, state[res]))
        
        const { author_id } = data;
        formData.append('author_id', author_id);
        formData.append('parent_id', parent_id);
        
        const result = await addAutorComment(formData);
        
        if(result.status === 200) {
            setParent_id(0)
            onCallBack()
            setState({
                'star-rating-0': 0,
                'star-rating-1': 0,
                'star-rating-2': 0,
                'star-rating-3': 0,
                'comment': ''
            })
        }
    }
    
    const replyTocomment = (parentId) => {
        setParent_id(parentId)
        myRef.current.scrollIntoView({block: "start",
        behavior: "smooth",})
    }

    return (
        <>
            {recursionReviewRating(data.comments, 0, replyTocomment)}
            
            {/* <ul className="commentlist list-unstyled">
                <li>
                    <div className='bg'>
                        <div className="row">
                            <div className="cm_imglistspector col-md-2 col-sm-3 col-xs-12">
                                <img src="https://images.unsplash.com/photo-1640904758535-067492e54ce9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80" alt="" />
                            </div>
                            <div className="col-md-10 col-sm-9 col-xs-12">
                                <div>
                                    <Link className='text-green fs-13px text-decoration-none' to="/author/vasildb">V Z</Link>
                                </div>
                                <div className='py-3'>
                                    <Link to="/" className='text-green fs-16px text-decoration-none'><i className="ion-ios-chatboxes-outline"></i></Link>
                                </div>
                                <div>
                                    <div className="comment-rating">
                                        <span>2.50</span>
                                    </div>
                                    <div>
                                        <div className="star-rating">
                                            <div className="text-green fs-13px">Writing Quality</div>
                                            <Rating
                                                initialRating={2} readonly
                                                emptySymbol="fa fa-star fs-13px empty me-1"
                                                fullSymbol="fa fa-star fs-13px full me-1"
                                            />
                                        </div>
                                        <div className="star-rating">
                                            <div className="text-green fs-13px">Content Expertise</div>
                                            <Rating
                                                initialRating={2} readonly
                                                emptySymbol="fa fa-star fs-13px empty me-1"
                                                fullSymbol="fa fa-star fs-13px full me-1"
                                            />
                                        </div>
                                        <div className="star-rating">
                                            <div className="text-green fs-13px">Responsiveness</div>
                                            <Rating
                                                initialRating={2} readonly
                                                emptySymbol="fa fa-star fs-13px empty me-1"
                                                fullSymbol="fa fa-star fs-13px full me-1"
                                            />
                                        </div>
                                        <div className="star-rating">
                                            <div className="text-green fs-13px">Professionalism</div>
                                            <Rating
                                                initialRating={2} readonly
                                                emptySymbol="fa fa-star fs-13px empty me-1"
                                                fullSymbol="fa fa-star fs-13px full me-1"
                                            />
                                        </div>
                                    </div>
                                    <div className='text-green fs-13px py-3'>test</div>
                                </div>
                                <Link to="/" className='text-green fs-13px text-decoration-none'><time>6 years ago</time></Link>
                            </div>
                        </div>
                    </div>
                    <ul className='children'>
                        <li>
                            <div className='bg'>
                                <div className="row">
                                    <div className="cm_imglistspector col-md-2 col-sm-3 col-xs-12">
                                        <img src="https://images.unsplash.com/photo-1640904758535-067492e54ce9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80" alt="" />
                                    </div>
                                    <div className="col-md-10 col-sm-9 col-xs-12">
                                        <div>
                                            <Link className='text-green fs-13px text-decoration-none' to="/author/vasildb">V Z</Link>
                                        </div>
                                        <div className='py-3'>
                                            <Link to="/" className='text-green fs-16px text-decoration-none'><i className="ion-ios-chatboxes-outline"></i></Link>
                                        </div>
                                        <div>
                                            <div className="comment-rating">
                                                <span>2.50</span>
                                            </div>
                                            <div>
                                                <div className="star-rating">
                                                    <div className="text-green fs-13px">Writing Quality</div>
                                                    <Rating
                                                        initialRating={2} readonly
                                                        emptySymbol="fa fa-star fs-13px empty me-1"
                                                        fullSymbol="fa fa-star fs-13px full me-1"
                                                    />
                                                </div>
                                                <div className="star-rating">
                                                    <div className="text-green fs-13px">Content Expertise</div>
                                                    <Rating
                                                        initialRating={2} readonly
                                                        emptySymbol="fa fa-star fs-13px empty me-1"
                                                        fullSymbol="fa fa-star fs-13px full me-1"
                                                    />
                                                </div>
                                                <div className="star-rating">
                                                    <div className="text-green fs-13px">Responsiveness</div>
                                                    <Rating
                                                        initialRating={2} readonly
                                                        emptySymbol="fa fa-star fs-13px empty me-1"
                                                        fullSymbol="fa fa-star fs-13px full me-1"
                                                    />
                                                </div>
                                                <div className="star-rating">
                                                    <div className="text-green fs-13px">Professionalism</div>
                                                    <Rating
                                                        initialRating={2} readonly
                                                        emptySymbol="fa fa-star fs-13px empty me-1"
                                                        fullSymbol="fa fa-star fs-13px full me-1"
                                                    />
                                                </div>
                                            </div>
                                            <div className='text-green fs-13px py-3'>test</div>
                                        </div>
                                        <Link to="/" className='text-green fs-13px text-decoration-none'><time>6 years ago</time></Link>
                                    </div>
                                </div>
                            </div>
                            <ul className='children'>
                                <li>
                                    <div className='bg'>
                                        <div className="row">
                                            <div className="cm_imglistspector col-md-2 col-sm-3 col-xs-12">
                                                <img src="https://images.unsplash.com/photo-1640904758535-067492e54ce9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80" alt="" />
                                            </div>
                                            <div className="col-md-10 col-sm-9 col-xs-12">
                                                <div>
                                                    <Link className='text-green fs-13px text-decoration-none' to="/author/vasildb">V Z</Link>
                                                </div>
                                                <div className='py-3'>
                                                    <Link to="/" className='text-green fs-16px text-decoration-none'><i className="ion-ios-chatboxes-outline"></i></Link>
                                                </div>
                                                <div>
                                                    <div className="comment-rating">
                                                        <span>2.50</span>
                                                    </div>
                                                    <div>
                                                        <div className="star-rating">
                                                            <div className="text-green fs-13px">Writing Quality</div>
                                                            <Rating
                                                                initialRating={2} readonly
                                                                emptySymbol="fa fa-star fs-13px empty me-1"
                                                                fullSymbol="fa fa-star fs-13px full me-1"
                                                            />
                                                        </div>
                                                        <div className="star-rating">
                                                            <div className="text-green fs-13px">Content Expertise</div>
                                                            <Rating
                                                                initialRating={2} readonly
                                                                emptySymbol="fa fa-star fs-13px empty me-1"
                                                                fullSymbol="fa fa-star fs-13px full me-1"
                                                            />
                                                        </div>
                                                        <div className="star-rating">
                                                            <div className="text-green fs-13px">Responsiveness</div>
                                                            <Rating
                                                                initialRating={2} readonly
                                                                emptySymbol="fa fa-star fs-13px empty me-1"
                                                                fullSymbol="fa fa-star fs-13px full me-1"
                                                            />
                                                        </div>
                                                        <div className="star-rating">
                                                            <div className="text-green fs-13px">Professionalism</div>
                                                            <Rating
                                                                initialRating={2} readonly
                                                                emptySymbol="fa fa-star fs-13px empty me-1"
                                                                fullSymbol="fa fa-star fs-13px full me-1"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='text-green fs-13px py-3'>test</div>
                                                </div>
                                                <Link to="/" className='text-green fs-13px text-decoration-none'><time>6 years ago</time></Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </li>

            </ul> */}
            {data.can_comment && <>
                <div ref={myRef} className='cm_boxTitle text-green'><Icon name="editicon" className="w-20px me-2" />Write a review</div>
                <table className='mb-4 mt-3'>
                    <tbody>
                        <tr>
                            <td><div className="text-green fs-13px fs-md-15px w-150px">Writing Quality</div></td>
                            <td><Rating
                                emptySymbol="fa fa-star empty me-1"
                                fullSymbol="fa fa-star full me-1"
                                initialRating={state['star-rating-0']}
                                onClick={(e) => onChangeRate('star-rating-0', e)}
                            /></td>
                        </tr>
                        <tr>
                            <td><div className="text-green fs-13px fs-md-15px w-150px">Content Expertise</div></td>
                            <td><Rating
                                emptySymbol="fa fa-star empty me-1"
                                fullSymbol="fa fa-star full me-1"
                                initialRating={state['star-rating-1']}
                                onClick={(e) => onChangeRate('star-rating-1', e)}
                            /></td>
                        </tr>
                        <tr>
                            <td><div className="text-green fs-13px fs-md-15px w-150px">Responsiveness</div></td>
                            <td><Rating
                                emptySymbol="fa fa-star empty me-1"
                                fullSymbol="fa fa-star full me-1"
                                initialRating={state['star-rating-2']}
                                onClick={(e) => onChangeRate('star-rating-2', e)}
                            /></td>
                        </tr>
                        <tr>
                            <td><div className="text-green fs-13px fs-md-15px w-150px">Professionalism</div></td>
                            <td><Rating
                                emptySymbol="fa fa-star empty me-1"
                                fullSymbol="fa fa-star full me-1"
                                initialRating={state['star-rating-3']}
                                onClick={(e) => onChangeRate('star-rating-3', e)}
                            /></td>
                        </tr>
                    </tbody>
                </table>
                <form>
                    <div className='mb-3'>
                        <Label text="Comment" />
                        <textarea rows="5" value={state.comment} onChange={onChangeComment} className='cm_input'></textarea>
                        <Button variant='green' className='mt-3' onClick={onClickToSubmitReview}>Publish Review</Button>
                    </div>
                </form>`
            </>}
        </>
    )
}