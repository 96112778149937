import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useHistory, NavLink } from "react-router-dom";
import { Col, Container, Row, Button, Dropdown, Collapse } from "react-bootstrap";
import SigninModal from "../Layout/SigninModal";
import Category from "../Category/Category";
import { InstantSearch, connectRefinementList } from "react-instantsearch-dom";
import logo from "./../../assets/images/CM_120.png";
// import img1 from './../../assets/images/get-paid-per-page-view.jpg'
import Icon from "../Icon";
import { client } from "../../Utils/AppUtill";
import { GlobalContext } from "../../context/globalContext";
import { getAllNotification, resetNotificationCount } from "../../action/common.action";
import { logout } from "../../action/auth.action";
import { NotificationContext } from "../../context/notificationContext";
import { wamp } from "../../helpers/WampThruway";
import { sendinvite } from "../../action/invite.action";
import queryString from 'query-string';
import { shortAddress } from "../../web3/helpers/utils";
import { TransactionContext } from "../../context/transactionContext";

const algoliaIndex = process.env.REACT_APP_ALGOLIA_INDEX;

const RefinementList = ({ items, callBack }) => {
  if(items.length > 0) {
    items = items.sort((a, b) => (a.label > b.label ? 1 : -1))
    localStorage.categoryList = JSON.stringify(items);
    callBack(items);
  }
  
  return <></>
};

const CustomRefinementList = connectRefinementList(RefinementList);

const Header = () => {
  const history = useHistory();
  const notificationContext = useContext(NotificationContext);
  const { transactionHistory } = useContext(TransactionContext);
  const { isLoggedIn, userData, setIsLoggedIn } =
    React.useContext(GlobalContext);
  // const [Login, setLogin] = useState(false)
  const [search, setSearch] = useState("");
  const [notification, setNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [invitationCounter, setInvitationCounter] = useState(0);
  const [categoryMenuOpen, setCategoryMenuOpen] = useState(false);
  const [contentMenuOpen, setContentMenuOpen] = useState(false);
  const [categoryList, setCategoryList] = useState(localStorage.categoryList?JSON.parse(localStorage.categoryList):[] || []);

  const [notificationType] = useState({
    INFO: 1,
    SUCCESS: 2,
    FAILURE: 3,
  });
  const [headerStyle, setHeaderStyle] = useState({});

  const onClickToLogout = async () => {
    const {data} = await logout();
    if(data.status === 200) {
      window.localStorage.clear();
      setIsLoggedIn(false);
    }
  };

  const onRetriveInviteCount = async () => {
    const { data } = await sendinvite();
    if (data.status === 200) {
      setInvitationCounter(data.data.invitation_counter);
    }
  };

  const onClickToResetNotification = async () => {
    if(notificationCount > 0) {
      const { data } = await resetNotificationCount()
      if(data.status === 200) {
        setNotificationCount(0);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (history.location.pathname === "/article/submit")
      setHeaderStyle({ position: "absolute" });
    else setHeaderStyle({});
    if (!matches) {
      setOpen(false);
      setContentMenuOpen(false);
      setCategoryMenuOpen(false);
    }
  }, [history.location])// eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        let not = wamp.topic("notification_" + userData.id).subscribe((v) => {
          setNotification((prevState) => {
            return [v.args[0], ...prevState];
          });
          setNotificationCount((prevState) => {
            return parseInt(prevState === null?0:prevState, 10) + 1;
          });
        });
  
        notificationContext.setNotification(not);
        const { data } = await getAllNotification();
        if (data.status === 200) {
          setNotification(data.data.notification);
          setNotificationCount(data.data.notification_count);
        }
  
        await onRetriveInviteCount();
      } else {
        if (notificationContext.notification)
          notificationContext.notification.unsubscribe();
      }
    })()
  }, [isLoggedIn])// eslint-disable-line react-hooks/exhaustive-deps

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 992px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 992px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const onRenderNotification = () => {
    const output = [];
      notification.map((res, indx) => {
        let color = "";
        switch (parseInt(res.type_id)) {
          case notificationType.FAILURE:
            color = "#ff4444";
            break;
          case notificationType.SUCCESS:
            color = "#adffaf";
            break;
          case notificationType.INFO:
            color = "#47bcf0";
            break;
          default:
            color = "#47bcf0";
        }
      output.push(
          <Dropdown.Item
            key={indx + 1}
            style={{ borderLeft: `3px solid ${color}` }}
            onClick={() => history.push(res.url)}
            className={res.read}
          >
            {/* <img src={img1} alt='' /> */}
            {res.text}
          </Dropdown.Item>
        );
        return res;
      })
      output.push(<Dropdown.Item
        key={0}
        onClick={() => history.push("/notification")}
        style={{ borderLeft: "white", backgroundColor: "white" }}
      >
        {" "}
        View All Notifications
    </Dropdown.Item>)
    return output;
  }

  const onRenderTransactionHistory = (device) => {
    if(transactionHistory.length > 0) {
      return (<Dropdown className="cm_nitificationDropdown cm_USDT">
        <Dropdown.Toggle variant="link" className="ps-0">
          <span className={device === 'mobile'?"":"text-white"}>
            USDC {userData.usdc_funds?parseFloat(userData.usdc_funds).toFixed(2):0}
            <span className="transationStatusDot"></span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {transactionHistory.map((r, ind) => (<Dropdown.Item key={ind + 1}>{r.type ==='deposit'?`Depositing ${r.amount} USDC to ${shortAddress(r.address)}`:`Withdrawing from ${shortAddress(r.address)}`}</Dropdown.Item>))}
        </Dropdown.Menu>
      </Dropdown>)
    } else {
      return (<Link to="/billing/transactions" className={`text_drkgreen text-decoration-none ${device === 'mobile'?"":"text-white"}`}>USDC {userData.usdc_funds?parseFloat(userData.usdc_funds).toFixed(2):0}</Link>)
    }
  }

  const onSearchQuery = (e) => {
    e.preventDefault();
    
    let obj = queryString.parse(history.location.search)
    
    if(obj.p) {
        let parseQuery = []
        if(obj.q) {
            parseQuery = JSON.parse(decodeURIComponent(history.location.search.split("?")[1].split("=")[1].slice(0, -2)));
        } else {
            parseQuery = JSON.parse(decodeURIComponent(history.location.search.split("?")[1].split("=")[1]));
        }
        
        obj.p = parseQuery;
    } else {
        obj.p = [];
    }
    
    let qstring = "";
    if(obj.p && obj.p.length > 0) {
        obj.p = encodeURIComponent(JSON.stringify(obj.p))
        qstring = `?p=${obj.p}`;
    }

    if(search) {
      if(obj.q) {
          if(obj.p && obj.p.length > 0) {
              qstring = `?p=${obj.p}&q=${search}`;
          } else {
              qstring = `?q=${search}`;
          }
      } else {
        if(obj.p && obj.p.length > 0) {
          qstring = `?p=${obj.p}&q=${search}`;
        } else {
            qstring = `?q=${search}`;
        }
      }
    }
    history.push(`/search${qstring}`)
  }

  const onCallBackCategory = useCallback((items) => {
    if(Array.isArray(items)) {
      setCategoryList(items);
    }
  },[])

  const onRenderCategoryList = () => {
    return (<>
      {categoryList.map((item) => (
        <Col md={3} key={item.label}>
          <Dropdown.Item
            className="category-Item"
            onClick={() => history.push(`/search?p=${encodeURIComponent(JSON.stringify([{'name': 'category_level_1', 'value': item.label}]))}`)}
          >
            {item.label} ({item.count})
          </Dropdown.Item>
        </Col>
      ))}
      <InstantSearch
        searchClient={client}
        indexName={algoliaIndex + "_cm_index_created_desc"}
      >
        <CustomRefinementList attribute="category_level_1" callBack={onCallBackCategory}/>
      </InstantSearch>
    </>)
  }

  const [open, setOpen] = useState(false);

  return (
    <>
      {/* <!-- Header Component --> */}
      <div className="nav_wrap">
        {/* nav desktop */}
        {matches && (
          <header className="fixed-top" style={headerStyle}>
            <div className="CM_topHeader">
              <Container>
                <Row>
                  <Col md={4}>
                    <Link to="/">
                      <img
                        src={logo}
                        alt="Content Mutual"
                        style={{ top: "9px", position: "relative" }}
                      />
                    </Link>
                  </Col>
                  <Col md={8}>
                    <div className="d-none d-lg-flex align-items-center justify-content-end flex-wrap">
                      <form
                        className="CM_searchHDR"
                        onSubmit={onSearchQuery}
                      >
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button
                          variant="link"
                          as={Link}
                          onClick={onSearchQuery}
                        >
                          <Icon name="search" />
                        </Button>
                      </form>
                      {isLoggedIn ? (
                        <>
                          <Dropdown className="cm_nitificationDropdown" onClick={onClickToResetNotification}>
                            <Dropdown.Toggle variant="link" className="ps-0">
                              <Icon name="bell" />
                              <span className="text-white fs-13px">
                                {notificationCount}
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {onRenderNotification()}
                              {/* <Dropdown.Item href="#/action-1" className="unread"><img src={img1} alt='' />Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Dropdown.Item>
                                      <Dropdown.Item href="#/action-1" className="unread"><img src={img1} alt='' />Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Dropdown.Item>
                                      <Dropdown.Item href="#/action-1" className="unread"><img src={img1} alt='' />Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Dropdown.Item>
                                      <Dropdown.Item href="#/action-1"><img src={img1} alt='' />Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Dropdown.Item>
                                      <Dropdown.Item href="#/action-1"><img src={img1} alt='' />Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Dropdown.Item>
                                      <Dropdown.Item href="#/action-1" className="text-center viewAll">View All Notifications</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                          <span
                            className="navlink headerUSDT"
                          >
                            {/* userData */}
                            <Link className="text-white" to="/billing/transactions">${userData.funds?parseFloat(userData.funds).toFixed(2):0}</Link> | {onRenderTransactionHistory()}
                          </span>
                          <Dropdown className="cm_userDropdown">
                            <Dropdown.Toggle
                              variant="link"
                              className="text-white fs-13px"
                            >
                              <img
                                src={userData.image}
                                className="cm_userimg me-2"
                                alt=""
                              />
                              <span>
                                {userData.first_name} {userData.last_name}
                              </span>
                              <Icon name="downarrow" className="w-15px ms-1" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                              <ul className="list-unstyled">
                                <li>
                                  <Dropdown.Item as={Link} to="/user/myaccount">
                                    My Account
                                  </Dropdown.Item>
                                  <ul className="sub-menu list-unstyled">
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/user/preferences"
                                      >
                                        My Preferences
                                      </Dropdown.Item>
                                    </li>
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/user/bookmarks"
                                      >
                                        My Bookmarks
                                      </Dropdown.Item>
                                    </li>
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/user/myaccount"
                                      >
                                        Edit Account
                                      </Dropdown.Item>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Dropdown.Item as={Link} to="/user/articles">
                                    My Articles
                                  </Dropdown.Item>
                                </li>
                                <li>
                                  <Dropdown.Item as={Link} to="/analytics">
                                    Analytics
                                  </Dropdown.Item>
                                </li>
                                <li>
                                  <Dropdown.Item as={Link} to="/user/requests">
                                    Requests
                                  </Dropdown.Item>
                                  <ul className="sub-menu list-unstyled">
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/user/requests"
                                      >
                                        Incoming Requests
                                      </Dropdown.Item>
                                    </li>
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/user/activesubscriptions"
                                      >
                                        Active Subscriptions
                                      </Dropdown.Item>
                                    </li>
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/user/myrequests"
                                      >
                                        My Requests
                                      </Dropdown.Item>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Dropdown.Item as={Link} to="/billing">
                                    Billing
                                  </Dropdown.Item>
                                  <ul className="sub-menu list-unstyled">
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/billing"
                                      >
                                        Invoices
                                      </Dropdown.Item>
                                    </li>
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/billing/transactions"
                                      >
                                        Transactions
                                      </Dropdown.Item>
                                    </li>
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/billing/withdraw"
                                      >
                                        Withdraw
                                      </Dropdown.Item>
                                    </li>
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/billing/deposit"
                                      >
                                        Deposit
                                      </Dropdown.Item>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Dropdown.Item
                                    as={Link}
                                    to="/invite/sendinvite"
                                    className="d-flex align-items-center justify-content-between"
                                  >
                                    <span>Invite</span>{" "}
                                    <span className="badge">
                                      {invitationCounter}
                                    </span>
                                  </Dropdown.Item>
                                  <ul className="sub-menu list-unstyled">
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/invite/sendinvite"
                                      >
                                        Invite
                                      </Dropdown.Item>
                                    </li>
                                    <li>
                                      <Dropdown.Item
                                        as={Link}
                                        to="/invite/listing"
                                      >
                                        Invitation List
                                      </Dropdown.Item>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Dropdown.Item onClick={onClickToLogout}>
                                    Sign Out
                                  </Dropdown.Item>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      ) : (
                        <>
                          <NavLink className="navlink" to="/">
                            How It Works
                          </NavLink>
                          <SigninModal />
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="CM_menuHeader">
              <Container>
                <div className="d-flex align-items-center flex-wrap">
                  <Dropdown>
                    <Dropdown.Toggle variant="link" className="px-0 me-2">
                      <i className="fa fa-list me-2" aria-hidden="true"></i>
                      Categories
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="CM_megamenu">
                      <Row>
                        {onRenderCategoryList()}
                      </Row>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle variant="link">
                      <i className="fa fa-list me-2" aria-hidden="true"></i>Get
                      Content
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="CM_megamenu2">
                      <Category />
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button variant="link" as={Link} to="/article/submit">
                    <i
                      className="fa fa-pencil-square-o me-2"
                      aria-hidden="true"
                    ></i>
                    Submit Article
                  </Button>
                </div>
              </Container>
            </div>
          </header>
        )}

        {/* nav mobile */}
        {!matches && isLoggedIn && (
          <header className="fixed-top" style={headerStyle}>
            <div className="CM_topHeader">
              <Container>
                <Row>
                  <Col md={4}>
                    <Link to="/">
                      <img
                        src={logo}
                        alt="Content Mutual"
                        style={{ top: "9px", position: "relative" }}
                      />
                    </Link>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="CM_menuHeader">
              <Container>
                <div className="hdr_top_row d-flex justify-content-between">
                  <div className="mobile_toggle d-flex align-items-center">
                    <Button onClick={() => setOpen(!open)} aria-expanded={open}>
                      <svg
                        height="24px"
                        width="24px"
                        version="1.1"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#0a405a"
                          d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
                        />
                      </svg>
                      <span className="text_drkgreen fs-15px ps-2">
                        {" "}
                        Primary
                      </span>
                    </Button>
                  </div>
                  <form
                    className="CM_searchHDR"
                    onSubmit={(e) => {
                      e.preventDefault();
                      history.push(
                        `/search${search ? `?search=${search}` : ""}`
                      );
                    }}
                  >
                    {/* <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    /> */}
                    <Button
                      variant="link"
                      as={Link}
                      to={`/search${search ? `?search=${search}` : ""}`}
                    >
                      <Icon name="search" />
                    </Button>
                  </form>
                </div>
              </Container>
              <Container fluid className="px-1">
                <Collapse in={open}>
                  <div className="mobile_nav_wrapper">
                    <div className="d-lg-flex align-items-center justify-content-end flex-wrap CM_topHeaderRight">
                      {isLoggedIn ? (
                        <>
                          <Dropdown className="cm_nitificationDropdown">
                            <Dropdown.Toggle variant="link">
                              <Icon name="bell" />
                              <span className="text_drkgreen fs-16px ps-2">
                                {notificationCount}
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            {onRenderNotification()}
                              {/* <Dropdown.Item href="#/action-1" className="unread"><img src={img1} alt='' />Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Dropdown.Item>
                                  <Dropdown.Item href="#/action-1" className="unread"><img src={img1} alt='' />Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Dropdown.Item>
                                  <Dropdown.Item href="#/action-1" className="unread"><img src={img1} alt='' />Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Dropdown.Item>
                                  <Dropdown.Item href="#/action-1"><img src={img1} alt='' />Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Dropdown.Item>
                                  <Dropdown.Item href="#/action-1"><img src={img1} alt='' />Joshua  Tobkin has submitted a new version of the article "11 Awesome Apps for Active Entrepreneurs"</Dropdown.Item>
                                  <Dropdown.Item href="#/action-1" className="text-center viewAll">View All Notifications</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                          <span
                            className="text_drkgreen text-decoration-none d-block mb-3 headerUSDT"
                          >
                            {/* userData */}
                            <Link className="text_drkgreen text-decoration-none" to="/billing/transactions">${userData.funds?parseFloat(userData.funds).toFixed(2):0}</Link> | {onRenderTransactionHistory('mobile')}
                          </span>
                          <NavLink className="lh-1 text-decoration-none" to="">
                            <img
                              src={userData.image}
                              className="cm_userimg me-2 "
                              alt=""
                            />
                            <span className="text_name">
                              {userData.first_name} {userData.last_name}
                            </span>
                          </NavLink>

                          <ul className="list-unstyled mobile_menu_list mt-4 pt-0 pb-0">
                            <li>
                              <Link to="/user/myaccount">My Account</Link>
                              <ul className="sub-menu list-unstyled">
                                <li>
                                  <Link to="/user/preferences">
                                    My Preferences
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/user/bookmarks">My Bookmarks</Link>
                                </li>
                                <li>
                                  <Link to="/user/myaccount">Edit Account</Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="/user/articles">My Articles</Link>
                            </li>
                            <li>
                              <Link to="/analytics">Analytics</Link>
                            </li>
                            <li>
                              <Link to="/user/requests">Requests</Link>
                              <ul className="sub-menu list-unstyled">
                                <li>
                                  <Link to="/user/requests">
                                    Incoming Requests
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/user/activesubscriptions">
                                    Active Subscriptions
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/user/myrequests">My Requests</Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="/billing">Billing</Link>
                              <ul className="sub-menu list-unstyled">
                                <li>
                                  <Link to="/billing/invoice">Invoices</Link>
                                </li>
                                <li>
                                  <Link to="/billing/transactions">
                                    Transactions
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/billing/withdraw">Withdraw</Link>
                                </li>
                                <li>
                                  <Link to="/billing/deposit">Deposit</Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="/invite/sendinvite">
                                <span>Invite</span>{" "}
                                <span className="badge">
                                  {invitationCounter}
                                </span>
                              </Link>
                              {/* <Dropdown.Item
                                as={Link}
                                to="/invite/sendinvite"
                                className="d-flex align-items-center justify-content-between"
                              >
                                <span>Invite</span>{" "}
                                <span className="badge">
                                  {invitationCounter}
                                </span>
                              </Dropdown.Item> */}
                              <ul className="sub-menu list-unstyled">
                                <li>
                                  <Link to="/invite/sendinvite">Invite</Link>
                                </li>
                                <li>
                                  <Link to="/invite/listing">
                                    Invitation List
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#/" onClick={onClickToLogout}>
                                Sign Out
                              </a>
                            </li>
                          </ul>
                        </>
                      ) : (
                        <>
                          <NavLink className="navlink" to="/">
                            How It Works
                          </NavLink>
                          <SigninModal />
                        </>
                      )}
                    </div>
                    <div className="d-flex flex-column mobile_nav_bottom">
                      <Dropdown>
                        <Dropdown.Toggle variant="link">
                          <i className="fa fa-list me-2" aria-hidden="true"></i>
                          Categories
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="CM_megamenu">
                          <Row>
                            {onRenderCategoryList()}
                          </Row>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle variant="link">
                          <i className="fa fa-list me-2" aria-hidden="true"></i>
                          Get Content
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="CM_megamenu2">
                          <Category />
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="">
                        <Button variant="link" as={Link} to="/article/submit">
                          <i
                            className="fa fa-pencil-square-o me-2"
                            aria-hidden="true"
                          ></i>
                          Submit Article
                        </Button>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </Container>
            </div>
          </header>
        )}
        {!matches && !isLoggedIn && (
          <header className="fixed-top header_wo_loggedin" style={headerStyle}>
            <div className="CM_topHeader">
              <Container>
                <Row>
                  <Col md={4}>
                    <Link to="/">
                      <img
                        src={logo}
                        alt="Content Mutual"
                        style={{ top: "9px", position: "relative" }}
                      />
                    </Link>
                  </Col>
                  <Col md={8}>
                    <div className="d-none d-lg-flex align-items-center justify-content-end flex-wrap">
                      <form
                        className="CM_searchHDR"
                        onSubmit={(e) => {
                          e.preventDefault();
                          history.push(
                            `/search${search ? `?search=${search}` : ""}`
                          );
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button
                          variant="link"
                          as={Link}
                          to={`/search${search ? `?search=${search}` : ""}`}
                        >
                          <Icon name="search" />
                        </Button>
                      </form>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="CM_menuHeader">
              <div className="hdr_top_row d-flex justify-content-between">
                <div className="mobile_toggle d-flex align-items-center px-4">
                  <Button onClick={() => setOpen(!open)} aria-expanded={open}>
                    <svg
                      height="24px"
                      width="24px"
                      version="1.1"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#0a405a"
                        d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
                      />
                    </svg>
                    <span className="text_drkgreen fs-15px ps-2"> Primary</span>
                  </Button>
                </div>
                <form
                  className="CM_searchHDR"
                  onSubmit={(e) => {
                    e.preventDefault();
                    history.push(`/search${search ? `?search=${search}` : ""}`);
                  }}
                >
                  {/* <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    /> */}
                  <Button
                    variant="link"
                    as={Link}
                    to={`/search${search ? `?search=${search}` : ""}`}
                  >
                    <Icon name="search" />
                  </Button>
                </form>
              </div>
              <Collapse in={open} className="collapse_wo_loggedin">
                <Container fluid>
                  <div className="hdrwo_login_top">
                    <div className="">
                      <Link className="navlink" to="/">
                        How It Works
                      </Link>
                    </div>
                    <div className="mt-3">
                      <Link className="navlink" to="/user/login">
                        Sign In
                      </Link>
                    </div>
                  </div>
                  <div className="mt-2">
                    <Button
                      variant="link"
                      onClick={() => {
                        setContentMenuOpen(false);
                        setCategoryMenuOpen(true);
                      }}
                    >
                      <i className="fa fa-list me-2" aria-hidden="true"></i>
                      Categories
                    </Button>
                  </div>
                  <div className="mt-2">
                    <Button
                      variant="link"
                      onClick={() => {
                        setCategoryMenuOpen(false);
                        setContentMenuOpen(true);
                      }}
                    >
                      <i className="fa fa-list me-2" aria-hidden="true"></i>
                      Content
                    </Button>
                  </div>
                  <div className="mt-2 mb-3">
                    <Button variant="link" as={Link} to="/article/submit">
                      <i
                        className="fa fa-pencil-square-o me-2"
                        aria-hidden="true"
                      ></i>
                      Submit Article
                    </Button>
                  </div>
                  {categoryMenuOpen && (
                    <Row className="CM_megamenu_wo_login">
                      {onRenderCategoryList()}
                    </Row>
                  )}
                  {contentMenuOpen && <Category />}
                </Container>
              </Collapse>
            </div>
          </header>
        )}
      </div>
    </>
  );
};

export default Header;
