import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { InstantSearch, connectRefinementList } from 'react-instantsearch-dom';
import { client } from '../../Utils/AppUtill'
import queryString from 'query-string';

const algoliaIndex = process.env.REACT_APP_ALGOLIA_INDEX;

const Category = () => {
    const [ShowType, setShowtype] = useState(false)
    const [Type, setType] = useState("")
    const [ShowProfession, setShowProfession] = useState(false)
    const [Profession, setProfession] = useState('')
    const [ShowKeyword, setShowKeyword] = useState(false)
    const [Keyword, setKeyword] = useState('')
    const [FinalKeyword, setFinalKeyword] = useState('')
    const history = useHistory()

    const handleSearch = () => {
        document.body.click()
        // queryString
        let obj = queryString.parse(history.location.search)
        if(obj.p) {
            let parseQuery = JSON.parse(decodeURIComponent(history.location.search.split("?")[1].split("=")[1]));
            parseQuery = parseQuery.filter(r => r.name !== 'page');
            let findQuery = parseQuery.findIndex(r => r.name === 'author_profession');
            if(findQuery !== -1) {
                parseQuery[findQuery].value =  Profession;
            } else {
                parseQuery.push({'name': 'author_profession', 'value': Profession});
            }
            obj.p = encodeURIComponent(JSON.stringify(parseQuery));
        } else {
            obj.p = encodeURIComponent(JSON.stringify([{'name': 'author_profession', 'value': Profession}]));
        }

        if(FinalKeyword) {
            obj.q = FinalKeyword;
        }
        
        history.push(`/search?p=${[obj.p]}${obj.q?`&q=${obj.q}`:''}`)
    }
    
    const RefinementList = ({ items }) => (
        <>
        {/* <li key={i} className={Profession === item ? "cm_checked" : ""} onClick={() => {
                setProfession(item)
                setShowProfession(false)
            }}>{item}</li> */}
          {items.sort((a, b) => a.label > b.label ? 1 : -1).map((item, i) => (
            <li key={i} onClick={() => {
                setProfession(item.label)
                setShowProfession(false)
            }}>{item.label}</li>
          ))}            
        </>
    );
    
    const CustomRefinementList = connectRefinementList(RefinementList);

    return (<>
        <Container className="text-center">
            <form className="cm_form">
                I'm lookin for <div className="position-relative d-inline-block"><Button type="button" variant="link" onClick={() => setShowtype(true)}>{Type === "" ? "select type" : Type === "article" ? "an article" : Type === "image" ? "an image" : ''}</Button>
                    <ul className={`w-325px list-unstyled mb-0 cm_greenBox ${ShowType ? "show" : ""}`}><li className={Type === "" ? "cm_checked" : ""} onClick={() => {
                        setType('')
                        setShowtype(false)
                    }}>select type</li><li className={Type === "article" ? "cm_checked" : ""} onClick={() => {
                        setType("article")
                        setShowtype(false)
                    }}>an article</li><li className={Type === "image" ? "cm_checked" : ""} onClick={() => {
                        setType("image")
                        setShowtype(false)
                    }}>an image</li></ul></div>
                {Type !== "image" && <span className="WrittenBy">
                    <br />written by <div className="position-relative d-inline-block"><Button type="button" variant="link" onClick={() => setShowProfession(true)}>{Profession === "" ? "Select a Profession" : Profession} </Button>
                        <ul className={`ProfessionUl list-unstyled mb-0 cm_greenBox ${ShowProfession ? "show" : ""}`}>
                            <InstantSearch searchClient={client} indexName={algoliaIndex + "_cm_index_created_desc"}>
                                <CustomRefinementList attribute="author_profession"/>
                            </InstantSearch>
                            {/* {professionList.map((item, i) => (<li key={i} className={Profession === item ? "cm_checked" : ""} onClick={() => {
                                setProfession(item)
                                setShowProfession(false)
                            }}>{item}</li>))} */}
                        </ul>
                    </div>
                </span>} about <div className="position-relative d-inline-block"><Button type="button" variant="link" onClick={() => setShowKeyword(true)}>{FinalKeyword === '' ? "keyword" : FinalKeyword}</Button>
                    <ul className={`w-325px list-unstyled mb-0 cm_greenBox ${ShowKeyword ? "show" : ""}`}>
                        <li className="d-flex align-items-center cm_keywordform"><input placeholder="keyword" onChange={(e) => setKeyword(e.target.value)} /><button className="btn btn-green px-4" type="button" onClick={() => {
                            setFinalKeyword(Keyword)
                            setShowKeyword(false)
                        }}><i className="fs-30px ion-android-arrow-forward" /></button></li>
                        <li className="cm-form-text">For example: <em className="text-white">Health</em></li>
                    </ul>
                </div>
                <div className="my-4">
                    <button className="btn btn-green arrowBtn" type="button" onClick={handleSearch}>Find me content</button>
                </div>
                <div className={`cm-form-overlay ${ShowType || ShowProfession || ShowKeyword ? "active" : ""}`} onClick={() => {
                    setShowtype(false)
                    setShowProfession(false)
                    setShowKeyword(false)
                }}></div>
            </form>
        </Container>
    </>)
}
export default Category