import React, { useState, useEffect } from 'react'
import { Button, Container, Table , Modal} from 'react-bootstrap'
import { PageTitle } from '../components/Typography'
import { cancelInvite, inviteListing, resendInvite } from '../action/invite.action'
import { useHistory } from 'react-router-dom'
import { RTimeAgo } from '../components/RTimeAgo'
import { formattedDate } from '../Utils/AppUtill'
import ReactPaginate from 'react-paginate'
import queryString from 'query-string';
import toast from "../helpers/toast"

const MyInvitation = () => {

    const history = useHistory();
    const [state, setState] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [cancelId, setCancelId] = useState();
    const [page, setPage] = useState(0);
    const onClickToCloseModal = () => setShowDeleteModal(false);
    const onClickToOpenModal = () => setShowDeleteModal(true);

    const onRetriveMyInvitation = async () => {
        const { data } = await inviteListing(history.location.search);
        if(data.status === 200) {
            setState(data.data);
        }
    }

    useEffect(() => {
        let {page} = queryString.parse(history.location.search)
        if(page) {
            setPage(page - 1);
        } else {
            setPage(0);
        }
        onRetriveMyInvitation()
    }, [history.location])// eslint-disable-line react-hooks/exhaustive-deps

    const onPageClick = (status='page', e) => {
        const {pathname, search} = history.location;
        let query = queryString.parse(search)
        if(status!=='page') {
            query = { ...query, ...status}
        } else {
            query.page = e.selected + 1;
        }
        query = queryString.stringify(query);
        history.push(`${pathname}?${query}`)
    }

    const onClickToCancel = async () => {
        const {data} = await cancelInvite(cancelId);
        if(data.status === 200) {
            onRetriveMyInvitation();
            onClickToCloseModal(); 
        }
    }

    const onClickToResend = async (id) => {
        const {data} = await resendInvite(id);
        if(data.status === 200) {
            toast.success(data.data.message);
        }
    }
    
    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="My Invitation" />
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <div className='pb-4 fs-13px text-green'>{`You have ${state.invitation_counter || 0} invitation(s) left.`}</div>
                <Table bordered hover responsive className='cm_table'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.invite && state.invite.map((res, indx) => {
                            return (<tr key={indx + 1}>
                                        <td>{res.id}</td>
                                        <td>{res.fname}</td>
                                        <td>{res.lname}</td>
                                        <td>{res.email}</td>
                                        <td>{res.isStatus}</td>
                                        <td><RTimeAgo date={formattedDate(res.created)}/></td>
                                        <td>
                                            {res.status === '0' && <>
                                                <Button onClick={() => {
                                                    onClickToOpenModal();
                                                    setCancelId(res.id);
                                                }} variant='link'><span className='ion-close-round'></span>Cancel</Button>
                                                {res.isUpdated && <Button variant='link' onClick={() => {
                                                    onClickToResend(res.id)
                                                }} ><i className="fa fa-file"></i>Resend</Button>}
                                            </>}
                                        </td>
                                    </tr>)
                        })}
                    </tbody>
                </Table>
            </div>
            {state.total_pages > 0 && <ReactPaginate
                style={{color: 'red'}}
                className="cmn-Pagination mt-4"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={(e) => onPageClick('page', e)}
                pageRangeDisplayed={5}
                pageCount={state.total_pages}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                forcePage={page}
            />}
        </Container>
        <Modal 
            show={showDeleteModal} 
            onHide={onClickToCloseModal}
        >
            <Modal.Header>
            <Modal.Title>Cancel Invitation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={onClickToCloseModal}>
                Close
            </Button>
            <Button variant="primary" onClick={onClickToCancel}>
                Okay
            </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default MyInvitation