import React, { useEffect, useState } from 'react'
import { Col, Container, Button, Row } from 'react-bootstrap'
import toast from "../helpers/toast"
import { sendinvite, addinvite } from '../action/invite.action'

const SendInvite = () => {

    const [invitationCounter, setInvitationCounter] = useState(0);

    const [state, setState] = useState([])

    const retrivesedInviteCounter = async () => {
        const {data} = await sendinvite();
        if(data.status === 200) {
            setInvitationCounter(data.data.invitation_counter || 0)
        }
    }
    
    useEffect(() => {
        retrivesedInviteCounter();
    }, [])

    const onChangeInput = (e, i) => {
        const data = state;
        if(data.length <= 0 || Object.keys(data[i]).length <= 0) {
            data[i] = {};
        }
        
        data[i][e.target.name] = e.target.value;

        setState([...data]);
    }

    const onClickToSendInvite = async (i) => {
        if(state.length === 0) {
            toast.error("Firstname is required")
            return false;
        } else {
            if((state[i]===undefined || state[i]) && Object.keys(state[i]).length === 0) {
                toast.error("Firstname is required")
                return false;
            } else {
                if(state[i].fname === undefined) {
                    toast.error("Firstname is required")
                    return false;
                } else if(state[i].lname === undefined) {
                    toast.error("Lastname is required")
                    return false;
                } else if(state[i].email === undefined) {
                    toast.error("Email is required")
                    return false;
                }
            }
        }

        const formData = new FormData();
        Object.keys(state[i]).map(res => formData.append(res, state[i][res]))

        const {data} = await addinvite(formData);
        if(data.status === 200) {
            toast.success(data.data.message)
            setState([]);
            let div_list = document.querySelectorAll('.cm_input');
            let div_array = [...div_list];
            div_array.forEach(input => {
                input.value = ""
            });
            retrivesedInviteCounter();
        } 
    }
    
    const invitationRow = () => {
        let output = [];
        for(let i = 0; i<invitationCounter; i++) {
            output.push(<Row className='pb-3' key={i + 1}>
                <Col lg={1} className='mb-3'>
                    <div className='fw-700 fs-13px pt-2'>{i + 1}:</div>
                </Col>
                <Col lg={3} className='mb-3'>
                    <input type="text" className="clearVal cm_input" name={`fname`} onChange={(e) => onChangeInput(e, i)} placeholder='First Name' />
                </Col>
                <Col lg={3} className='mb-3'>
                    <input type="text" className="clearVal cm_input" name={`lname`} onChange={(e) => onChangeInput(e, i)} placeholder='Last Name' />
                </Col>
                <Col lg={3} className='mb-3'>
                    <input type="email" className="clearVal cm_input" name={`email`} onChange={(e) => onChangeInput(e, i)} placeholder='Email' />
                </Col>
                <Col lg={2} className='mb-3'>
                    <Button variant='green' className="clearVal py-10px" onClick={() => onClickToSendInvite(i)}>Send Invite</Button>
                </Col>
            </Row>)
        }
        // setState(state);
        return output;
    }
    
    return (<>
        <div className='text-green text-center fs-md-26px fs-20px pt-5'>Send Invite</div>
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <div className='pb-4 fs-13px text-green'>{`You have ${invitationCounter} invitation(s) left .`}</div>
                <form>
                    {invitationRow()}
                </form>
            </div>
        </Container>
    </>)
}

export default SendInvite