import axios from '../helpers/axios';
import { APIEndpoints } from './endpoint'
import queryString from 'query-string';
import { isArray } from 'lodash';

export function getOneArticle({ author, type, alias }, queryString) {
    return axios.post(`${APIEndpoints.getOneArticle}/${author}/${type}/${alias}${queryString}`, {});
}

export function getRecentArticle(data) {
    return axios.post(`${APIEndpoints.getRecentArticle}`, data);
}

export function getArticleFeed(data) {
    return axios.post(`${APIEndpoints.getArticleFeed}`, data);
}

export function getAuthors({ url }) {
    return axios.post(`${APIEndpoints.getAutors}${url}`);
}

export function followAuthor(id, currentUserId) {
    return axios.post(`${APIEndpoints.followAuthor}`, { id, currentUserId });
}

export function addBookMark(data) {
    return axios.post(`${APIEndpoints.addBookMark}`, data);
}

export function getBookmark(id, type) {
    return axios.get(`${APIEndpoints.getBookmark}?id=${id}&type=${type}`);
}

export function deleteBookMark(id) {
    return axios.get(`${APIEndpoints.deleteBookMark}?id=${id}`);
}

export function addAutorComment(data) {
    return axios.post(`${APIEndpoints.addAutorComment}`, data);
}

export function getCategoryList(parent_id = 0) {
    return axios.post(`${APIEndpoints.getCategoryList}`, { parent_id });
}

export function getAllAttributes(data) {
    return axios.get(`${APIEndpoints.getAllAttributes}` + (data ? '?' + queryString.stringify(data) : ''));
}

export function getAllLanguage() {
    return new Promise(resolve => {
        const lang = window.localStorage.languages;
        if (!lang || !isArray(JSON.parse(lang))) {
            return axios.post(`${APIEndpoints.getAllLanguage}`).then(res => {
                window.localStorage.setItem('languages', JSON.stringify(res.data.data));
                resolve(res.data.data);
            })
        } else {
            resolve(JSON.parse(lang));
        }
    })
}

export function generateVerificationId(obj) {
    const query = queryString.stringify(obj);
    return axios.post(`${APIEndpoints.generateVerificationId}${query ? `?${query}` : ''}`)
}

export function articleCheckSubmittedContent(data) {
    return axios.post(`${APIEndpoints.articleCheckSubmittedContent}`, data)
}

export function uniquenesscheck(data) {
    return axios.post(`${APIEndpoints.uniquenesscheck}`, data)
}

export function finalSubmitArticle(query, data) {
    return axios.post(`${APIEndpoints.finalSubmitArticle}${query}`, data)
}

export function getAllDomains() {
    return axios.get(`${APIEndpoints.getAllDomains}`)
}

export function requestpermission(data) {
    return axios.post(`${APIEndpoints.requestpermission}`, data);
}

export function getSingleArticle(query) {
    return axios.get(`${APIEndpoints.getSingleArticle}${query}`);
}

export function updateArticlePrice(query, data) {
    return axios.post(`${APIEndpoints.updateArticlePrice}${query}`, data);
}

export function editArticleCheckCopyRightUrl(data) {
    return axios.post(`${APIEndpoints.editArticleCheckCopyRightUrl}`, data);
}

export function check_unique(query) {
    return axios.post(`${APIEndpoints.check_unique}${query}`, {});
}

export function updateArticle(query, data) {
    return axios.post(`${APIEndpoints.updateArticle}${query}`, data);
}

export function getAllMyArticles(query) {
    return axios.post(`${APIEndpoints.getAllMyArticles}${query}`);
}

export function deleteArticle(id) {
    return axios.post(`${APIEndpoints.deleteArticle}?id=${id}`)
}

export function requestversions(query) {
    return axios.post(`${APIEndpoints.requestversions}${query}`);
}

export function articleRequestCustomize(query = {}, data = {}) {
    return axios.post(`${APIEndpoints.articleRequestCustomize}${query}`, data);
}

export function articleRevision(query) {
    return axios.post(`${APIEndpoints.articleRevision}${query}`);
}

export function articlerevisionrestore(id) {
    return axios.post(`${APIEndpoints.articlerevisionrestore}?id=${id}`);
}

export function articlerevisiondelete(id) {
    return axios.post(`${APIEndpoints.articlerevisiondelete}?id=${id}`);
}

export function articleRevisionCompare(data) {
    return axios.post(`${APIEndpoints.articleRevisionCompare}`, data);
}

export function articleCopyhistory(query) {
    return axios.post(`${APIEndpoints.articleCopyhistory}${query}`);
}

export function articleCheckCopy(query) {
    return axios.post(`${APIEndpoints.articleCheckCopy}${query}`);
}

export function viewCopy(query) {
    return axios.post(`${APIEndpoints.viewCopy}${query}`);
}

export function dragDropCopyright(data) {
    return axios.post(`${APIEndpoints.dragDropCopyright}`, data);
}

export function deleteCopyright(query) {
    return axios.post(`${APIEndpoints.deletecopyright}?${query}`);
}

export function toTakedown(query) {
    return axios.post(`${APIEndpoints.takedown}?${query}`);
}

export function featuresArticleList() {
    return axios.post(`${APIEndpoints.featuresArticleList}`);
}

export function searchTags(query) {
    return axios.post(`${APIEndpoints.searchTags}?tag=${query}`);
}

export function updateVersion(data = {}) {
    return axios.post(`${APIEndpoints.updateVersion}`, data);
}

export function versionStatus(data = {}) {
    return axios.post(`${APIEndpoints.versionStatus}`, data);
}

export function addRevision(data = {}) {
    return axios.post(`${APIEndpoints.addRevision}`, data);
}

export function nftGetContractDetails(contract, token) {
    return axios.post(`${APIEndpoints.nftGetContractDetails}${contract}${token?`/${token}`:''}`, {});
}

export function fixDatabase(data = {}) {
    return axios.post(`${APIEndpoints.fixDatabase}`, data);
}

export function getUserFeed(data = {}) {
    return axios.post(`${APIEndpoints.getUserFeed}`, data);
}

