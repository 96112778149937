import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from './../../assets/images/CM_120.png'
const Footer = () => {
    return (<>
        {/* <!-- Footer Component --> */}
        <section className='CM_secText'>
            <Container>
                <div className='CM_secTitle max-w-950px mb-4'>Our platform enables you to manage and monetize your copyrighted works the way you want.</div>
                <div className='CM_secSubTitle'>Your expressed thoughts, ideas, opinions, and frameworks are your copyrighted works. We believe in its value.</div>
            </Container>
        </section>
        <footer className="CMftr1">
            <Container>
                <Row>
                    <Col md={8}>
                        <div className="text-white fs-14px mb-3">Content Mutual</div>
                        <div className="text-white fs-14px max-w-450px mb-3">Your unique thoughts, frameworks, opinions, and commentaries are copyrighted and valuable. Our platform and technology ensures that you get paid for what your knowledge is worth, on your terms, on time, on a pay-per-view basis.</div>
                        <div className="text-white fs-14px max-w-450px mb-3">Assembled on Planet Earth.</div>
                        <img src={logo} alt='Logo' />
                    </Col>
                    <Col md={4}>
                        <div className="text-white fs-14px text-uppercase mb-3">STAY CONNECTED</div>
                        <ul className="list-unstyled mb-0 CM_social">
                            <li><a href="https://www.linkedin.com/pub/joshua-tobkin/5a/611/4a1" rel="noreferrer" target="_blank">linkedin</a></li>
                            <li><a href="http://twitter.com/ContentMutual" target="_blank" rel="noreferrer">twitter</a></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
        <footer className="CMftr2">
            <Container>
                <Row>
                    <Col md={8}>
                        <div className="CM_copy text-white fs-14px">
                            <span className="text-gray">Copyright ContentMutual.com © 2018. All Rights Reserved,</span> <a href="http://socialclimax.net/" rel="noreferrer" target="_blank">a Social Climax Project</a><br />
                            <a href="http://mutualcoin.io/">See How It Works</a>
                        </div>

                    </Col>
                    <Col md={4}>
                        <ul className="list-unstyled mb-0 CM_social d-flex justify-content-md-end align-items-center">
                            <li><a href="https://www.linkedin.com/pub/joshua-tobkin/5a/611/4a1" rel="noreferrer" target="_blank" className="opacity-60 hover-opacity-100 me-3"><i className="fs-18px fa fa-linkedin-square" aria-hidden="true"></i>&nbsp;</a></li>
                            <li><a href="http://twitter.com/ContentMutual" rel="noreferrer" target="_blank" className="opacity-60 hover-opacity-100"><i className="fs-18px fa fa-twitter" aria-hidden="true"></i>&nbsp;</a></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
        {/* <!-- Footer Component --> */}
    </>)

}
export default Footer;