import React, { useState, useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import { Label, PageTitle, RadioButton } from '../components/Typography'
import { preferences } from '../action/myaccount.action';
import toast from '../helpers/toast';

const PreferencesPage = () => {
    
    const [state, setState] = useState({});

    const onRetivePrefence = async () => {
        const {data} = await preferences();
        if(data.status === 200) {
            setState(data.data);
        }
    }

    useEffect(() => {
        onRetivePrefence()
    },[])

    const onClickToRadio = (key, value) => {
        setState({...state, [key]: value})
    }

    const onClickToSave = async () => {
        const formData = new FormData();
        Object.keys(state).map(res => formData.append(res, state[res]))

        const {data} = await preferences(formData)
        if(data.status === 200) {
            toast.success(data.data.message);
            onRetivePrefence();
        }
    }

    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Preferences" />
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <form>
                    <Label text="What best describes you?" />
                    <div>
                        <RadioButton text="I'm a Content Creator" name="user_type" onClick={() => onClickToRadio('user_type', 'content_creator')} checked={state.user_type?(state.user_type === 'content_creator'?true:false):false}/>
                    </div>
                    <div>
                        <RadioButton text="I'm a Publisher" name="user_type" onClick={() => onClickToRadio('user_type', 'publisher')} checked={state.user_type?(state.user_type === 'publisher'?true:false):false}/>
                    </div>
                    <div className='pb-4'>
                        <RadioButton text="I'm both" name="user_type" onClick={() => onClickToRadio('user_type', 'both')} checked={state.user_type?(state.user_type === 'both'?true:false):false}/>
                    </div>
                    <Label text="Do you currently have a website?" />
                    <div>
                        <RadioButton text="I have a website" name="website" onClick={() => onClickToRadio('website', 'yes')} checked={state.website?(state.website === 'yes'?true:false):false}/>
                    </div>
                    <div>
                        <RadioButton text="I don't have a website" name="website" onClick={() => onClickToRadio('website', 'do_not_have')} checked={state.website?(state.website === 'do_not_have'?true:false):false}/>
                    </div>
                    <div className='pb-4'>
                        <RadioButton text="I want a better website or would like a new one" name="website" onClick={() => onClickToRadio('website', 'want_better_or_new')} checked={state.website?(state.website === 'want_better_or_new'?true:false):false}/>
                    </div>
                    <Label text="I'm most interested to..." />
                    <div>
                        <RadioButton text="Get more exposure" name="most_interested_to" onClick={() => onClickToRadio('most_interested_to', 'get_more_exposure')} checked={state.most_interested_to?(state.most_interested_to === 'get_more_exposure'?true:false):false}/>
                    </div>
                    <div>
                        <RadioButton text="Improve my Content Strategy" name="most_interested_to" onClick={() => onClickToRadio('most_interested_to', 'improve_content_strategy')} checked={state.most_interested_to?(state.most_interested_to === 'improve_content_strategy'?true:false):false}/>
                    </div>
                    <div className='pb-4'>
                        <RadioButton text="Make more money from my existing Content" name="most_interested_to" onClick={() => onClickToRadio('most_interested_to', 'make_more_money_from_existing_content')} checked={state.most_interested_to?(state.most_interested_to === 'make_more_money_from_existing_content'?true:false):false}/>
                    </div>
                    <Button variant='green' onClick={onClickToSave} >Save</Button>
                </form>
            </div>
        </Container>
    </>)
}

export default PreferencesPage