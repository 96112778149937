import React from "react";

const Select = (props) => {
    const { id, name, values, onChange, value } = props;

    return <>
        <label>{name}</label>
        <select className="cm_input" name={"attr_" + id} onChange={(e) => onChange("attr_" + id, e.target.value)} value={value}>
            {(values || []).map((v, indx) => <option key={indx + 1} value={v.id}>{v.name}</option>)}
        </select>
    </>

}

export default Select;