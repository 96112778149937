import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Label } from '../components/Typography';
import Joi from 'joi'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import {joiUpdatedMessage} from '../Utils/AppUtill'
import ErrorLabel from '../widgets/ErrorLable';
import {resetPassword} from '../action/auth.action';
import { useHistory } from 'react-router-dom';
import toast from "../helpers/toast"

const ResetPasswordPage = () => {
    const history = useHistory();

    const {
        handleSubmit,
        formState: {errors},
        control,
        reset
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                password_1: Joi.string()
                    .required()
                    .label('Password')
                    .messages(joiUpdatedMessage),
                password_2: Joi.string()
                    .required()
                    .label('Confirm Password')
                    .valid(Joi.ref('password_1'))
                    .messages(joiUpdatedMessage)
            }),
        ),
    })

    const onClickToSubmit = async (values) => {
        const formData = new FormData();
        Object.keys(values).map(r => {
            return formData.append(r, values[r]);
        })
        
        const {data} = await resetPassword(history.location.search, formData);
        if(data.status === 200) {
            reset({password_1: '', password_2: ''});
            toast.success(data.data.message);
            history.push('/user/login')
        }
    }

    return (<>
        {/* {console.log("error", errors)} */}
        <Container>
            <Col className='text-center'>
                <h1 className="page-title cover-wrapper green_color pt-5">My Account</h1>
            </Col>
        </Container>
        <Container className="py-5">
            <Row>
                {/* {console.log('errors.email', errors.email.message)} */}
                <Col md={12} sm={12} xs={12}>
                    <div className='CM_whiteBox user_login'>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <h2 className='green_color'>Enter a new password below.</h2>
                            </Col>
                        </Row>
                        <form className='row'>
                            <Col md={12} className='mb-3'>
                                <Controller
                                    name="password_1"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <Label text="New password *" />
                                            <input 
                                                type="password" 
                                                className="cm_input"
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }} 
                                            />
                                            {errors.password_1 && <ErrorLabel    
                                                msg={errors.password_1 && errors.password_1.message}
                                            />}
                                        </>
                                    )}
                                />
                                
                            </Col>
                            <Col md={12} className='mb-3'>
                                <Controller
                                    name="password_2"
                                    control={control}
                                    render={({field: {value, onChange}}) => (
                                        <>
                                            <Label text="Re-enter new password *" />
                                            <input 
                                                type="password" 
                                                className="cm_input"
                                                value={value}
                                                onChange={e => {
                                                    onChange(e)
                                                }} 
                                            />
                                            {errors.password_2 && <ErrorLabel    
                                                msg={errors.password_2 && errors.password_2.message}
                                            />}
                                        </>
                                    )}
                                />
                                
                            </Col>
                            <div className='login_cehckbox mb-3'>
                                <div><button className='btn-green' onClick={handleSubmit(onClickToSubmit)}>Reset Password</button></div>
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    </>)
}
export default ResetPasswordPage;