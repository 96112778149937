import React, {useState, useEffect} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { generateVerificationId, articleCheckSubmittedContent } from '../../action/article.action';
import { useHistory } from 'react-router';

export const Step2 = ({onChangeStep, onChangeState, state}) => {
    const history = useHistory();
    const [HowModal, setHowModal] = useState(false)
    const [step, setStep] = useState(1)
    const [submit_id, setSubmit_id] = useState("");
    const handleHowModalHide = () => {
        setHowModal(false)
    }

    const [error, setError] = useState(false)
    
    useEffect(() => {
        (async () => {
            if(!state.article_submit_id) {
                const {data} = await generateVerificationId({article_submit_id: step.article_submit_id || ''});
                if(data.status === 200) {
                    const { article_submit_id } = data.data;
                    const div = `<div id="${article_submit_id}"></div>`
                    setSubmit_id(div)
                    onChangeState({article_submit_id})
                }
            }
        })()
        // eslint-disable-next-line
    }, [])

    useState(() => {
        if(state.article_submit_id) {
            const div = `<div id="${state.article_submit_id}"></div>`
            setSubmit_id(div);
        }
    }, [state.article_submit_id])
    
    const onClickToNext = async () => {
        const formData = new FormData();
        formData.append('content_provider', state.content_provider);
        formData.append('url', state.url);
        formData.append('article_submit_id', state.article_submit_id);
        const {data} = await articleCheckSubmittedContent(formData);
        if(data.status === 200) {
            if(!data.data.error) {
                onChangeState({
                    fix_content: data.data.content,
                    content: data.data.content
                })
                formData.append('content', data.data.content);
                history.push('/article/submit?step=3');
            }
            setError(data.data.error)
        }
        
    }
    

    return (<>
        <div>
            {error && <div className='text-green fs-md-22px fs-18px'>We couldn't verify your article. Please try again.</div>}
            <div className='text-green mb-3'>Verification:<Button variant='green' className='ms-2' onClick={() => setHowModal(true)}> Show Me How This Works</Button></div>
            <textarea className='cm_input' rows="2" spellCheck="false" value={submit_id}>
            </textarea>
            <Button variant='green' className='mt-3' onClick={onClickToNext}>{error?'Verify':'Next'} →</Button>
            {/* <Button variant='green' className='mt-3'>Refresh</Button> */}
        </div>
        <Modal show={HowModal} onHide={handleHowModalHide} centered size="lg">
            <Modal.Header className='border-0 pb-0' closeButton>
            </Modal.Header>
            <Modal.Body>
                {step === 1 && <><div className='text-green fs-md-22px fs-18px'>Here is your Unique Verification Code:</div>
                    <p className='text-green fs-13px'>&lt;div id="contentmutual.master.rights.ID.789789798"&gt;<br />Please place this at the top of your Article's HTML. This is how we know where your content begins and it will not affect how your article looks.</p>
                    <div className='text-center mt-4'><Button variant="green" onClick={() => setStep(2)}>Next</Button></div></>}
                {step === 2 && <><div className='text-green fs-md-22px fs-18px'>Step 2:</div>
                    <p className='text-green fs-13px'>Please add a "closing div tag" at the end of your article. This is how we know where you article ends. Add this at the end of your article's HTML:&lt;div&gt;</p>
                    <div className='d-flex align-items-center justify-content-center mt-4'>
                        <Button variant="green" onClick={() => setStep(1)} className='me-2'>Previous</Button>
                        <Button variant="green" onClick={() => setStep(3)}>Next</Button>
                    </div></>}
                {step === 3 && <><div className='text-green fs-md-22px fs-18px'>Step 3:</div>
                    <p className='text-green fs-13px'>After you've added your Unique Verification Code to the beginning of your article and the closing &lt;div&gt; tag at the end of your article, just update and save your article's HTML and visit contentmutual.com again to let us know you're ready for us to review your aticle.</p>
                    <div className='d-flex align-items-center justify-content-center mt-4'>
                        <Button variant="green" onClick={() => setStep(2)} className='me-2'>Previous</Button>
                        <Button variant="green" onClick={() => setStep(4)}>Next</Button>
                    </div></>}
                {step === 4 && <><div className='text-green fs-md-22px fs-18px'>Please watch this short clip to understand how to List Content on our site:</div>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/9xwazD5SyVg" title="YouTube video player" frameborder="0" allowfullscreen></iframe>
                    <div className='d-flex align-items-center justify-content-center mt-4'>
                        <Button variant="green" onClick={() => setStep(3)} className='me-2'>Previous</Button>
                        <Button variant="green" onClick={() => {
                            setHowModal(false)
                            setStep(1)
                        }}>Ok I Got It!</Button>
                    </div></>}
            </Modal.Body>
        </Modal>
    </>)
}