import React, {useState, useEffect, useContext} from 'react';
import { Button, Modal } from 'react-bootstrap'
import { Label } from '../Typography'
import { getAllDomains, requestpermission } from '../../action/article.action';
import { GlobalContext } from '../../context/globalContext';
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../../Utils/AppUtill';
import ErrorLabel from '../../widgets/ErrorLable';
import toast from "../../helpers/toast"
import Joi from 'joi'
import { MERGING } from 'slate-history';

export const RequestToPermissionComponent = ({disabled = false, onCallBack, article_id, data}) => {
    const { isLoggedIn, setSignIn, userData } = useContext(GlobalContext);
    const [showModal, setShowModal] = useState(false);
    const [state, setState] = useState({});
    const [domains, setDomains] = useState([]);
    
    const {
        handleSubmit,
        formState: {errors},
        control,
        reset,
        getValues
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                domain: Joi.string()
                    .required()
                    .label('domain')
                    .messages(joiUpdatedMessage),
                '1000_imp': Joi.number()
                    .integer().min(1)
                    .required()
                    .label('1000_imp')
                    .messages(joiUpdatedMessage),
                '10000_imp': Joi.number()
                    .integer().min(1)
                    .required()
                    .label('10000_imp')
                    .messages(joiUpdatedMessage),
                introduction: Joi.string()
                    .required()
                    .label('introduction')
                    .messages(joiUpdatedMessage),
                daily_cap: Joi.number()
                    .integer().min(1)
                    .required()
                    .label('daily_cap')
                    .messages(joiUpdatedMessage),
                starting_fee: Joi.number()
                    .integer().min(1)
                    .required()
                    .label('starting_fee')
                    .messages(joiUpdatedMessage),
                type: Joi.boolean()
                    .label('type'),
                user_domains: Joi
                    .string().allow(null, '')
            }),
        ),
    })
    
    useEffect(() => {
        (async () => {
            if(isLoggedIn) {
                const { data } = await getAllDomains();
                if(data.status === 200) {
                    setDomains(data.data.domains)
                }
            }
        })()
    }, [isLoggedIn]);

    const onClickToCloseModal = () => {
        setShowModal(false)
    }
    
    const onClickToOpenModal = () => {
        if(userData.coinbase_wallet_address || userData.user_wallet_address) {
            if(!isLoggedIn) {
                setSignIn(true);
            } else {
                setShowModal(true);
            }
        } else {
            toast.info("Please connect your wallet in order to request permission for this article");
        }
    }

    const onChangeInput = (e) => {
        setState({...state, [e.target.name]: e.target.value})
    }

    const onChangeSelectOption = (e) => {
        const find = domains.find(r => r.id === e.target.value)
        if(find) {
            setState({...state, user_domains: e.target.value, ...find})
        }
        
        reset({
            ...getValues(),
            user_domains: e.target.value,
            domain: find.domain,
            introduction: find.introduction
        })
    }
    
    const onClickToSubmit = async (e) => {
        const formData = new FormData();
        formData.append('domain', state.domain);
        formData.append('1000_imp', state['1000_imp']);
        formData.append('10000_imp', state['10000_imp']);
        formData.append('introduction', state.introduction);
        formData.append('daily_cap', state.daily_cap);
        formData.append('starting_fee', state.starting_fee);
        if(state.user_domains) {
            formData.append('user_domains', state.user_domains);
        }
        formData.append('article_id', article_id);
        formData.append('message', '');
        const {data} = await requestpermission(formData);
        if(data.status === 200) {
            reset({})
            onCallBack()
            setShowModal(false)
            setState({});
        }
    }
    
    return (<>
        <Button variant='blue' disabled={disabled} onClick={onClickToOpenModal}>Request Permission</Button>
        <Modal show={showModal} onHide={onClickToCloseModal} size="xl" centered>
            <Modal.Header className='border-0 py-0' closeButton>
                {/* <div className='fs-20px text-green fs-md-24px'>Bookmark This Listing</div> */}
            </Modal.Header>
            <Modal.Body>
                <form className='row'>
                    <div className='col-lg-8 mb-3'>
                        <Label text="Domain to repost on*" />
                        <Controller
                            name="domain"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="domain" 
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                    />
                                    {errors.domain && <ErrorLabel
                                        msg={"Domain is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>
                    {domains.length > 0 && <div className='col-lg-5 mb-3'>
                        <Controller
                            name="user_domains"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <select 
                                        name="user_domains" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeSelectOption(e)
                                        }} 
                                        className="cm_input"
                                    >
                                        <option value="">Previously submitted domains</option>
                                        {domains && domains.map((r, idx) => <option key={idx + 1} value={r.id}>{r.domain}</option>)}
                                    </select>
                                    {errors.user_domains && <ErrorLabel
                                        msg={"User Domain is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>}
                    <div className='col-lg-12 mb-3'>
                        <Label text="Introduction (500 words max)" />
                        <Controller
                            name="introduction"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <textarea 
                                        rows="5" 
                                        name="introduction" 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className='cm_input' 
                                        value={value}
                                    ></textarea>
                                    {errors.introduction && <ErrorLabel
                                        msg={"Introduction is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>
                    <div className='col-lg-3 mb-12'>
                        <Label text="Starting fee" />
                        <Controller
                            name="starting_fee"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="starting_fee" 
                                        value={value} 
                                        placeholder={data?.startingfee} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }}
                                        className="cm_input" 
                                    />
                                    {errors.starting_fee && <ErrorLabel
                                        msg={"Starting fee is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>
                    <div className='col-lg-3 mb-12'>
                        <Label text="Price per 1,000 impressions" />
                        <Controller
                            name="1000_imp"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="1000_imp" 
                                        value={value} 
                                        placeholder={data?.fee1000imp} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                    />
                                    {errors['1000_imp'] && <ErrorLabel
                                        msg={"1000 impressions fee is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>
                    <div className='col-lg-3 mb-12'>
                        <Label text="Price per 10,000 impressions" />
                        <Controller
                            name="10000_imp"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="10000_imp" 
                                        value={value} 
                                        placeholder={data?.fee10000imp}
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                    />
                                    {errors['10000_imp'] && <ErrorLabel
                                        msg={"10000 impressions fee is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>
                    <div className='col-lg-3 mb-12'>
                        <Label text="Daily Cap" />
                        <Controller
                            name="daily_cap"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="daily_cap" 
                                        value={value} 
                                        placeholder='500.00' 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                    />
                                    {errors['daily_cap'] && <ErrorLabel
                                        msg={"Daily Cap is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>
                    <div className='col-lg-12 mb-12'>&nbsp;</div>
                    <div className='col-lg-12'>
                        <Button variant='green' onClick={handleSubmit(onClickToSubmit)}>Submit</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    </>)
}
