import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Button, Table } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { PageTitle } from '../components/Typography';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { articlegraph, requestgraph } from '../action/request.action';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ReactApexChart from 'react-apexcharts';
import { formattedDate } from '../Utils/AppUtill'
import queryString from 'query-string';

const Analytics = () => {

    const history = useHistory();

    const [state, setState] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    
    const { start, end } = state;
    
    const graphData = {
          options: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          title: {
            text: '',
            align: 'left'
          },
          legend: {
            tooltipHoverFormatter: function(val, opts) {
              return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
            }
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function (val) {
                    // return val + " (mins)"
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val + " per session"
                  }
                }
              },
              {
                title: {
                  formatter: function (val) {
                    return val;
                  }
                }
              }
            ]
          },
          grid: {
            borderColor: '#f1f1f1',
          }
        },
    };

    const onRenderAnalytics = async () => {
        // articlegraph
        let data = {};

        if(history.location.pathname === '/analytics/articlegraph') {
          data = await articlegraph(history.location.search);
        } else if(history.location.pathname === '/analytics/requestgraph') {
          data = await requestgraph(history.location.search);
        }
        
        if(data.data.status === 200) {
            data.data.data.data = [{
                name: '',
                data: data.data.data.data.map(r => [formattedDate(r.date), r.count?parseInt(r.count):0])
            }];
            setState({...state, ...data.data.data}); 
        }
    }

    let label = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
    useEffect(() => {
        onRenderAnalytics();
        // eslint-disable-next-line
    }, [])
    
    useEffect(() => {
        const {from, to} = queryString.parse(history.location.search);
        if(from && to) {
            onRenderAnalytics();
        }
        // eslint-disable-next-line
    }, [history.location])
    
    const handleCallback = (start, end) => {
        const { pathname, search } = history.location;
        const obj = queryString.parse(search);
        obj.from = start.format('DD-MM-YYYY');
        obj.to = end.format('DD-MM-YYYY')
        setState({ ...state, start, end });
        history.push(`${pathname}?${queryString.stringify(obj)}`)
    };
        
    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Analytics" />
        
        <Container className="my-5">
            <div className="CM_whiteBox">
                <Row>
                    <Col lg={3}>
                        <DateRangePicker
                            onCallback={handleCallback}
                            initialSettings={{
                                applyButtonClasses: "btn-green",
                                cancelButtonClasses: "btn-green",
                                linkedCalendars: false,
                                startDate: start.toDate(),
                                endDate: end.toDate(),
                                ranges: {
                                    'Today': [moment().toDate(), moment().toDate()],
                                    'Yesterday': [
                                        moment().subtract(1, 'days').toDate(),
                                        moment().subtract(1, 'days').toDate(),
                                    ],
                                    'Last 7 Days': [
                                        moment().subtract(6, 'days').toDate(),
                                        moment().toDate(),
                                    ],
                                    'Last 30 Days': [
                                        moment().subtract(29, 'days').toDate(),
                                        moment().toDate(),
                                    ],
                                    'This Month': [
                                        moment().startOf('month').toDate(),
                                        moment().endOf('month').toDate(),
                                    ],
                                    'Last Month': [
                                        moment().subtract(1, 'month').startOf('month').toDate(),
                                        moment().subtract(1, 'month').endOf('month').toDate(),
                                    ],
                                },
                            }}
                        >
                        <Button>{label}</Button>
                        </DateRangePicker>
                        <br/><br/>
                        {state.article && <div className="card card-border">
                            <div className="card-header fs-13px"><i className="fa fa-user"></i> Author Box</div>
                            <div className="card-body">
                                <Table bordered className='cm_table shadow-none'>
                                    <tbody>
                                        <tr>
                                            <td>Total Days Active:</td><td>{state.artReq.total_days_active}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Page Views:</td><td>{state.artReq.total_page_views}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Daily Page Views:</td><td>{state.artReq.average_daily_page_views}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>}
                    </Col>
                    <Col lg={9}>
                        {state.data && <ReactApexChart options={graphData.options} series={state.data} type="line" height={350} />}
                    </Col>
                </Row>
            </div>
        </Container>
    </>)
}

export default Analytics;