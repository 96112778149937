import {useState} from 'react';
import Icon from './Icon'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CMTooltip = (props) => {
    const{ text } = props;
    return (<OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">{text}</Tooltip>}
            >
                {props.children}
            </OverlayTrigger>)
}

export default CMTooltip;