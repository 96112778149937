import React from 'react'
import { Link } from 'react-router-dom';
import NotFoundSvg from '../assets/images/notFound.svg';

const PageNotFound = () => {
    return (<div>
        <div className="wrapper404">
            <div className="landing-page404">
                <div style={{"textAlign":"center"}} className="icon__download">
                    <img alt="" className="svg404" src={NotFoundSvg}/>  
                </div>
                
                <h1 className='h1404'>404 - Page not found</h1>
                <p className='p404'> Wow, well that was cool!<br/>
The page you are looking for was moved, removed, renamed or might never existed.</p>
                <Link to="/" className='button404'>Back to home</Link>
            </div>
        </div>
    </div>)
}

export default PageNotFound