import React, { useState, useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Col, Modal, Row, Button } from 'react-bootstrap';
import { Label } from '../Typography'
import { getCategoryList, getAllAttributes, getAllLanguage, searchTags } from '../../action/article.action';
import { WithContext as ReactTags } from 'react-tag-input';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import ErrorLabel from '../../widgets/ErrorLable';
import CMEditor from '../Editor/CMEditor';
import { CropImage } from '../CropImage';
import Attribute from '../Attribute/Attribute';
import toast from '../../helpers/toast';

const FieldLength = ({ field, length }) => {
    const left = length - (field ? field.split(' ').length : 0);
    const classList = left < 0 ? "text-danger" : null;

    return <Label text={left} className={classList} />
}

export const Step1 = ({ onChangeStep, onChangeState, state }) => {
    const history = useHistory();
    const [category, setCategory] = useState("")
    const [language, setLanguage] = useState([])
    const [errors, setErrors] = useState({});
    const [validatedOnce, setValidatedOnce] = useState(false);
    const KeyCodes = {
        comma: 188,
        enter: [10, 13],
    };

    const delimiters = [...KeyCodes.enter, KeyCodes.comma];

    const [tags, setTags] = useState(state.tags || [])
    
    const [suggestions, setSuggestions] = useState([]);

    const handleDelete = (i) => {
        const filter = tags.filter((tag, index) => index !== i);
        onChangeState({ tags: filter })
        setTags(filter);
    }

    const handleAddition = (tag) => {
        if(tag.text.length < 3) {
            toast.error("Tag should be atleast 3 or more characters");
            return false;
        }

        onChangeState({ tags: [...tags, tag] })
        setTags([...tags, tag]);
    }

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags({ tags: newTags });
    }

    const handleTagInputChange = useDebouncedCallback(async (q) => {
        if(q.length >= 3) {
            const {data} = await searchTags(q);
            if(data.status === 200) {
                setSuggestions(data.data.tags)
            }
        } else {
            setSuggestions([])
        }
    }, 500);

    const [confirmModal, setConfirmModal] = useState(false)

    const validate = () => {
        let required = ['name', 'category_id', 'tags', 'language', 'description', 'accolade_url',
            'image', 'content_provider'];

        if (state.content_provider === 'url')
            required.push('url');
        else
            required.push('content_editor');

        let valid = true;
        let errTmp = {};
        required.forEach(r => {
            if(r === 'accolade_url') {
                if(state['accolade']!==undefined && state['accolade']!=='' && (state[r] === undefined || !state[r])) {
                    errTmp = { ...errTmp, [r]: true };
                    valid = false;
                }
            } else if(r === 'tags') {
                if(tags.length <= 0) {
                    errTmp = { ...errTmp, [r]: true };
                    valid = false
                }
            } else {
                if (state[r] === undefined || !state[r]) {
                    errTmp = { ...errTmp, [r]: true };
                    valid = false;
                }
            }
        })
        
        setValidatedOnce(true)
        setErrors(errTmp)
        return valid;
    }

    useEffect(() => {
        if (validatedOnce)
            validate()
        // eslint-disable-next-line
    }, [state])

    const handleNext = () => {
        if (validate())
            setConfirmModal(true)
    }

    const handleConfirmModalHide = () => {
        setConfirmModal(false)
        // setFormStep(2)
    }
    
    const onRetriveCategory = async (parentId = 0) => {
        const { data } = await getCategoryList(parentId);
        if (data.status === 200) {
            setCategory(data.data)
        }
    }

    const onRetriveLanguage = async () => {
        const langs = await getAllLanguage();
        setLanguage(langs)
        const { lang, parent_id } = queryString.parse(history.location.search)
        let obj = {}
        if (lang) {
            obj = { ...obj, language: lang };
        }

        if (parent_id) {
            obj = { ...obj, parent_id: parent_id };
        }
        onChangeState(obj);
    }

    useEffect(() => {
        onRetriveCategory()
        onRetriveLanguage();
        getNewAttributes();
        // eslint-disable-next-line
    }, [])

    const onChangeInput = (e) => {
        onChangeState({ [e.target.name]: e.target.value })
    }

    const onChangeAttribute = (name, value) => {
        onChangeState({ [name]: value });
    }

    const onClickToImageUpload = (data) => {
        onChangeState({ image: data })
    }

    const onClickToConfirm = () => {
        handleConfirmModalHide()
        if (state.content_provider === 'url') {
            history.push('/article/submit?step=2')
        } else if (state.content_provider === 'editor') {
            onChangeState({
                fix_content: state.content
            });
            history.push('/article/submit?step=3')
        }
    }

    const [categoryMultiple, setCategoryMultiple] = useState(false);
    const categoryEventHandlers = useMemo(() => ({
        onFocus: () => setCategoryMultiple(true),
        onBlur: () => setCategoryMultiple(false)
    }), []);

    const [newAttributes, setNewAttributes] = useState([]);
    const getNewAttributes = async () => {
        const { data } = await getAllAttributes();
        setNewAttributes(data.data);
    };

    return (<>
        <Row>
            <Col md={12} className='mb-3'>
                <Label text="Article Title" />
                <>
                    <input
                        type="text"
                        name="name"
                        value={state.name}
                        onChange={(e) => {
                            onChangeInput(e)
                        }}
                        className="cm_input"
                    />
                    {errors.name && <ErrorLabel
                        msg={"Name is required."}
                    />}
                </>
            </Col>
            <Col md={12}>
                <Row>
                    <Col md={4} className='mb-3'>
                        <Label text="Category" />
                        <>
                            <select className="cm_input"
                                name="category_id"
                                value={state.category_id}
                                onChange={(e) => {
                                    onChangeInput(e)
                                    onRetriveCategory(e.target.value)
                                }} dangerouslySetInnerHTML={{ __html: category }}
                                {...categoryEventHandlers}
                                multiple={categoryMultiple}>
                            </select>
                            {errors.category_id && <ErrorLabel
                                msg={"Category is required."}
                            />}
                        </>
                    </Col>
                    {newAttributes.map(a => {
                        return <Col md={4} className='mb-3' key={a.id}>
                            <>
                                <Attribute {...a} onChange={(n, v) => {
                                    onChangeAttribute(n, v)
                                }}
                                    value={state["attr_" + a.id]} />
                                {errors['attr_' + a.id] && <ErrorLabel
                                    msg={a.name + " is required."}
                                />}
                            </>
                        </Col>
                    })}

                    <Col md={8} className='mb-3'>
                        <CropImage cimage={state.image} callBack={onClickToImageUpload} aspect={3 / 1} />
                        {errors.image && <ErrorLabel
                            msg={"Image is required."}
                        />}
                        {/* {image === '' && <div className='cm_uploadImage'>
                            <input type='file' onChange={onFileChange} />
                            Drop your image here or click to add one!
                        </div>}
                        {image !== '' && <div className='cm_uploadImage'>
                            <div className='cropWrapper w-100 h-100'>
                                <img src={image} alt="" />
                            </div>
                            <div className="cm_imageTools">
                                <div className="btn btn-light btn-zoom-in" title="Zoom in">
                                    <Icon name="resizefull" />
                                </div>
                                <div className="btn btn-light btn-zoom-reset" title="Fullsize">
                                    <Icon name="fullscreen" />
                                </div>
                                <div className="btn btn-light btn-zoom-out" title="Zoom out">
                                    <Icon name="resizesmall" />
                                </div>
                                <div className="btn btn-danger btn-cancel" title="Cancel">
                                    <Icon name="close" />
                                </div>
                                <div className="btn btn-success btn-ok" title="Ok">
                                    <Icon name="check" />
                                </div>
                            </div>
                        </div>} */}
                    </Col>
                    <Col md={4} className='mb-3'>
                        <Label text="Tag" />
                        <div className='cm_input mb-3 p-1 d-flex flex-wrap align-items-center'>
                            <ReactTags tags={tags}
                                suggestions={suggestions}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                handleDrag={handleDrag}
                                delimiters={delimiters}
                                handleInputChange={handleTagInputChange} />
                        </div>
                        {errors.tags && <ErrorLabel
                            msg={"Tags is required."}
                        />}
                        <Label text="Language" />
                        <>
                            <select className="cm_input"
                                name="language"
                                value={state.language}
                                onChange={(e) => {
                                    onChangeInput(e)
                                }}>
                                <option value="">Choose Language</option>
                                {language && language.map((res, idx) => <option key={idx + 1} value={res.code}>{res.name}</option>)}
                            </select>
                            {errors.language && <ErrorLabel
                                msg={"Language is required."}
                            />}
                        </>
                    </Col>
                    <Col md={12} className='mb-3'>
                        <Label text="Description" />
                        <>
                            <textarea rows="5"
                                className='cm_input'
                                name="description"
                                value={state.description}
                                onChange={(e) => {
                                    onChangeInput(e)
                                }}>
                            </textarea>
                            {errors.description && <ErrorLabel
                                msg={"Description is required."}
                            />}
                        </>
                        <FieldLength length={100} field={state.description} />
                    </Col>
                    <Col md={12} className='mb-3'>
                        <div className='d-flex align-items-center'>
                            <Button variant='link' className={'pe-1 py-1 ps-0 cm_OptionBtn' + (state.content_provider === 'url' ? '  activeBtn' : '')} onClick={() => onChangeState({ content_provider: 'url' })}>URL</Button> |
                            <Button variant='link' className={'p-1 cm_OptionBtn' + (state.content_provider === 'editor' ? '  activeBtn' : '')} onClick={() => onChangeState({ content_provider: 'editor' })}>Editor</Button>
                        </div>
                        {state.content_provider === 'url' && <>
                            <Label text="URL" />
                            <>
                                <input
                                    type="text"
                                    name="url"
                                    value={state.url}
                                    onChange={(e) => {
                                        onChangeInput(e)
                                    }}
                                    className="cm_input"
                                />
                                {errors.url && <ErrorLabel
                                    msg={"Url is required."}
                                />}
                            </>
                        </>}
                        {state.content_provider === 'editor' &&
                            <>
                                <Label text="Editor" />
                                <CMEditor save={(content, html) => {
                                    onChangeState({ content_editor: content, content: html })
                                }} content={state.content_editor} />
                                {errors.content_editor && <ErrorLabel
                                    msg={"Content is required."}
                                />}
                            </>
                        }
                    </Col>
                    <Col md={8} className='mb-3'>
                        <Label text="This article has been seen on" />
                        <>
                            <select className="cm_input"
                                name="accolade"
                                value={state.accolade}
                                onChange={(e) => {
                                    onChangeInput(e)
                                }}>
                                <option value="">Choose</option>
                                <option value="business_insider">Business Insider</option>
                                <option value="computer_world">Computer World</option>
                                <option value="digg">Digg</option>
                                <option value="efactor">EFactor</option>
                                <option value="forbes">Forbes</option>
                                <option value="fortune">Fortune</option>
                                <option value="gizmodo">Gizmodo</option>
                                <option value="iflscience">IFLScience</option>
                                <option value="information_week">Information Week</option>
                                <option value="lifehacker">LifeHacker</option>
                                <option value="mashable">Mashable</option>
                                <option value="newsweek">Newsweek</option>
                                <option value="popular_science">Popular Science</option>
                                <option value="quora">Quora</option>
                                <option value="scientific_american">Scientific American</option>
                                <option value="slate">Slate</option>
                                <option value="the_guardian">The Guardian</option>
                                <option value="the_independent">The Independent</option>
                                <option value="the_washington_post">The Washington Post</option>
                                <option value="time">Time</option>
                            </select>
                            {errors.accolade && <ErrorLabel
                                msg={"Accolade is required."}
                            />}
                        </>
                        {state.accolade && <>
                            <input type="text"
                                className="cm_input"
                                name="accolade_url"
                                value={state.accolade_url}
                                onChange={(e) => {
                                    onChangeInput(e)
                                }}
                            />
                            {errors.accolade_url && <ErrorLabel
                                msg={"Accolade url is required."}
                            />}
                        </>}
                    </Col>
                    <Col md={4} className='mb-3'>
                        <button type="button" className='btn btn-dark' onClick={handleNext}>Next →</button>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Modal show={confirmModal} onHide={handleConfirmModalHide} className='cm_confirmModal' centered>
            <Modal.Header className='border-0 pb-0' closeButton>
            </Modal.Header>
            <Modal.Body>
                <p>Hey there, we just want you to confirm that to the best of your knowledge this piece of content you're submitting is your unique expression and copyrighted work.</p>
                <p>You are agreeing to let us make an original copy of your content so that we may service and cross-publish your article onto properties that you want. By clicking Confirm you are stating that this is your content and that you're allowing ContentMutual.com to facilitate your copyright management.</p>
                <label className='CM_checkbox mt-2'><input type='checkbox' name="toc" onChange={(e) => onChangeState({ toc: e.target.checked })} /><b>I accept the terms & conditions.</b></label>
                <div className='text-center mt-4'><Button variant="green" className='w-275px' onClick={onClickToConfirm} disabled={!state.toc}>
                    Confirm
                </Button></div>
            </Modal.Body>
        </Modal>
    </>)
}