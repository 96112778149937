import React from 'react'
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';

const Main = props => {
  return (
    <>
      <Header />
      <div>{props.children}</div>
      <Footer />
    </>
  )
}

export default Main
