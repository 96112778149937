import React, { useState, useEffect, useContext } from 'react'
import { Col, Container, Row, Tabs, Tab, Button } from 'react-bootstrap';
import { Label, PageTitle } from '../components/Typography';
import { myAccount } from '../action/myaccount.action';
import { connectWallet, disconnectWallet, getNonce, verifyEmail, emailVerification } from '../action/auth.action';
import { Controller, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../Utils/AppUtill';
import ErrorLabel from '../widgets/ErrorLable';
import MetamaskIcon from '../assets/images/metamask.svg';
import CoinbaseIcon from '../assets/images/coinbase.svg';
import Joi from 'joi'
import { useHistory } from 'react-router-dom';
import toast from '../helpers/toast';
import { CropImage } from '../components/CropImage';
import { GlobalContext } from '../context/globalContext';
import { Web3Context } from '../web3/contexts/web3Context'
import { ellipseAddress } from '../web3/helpers/utils';
import queryString from 'query-string';
import { signMessage } from '../web3/functions';

const MyAccount = () => {
    const [state, setState] = useState({});
    const { setUserInfo, userData } = useContext(GlobalContext);
    const { handleConnect } = useContext(Web3Context);
    const history = useHistory();
    const { tab, key } = queryString.parse(history.location.search);
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        reset,
        getValues
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                type: Joi.boolean()
                    .label('type'),
                username: Joi
                    .when('type', { is: true, then: Joi.string()
                    .regex(/^[^0x]{1}[a-zA-Z0-9_]*$/)    
                    .required()
                    .label('username')
                    .messages(joiUpdatedMessage) }),
                email: Joi.string()
                    .label('email')
                    .allow(null, ''),
                industry_id: Joi.string()
                    .label('Industry Id')
                    .allow(null, ''),
                first_name: Joi.string()
                    .label('first_name')
                    .allow(null, ''),
                last_name: Joi.string()
                    .label('last_name')
                    .allow(null, '')
            }),
        ),
    })

    const {
        handleSubmit: cphandleSubmit,
        formState: { errors: cperrors, isDirty: cpisDirty },
        control: cpcontrol,
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                password: Joi.string()
                    .required()
                    .label('Password')
                    .messages(joiUpdatedMessage),
                repassword: Joi.string()
                    .required()
                    .label('Confirm Password')
                    .valid(Joi.ref('password'))
                    .messages(joiUpdatedMessage)
            }),
        ),
    })

    const {
        handleSubmit: dchandleSubmit,
        formState: { errors: dcerrors, isDirty: dcisDirty },
        control: dccontrol,
        reset: dcreset
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                description: Joi.string()
                    .required()
                    .max(140)
                    .label('Desctiption')
                    .messages(joiUpdatedMessage),
                short_description: Joi.string()
                    .required()
                    .max(140)
                    .label('Short Desctiption')
                    .messages(joiUpdatedMessage)
            }),
        ),
    })

    const {
        handleSubmit: cahandleSubmit,
        control: cacontrol,
        formState: { errors: caerrors, isDirty: caisDirty },
        reset: careset
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                city: Joi.string()
                    .required()
                    .label('city')
                    .messages(joiUpdatedMessage),
                state: Joi.string()
                    .required()
                    .label('state')
                    .messages(joiUpdatedMessage),
                paypal: Joi.string()
                    .required()
                    .label('paypal')
                    .messages(joiUpdatedMessage),
            }),
        ),
    })

    const onRetriveMyAccount = async () => {
        const { data } = await myAccount({});
        if (data.status === 200) {
            const { first_name, username, temp_email, isemailverified, last_name, email, image, industry_id, user_wallet_address, coinbase_wallet_address, city, country, paypal, description, short_description } = data.data.profiles;
            let obj = {
                ...userData,
                first_name,
                last_name,
                username,
                temp_email,
                email,
                isemailverified,
                
                user_wallet_address: user_wallet_address ? user_wallet_address : userData.user_wallet_address,
                coinbase_wallet_address: coinbase_wallet_address ? coinbase_wallet_address : userData.coinbase_wallet_address
            };
            
            if(!['', null, undefined].includes(image)) {
                obj.image = image;
            }
            
            setUserInfo(obj);
            // username: state.user ? state.user.username : '', email: state.profiles ? state.profiles.email : ''
            reset({ first_name, last_name, industry_id, username });
            careset({ city, state: country, paypal });
            dcreset({ description, short_description });
            setState(data.data);
        }
    }
    
    useEffect(() => {
        (async () => {
            if(key) {
                const formData = new FormData();
                formData.append('verify_email_key', key);
                try {
                    const { data } = await emailVerification(formData);
                    if(data.status === 200) {
                        toast.success(data.data.message);
                        onRetriveMyAccount();
                    }
                } catch(e) {
                    onRetriveMyAccount();
                }
                history.push('?tab=Personal')
            } else {
                onRetriveMyAccount()
            }
        })()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(state.user && state.user.username) {
            reset({...getValues(), username: state.user.username})
        }
    }, [state.user])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(userData && (userData.email || userData.temp_email)) {
            reset({...getValues(), email: userData.temp_email?userData.temp_email:userData.email})
        }
    }, [userData])// eslint-disable-line react-hooks/exhaustive-deps

    const onClickToUpdateAccount = async (values, tabName) => {
        const formData = new FormData();

        Object.keys(values).map(res => {
            if(values[res] !== null && values[res] !== '' && values[res] !== undefined) {
                formData.append(res, values[res])
            }
            return res;
        });
        formData.append('tab_name', tabName);
        if (tabName === 'personal' && state.profiles.image_updated)
            formData.append('image_updated', state.profiles.image_updated)

        const { data } = await myAccount(formData);
        if (data.status === 200) {
            toast.success(data.data.message)
            onRetriveMyAccount()
        }
    }

    const onClickToUpdatePersonal = (values) => {
        onClickToUpdateAccount(values, "personal");
    }

    const onClickToChangePassword = (values) => {
        onClickToUpdateAccount(values, "password");
    }

    const onClickToUpdateDescription = (values) => {
        onClickToUpdateAccount(values, "description");
    }

    const onClickToUpdateCustomizeAccount = (values) => {
        onClickToUpdateAccount(values, "profile");
    }

    const onClickToImageUpload = (data) => {
        let profiles = state.profiles;
        profiles.image_updated = data;
        setState({ ...state, profiles: profiles })
    }

    const onClickToDisconnect = async (e, wlt) => {
        e.preventDefault();
        const formData = new FormData();
        if (wlt === 'metamask') {
            formData.append('walletAddress', userData.user_wallet_address);
        } else if (wlt === 'coinbase') {
            formData.append('coinbaseWalletAddress', userData.coinbase_wallet_address);
        }

        const { data } = await disconnectWallet(formData);

        if (data.status === 200) {
            if (wlt === 'metamask')
                setUserInfo({ ...userData, user_wallet_address: '' })
            else
                setUserInfo({ ...userData, coinbase_wallet_address: '' })
            toast.success(data.data.message);
        }
    }

    const connectweb3 = (plugin) => {
        handleConnect(plugin).then(address => {
            getNonce(address).then(async nonce => {
                let signature = await signMessage(address, nonce.data.data.message, nonce.data.data.nonce)
                connectWallet(plugin, address, signature).then(d => {
                    const { success } = d.data.data;
                    if (success) {
                        if (plugin === 'metamask')
                            setUserInfo({ ...userData, user_wallet_address: address })
                        else
                            setUserInfo({ ...userData, coinbase_wallet_address: address })
                    } else {
                        toast.error(Object.keys(d.data.data.errors).map(o => d.data.data.errors[o]).join(' '))
                    }
                })
            })
        });
    }

    const onClickToVerifyEmail = async () => {
        const formData = new FormData();
        formData.append('email', getValues('email'));
        const {data} = await verifyEmail(formData);
        if(data.status === 200) {
            toast.success(data.data.message);
        }
    }

    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="My Account" />
        <Container className="my-5">
            <div className='edit_account '>
                <div className='CM_whiteBox'>
                    <Tabs defaultActiveKey={tab ? tab : "Personal"} id="tab1" className="mb-3" onSelect={(tab) => history.push(`?tab=${tab}`)}>
                        <Tab eventKey="Personal" title="Personal">
                            <form>
                                <Row  className="justify-content-between">
                                    <Col lg={3}>
                                        {state.profiles &&
                                            <CropImage cimage={state.profiles.image} callBack={onClickToImageUpload} aspect={1} />
                                            /*<img src={state.profiles.image}/>*/
                                        }
                                    </Col>
                                    <Col lg={9} className="ps-3 ps-xl-5">
                                        {/* <div className='d-flex align-items-center flex-wrap pb-3 pb-lg-4 mt-3 mt-lg-0'> */}
                                            {/* <div className='text-green fs-13px me-3'><b>Username: </b>{state.user ? state.user.username : ''}</div>
                                            <div className='text-green fs-13px'><b>Email: </b>{state.profiles ? state.profiles.email : ''}</div> */}
                                        {/* </div> */}
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <b><Label text="Username:" /></b>
                                                    <Controller
                                                        name="username"
                                                        control={control}
                                                        defaultValue = {''}
                                                        render={({ field: { value, onChange } }) => {

                                                        return (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    name="username"
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        if(e.target.value === userData.username) {
                                                                            setValue('type', false, { shouldValidate: true })
                                                                        } else {
                                                                            setValue('type', true, { shouldValidate: true })
                                                                        }
                                                                        onChange(e)
                                                                    }}
                                                                    className="cm_input"
                                                                    required
                                                                />
                                                                {errors.username && <ErrorLabel
                                                                    msg={errors.username && [`Please enter a "username".`, `"username" is required`].includes(errors.username.message)?"Username is required.":"It should not be start from 0x and allow only alphanumeric values"}
                                                                />}
                                                            </>
                                                        )}}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <b><Label text="Email:" /></b>
                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        defaultValue = {''}
                                                        render={({ field: { value, onChange } }) => (
                                                            <>
                                                                <input
                                                                    name="email"
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e)
                                                                    }}
                                                                    className="cm_input"
                                                                    required
                                                                />
                                                                {errors.email && <ErrorLabel
                                                                    msg={errors.email && errors.email.message}
                                                                />}
                                                            </>
                                                        )}
                                                    />
                                                    {getValues('email') && getValues('email') !== userData.temp_email && userData.temp_email!==null && userData.temp_email !=='' && (!userData.isemailverified || userData.isemailverified === '0' || userData.isemailverified === '1') && <button type="button" className="btn btn-green px-4" onClick={onClickToVerifyEmail}>Verify Email</button>}
                                                    {(userData.temp_email === null || userData.temp_email === undefined || userData.temp_email === "") && getValues('email') && getValues('email') !== userData.email && (userData.isemailverified===null || !userData.isemailverified || userData.isemailverified === '0' || userData.isemailverified === '1') && <button type="button" className="btn btn-green px-4" onClick={onClickToVerifyEmail}>Verify Email</button>}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <b><Label text="First Name:" /></b>
                                                    <Controller
                                                        name="first_name"
                                                        control={control}
                                                        defaultValue = {''}
                                                        render={({ field: { value, onChange } }) => (
                                                            <>
                                                                <input
                                                                    name="first_name"
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e)
                                                                    }}
                                                                    className="cm_input"
                                                                    required
                                                                />
                                                                {errors.first_name && <ErrorLabel
                                                                    msg={"Firstname is required."}
                                                                />}
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <b><Label text="Last Name:" /></b>
                                                    <Controller
                                                        name="last_name"
                                                        control={control}
                                                        defaultValue = {''}
                                                        render={({ field: { value, onChange } }) => (
                                                            <>
                                                                <input
                                                                    name="last_name"
                                                                    type="text"
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e)
                                                                    }}
                                                                    className="cm_input"
                                                                    required
                                                                />
                                                                {errors.last_name && <ErrorLabel
                                                                    msg={"Lastname is required."}
                                                                />}
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <b><Label text="Profession:" /></b>
                                                    {state.profiles && <Controller
                                                        name="industry_id"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <>
                                                                <select
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e)
                                                                    }}
                                                                    className="cm_input"
                                                                    dangerouslySetInnerHTML={{ __html: state.profiles.profession }}>
                                                                </select>
                                                                {errors.industry_id && <ErrorLabel
                                                                    msg={"Industry is required!"}
                                                                />}
                                                            </>
                                                        )}
                                                    />}

                                                </div>
                                            </Col>
                                            <Col md={12} className="mt-2" >
                                                <button type="button" /*disabled={!isDirty}*/ className="btn btn-green px-4" onClick={handleSubmit(onClickToUpdatePersonal)}>Update</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        </Tab>
                        <Tab eventKey="Password" title="Password">
                            <form>
                                <Row>
                                    <Col lg={10}>
                                        <Row className='password_sec'>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <b><Label text="Password:" /></b>
                                                    <Controller
                                                        name="password"
                                                        control={cpcontrol}
                                                        defaultValue = {''}
                                                        render={({ field: { value, onChange } }) => (
                                                            <>
                                                                <input
                                                                    name="password"
                                                                    type="password"
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e)
                                                                    }}
                                                                    className="cm_input"
                                                                    required
                                                                />
                                                                {cperrors.password && <ErrorLabel
                                                                    msg={"Password is required."}
                                                                />}
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <b><Label text="Repeat Password:" /></b>
                                                    <Controller
                                                        name="repassword"
                                                        control={cpcontrol}
                                                        defaultValue = {''}
                                                        render={({ field: { value, onChange } }) => (
                                                            <>
                                                                <input
                                                                    name="repassword"
                                                                    type="password"
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e)
                                                                    }}
                                                                    className="cm_input"
                                                                    required
                                                                />
                                                                {cperrors.repassword && <ErrorLabel
                                                                    msg={cperrors.repassword.message}
                                                                />}
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                <button type="button" disabled={!cpisDirty} onClick={
                                                    cphandleSubmit(onClickToChangePassword)} className="btn btn-green" >Change Password</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        </Tab>
                        <Tab eventKey="Brief" title="Brief Description" className='brif_des' >
                            <Row>
                                <Col lg={10}>
                                    <b><Label text="Write a short description about yourself. This will be used as your abbreviated Author Box:" /></b>
                                    <Controller
                                        name="short_description"
                                        control={dccontrol}
                                        render={({ field: { value, onChange } }) => (
                                            <>
                                                <textarea
                                                    rows="3"
                                                    className='cm_input'
                                                    value={value}
                                                    onChange={(e) => onChange(e)}
                                                    placeholder='Max 140 Characters.'
                                                >
                                                </textarea>
                                                {dcerrors.short_description && <ErrorLabel
                                                    msg={"Short Description is required."}
                                                />}
                                                <div className='text-end green_color fs-14px'>{value?value.length:0}</div>
                                            </>
                                        )}
                                    />
                                </Col>
                                <Col lg={10}>
                                    <b><Label text="Describe yourself in 200 words. This will populate your full Author Box below your published articles:" /></b>
                                    <Controller
                                        name="description"
                                        control={dccontrol}
                                        render={({ field: { value, onChange } }) => (
                                            <>
                                                <textarea
                                                    rows="3"
                                                    className='cm_input'
                                                    value={value}
                                                    onChange={(e) => onChange(e)}
                                                    placeholder='Max 140 Characters.'
                                                >
                                                </textarea>
                                                {dcerrors.description && <ErrorLabel
                                                    msg={"Description is required."}
                                                />}
                                                <div className='text-end green_color fs-14px'>{value ? value.length : 0}</div>
                                            </>
                                        )}
                                    />
                                </Col>
                            </Row>
                            <button className="btn btn-green" disabled={!dcisDirty} onClick={dchandleSubmit(onClickToUpdateDescription)}>Update Profile Description</button>
                        </Tab>
                        <Tab eventKey="Account" title="Customize your Account">
                            <form>
                                <Row>
                                    <Col lg={10}>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <b><Label text="Background Image:" /></b>
                                                    <input type="file" name="file" className="form-control" />
                                                    <small className='text-green fs-13px'>Choose an Image to Represent You, State Your Location, Get Paid.</small>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <b><Label text="City:" /></b>
                                                    <Controller
                                                        name="city"
                                                        defaultValue = {''}
                                                        control={cacontrol}
                                                        render={({ field: { value, onChange } }) => (
                                                            <>
                                                                <input
                                                                    name="city"
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={value}
                                                                    onChange={(e) => onChange(e)}
                                                                    placeholder=''
                                                                />
                                                                {caerrors.city && <ErrorLabel
                                                                    msg={caerrors.city || caerrors.city}
                                                                />}
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <b><Label text="Country:" /></b>
                                                    <Controller
                                                        name="state"
                                                        control={cacontrol}
                                                        defaultValue = {''}
                                                        render={({ field: { value, onChange } }) => (
                                                            <>
                                                                <input
                                                                    name="state"
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={value}
                                                                    onChange={(e) => onChange(e)}
                                                                    placeholder=''
                                                                />
                                                                {caerrors.country && <ErrorLabel
                                                                    msg={caerrors.country || caerrors.country}
                                                                />}
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <b><Label text="Paypal Username:" /></b>
                                                    <Controller
                                                        name="paypal"
                                                        control={cacontrol}
                                                        defaultValue = {''}
                                                        render={({ field: { value, onChange } }) => (
                                                            <>
                                                                <input
                                                                    name="paypal"
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={value}
                                                                    onChange={(e) => onChange(e)}
                                                                    placeholder=''
                                                                />
                                                                {caerrors.paypal && <ErrorLabel
                                                                    msg={caerrors.paypal || caerrors.paypal}
                                                                />}
                                                            </>
                                                        )}
                                                    />

                                                    {state.profiles && state.profiles.billing_agreementId && <Button variant='link' className='text-green p-0 text-decoration-none fs-13px'>Enable Future Payments</Button>}
                                                </div>
                                            </Col>
                                            <Col><button type="button" disabled={!caisDirty} onClick={cahandleSubmit(onClickToUpdateCustomizeAccount)} className="btn btn-green px-4" >Update</button></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        </Tab>
                        <Tab eventKey="Social" title="Social" >
                            <Row>
                                <Col md={6}>
                                    <div className='table-responsive'>
                                        <table className="social-loginprofiles table-bordered w-100 ">
                                            <thead>
                                                <tr>
                                                    <th className="green_color">Connect Your Social Accounts  (optional) </th>
                                                    <th className="green_color">Account</th>
                                                    <th className="green_color">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody><tr>
                                                <td data-title="Provider">
                                                    <span className="social-badge ">
                                                        <i className="bi bi-linkedin"></i>
                                                        LinkedIn </span>
                                                </td>
                                                <td data-title="Account">{state.social ? state.social.li : ''}</td>
                                                <td className="profile-actions">
                                                    <a className="btn btn-green w-100 text-center" href="#/">{(state.social && state.social.li) ? 'Unlink' : 'Link'}</a>
                                                </td>
                                            </tr>
                                                <tr>
                                                    <td data-title="Provider">
                                                        <span className="social-badge "><span><i className="bi bi-twitter"></i>
                                                        </span>Twitter </span></td>
                                                    <td data-title="Account" className='green_color fs-14px'>{state.social ? state.social.tw : ''}</td>
                                                    <td className="profile-actions">
                                                        <a className="btn btn-green w-100 text-center" href="#/">{(state.social && state.social.tw) ? 'Unlink' : 'Link'}</a>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="account" title="Account" className='brif_des'>
                            <Row>
                                <Col md={6}>
                                    {!userData.user_wallet_address &&
                                        <button className="btn btn-outline-black crypto-connect" onClick={() => connectweb3('metamask')}><img alt="" src={MetamaskIcon} />Connect With Metamask</button>}
                                    {userData.user_wallet_address && <>
                                        <button className="btn btn-outline-black crypto-connect" onClick={(e) => onClickToDisconnect(e, 'metamask')}><img alt="" src={MetamaskIcon} />Disconnect With Metamask</button>
                                        <br /><br />
                                    </>}
                                    {userData.user_wallet_address && <>
                                        <h4>Metamask Wallet Address is {ellipseAddress(userData.user_wallet_address)}
                                            <br />
                                        </h4>
                                    </>}
                                    {/* userData.coinbase_wallet_address */}
                                    </Col>
                                <Col md={6} className='mt-3 mt-md-0'>
                                    {!userData.coinbase_wallet_address && 
                                        <button className="btn btn-outline-black crypto-connect" onClick={() => {
                                            // setSelectWallet('coinbase')
                                            connectweb3('Coinbase')
                                        }}><img src={CoinbaseIcon} alt="" style={{width: "27px"}}/>Connect With Coinbase</button>
                                    }
                                    {userData.coinbase_wallet_address &&
                                        <>
                                            <button className="btn btn-outline-black" onClick={(e) => onClickToDisconnect(e, 'coinbase')}><img src={CoinbaseIcon} style={{width: "27px", marginRight:"10px"}} alt=""/>Disconnect With Coinbase</button>
                                            <br/><br/>
                                        </>
                                    }
                                    {userData.coinbase_wallet_address && <>
                                        <h4 className='wallet_address'>Coinbase Wallet Address is {ellipseAddress(userData.coinbase_wallet_address)}
                                        <br />
                                        </h4>
                                    </>}
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </Container >
    </>)
}
export default MyAccount;