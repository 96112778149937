import React, { useState, useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import { Checkbox, Label } from '../components/Typography';
import { ChromePicker } from 'react-color'
import { useHistory } from 'react-router-dom';
import { articleRequestCustomize } from '../action/article.action';

const Customize = () => {
    const history = useHistory();
    
    const [setColor] = useState("#337ab7")
    const [state, setState] = useState({articlerequest: {}});
    
    const onRetriveCustomize = async () => {
        const {data} = await articleRequestCustomize(history.location.search);
        if(data.status === 200) {
            setState(data.data);
        }
    }
        
    useEffect(() => {
        onRetriveCustomize()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    
    const handleChangeColor = (color) => {
        const {articlerequest} = state;
        articlerequest['author_box_color'] = color.hex;
        setState({...state, articlerequest});
    }
    
    const [ColorPicker, setColorPicker] = useState(false)
    
    const handleClick = () => {
        setColorPicker(!ColorPicker)
    };
    
    const handleClose = () => {
        setColorPicker(false)
    };

    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
        
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }
    
    const onChangeCheckbox = (e) => {
        const {articlerequest} = state;
        articlerequest[e.target.value] = !articlerequest[e.target.value];
        setState({...state, articlerequest});
    }

    const onChangInput = (e) => {
        const {articlerequest} = state;
        articlerequest[e.target.name] = e.target.value;
        setState({...state, articlerequest});
    }

    const onClickToSave = async () => {
        const {articlerequest} = state;
        const formData = new FormData();
        Object.keys(articlerequest).map(res => formData.append(res, articlerequest[res]))

        const {data} = await articleRequestCustomize(history.location.search, formData);
        if(data.status === 200) {
            onRetriveCustomize()
        }
    }

    return (<>
        <div className='text-green text-center fs-md-26px fs-20px pt-5'>Customize Article Request</div>
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <form>
                    <div className='mb-2'>
                        <Checkbox text="Copy protection" onClick={onChangeCheckbox} value="copy_protection" checked={state.articlerequest?state.articlerequest.copy_protection:false} name="customize" />
                    </div>
                    <div className='mb-2'>
                        <Checkbox value="append_author_bio" onClick={onChangeCheckbox} checked={state.articlerequest?state.articlerequest.append_author_bio:false} text="When user copy/pastes, add my author bio to the copied content" name="customize" />
                    </div>
                    <div className='w-200px mb-3'>
                        <Label text="Author box color" />
                        <input type="text" onClick={handleClick} style={{ borderColor: state.articlerequest?state.articlerequest.author_box_color:'' }} value={state.articlerequest?state.articlerequest.author_box_color:''} name="color" onChange={(e) => setColor(e.target.value)} className='cm_input cm_colorInput' />
                        {ColorPicker && <div style={popover}>
                            <div style={cover} onClick={handleClose} />
                            <ChromePicker
                                color={(state.articlerequest && state.articlerequest.author_box_color)?state.articlerequest.author_box_color:''}
                                onChange={handleChangeColor} />
                        </div>}
                    </div>
                    <div className='mb-2'>
                        <Checkbox text="LinkedIn Share" onClick={onChangeCheckbox} value="social_share_linkedin" checked={state.articlerequest?state.articlerequest.social_share_linkedin:false} name="customize" />
                    </div>
                    <div className='mb-2'>
                        <Checkbox text="Twitter Share" onClick={onChangeCheckbox} value="social_share_twitter" checked={state.articlerequest?state.articlerequest.social_share_twitter:false} name="customize" />
                    </div>
                    <div className='w-200px mb-3'>
                        <Label text="Share buttons position" />
                        <select className='cm_input' name="share_buttons_position" onChange={onChangInput} value={state.articlerequest?state.articlerequest.share_buttons_position:''}>
                            <option value="bottom_left">Bottom Left</option>
                            <option value="bottom_center">Bottom Center</option>
                            <option value="bottom_right">Bottom Right</option>
                            <option value="top_left">Top Left</option>
                            <option value="top_center">Top Center</option>
                            <option value="top_right">Top Right</option>
                        </select>
                    </div>
                    <div className='mb-2'>
                        <Checkbox text="Show mini author box" onClick={onChangeCheckbox} value="show_mini_author_box" checked={state.articlerequest?state.articlerequest.show_mini_author_box:false} name="customize" />
                    </div>
                    <div className='mb-2'>
                        <Checkbox text="Show author box" onClick={onChangeCheckbox} value="show_author_box" checked={state.articlerequest?state.articlerequest.show_author_box:false} name="customize" />
                    </div>
                    <div className='mb-2'>
                        <Checkbox text="Open external links in new tab" onClick={onChangeCheckbox} value="external_links_new_tab" checked={state.articlerequest?state.articlerequest.external_links_new_tab:false} name="customize" />
                    </div>
                    <div className='mb-2'>
                        <Checkbox text="Show adblock warning" onClick={onChangeCheckbox} value="show_adblock_warning" checked={state.articlerequest?state.articlerequest.show_adblock_warning:false} name="customize" />
                    </div>

                    <Button variant='green' onClick={onClickToSave}>Save</Button>
                </form>
            </div>
        </Container>
    </>)
}

export default Customize