import React, {useState, useContext} from 'react';
import { Button, Modal } from 'react-bootstrap'
import { Label } from '../Typography'
import { getAllDomains } from '../../action/article.action';
import { getmutualcodePost } from '../../action/request.action';
import { GlobalContext } from '../../context/globalContext';
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../../Utils/AppUtill';
import ErrorLabel from '../../widgets/ErrorLable';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import toast from '../../helpers/toast';
import Joi from 'joi'

export const accessUrl = process.env.REACT_APP_ACCESS_URL;

const modalSubscriberCode = ({ image, http_host, article_description, subscribe_id }) => {
    http_host = http_host.toString().replace('api.', '');
    let output = [];
    output.push('<div class="cm_description">');
    if(image) {
        output.push(`<img src="${image}">`);
    }
    output.push(article_description,
        '</div>',
        '<script src="https://access.'+http_host+'/embed.js?subscriberID=' + subscribe_id + '"></script>',
        '<noscript>',
        '<iframe src="https://access.'+http_host+'/iframe.html?subscriberID=' + subscribe_id + '"></iframe>',
        '</noscript>')
    return output.join("\n");
}

export const GetMutualCodeComponent = ({disabled = false, onCallBack, article_id, smart_contract_payment}) => {

    const { isLoggedIn, setSignIn } = useContext(GlobalContext);
    const [showModal, setShowModal] = useState(false);
    const [state, setState] = useState({});
    const [domains, setDomains] = useState([]);
    
    const {
        handleSubmit,
        formState: {errors},
        control,
        reset,
        getValues
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                user_domains: Joi
                    .string().allow(null, ''),
                domain: Joi.string()
                    .required()
                    .label('domain')
                    .messages(joiUpdatedMessage),
                introduction: Joi.string()
                    .required()
                    .label('introduction')
                    .messages(joiUpdatedMessage)
            }),
        ),
    })

    const onRetriveAllDemains = async () => {
        const { data } = await getAllDomains();
        if(data.status === 200) {
            setDomains(data.data.domains)
        }
    }

    const onClickToCloseModal = () => {
        setShowModal(false)
        onCallBack()
    }

    const onClickToOpenModal = () => {
        if(!isLoggedIn) {
            setSignIn(true);
        } else {
            onRetriveAllDemains()
            setShowModal(true)
        }
    }

    const onChangeInput = (e) => {
        setState({...state, [e.target.name]: e.target.value})
    }

    const onChangeSelectOption = (e) => {
        const find = domains.find(r => r.id === e.target.value)
        if(find) {
            setState({...state, user_domains: e.target.value, ...find})
        }
        
        reset({
            ...getValues(),
            user_domains: e.target.value,
            domain: find.domain,
            introduction: find.introduction
        })
    }
    
    const onClickToSubmit = async (e) => {
        const formData = new FormData();
        formData.append('domain', state.domain);
        formData.append('introduction', state.introduction);
        if(state.user_domains) {
            formData.append('user_domains', state.user_domains);
        }
        formData.append('id', article_id);
        formData.append('article_id', article_id);
        const {data} = await getmutualcodePost(formData);
        if(data.status === 200) {
            reset({})
            // setShowModal(false)
            setState({...state, articlerequest: data.data.articlerequest});
        }
    }
    
    return (<>
        <Button variant='blue' disabled={disabled} onClick={onClickToOpenModal}>Get Mutual-Code™</Button>
        <Modal show={showModal} onHide={onClickToCloseModal} size="xl" centered>
            <Modal.Header className='border-0 py-0' closeButton>
                {/* <div className='fs-20px text-green fs-md-24px'>Bookmark This Listing</div> */}
            </Modal.Header>
            <Modal.Body>
                {!state.articlerequest && <form className='row'>
                    <div className='col-lg-8 mb-3'>
                        <Label text="Domain to repost on*" />
                        <Controller
                            name="domain"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="domain" 
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                    />
                                    {errors.domain && <ErrorLabel
                                        msg={"Domain is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>
                    {domains.length > 0 && <div className='col-lg-5 mb-3'>
                        <Controller
                            name="user_domains"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <select 
                                        name="user_domains" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeSelectOption(e)
                                        }} 
                                        className="cm_input"
                                    >
                                        <option value="">Previously submitted domains</option>
                                        {domains && domains.map((r, idx) => <option key={idx + 1} value={r.id}>{r.domain}</option>)}
                                    </select>
                                    {errors.user_domains && <ErrorLabel
                                        msg={"User Domain is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>}
                    <div className='col-lg-12 mb-3'>
                        <Label text="Introduction (500 words max)" />
                        <Controller
                            name="introduction"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <textarea 
                                        rows="5" 
                                        name="introduction" 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className='cm_input' 
                                        value={value}
                                    ></textarea>
                                    {errors.introduction && <ErrorLabel
                                        msg={"Introduction is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>
                    <div className='col-lg-12 mb-12'>&nbsp;</div>
                    <div className='col-lg-12'>
                        <Button variant='green' onClick={handleSubmit(onClickToSubmit)}>Submit</Button>
                    </div>
                </form>}
                {state.articlerequest && state.articlerequest!==undefined && <>
                    <textarea className='cm_input' rows="10" spellCheck="false" defaultValue={modalSubscriberCode(state.articlerequest)}></textarea>
                    <br />
                    <CopyToClipboard text={modalSubscriberCode(state.articlerequest)}>
                        <Button variant='link' onClick={() => toast.success("Successfully Copied.")} className='text-green p-0 text-decoration-none fs-13px'>Copy to clipboard</Button>
                    </CopyToClipboard>
                </>}
            </Modal.Body>
        </Modal>
    </>)
}
