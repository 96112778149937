import axios from 'axios';
import toast from "../helpers/toast"

const responseToast = (response, message = false) => {
    let text = '';
    if(response && response.status !== 200 && response.data.error) {
        text = response.data.error;
    } else if (response && response.status !== 200 && response.data.data.message) {
        text = response.data.data.message;
    } else if (message) {
        text = message;
    }
    
    if(text) {
        toast.error(text);
    }
}

axios.interceptors.request.use(config => {
    const token = window.localStorage.token;
    if(config.data && config.data.lt) {
        document.querySelector('.loader').style.setProperty('display', 'none', 'important');
    } else {
        document.querySelector('.loader').style.setProperty('display', 'block', 'important');
    }

    if(token) 
        config.headers.Authorization = token;

    return config;
    
}, error => {
    return Promise.reject(error);
})

axios.interceptors.response.use(response => {
    document.querySelector('.loader').style.setProperty('display', 'none', 'important');
    responseToast(response);
    return response;
}, error => {
    document.querySelector('.loader').style.setProperty('display', 'none', 'important');
    responseToast(error.response, error.message);
    if(error.response !== undefined && error.response.status === 401) {
        window.localStorage.removeItem('token');
        const {pathname, search} = window.location
        if(pathname === '/') {
            window.location = `/user/login`;
        } else {
            window.location = `/user/login?return=${pathname}${search}`;
        }
    }
    
    return Promise.reject(error);
})

export default axios;