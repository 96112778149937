import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import Element from './Element'
import Leaf from './Leaf'
import BlockButton from './BlockButton'
import MarkButton from './MarkButton'
import { withImages, editorToHTML, withInlines, withHtml, withVideos } from './utils'
import { withHistory } from 'slate-history'
import Toolbar from './Toolbar'
import "./style.scss"
import AddLinkButton from './AddLinkButton'

const CMEditor = ({ save, content }) => {
  const [value, setValue] = useState(content ?? initialValue)
  const editor = useMemo(() => withHtml(withInlines(withVideos(withImages(withHistory(withReact(createEditor())))))), [])
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  useEffect(() => {
    save(value, editorToHTML({ children: value }))
    // eslint-disable-next-line
  }, [])
  
  return (
    <Slate editor={editor} value={value} onChange={value => {
      setValue(value)
      save(value, editorToHTML({ children: value }))

    }}>
      <Toolbar>
        <MarkButton format="bold" icon="bold" />
        <MarkButton format="italic" icon="italic" />
        <MarkButton format="underline" icon="underline" />
        <MarkButton format="code" icon="code" />
        <BlockButton format="heading-one" text="H1" />
        <BlockButton format="heading-two" text="H2" />
        <BlockButton format="block-quote" icon="quote" />
        <BlockButton format="numbered-list" icon="numbered-list" />
        <BlockButton format="bulleted-list" icon="bulleted-list" />
        <BlockButton format="add-image" icon="image" />
        <BlockButton format="add-video" icon="video" />
        <AddLinkButton />
        <BlockButton format="the-conversation-embed" icon="compass" />
        {/* old insert image button
          <InsertImageButton />
        */}
        {/*<InsertNFTButton />*/}
      </Toolbar>
      <Editable
        placeholder="Enter some plain text..."
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        id="cm-editor"
      />
    </Slate>
  )
}

const initialValue = [
  {
    type: 'heading-two',
    children: [
      { text: 'Sample Subheading' },
    ],
  },
  {
    type: 'paragraph',
    children: [
      { text: 'This is editable plain text!' },
    ],
  },
  {
    type: 'image',
    url: 'https://source.unsplash.com/kFrdX5IeQzI',
    children: [{ text: '' }],
  },
  {
    type: 'block-quote',
    children: [
      { text: 'This is editable blockquote' },
    ],
  },
  {
    type: 'link',
    url: 'https://www.google.com',
    children: [
      {
        text: 'Test link'
      }
    ]
  }
]

export default CMEditor;