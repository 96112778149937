import React from 'react';
import { Spinner } from 'react-bootstrap';
import logo from '../../assets/images/CM_120.png'

export const ConformationComponent = () => {
    return (<>
        <div className="successfullyMessage">
            <header className="text-center">
                <img src={logo} alt="" />
                <h1 className='text-white fw-400 fs-24px py-4'>
                    Article successfully saved
                </h1>
                <Spinner animation="border" className="text-blue" variant="light" />
            </header>
        </div>
    </>)
}