import axios from '../helpers/axios';
import { APIEndpoints } from './endpoint'

export const login = (data) => {
  return axios.post(`${APIEndpoints.login}`, data);
}

export const lostPassword = (data) => {
  return axios.post(`${APIEndpoints.lostPassword}`, data);
}

export const resetPassword = (query = {}, data = {}) => {
  return axios.post(`${APIEndpoints.resetPassword}${query}`, data);
}

export const loginWithMetaMaskWlt = (data = {}) => {
  return axios.post(`${APIEndpoints.loginWithMetaMask}`, data);
}

export const disconnectWallet = (data = {}) => {
  return axios.post(`${APIEndpoints.disconnectWallet}`, data);
}

export const getNonce = (address) => {
  return axios.get(`${APIEndpoints.web3nonce}?address=` + address);
}

export const verifyEmail = (data) => {
  return axios.post(`${APIEndpoints.verifyEmail}`, data);
}

export const emailVerification = (data) => {
  return axios.post(`${APIEndpoints.emailVerification}`, data);
}

export const connectWallet = (wallet, address, signature) => {
  return axios.post(APIEndpoints.connectWallet, { wallet, address, signature });
}

export const getUserDetails = () => {
  return axios.post(APIEndpoints.getUserDetails, {});
}

export const logout = () => {
  return axios.post(APIEndpoints.logout, {});
}
