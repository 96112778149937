import React, { useState, useEffect } from 'react'
import { Container, Table, Button, Row, Col, Modal } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { Label, PageTitle } from '../components/Typography'
import { articleRevision, articlerevisionrestore, articlerevisiondelete, articleRevisionCompare } from '../action/article.action';
import { formattedDate } from '../Utils/AppUtill';
import { RTimeAgo } from '../components/RTimeAgo';
import toast from "../helpers/toast"
import Joi from 'joi'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../Utils/AppUtill';
import ErrorLabel from '../widgets/ErrorLable';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';

const RevisionsPage = () => {
    const history = useHistory();
    const [state, setState] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [compareState, setCompareState] = useState({});
    const [page, setPage] = useState(0);

    const retriveRevision = async () => {
        const {data} = await articleRevision(history.location.search);
        if(data.status === 200) {
            setState(data.data);
        }
    }
    
    const {
        handleSubmit,
        formState: {errors},
        control,
      } = useForm({
        resolver: joiResolver(
          Joi.object({
            version1: Joi.string()
              .required()
              .label('version1')
              .messages(joiUpdatedMessage),
            version2: Joi.string()
              .required()
              .label('version2')
              .messages(joiUpdatedMessage),
            content_type: Joi.string()
                .required()
                .label('content_type')
                .messages(joiUpdatedMessage),
            }),
        ),
    })

    const onClickToCloseModal = () => setShowDeleteModal(false)
    const onClickToDelete = async () => {
        const {data} = await articlerevisiondelete(deleteId)
        if(data.status === 200) {
            retriveRevision()
            onClickToCloseModal()
        }
    }

    useEffect(() => {
        let {page} = queryString.parse(history.location.search)
        if(page) {
            setPage(page - 1);
        } else {
            setPage(0);
        }
        retriveRevision();
    }, [history.location])// eslint-disable-line react-hooks/exhaustive-deps

    const onClickToRestore = async (id) => {
        const { data } = await articlerevisionrestore(id);
        if(data.status === 200) {
            retriveRevision()
            toast.success(data.data.message)
        }
    }

    const onClickToSubmit = async (values) => {
        const obj = { ...values, type: 'side_by_side', article_id: state.article.id}
        const formData = new FormData();
        Object.keys(obj).map(res => formData.append(res, obj[res]))
        
        const {data} = await articleRevisionCompare(formData);
        if(data.status === 200) {
            setCompareState(data.data)
        }
    }

    const onPageClick = (status='page', e) => {
        const {pathname, search} = history.location;
        let query = queryString.parse(search)
        if(status!=='page') {
            query = { ...query, ...status}
        } else {
            query.page = e.selected + 1;
        }
        query = queryString.stringify(query);
        history.push(`${pathname}?${query}`)
    }

    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Article Revisions" />
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <Button variant='link' onClick={() => history.push(`${state.article && state.article.public_url}?add_revision=1`)} className='fs-13px p-0 text-dark text-decoration-none hover-text-decoration-underline'><i className="fa fa-plus text-dark"></i> Revision</Button>
                <Table hover responsive striped className='cm_table shadow-none bg_lightBlue'>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Revision</th>
                            <th>Date</th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                        {state.articlerevisions && state.articlerevisions.map((res, indx) => {
                            return (<tr key={indx + 1}>
                                    <td className='w-100px'>{res.id}</td>
                                    <td><Link to={state.article.public_url?state.article.public_url:''}>{res.content}</Link></td>
                                    <td className='w-150px'>{res.created && <RTimeAgo date={formattedDate(res.created)}/>}</td>
                                    <td className='w-100px'>{res.current === '1'?'Yes':''}</td>
                                    <td className='w-150px'>
                                        <Button variant='link' className='p-0' onClick={() => onClickToRestore(res.id)}><i className="fa fa-plus text-dark"></i> Restore</Button>
                                        <Button variant='link' className='p-0' onClick={() => {
                                            setDeleteId(res.id)
                                            setShowDeleteModal(true)
                                        }} ><i className="fa fa-remove text-dark"></i> Delete</Button>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </Table>
            </div>
            {state.total_pages > 0 && <ReactPaginate
                style={{color: 'red'}}
                className="cmn-Pagination"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={(e) => onPageClick('page', e)}
                pageRangeDisplayed={5}
                pageCount={state.total_pages?state.total_pages:0}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                forcePage={page}
            />}
            <div className='fs-xl-24px fs-20px text-green mt-5'>Compare your revision</div>
            <Row>
                <Col md={3} className='mb-3'>
                    <Label text="Select Revision 1:" />
                    <Controller
                        name="version1"
                        control={control}
                        render={({field: {value, onChange}}) => (
                            <>
                                <select 
                                    className="cm_input h-45px arrow"
                                    value={value}
                                    onChange={onChange}
                                >
                                    <option value="">Choose Option</option>
                                    {state.articlerevisions && state.articlerevisions.map((res, indx) => (<>
                                        <option key={indx + 1} value={res.id}>{res.id}</option>
                                    </>))}
                                </select>
                                {errors.version1 && <ErrorLabel
                                    msg={"Version1 is required."}
                                />}
                            </>
                        )}
                    />
                </Col>
                <Col md={3} className='mb-3'>
                    <Label text="Select Revision 2:" />
                    <Controller
                        name="version2"
                        control={control}
                        render={({field: {value, onChange}}) => (
                            <>
                                <select 
                                    className="cm_input h-45px arrow"
                                    value={value}
                                    onChange={onChange}
                                >
                                    <option value="">Choose Option</option>
                                    {state.articlerevisions && state.articlerevisions.map((res, indx) => (<>
                                        <option key={indx + 1} value={res.id}>{res.id}</option>
                                    </>))}
                                </select>
                                {errors.version2 && <ErrorLabel
                                    msg={"Version2 is required."}
                                />}
                            </>
                        )}
                    />
                </Col>
                <Col md={3} className='mb-3'>
                    <Label text="Select content type:" />
                    <Controller
                        name="content_type"
                        control={control}
                        render={({field: {value, onChange}}) => (
                            <>
                                <select 
                                    className="cm_input h-45px arrow" 
                                    value={value}
                                    onChange={onChange}
                                >
                                    <option value="">Choose Option</option>
                                    <option value="text">Text</option>
                                    <option value="html">Html</option>
                                </select>
                                {errors.content_type && <ErrorLabel
                                    msg={"Content Type is required."}
                                />}
                            </>
                        )}
                    />
                </Col>
                <Col md={3} className='mb-3'>
                    <Label text="" />
                    <Button variant='green' onClick={handleSubmit(onClickToSubmit)}>Compare</Button>
                </Col>
                <Col md={12} className='mb-12'>
                    <h2 className='text-green'>{compareState.type}</h2>
                    <div dangerouslySetInnerHTML={{ __html: compareState.content }}></div>
                </Col>
            </Row>
        </Container>
        <Modal show={showDeleteModal} onHide={onClickToCloseModal}>
            <Modal.Header>
            <Modal.Title>Delete Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={onClickToCloseModal}>
                Close
            </Button>
            <Button variant="primary" onClick={onClickToDelete}>
                Okay
            </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default RevisionsPage