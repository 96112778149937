import { useState } from 'react';
import { useSlateStatic, ReactEditor } from 'slate-react'
import { insertVideo } from './utils'
import { FormControl, Row, Col } from "react-bootstrap"
import { Transforms } from "slate"

const AddVideo = ({ element }) => {
    const editor = useSlateStatic()
    const path = ReactEditor.findPath(editor, element)

    const [url, setUrl] = useState('');
    
    const onChange = (e) => {
        setUrl(e.target.value);
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13) { // enter
            Transforms.removeNodes(editor, { at: path })
            insertVideo(editor, url, [{ type: 'paragraph', children: [{ text: 'Add video description' }], video_description: true }], path)
        }
    }

    return <div contentEditable={false} className="my-4 col-md-6 col-sm-12 video-embed">
        <Row>
            <Col>
                <FormControl placeholder="Paste Youtube or Vimeo link and hit enter" onChange={onChange} onKeyDown={onKeyDown}/>
            </Col>
        </Row>
    </div>
}

export default AddVideo;