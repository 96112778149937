import React from 'react'
import { Highlight } from 'react-instantsearch-dom';
import * as moment from 'moment'
import { useHistory } from 'react-router';

const apiUrl = process.env.REACT_APP_API_URL;

export const ArticleList = ({ hit }) => {
    const history = useHistory();

    const getImageUrl = (path) => {
        if(path.includes(window.location.origin)) {
            return path;   
        }
        return apiUrl + "/thumb?src=" + path + "&w=120&h=120"
    }
    // const yourDate = hit.created
    // console.log('yourDate', yourDate)
    
    return (<div className='row align-items-start bg_white  mb-2 rounded-2 pb-4 pt-lg-0 pt-3 '>
        <div className='col-lg-2'>
            <img src={getImageUrl(hit.image)} className='rounded-circle' alt="" />
        </div>
        <div className='col-lg-8 border-right'>
            <h2>
                <a href="#/" onClick={() => history.push(hit.public_url)}><Highlight attribute={"name"} hit={hit} /></a>
            </h2>
            <div className="detailsinfo">
                <Highlight attribute={"description"} hit={hit} />
            </div>
            <div className="date-apps">
                <span><strong className='green_color'>Category: </strong><a href="#/">{hit.category_level_1}</a></span>
                &nbsp; | &nbsp;
                <span><strong className='green_color'>Sub Category: </strong><a href="#/">{hit.category_level_2}</a></span>
                &nbsp; | &nbsp;
                <span><strong className='green_color'>Date: </strong>{moment(hit.created * 1000).format('DD MMM YYYY')}</span>
                &nbsp; | &nbsp;
                <span><strong className='green_color'>Reprints: </strong>{hit.applications}</span>
                &nbsp; | &nbsp;
                <span><strong className='green_color'>Social Shares: </strong>{hit.social_shares}</span>
            </div>
        </div>
        <div className='col-lg-2 price_tag'>
            <strong className='green_color mr-1'>Price:</strong>
            &nbsp;${hit.price}
        </div>

    </div>)
    // return <pre>{JSON.stringify(hit, null, 2)}</pre>
}
