import React, { useState, useEffect } from 'react'
import { Container, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PageTitle } from '../components/Typography'
import { useHistory } from 'react-router-dom'
import { getInvoice, payInvoice } from '../action/billing.action'
import toast from "../helpers/toast"

const Invoice = () => {

    const history = useHistory();
    const [state, setState] = useState({});

    const onRetriveInvoice = async () => {
        const { data } = await getInvoice(history.location.search);
        if(data.status === 200) {
            setState(data.data);
        }
    }

    useEffect(() => {
        onRetriveInvoice();
    }, [history.location])// eslint-disable-line react-hooks/exhaustive-deps

    const onClickToPay = async () => {
        const {data} = await payInvoice(state.invoice_id)
        if(data.status === 200) {
            toast.success("Payment sent successfully.");
        }
    }

    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Invoice" />
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <Table bordered hover responsive className='cm_table'>
                    <thead>
                        <tr>
                            <th>Article</th>
                            <th>Views</th>
                            <th>Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.articles && state.articles.map((res, indx) => {
                            return (<tr key={indx + 1}>
                                <td><Link to={res.public_url?res.public_url:''}>{res.name}</Link></td>
                                <td>{res.count}</td>
                                <td>${res.cost}</td>
                            </tr>)
                        })}
                        <tr>
                            <td colSpan={2}><b>Total</b> {parseInt(state.paid) === 0 && <a href="#/" onClick={onClickToPay}>Pay</a>}</td>
                            <td><b>${state.invoice_total}</b></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </Container>
    </>)
}

export default Invoice