import React, { useState, useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import { Checkbox } from '../components/Typography'
import { UploadImage } from '../components/UploadImage'
import {useHistory} from 'react-router-dom';
import { splittesting } from '../action/request.action';
// import { Chart } from "react-google-charts";
import ReactApexChart from 'react-apexcharts';

const SplitTesting = () => {
    
    const history = useHistory();
    
    const [file] = useState(`https://contentmutual.com/assets/front/img/noimage.gif`);
    const [state, setState] = useState({});
    
    const onRetriveSplitetesting = async () => {
        const {data} = await splittesting(history.location.search);
        if(data.status === 200) {
            const series = [];
            Object.keys(data.data.data).map(r => {
                let name = "";
                switch(r) {
                    case 'views':
                        name = "Views";
                        break;
                    case 'time_average':
                        name = "Average time per visit (seconds)";
                        break;
                    case 'time':
                        name = "Total time (seconds)";
                        break;
                    default:
                      name="";
                }
                return series.push({
                    name,
                    data: data.data.data[r].data.map(re => {
                        re[0] = new Date(re[0])
                        return re;
                    }) 
                })
            })
            data.data.data = series;
            setState(data.data);    
        }
    }
    
    useEffect(() => {
      onRetriveSplitetesting()
      // eslint-disable-next-line
    }, [])
    
    const options = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function(val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val + " (mins)"
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val + " per session"
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        }
    }

    const onSelectFile = (e, index) => {
      state.st_images[index].image = URL.createObjectURL(e.target.files[0]) 
      setState({...state})
    }

    const onDeleteFile = (index) => {
      state.st_images[index].image = ''; 
      setState({...state})
    }

    const onClickToChangeVersion = () => {
      state.version[0].isStVersion = !state.version[0].isStVersion
      setState({...state});
    }

    const onClickToSave = async () => {
      const formData = new FormData();
      state.st_images.map((res, ind) => {
        if(res.image) {
          formData.append(`image[]`, res);
        }
        return res;
      })
      
      formData.append("versions", JSON.stringify(state.version))
      const {data} = await splittesting(history.location.search, formData);
      if(data.status === 200) {
        onRetriveSplitetesting();
      }
    }

    return (<>
        <div className='text-green text-center fs-md-26px fs-20px pt-5'>Split Testing</div>
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <form>
                    <div className='fs-13px text-green mb-4'>Images:</div>
                    <div className='d-flex flex-wrap pb-4'>
                        {state.st_images && state.st_images.map((res, indx) => {
                            return (<UploadImage
                                key={indx + 1}
                                file={res.image===''?file:res.image}
                                onChange={(e) => onSelectFile(e, indx)}
                                onClick={(e) => onDeleteFile(indx)}
                            />)
                        })}
                    </div>
                    <div className='fs-13px text-green mb-4'>Versions:</div>
                    {state.version && state.version.map((res, indx) => {
                        return (<div className='mb-2' key={indx + 1}>
                                    <Checkbox text={`${res.content}`} onClick={onClickToChangeVersion} checked={res.isStVersion} name="splittesting" />
                                    {res.views && <span className='p-0 text-green fs-13px text-decoration-none'>(Views: {res.views}, Time spent: {res.time_spent}, Time spent per view: {res.time_spent_per_view}) </span>}
                                    <Button variant='link' onClick={() => history.push(`${history.location.pathname}${history.location.search}`)} className='p-0 text-green fs-13px text-decoration-none'> | Stats <i className="fa fa-plus"></i></Button>
                                </div>)
                      })}
                    <Button variant='green' onClick={onClickToSave}>Save</Button>
                    <br /><br />
                    {/* {state.data && state.data.length > 0 && <Chart
                        chartType="Line"
                        width="100%"
                        height="400px"
                        data={state.data}
                        options={options}
                    />} */}
                    {state.data && state.data.length > 0 && <ReactApexChart options={options} series={state.data} type="line" height={350} />}
                    {/* <Table bordered className='cm_tableSplitTesting w-200px cm_table shadow-none my-3'>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='cm_colorBox'>
                                        <div style={{ border: '5px solid rgb(237, 194, 64)' }}></div>
                                    </div>
                                </td>
                                <td>Views</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='cm_colorBox'>
                                        <div style={{ border: '5px solid rgb(175,216,248)' }}></div>
                                    </div>
                                </td>
                                <td>Average time per visit (seconds)</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='cm_colorBox'>
                                        <div style={{ border: '5px solid rgb(203,75,75)' }}></div>
                                    </div>
                                </td>
                                <td>Total time (seconds)</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className='d-flex justify-content-end'>
                        <div>
                            <div className='mb-2'>
                                <Checkbox text="Views" name="splittesting" />
                            </div>
                            <div className='mb-2'>
                                <Checkbox text="Average time per visit (seconds)" name="splittesting" />
                            </div>
                            <div className='mb-2'>
                                <Checkbox text="Total time (seconds)" name="splittesting" />
                            </div>
                        </div>
                    </div> */}
                </form>
            </div>
        </Container>
    </>)
}

export default SplitTesting