import React, { useState, useEffect } from 'react';
import { Modal, Container, Col, Row } from 'react-bootstrap';
import { Label } from './Typography';
import { updateArticlePrice, nftGetContractDetails } from '../action/article.action';
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../Utils/AppUtill';
import ErrorLabel from '../widgets/ErrorLable';
import toast from '../helpers/toast'
import Joi from 'joi'

const UpdatePriceModal = (props) => {

    const [show, setShow] = useState(false);
    const [stateD, setState] = useState({isContract: false});
    const onCloseModal = () => setShow(false);
    const onOpenModal = () => setShow(true);
    
    const {
        handleSubmit,
        formState: {errors},
        control,
        reset,
      } = useForm({
        resolver: joiResolver(
          Joi.object({
            starting_fee: Joi.string()
              .required()
              .label('starting_fee')
              .messages(joiUpdatedMessage),
            fee1000imp: Joi.string()
              .required()
              .label('fee1000imp')
              .messages(joiUpdatedMessage),
            fee10000imp: Joi.string()
                .required()
                .label('fee10000imp')
                .messages(joiUpdatedMessage)
          }),
        ),
    })

    const {
        handleSubmit: nftHandleSubmit,
        control: nftControl,
        reset: nftReset,
        getValues: nftGetValues
      } = useForm({
        resolver: joiResolver(
            Joi.object({
                contract: Joi.string()
                    .required()
                    .label('Contract')
                    .messages(joiUpdatedMessage),
                token: Joi.string().allow(null, ''),
            }),
        ),
    })

    useEffect(() => {
        reset({
            starting_fee: props.state.starting_fee,
            fee1000imp: props.state.fee1000imp,
            fee10000imp: props.state.fee10000imp
        })
        if(props.state.contract) {
            setState({
                ...stateD,
                ...props.state,
                isContract: true 
            })
            nftReset({
                contract: props.state.contract,
                token: props.state.token
            })
        } else {
            nftReset({
                contract: '',
                token: ''
            })
            setState({...props.state, ...stateD});
        }
    }, [props]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeInput = (e) => {
        setState({...stateD, [e.target.name]: e.target.value});
    }

    const onClickToSubmitNFT = async (values) => {
        const { contract, token } = nftGetValues();
        if(token && !contract) {
            toast.error("You can't use token without contract address.")
            return false;
        }
        
        if(stateD.isContract) {
            const formData = new FormData();
            const {contract, token} = nftGetValues();
            formData.append('starting_fee', stateD.starting_fee?stateD.starting_fee:0);
            formData.append('i1000', stateD.fee1000imp?stateD.fee1000imp:0);
            formData.append('i10000', stateD.fee10000imp?stateD.fee10000imp:0);
            formData.append('token', token?token:'');
            formData.append('contract', contract?contract:'');
            formData.append('banner_image_url', stateD.banner_image_url);
            formData.append('nft_description', stateD.nft_description);
            formData.append('external_link', stateD.external_link);
            formData.append('id', stateD.getId)
            const { data } = await updateArticlePrice('', formData);
            if(data.status === 200) {
                props.onCallBack()
                onCloseModal();
            }
        } else {
            toast.error("Your contract address is wrong.")
        }
    }

    const onUpdatePrice = async (values) => {
        const formData = new FormData();
        formData.append('starting_fee', stateD.starting_fee);
        formData.append('i1000', stateD.fee1000imp);
        formData.append('i10000', stateD.fee10000imp);
        formData.append('token', '');
        formData.append('contract', '');
        formData.append('banner_image_url', '');
        formData.append('nft_description', '');
        formData.append('external_link', '');
        formData.append('id', stateD.getId)
        const { data } = await updateArticlePrice('', formData);
        if(data.status === 200) {
            props.onCallBack()
            onCloseModal();
        }
    }
    
    const onChangeContract = async () => {
        const { contract, token } = nftGetValues();
        
        const { data } = await nftGetContractDetails(contract?contract:0, token);
        if(data.status === 200) {
            setState({...stateD, isContract: true, banner_image_url: data.data.image, nft_description: data.data.description, external_link : data.data.url})
        } else {
            setState({...stateD, isContract: false, token: '', banner_image_url: "", nft_description: "", external_link :''})
        }
    }

    return (<>
        <a href="#/" onClick={onOpenModal}><i className="fa fa-usd" />Update Price</a>
        <Modal show={show} onHide={onCloseModal} size="xl" centered >
            <Modal.Header className='border-0' closeButton>
                <div className='fs-20px text-green fs-md-24px'>Update price for: {stateD.name}</div>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <div className='mb-3'>
                        <Label text="Starting Fee" />
                        <Controller
                            name="starting_fee"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="starting_fee" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                        required
                                    />
                                    {errors.starting_fee && <ErrorLabel
                                        msg={"Starting Fee is required."}
                                    />}
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-3'>
                        <Label text="1,000 impressions" />
                        <Controller
                            name="fee1000imp"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="fee1000imp" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                        required 
                                    />
                                    {errors.fee1000imp && <ErrorLabel
                                        msg={"Fee1000imp is required."}
                                    />}
                                </>
                            )}
                        />
                        <Label text="Effective price per 1,000 views:$0" />
                    </div>
                    <div className='mb-3'>
                        <Label text="10,000 impressions" />
                        <Controller
                            name="fee10000imp"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        name="fee10000imp" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                        required 
                                    />
                                    {errors.fee10000imp && <ErrorLabel
                                        msg={"Fee10000imp is required."}
                                    />}
                                </>
                            )}
                        />
                        <Label text="Effective price per 10,000 views:$0" />
                    </div>
                    <div className='mb-3'>
                        <Label text="Contract" />
                        <Controller
                            name="contract"
                            control={nftControl}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input type="text" 
                                        name="contract" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeContract();
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className='mb-3'>
                        <Label text="Token" />
                        <Controller
                            name="token"
                            control={nftControl}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input type="text" 
                                        name="token" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                            onChangeContract();
                                            onChangeInput(e)
                                        }} 
                                        className="cm_input" 
                                    />
                                </>
                            )}
                        />
                    </div>
                    {stateD.isContract && <>
                        <div className='mb-3 text-center'>
                            <a href={stateD.external_link} rel="noreferrer" target="_blank"><img alt="" src={stateD.banner_image_url} style={{maxHeight: '300px', width: 'auto'}}/></a>
                        </div>
                        <div className='mb-3'>
                            {stateD.nft_description}
                        </div>
                    </>}
                    <Row>
                        <Col md={2} className='mb-3'>
                            {!stateD.isContract && ['', undefined, null].includes(nftGetValues('contract')) && <button type="button" onClick={handleSubmit(onUpdatePrice)} className='btn btn-dark'>Update Price</button>}
                            {(stateD.isContract || (!stateD.isContract && nftGetValues('contract'))) && <button type="button" onClick={nftHandleSubmit(onClickToSubmitNFT)} className='btn btn-dark'>Update Price</button>}
                        </Col>
                        <Col md={2} className='mb-3'>
                            <button type="button" className='btn btn-dark' onClick={onCloseModal}>Close</button>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={2} className='mb-3'>
                            <button type="button" onClick={handleSubmit(onUpdatePrice)} className='btn btn-dark'>Update Price</button>
                        </Col>
                        <Col md={2} className='mb-3'>
                            <button type="button" className='btn btn-dark' onClick={onCloseModal}>Close</button>
                        </Col>
                    </Row> */}
                </Container>
            </Modal.Body>
        </Modal >
    </>)
}

export default UpdatePriceModal;