import React, { useEffect, useState } from "react";
import { Modal, Table, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { negotiate, negotiationDelete, negotiationMessage, negotiationAccept } from "../action/request.action";
import toast from '../helpers/toast';
import Joi from 'joi'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../Utils/AppUtill';
import ErrorLabel from '../widgets/ErrorLable';
import { Label } from './Typography';

const apiUrl = process.env.REACT_APP_API_URL;

export const Negotiations = (props) => {
    
    const [state, setState] = useState({});
    const [deleteId, setDeleteId] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [parentId, setParentId] = useState(0);
    const onClickToCloseModal = () => setShowDeleteModal(false);
    const onClickToOpenModal = () => setShowDeleteModal(true);
    
    const onRetriveNegotiationList = async () => {
        const {data} = await negotiate(props.NegotiationId);
        if(data.status === 200) {
            setState(data.data);
        }
    }

    const getImageUrl = (path) => {
        if(path.includes(window.location.origin)) {
            return path;   
        }
        return apiUrl + "/thumb?src=" + path + "&w=120&h=120"
    }

    useEffect(() => {
        if(props.NegotiationId) {
            onRetriveNegotiationList(); 
        }
        // eslint-disable-next-line
    }, [props.NegotiationId])

    const onClickToCancelOffer = async () => {
        const {data} = await negotiationDelete(deleteId);
        if(data.status === 200) {
            onRetriveNegotiationList()
            onClickToCloseModal();
            toast.success(data.data.message);
        }
    }
    
    const onClickToAcceptNegotiation = async (id) => {
        const {data} = await negotiationAccept(id);
        if(data.status === 200) {
            onRetriveNegotiationList()
            onClickToCloseModal();
            toast.success(data.data.message);
        }
    }

    const onRenderMessage = (messages) => {
        return messages.map((r, ind) => (
            <React.Fragment key={ind + 1}>
                <div className="fs-13px d-flex align-items-center justify-content-between ms-3 border-bottom">
                    <div style={{left: `${r.level * 20}px`, position: 'relative'}} className="text-green d-flex align-items-center">
                        <img alt="" className="cm_img32px me-1" src={getImageUrl(r.image)} />
                        {r.message}&nbsp;&nbsp;
                        {r.isNotSameUser && <a href="#/" onClick={() => setParentId(r.id)}>Reply</a>}
                    </div>
                    <span className="opacity-50">{r.created}</span>
                </div>
                {onRenderMessage(r.child)}
            </React.Fragment>
        ))
    }

    const {
        handleSubmit,
        formState: {errors},
        control,
        reset,
      } = useForm({
        resolver: joiResolver(
          Joi.object({
            starting_fee: Joi.number()
                .required()
                .min(1)
                .label('starting_fee')
                .messages(joiUpdatedMessage),
            "1000_imp": Joi.number()
                .required()
                .min(1)
                .label('i1000')
                .messages(joiUpdatedMessage),
            "10000_imp": Joi.number()
                .required()
                .min(1)
                .label('i10000')
                .messages(joiUpdatedMessage),
            daily_cap: Joi.number()
                .required()
                .min(1)
                .label('Daily Cap')
                .messages(joiUpdatedMessage),
            message: Joi.string()
                .required()
                .label('Message')
                .messages(joiUpdatedMessage)
          }),
        ),
    })

    const {
        handleSubmit: handleSubmit1,
        formState: {errors: errors1},
        control: control1,
        reset: reset1
      } = useForm({
        resolver: joiResolver(
          Joi.object({
            message: Joi.string()
                .required()
                .label('Message')
                .messages(joiUpdatedMessage)
          }),
        ),
    })

    const onClickToSubmit = async (values) => {
        const formData = new FormData();
        Object.keys(values).map(res => formData.append(res, values[res]))
        
        const {data} = await negotiate(props.NegotiationId, formData);
        if(data.status === 200) {
            reset({ 
                'starting_fee': '',
                '1000_imp': '',
                '10000_imp': '',
                'daily_cap': '',
                'message': '',
            })
            onRetriveNegotiationList()
        }
    }
    
    const onClickToSendMessage = async (values) => {
        const formData = new FormData();
        formData.append('parent_id', parentId);
        formData.append('message', values.message);
        formData.append('negotiation_id', state.last_negotiation_id);
        formData.append('articlerequest_id', state.articlerequest.id);
        const { data } = await negotiationMessage(state.articlerequest.id, formData);
        if(data.status === 200) {
            reset1({ 
                'message': '',
            })
            setParentId(0);
            onRetriveNegotiationList()
        }
    }
    
    return (<>
        <Modal show={props.showNegotiationsModal} onHide={props.handleCloseNegotiationsModal} size="xl" centered>
            <Modal.Header className='border-0 pt-0' closeButton></Modal.Header>
            {/* {console.log("negotiation_accepted", state.articlerequest.negotiation_accepted)} */}
            <Modal.Body>
                {state.articlerequest && Object.keys(state.articlerequest).length>0 && state.articlerequest.negotiation_accepted === '0' && <Row>
                    <Col md={3} className='mb-3'>
                        <Label text="Starting Fee" />
                        <Controller
                            name="starting_fee"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input type="text" 
                                        name="starting_fee" 
                                        value={value} 
                                        placeholder="5.00"
                                        onChange={(e) => {
                                            onChange(e)
                                        }} 
                                        className="cm_input"
                                        required />
                                    {errors.starting_fee && <ErrorLabel
                                        msg={"Starting Fee is required."}
                                    />}
                                </>
                            )}
                        />
                    </Col>
                    <Col md={3} className='mb-3'>
                        <Label text="Price per 1,000 impressions" />
                        <Controller
                            name="1000_imp"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input type="text" 
                                        name="1000_imp" 
                                        value={value} 
                                        placeholder="5.00"
                                        onChange={(e) => {
                                            onChange(e)
                                        }} 
                                        className="cm_input"
                                        required />
                                    {errors['1000_imp'] && <ErrorLabel
                                        msg={"Price per 1,000 impressions is required."}
                                    />}
                                </>
                            )}
                        />
                    </Col>
                    <Col md={3} className='mb-3'>
                        <Label text="Price per 10,000 impressions" />
                        <Controller
                            name="10000_imp"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input type="text" 
                                        name="10000_imp"
                                        placeholder="5.00" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                        }} 
                                        className="cm_input"
                                        required />
                                    {errors['10000_imp'] && <ErrorLabel
                                        msg={"Price per 10,000 impressions is required."}
                                    />}
                                </>
                            )}
                        />
                    </Col>
                    <Col md={3} className='mb-3'>
                        <Label text="Daily Cap" />
                        <Controller
                            name="daily_cap"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input type="text" 
                                        name="daily_cap"
                                        placeholder="5.00" 
                                        value={value} 
                                        onChange={(e) => {
                                            onChange(e)
                                        }} 
                                        className="cm_input"
                                           required />
                                    {errors.daily_cap && <ErrorLabel
                                        msg={"Daily Cap is required."}
                                    />}
                                </>
                            )}
                        />
                    </Col>
                    <Col md={12} className='mb-3'>
                        <Label text="Message" />
                        <Controller
                            name="message"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input type="text" 
                                        name="message" 
                                        value={value} 
                                        placeholder="Your message about the negotiation"
                                        onChange={(e) => {
                                            onChange(e)
                                        }} 
                                        className="cm_input"
                                        required />
                                    {errors.message && <ErrorLabel
                                        msg={"Massage is required."}
                                    />}
                                </>
                            )}
                        />
                    </Col>
                    <Col md={12} className='mb-3'>
                        <Button variant="green" onClick={handleSubmit(onClickToSubmit)}>
                            Submit
                        </Button>
                    </Col>
                </Row>}
                <Table hover responsive striped className='cm_tableBlue'>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>User</th>
                            <th>Starting Fee</th>
                            <th>1,000 Impressions</th>
                            <th>10,000 Impressions</th>
                            <th>Daily Cap</th>
                            <th>Created</th>
                            <th>Action</th>
                        </tr>
                        {state.negotiationhistory && state.negotiationhistory.map((res, indx) => (<React.Fragment key={indx + 1}><tr>
                            <td>{res.id}</td>
                            <td>
                                {res.isSameUser && <>You</>}
                                {!res.isSameUser && <Link to={res.author_url?res.author_url:''}>{res.print_name}</Link>}
                            </td>
                            <td>{res.startingFee}</td>
                            <td>{res.fee1000Imp}</td>
                            <td>{res.fee10000Imp}</td>
                            <td>{res.daily_cap}</td>
                            <td>{res.created}</td>
                            <td>
                                {res.isNagotiationAccepted && <a href="#/" onClick={() => onClickToAcceptNegotiation(res.id)}>Accept Offer</a>}
                                {res.isNotNagotiationAccepted && <a href="#/" onClick={() =>{
                                    onClickToOpenModal();
                                    setDeleteId(res.id);
                                }}>Cancel Offer</a>}
                            </td>
                        </tr>
                        {res.messages && <tr>
                            <td colSpan={8}>
                                {onRenderMessage(res.messages)}        
                            </td>
                        </tr>}
                        </React.Fragment>))}
                    </tbody>
                </Table>
                {state.isNegotiationAccepted && <Row>
                    <Col md={8} className='mb-12'>
                        <Controller
                            name="message"
                            control={control1}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input type="text" 
                                        name="message" 
                                        value={value} 
                                        placeholder="Message"
                                        onChange={(e) => {
                                            onChange(e)
                                        }} 
                                        className="cm_input"
                                        required />
                                    {errors1.message && <ErrorLabel
                                        msg={"Message is required."}
                                    />}
                                </>
                            )}
                        />
                    </Col>
                    <Col md={2} className='mb-12'>
                        <Button variant="green" className='w-275px' onClick={handleSubmit1(onClickToSendMessage)}>
                            Send
                        </Button>
                    </Col>
                </Row>}
            </Modal.Body>
        </Modal>
        <Modal show={showDeleteModal} onHide={onClickToCloseModal}>
            <Modal.Header>
                <Modal.Title>Delete Bookmarks</Modal.Title>
            </Modal.Header>
                <Modal.Body>Are you sure?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClickToCloseModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={onClickToCancelOffer}>
                    Okay
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}