import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Label } from '../Typography';
import Joi from 'joi'
import { Link } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../../Utils/AppUtill';
import ErrorLabel from '../../widgets/ErrorLable';
import { nftGetContractDetails } from '../../action/article.action';
import toast from '../../helpers/toast';
import { GlobalContext } from '../../context/globalContext'
export const Step5 = ({ onSubmitArticle, onChangeState, state }) => {
    const { isLoggedIn, setSignIn, userData } = useContext(GlobalContext);
    const [stateStep5, setStateStep5] = useState({});
    const [stateD, setState] = useState({ isContract: false });
    const [smartContractPayment, setSmartContractPayment] = useState(true)
    const [ connectYourWalletToEnable, setConnectYourWalletToEnable ] = useState(false);

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        getValues
    } = useForm()

    const {
        handleSubmit: nftHandleSubmit,
        control: nftControl,
        reset: nftReset,
        getValues: nftGetValues
    } = useForm({
        resolver: joiResolver(
            Joi.object({
                contract: Joi.string()
                    .required()
                    .label('Contract')
                    .messages(joiUpdatedMessage),
                token: Joi.string().allow(null, '')
            }),
        ),
    })

    useEffect(() => {
        const obj = {
            'starting_fee': state.starting_fee ? state.starting_fee : 0,
            'i1000': state.i1000 ? state.i1000 : 0,
            'i10000': state.i10000 ? state.i10000 : 0
        }
        if(userData && (userData.coinbase_wallet_address || userData.user_wallet_address)) {
            obj['smartContractPayment'] = true;
        } else {
            obj['smartContractPayment'] = false;
        }

        onChangeState(obj);

        if (!['', null, undefined].includes(state.contract)) {
            setState({
                isContract: true,
                smartContractPayment: state.smartContractPayment,
                contract: state.contract,
                token: state.token ? state.token : '',
                banner_image_url: state.banner_image_url,
                description: state.nft_description,
                external_link: state.external_link
            })
            nftReset({
                contract: state.contract,
                token: state.token ? state.token : ''
            })
        }
        reset({
            'starting_fee': state.starting_fee ? state.starting_fee : 0,
            'i1000': state.i1000 ? state.i1000 : 0,
            'i10000': state.i10000 ? state.i10000 : 0
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(userData && (userData.coinbase_wallet_address || userData.user_wallet_address)) {
            setSmartContractPayment(true);
            setConnectYourWalletToEnable(false);
        } else {
            setSmartContractPayment(false);
            setConnectYourWalletToEnable(true);
        }
    }, [userData])

    const onChangeInput = (e) => {
        setStateStep5({ ...stateStep5, [e.target.name]: e.target.value });
        onChangeState({ [e.target.name]: e.target.value });
    }

    const onClickToSubmit = (values) => {
        if(isLoggedIn) {
            if (!stateD.isContract) {
                onChangeState({ banner_image_url: '', nft_description: '', contract: '', token: '', external_link: '' });
            }
            onSubmitArticle();
        } else {
            setSignIn(true);
        }
        
    }

    const onClickToSubmitNFT = (values) => {
        if (stateD.isContract) {
            if(isLoggedIn) {
                onSubmitArticle();
            } else {
                setSignIn(true);
            }
        } else {
            toast.error("Your contract address is wrong.")
        }
    }

    const onSetValue = (values) => {
        onChangeState(values);
    }

    const onChangeContract = async () => {
        const { contract, token } = nftGetValues();
        
        if (contract) {
            const { data } = await nftGetContractDetails(contract, token);
            if (data.status === 200) {
                const obj = { external_link: data.data.url, banner_image_url: data.data.image, nft_description: data.data.description, contract, token };
                onSetValue(obj);
                setState({ isContract: true, external_link: data.data.url, banner_image_url: data.data.image, description: data.data.description })
            } else {
                const obj = { external_link: '', banner_image_url: '', nft_description: '', contract: '', token: '' };
                onSetValue(obj);
                setState({ isContract: false, banner_image_url: "", description: "", external_link: '' })
            }
        } else {
            const obj = { external_link: '', banner_image_url: '', nft_description: '', contract: '', token: '' };
            setState({ isContract: false, banner_image_url: "", description: "", external_link: '' })
            onSetValue(obj);
        }
    }

    return (<>
        <div className='text-green fs-md-22px fs-18px mb-3'>Listing fee: $5</div>
            <div className='mb-3'>
                <Label text="Starting Fee" />
                <Controller
                    name="starting_fee"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <>
                            <input type="text"
                                name="starting_fee"
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    onChangeInput(e)
                                }}
                                className="cm_input"
                                required />
                            {errors.starting_fee && <ErrorLabel
                                msg={"Starting Fee is required."}
                            />}
                        </>
                    )}
                />
            </div>
            <div className='mb-3'>
                <Label text="1,000 impressions" />
                <Controller
                    name="i1000"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <>
                            <input type="text"
                                name="i1000"
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    onChangeInput(e)
                                }}
                                className="cm_input"
                                required
                            />
                            {errors.i1000 && <ErrorLabel
                                msg={"i1000 is required."}
                            />}
                        </>
                    )}
                />
            </div>
            <div className='mb-3'>
                <Label text="10,000 impressions" />
                <Controller
                    name="i10000"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <>
                            <input type="text"
                                name="i10000"
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    onChangeInput(e)
                                }}
                                className="cm_input"
                                required
                            />
                            {errors.i10000 && <ErrorLabel
                                msg={"i10000 is required."}
                            />}
                        </>
                    )}
                />
                <Label text={`Effective price per 1,000 views: $${(!isNaN(parseFloat(getValues('i10000')))?getValues('i10000'):0)/10}`} />
            </div>
            <div className="mb-3">
                <div className='text-green fs-md-22px fs-18px '>NFT Gating</div>
                <div className='text-green'>Require users to own an NFT to read the article.</div>    
            </div>
            <div className='mb-3'>
                <Label text="Contract" />
                <Controller
                    name="contract"
                    control={nftControl}
                    render={({ field: { value, onChange } }) => (
                        <>
                            <input type="text"
                                name="contract"
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    onChangeContract();
                                    // onChangeInput(e)
                                }}
                                className="cm_input"
                            />
                        </>
                    )}
                />
            </div>
            <div className='mb-3'>
                <Label text="Token" />
                <Controller
                    name="token"
                    control={nftControl}
                    render={({ field: { value, onChange } }) => (
                        <>
                            <input type="text"
                                name="token"
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    onChangeContract();
                                    // onChangeInput(e)
                                }}
                                className="cm_input"
                            />
                        </>
                    )}
                />
            </div>
            <div className='mb-3'>
                <Label text="Smart contract payments" />
                <label class="switch">
                    <input type="checkbox" checked={smartContractPayment} disabled={connectYourWalletToEnable} onChange={() => {
                        setSmartContractPayment(!smartContractPayment)
                        onSetValue({smartContractPayment: !smartContractPayment})
                    }}/>
                    <span class="switch-slider switch-round"></span>
                </label>        
            </div>
            {connectYourWalletToEnable && <div className='mb-3'>
                <Link to="/user/myaccount?tab=account">Connect your wallet to enable</Link>        
            </div>}
            {/* setConnectYourWalletToEnable(false); */}
            {stateD.isContract && <>
                <div className='mb-3 text-center'>
                    <a href={stateD.external_link} rel="noreferrer" target="_blank"><img alt="" src={stateD.banner_image_url} style={{maxHeight: '300px', width: 'auto'}}/></a>
                </div>
                <div className='mb-3'>
                    {stateD.description}
                </div>
            </>}
            {!stateD.isContract && ['', undefined, null].includes(nftGetValues('contract')) && <Button variant='green' className='mt-3 me-2' onClick={handleSubmit(onClickToSubmit)}>Submit →</Button>}
            {(stateD.isContract || (!stateD.isContract && nftGetValues('contract'))) && <Button variant='green' className='mt-3 me-2' onClick={nftHandleSubmit(onClickToSubmitNFT)}>Submit →</Button>}
    </>)
}