const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    // The following is a workaround for a Chromium bug where,
    // if you have an inline at the end of a block,
    // clicking the end of a block puts the cursor inside the inline
    // instead of inside the final {text: ''} node
    // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
    if (leaf.text === '')
        return <span style={{ paddingLeft: '0.1px' }} {...attributes}>{children}</span>
    else
        return <span {...attributes}>{children}</span>
}

export default Leaf;