import axios from '../helpers/axios';
import { APIEndpoints } from './endpoint'

export function imageSearch(data) {
    return axios.post(`${APIEndpoints.imageSearch}`, data);
}

export function flickrImageInfo(photo_id, user_id) {
    return axios.get(`${APIEndpoints.imageInfo}?photo_id=` + photo_id + "&user_id=" + user_id);
}
