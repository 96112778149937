import React, { useState, useEffect } from 'react'
import { Col, Container, Button } from 'react-bootstrap'
import { Label } from '../components/Typography'
import { useHistory } from 'react-router-dom'
import { withdraw } from '../action/billing.action'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers/joi'
import { joiUpdatedMessage } from '../Utils/AppUtill';
import ErrorLabel from '../widgets/ErrorLable';
import Joi from 'joi'
import toast from "../helpers/toast"

const Withdraw = () => {

    const history = useHistory();
    const [state, setState] = useState({});

    const onWithdraw = async () => {
        const { data } = await withdraw({});
        if(data.status === 200) {
            setState({...state, ...data.data});
        }
    }
    
    useEffect(() => {
        onWithdraw()
        // eslint-disable-next-line
    }, [history.location])
    
    const {
        handleSubmit,
        formState: {errors},
        control,
        reset,
    } = useForm({
        resolver: joiResolver(
          Joi.object({
            amount: Joi.string()
              .required()
              .label('amount')
              .messages(joiUpdatedMessage),
            paypal: Joi.string()
              .required()
              .label('paypal')
              .messages(joiUpdatedMessage),
          }),
        ),
    })

    const onClickToSubmit = async (values) => {
        const { amount, paypal } = values
        const formData = new FormData();
        formData.append('amount', amount)
        formData.append('paypal', paypal)
        const { data } = await withdraw(formData);
        if(data.status === 200) {
            toast.success(data.data.message)
            reset({amount: '', paypal: ''});
        }
    }

    return (<>
        <div className='text-green text-center fs-md-26px fs-20px pt-5'>Withdraw</div>
        <Container className='py-5'>
            <div className='CM_whiteBox'>
                <div className='cm_boxTitle text-green'>Withdraw funds</div>
                <form className='row' onSubmit={handleSubmit(onClickToSubmit)}>
                    <Col md={12} className='mb-3'>
                        <Label text={`Amount* (you have $${state.funds} in your account)`} />
                        <Controller
                            name="amount"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        value={value}
                                        onChange={onChange} 
                                        className="cm_input" 
                                    />
                                    {errors.amount && <ErrorLabel
                                        msg={"Amount is required."}
                                    />}
                                </>
                            )}
                        />
                    </Col>
                    <Col md={12} className='mb-3'>
                        <Label text="Paypal*" />
                        <Controller
                            name="paypal"
                            control={control}
                            render={({field: {value, onChange}}) => (
                                <>
                                    <input 
                                        type="text" 
                                        value={value}
                                        onChange={onChange} 
                                        className="cm_input" 
                                    />
                                    {errors.amount && <ErrorLabel
                                        msg={"Paypal is required."}
                                    />}
                                </>
                            )}
                        />
                    </Col>
                    <Col md={12} className='mb-3'>
                        <Button variant='green' onClick={handleSubmit(onClickToSubmit)}>Submit</Button>
                    </Col>
                </form>
            </div>
        </Container>
    </>)
}

export default Withdraw