import React, {useState, useEffect} from 'react'
import { Button, Container, Table, Modal } from 'react-bootstrap'
import { PageTitle } from '../components/Typography'
import { useHistory } from 'react-router-dom'
import { articleCopyhistory, articleCheckCopy } from '../action/article.action'


const CopyrightPage = () => {
    const history = useHistory();
    const [state, setState] = useState({});
    const [show, setShow] = useState(false);    
    
    const onRetriveCopyright = async () => {
        const {data} = await articleCopyhistory(history.location.search);
        if(data.status === 200) {
            setState(data.data); 
        }
    }

    const onClickToOpenModal = () => setShow(true)
    const onClickToCloseModal = () => setShow(false)
    
    useEffect(() => {
        onRetriveCopyright()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    
    const onClickToCheckCopy = async () => {
        const data = await articleCheckCopy(history.location.search);
        if(data.status === 200) {
            onRetriveCopyright();
            onClickToCloseModal()
        }
    }
    
    const onClickToSend = (e) => {
        
    }
    
    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Copied Article" />
        <Container className='py-5'>
            <div className='CM_whiteBox position-relative pt-5'>
                <Button variant='green' onClick={() => history.push(state.public_url)} className='topLeft'>
                    Go to article page
                </Button>
                {state.funds >= 5 && 
                state.request_total_count===0 && 
                    <Button variant='green' onClick={onClickToOpenModal} className='topLeft' style={{left: '13%'}} >
                        Check your copyright in internet
                    </Button>}
                    <Table bordered hover responsive className='cm_table mt-5'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>View all copyright Articles</th>
                                <th>Send Mass takedown notification mail</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.all_request && state.all_request.map((r, indx) => {
                                return <tr key={indx + 1}>
                                    <td>{r.id}</td>
                                    <td>{r.created}</td>
                                    <td>
                                        {r.output!=='' && <>{`${r.output}`}<br />{`Total no of disapprove article(s):${Object.keys(r.disapprove_articles_total).length > 0?r.disapprove_articles_total:""}`}</>}
                                        {r.output==='' && <>Pending</>}
                                    </td>
                                    <td>
                                        {parseInt(r.status) > 0 && parseInt(r.count) > 0 && <a href="#/" onClick={() => {
                                            history.push(`/copyright/viewcopy?id=${r.id}`)
                                        }}>View</a>}
                                    </td>
                                    <td>
                                        {parseInt(r.status) > 0 && r.count_of_disapprove_total > 0 && <>
                                            {r.is_updated && <>{r.updated}</>}
                                            {!r.is_updated && <>
                                                <a href="#/" onClick={onClickToSend}>Send</a>
                                            </>}
                                        </>}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </div>
            <Modal show={show} onHide={onClickToCloseModal}>
                <Modal.Body>$5 will be deducted from your account!</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={onClickToCloseModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={onClickToCheckCopy}>
                    Okay
                </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    </>)
}

export default CopyrightPage