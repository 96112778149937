import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { PageTitle } from '../components/Typography'
import { DnDComponent } from '../components/DnDComponent';
import { viewCopy, dragDropCopyright, deleteCopyright, toTakedown } from '../action/article.action';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

const ViewCopyPage = () => {
    const history = useHistory();
    const [state, setState] = useState({});

    const onRenderViewCopy = async () => {
        const {data} = await viewCopy(history.location.search);
        if(data.status === 200) {
            setState(data.data);
        }
    }

    const onDragAndDrop = async (obj) => {
        const { id } = queryString.parse(history.location.search)
        obj['request_id'] = id;
        const formData =  new FormData();
        Object.keys(obj).map(rs => formData.append(rs, obj[rs]))

        await dragDropCopyright(formData);
        // dragDropCopyright
    }

    const onClicToDeleteViewCopy = async (copyrightId) => {
        const { id } = queryString.parse(history.location.search)
        const obj = {request_id: id, id: copyrightId};
        const {data} = await deleteCopyright(queryString.stringify(obj));
        if(data.status === 200) {
            onRenderViewCopy();
        }
    }
    
    const onClicToTakeDown = async (copyrightId) => {
        const obj = {id: copyrightId};

        await toTakedown(queryString.stringify(obj));
        
    }

    useEffect(() => {
        onRenderViewCopy();
        // eslint-disable-next-line
    },[])

    return (<>
        <PageTitle style={{ backgroundImage: `url(https://contentmutual.com/assets/front/img/how-it-works.jpg)` }} text="Copied Article" />
        <Container className='py-5'>
            <div className='CM_whiteBox position-relative pt-5'>
                <div className='row mx-0'>
                    {Object.keys(state).length > 0 && <DnDComponent data={state} onClicToTakeDown={onClicToTakeDown} onDragAndDrop={onDragAndDrop} deleteCopyRight={onClicToDeleteViewCopy}/>}
                    {/* <div id="left_list" className="col-md-6">
                        <h3>Disapprove Article-&gt;Drop here from right list to disapprove</h3>
                        <ul className='drugContaner'>
                                
                        </ul>
                    </div>
                    <div id="right_list" className="col-md-6">
                        <h3>Approve Article -&gt; Drop here from left list to approve</h3>
                        <ul className='drugContaner'>
                            
                        </ul>
                    </div> */}
                </div>
            </div>
        </Container>
    </>)
}

export default ViewCopyPage

export const DrugItem = props => {
    const dnd = props.dnd
    return (
        <li
            style={{ ...dnd.item.styles, ...dnd.handler.styles }}
            className={dnd.item.classes}
            ref={dnd.item.ref}
            {...dnd.handler.listeners}
        >
            {props.item}
        </li>
    )
}