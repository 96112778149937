import React, {useState} from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
export const DnDComponent = ({data, onDragAndDrop, deleteCopyRight, onClicToTakeDown}) => {

  const columnsFromBackend = {
        [1]: {
          name: "Disapprove Article -> Drop here from right list to disapprove",
          items: data.disapproveArticles?data.disapproveArticles:[]
        },
        [2]: {
          name: "Approve Article -> Drop here from left list to approve",
          items: data.approveArticles?data.approveArticles:[]
        }
    };

    const [taskColumns, setTaskColumns] = useState(columnsFromBackend);
    
    const onDragEnd = (result, taskColumns, setTaskColumns) => {  
      if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
          const sourceColumn = taskColumns[source.droppableId];
          const destColumn = taskColumns[destination.droppableId];
          const sourceItems = [...sourceColumn.items];
          const destItems = [...destColumn.items];
          const [removed] = sourceItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, removed);
          setTaskColumns({
            ...taskColumns,
            [source.droppableId]: {
              ...sourceColumn,
              items: sourceItems
            },
            [destination.droppableId]: {
              ...destColumn,
              items: destItems
            }
          });
          const obj = {};
          if(result.source && result.source.droppableId === "2") {
            obj.disapproveList = `copy_${result.draggableId}`
            obj.status = 0;
          } else if(result.source && result.source.droppableId === "1") {
            obj.approveList = `copy_${result.draggableId}`
            obj.status = 1;
          }
          obj.current_li_id = `copy_${result.draggableId}`;
          onDragAndDrop(obj);
        } else {
          const column = taskColumns[source.droppableId];
          const copiedItems = [...column.items];
          const [removed] = copiedItems.splice(source.index, 1);
          copiedItems.splice(destination.index, 0, removed);
          setTaskColumns({
            ...taskColumns,
            [source.droppableId]: {
              ...column,
              items: copiedItems
            }
          });
        }
    };

    return (
        <DragDropContext
            onDragEnd={result => onDragEnd(result, taskColumns, setTaskColumns)}
        >
            {Object.entries(taskColumns).map(([columnId, column], index) => {
            
            return (<div key={index + 1} id={index === 0?"left_list":'right_list'} className="col-md-6">
                        <h3>{column.name}</h3>
                        <ul className='drugContaner'>
                        <Droppable droppableId={columnId} key={columnId}>
                          {(provided, snapshot) => {
                              return (
                              <div className="dropbox"
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                  background: snapshot.isDraggingOver
                                      ? "#eee"
                                      : "#ddd"
                                  
                                  }}
                              >
                              {column.items.map((item, index) => {
                                  return (
                                      <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}
                                      >
                                      {(provided, snapshot) => {
                                          return (
                                          <div className="dragbox"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <div className='copyList' id="copy_3916" style={{position: "relative", left: '0px', top: '0px'}}>
                                                  <a target="_blank" rel="noreferrer" href={item.url}>{item.title}</a> <br />
                                                  <a target="_blank" rel="noreferrer" href={item.url}>{item.url}</a> <br />
                                                  [minimimum words matched: {item.minwordsmatched}] &nbsp; {item.created} &nbsp;
                                                  &nbsp;<i style={{cursor: 'pointer'}} onClick={() => deleteCopyRight(item.id)} className="fa fa-remove"></i>&nbsp;&nbsp; <a style={{cursor: 'pointer', color:'#F00'}} onClick={() => onClicToTakeDown(item.id)}>Take down status</a>
                                            </div>
                                              {/* <div className="list-group-item ui-sortable-handle" id="copy_3916" style="position: relative;left: 0px;top: 0px;">
                                              <a target="_blank" href="https://www.foodstems.com/blog/blockchain-technology-food-supply-chain-revolution-consumers/">Blockchain Technology in the Food Supply Chain: A Revolution for ...</a> <br />
                                                  <a target="_blank" href="https://www.foodstems.com/blog/blockchain-technology-food-supply-chain-revolution-consumers/">https://www.foodstems.com/blog/blockchain-technology-food-supply-chain-revolution-consumers/</a> <br />
                                                  
                                                  [minimimum words matched: 449] &nbsp; 3 years ago &nbsp; 
                                                  &nbsp;<i className="fa fa-remove"></i>&nbsp;&nbsp; <a style={{color:'#F00'}} >Take down status</a>
                                                </div> */}
                                              {/* {item.content} */}
                                          </div>
                                          );
                                      }}
                                      </Draggable>
                                  );
                                  })}
                                  {provided.placeholder}
                              </div>
                              );
                          }}
                          </Droppable>      
                        </ul>
                    </div>                   
            );
            })}
        </DragDropContext>
    );
}
